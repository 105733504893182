/**
 * Created by r4zi4l on 04.07.2022
 */

var OrderDetails = cc.Scale9Sprite.extend({
    ctor: function (orders) {
        var styles = cleverapps.styles.OrderDetails;
        var background = bundles.orderswindow.frames.orders_background;

        this._super(background, cleverapps.UI.getScale9Rect(background, cleverapps.UI.Scale9Rect.TwoPixelXY));

        this.orders = orders;
        this.maker = orders.getCurrentMaker();

        this.setContentSize2(styles);

        if (bundles.windows.frames.details_rings) {
            styles.rings.forEach(function (style) {
                var ring = new cc.Sprite(bundles.windows.frames.details_rings);
                ring.setPositionRound(style);
                this.addChild(ring);
            }, this);
        }

        var required = cleverapps.UI.generateOnlyText("OrderDetails.required", cleverapps.styles.FONTS.HERODETAILS_TEXT);
        required.setPositionRound(styles.required);
        this.addChild(required);

        this.createHeroIcon();
        this.createIngredients();
        this.createButton();

        this.orders.on("makerSelected", this.updateMaker.bind(this), this);
        this.orders.on("orderStateChanged", this.updateOrder.bind(this), this);
        this.orders.on("orderStateChanged", this.updateIngredients.bind(this), this);
    },

    createHeroIcon: function () {
        var styles = cleverapps.styles.OrderDetails.heroIcon;

        var unit = this.maker && this.maker.unit || { code: "dwarf", stage: 1 };

        var heroIcon = this.heroIcon = new HeroIcon(unit);
        heroIcon.setOpened(true);
        heroIcon.showSelection();
        heroIcon.setPositionRound(styles);
        heroIcon.setScale(styles.scale);
        this.addChild(heroIcon);
    },

    createButton: function () {
        var styles = cleverapps.styles.OrderDetails.button;

        var maker = this.maker || {};

        var Button = cleverapps.UI.Button;
        var buttonOptions = {
            width: styles.width,
            height: styles.height,
            onClicked: this.orders.processOrder.bind(this.orders, this.maker)
        };

        switch (maker.getState()) {
            case Customer.STATE_RECIPE:
                buttonOptions.text = maker.canCook() ? "OrdersWindow.button.make" : "OrdersWindow.button.buyingredients";
                break;

            case Customer.STATE_READY:
                buttonOptions.text = "Claim";
                break;

            case Customer.STATE_COOKING:
                Button = UseGoldButton;

                buttonOptions.canCoins = true;
                buttonOptions.price = SpeedUpWindow.CalcPrice(maker.getCookingTimeLeft());
                buttonOptions.freeIcon = TextWithIcon.ICONS_BY_NAME.speedup;
                buttonOptions.eventName = cleverapps.EVENTS.SPENT.ORDER;
                buttonOptions.speedUp = true;
                buttonOptions.type = cleverapps.styles.UI.Button.Images.button_blue;

                if (ConfirmSpeedUpWindow.isAvailable(buttonOptions.price)) {
                    buttonOptions.confirmWindowOptions = {
                        timeLeft: maker.getCookingTimeLeft(),
                        totalDuration: maker.recipe.cookingDuration
                    };
                }
                break;

            default:
                buttonOptions.disabled = true;
                buttonOptions.text = "OrdersWindow.button.make";
        }

        var button = this.button = new Button(buttonOptions);
        button.setPositionRound(styles);
        this.addChild(button);

        if (Merge.currentMerge.tutorial.isActive() || maker.getState() === Customer.STATE_READY) {
            button.runAction(new cc.Sequence(
                new cc.DelayTime(Merge.currentMerge.tutorial.isActive() ? 0.3 : 2.5),
                new cc.CallFunc(function () {
                    FingerView.hintClick(button, {
                        delay: 2.5,
                        repeatDelay: 2.5
                    });
                })
            ));
        }
    },

    createIngredients: function () {
        if (!this.maker) {
            return;
        }

        var styles = cleverapps.styles.OrderDetails.ingredients;
        var icons = this.maker.recipe.ingredients.concat([undefined, undefined, undefined]).slice(0, 3).map(function (ingredient) {
            return new IngredientOrderIcon(ingredient, this.maker);
        }, this);

        var ingredientsBackgrounds = icons.map(function (icon) {
            var ingredientsBackground = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ingredients_background, cleverapps.UI.Scale9Rect.TwoPixelXY);
            ingredientsBackground.setContentSize2(icon.width, icon.height);
            return ingredientsBackground;
        });

        var ingredientsBackgroundsLayout = this.ingredientsBackgrounds = new cleverapps.Layout(ingredientsBackgrounds, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        ingredientsBackgroundsLayout.setPositionRound(styles);
        this.addChild(ingredientsBackgroundsLayout);

        var ingredients = this.ingredients = new cleverapps.Layout(icons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        ingredients.setPositionRound(styles);
        this.addChild(ingredients);
    },

    changeHeroIcon: function () {
        if (this.heroIcon) {
            this.heroIcon.removeFromParent();
        }
        this.createHeroIcon();
    },

    changeButton: function () {
        this.button.removeFromParent();
        this.createButton(this.maker);
    },

    changeIngredients: function () {
        if (this.ingredients) {
            this.ingredients.removeFromParent();
            this.ingredients = undefined;

            this.ingredientsBackgrounds.removeFromParent();
            this.ingredientsBackgrounds = undefined;
        }

        this.createIngredients();
    },

    updateMaker: function (maker) {
        if (maker === this.maker) {
            return;
        }
        this.maker = maker;

        this.changeHeroIcon();
        this.changeButton();
        this.changeIngredients();
    },

    updateOrder: function (maker) {
        if (this.maker === maker) {
            this.changeButton();
        }
    },

    updateIngredients: function () {
        var canCook = this.maker && this.maker.canCook();
        if (this.canCook !== canCook) {
            this.canCook = canCook;
            this.changeButton();
        }

        if (this.maker && this.maker.getState() === Customer.STATE_RECIPE) {
            this.ingredients.children.forEach(function (ingredient) {
                ingredient.updateAmount();
            });
        }
    },

    removeAnimated: function (node) {
        node.children.forEach(function (child) {
            if (child.beforeRemoveAnimated) {
                child.beforeRemoveAnimated();
            }
        });

        node.setCascadeOpacityEnabledRecursively(true);
        node.runAction(new cc.Spawn(
            new cc.FadeOut(0.4),
            new cc.Sequence(
                new cc.ScaleTo(0.3, 0.5).easing(cc.easeIn(2)),
                new cc.RemoveSelf()
            )
        ));
    },

    showAnimated: function (node) {
        node.setCascadeOpacityEnabledRecursively(true);
        node.setScale(0.8);
        node.setOpacity(0.3);

        node.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.Spawn(
                new cc.FadeIn(0.4),
                new cc.ScaleTo(0.3, 1)
            )
        ));
    }
});

cleverapps.styles.OrderDetails = {
    width: 660,
    height: 820,

    rings: [{
        x: { align: "left", dx: 90 },
        y: { align: "top", dy: -10 }
    }, {
        x: { align: "right", dx: -90 },
        y: { align: "top", dy: -10 }
    }],

    heroIcon: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 200 },
        scale: 0.9
    },

    required: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    ingredients: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -130 },
        margin: 28
    },

    button: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -295 },
        width: 300,
        height: 110
    }
};
