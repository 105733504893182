/**
 * Created by andrey on 11.02.2021.
 */

var QuestsGroupIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.quests_icon_json,
        control: "QuestsGroupIcon"
    });

    this.activeInfoView = undefined;
    this.canShowInfoView = true;

    cc.eventManager.addListener(cc.EventListener.create({
        event: cc.EventListener.TOUCH_ONE_BY_ONE,
        onTouchBegan: this.handleClickOutside.bind(this)
    }), -1);

    if ("mouse" in cc.sys.capabilities) {
        cc.eventManager.addListener(cc.EventListener.create({
            event: cc.EventListener.MOUSE,
            onMouseScroll: this.handleClickOutside.bind(this)
        }), -1);
    }

    this.onGetIconViewListener = function () {};
};

QuestsGroupIcon.prototype = Object.create(SideBarIcon.prototype);
QuestsGroupIcon.prototype.constructor = QuestsGroupIcon;

QuestsGroupIcon.prototype.resetState = function () {
    this.available = Merge.currentMerge && Merge.currentMerge.quests && Merge.currentMerge.quests.config && !cleverapps.config.editorMode && cleverapps.meta.selectedLocationId() !== "collections";

    if (this.available) {
        Merge.currentMerge.sidebarQuests.on("showInfo", function (quest, options) {
            options = options || {
                duration: 0.5,
                delay: 0.2
            };

            if (options.canShowInfoView) {
                this.allowInfoView();
            }

            var addedIcon = this.onGetIconViewListener(quest);
            if (addedIcon) {
                this.showInfo(addedIcon, options);
            }
        }.bind(this), this);

        Merge.currentMerge.sidebarQuests.on("hideInfo", this.hideInfo.bind(this), this);
    }
};

QuestsGroupIcon.prototype.onPressed = function (iconView) {
    if (!this.activeInfoView || this.activeInfoView.questIcon !== iconView) {
        this.showInfo(iconView, {});
    }
};

QuestsGroupIcon.prototype.allowInfoView = function () {
    this.canShowInfoView = true;
};

QuestsGroupIcon.prototype.denyInfoView = function () {
    this.canShowInfoView = false;
    this.hideInfo();
};

QuestsGroupIcon.prototype.hideInfo = function () {
    if (this.activeInfoView && !this.activeInfoView.isCompletion()) {
        this.activeInfoView.hide();
        delete this.activeInfoView;
    }
};

QuestsGroupIcon.prototype.showInfo = function (iconView, options) {
    this.hideInfo();

    if (!this.canShowInfoView) {
        return;
    }

    var questInfoView = new QuestInfoView(iconView, options);

    cleverapps.scenes.getRunningScene().movingNode.addChild(questInfoView);
    addCleaner(questInfoView, function () {
        if (this.activeInfoView === questInfoView) {
            delete this.activeInfoView;
        }
    }.bind(this));

    this.activeInfoView = questInfoView;
};

QuestsGroupIcon.prototype.getForce = function () {
};

QuestsGroupIcon.prototype.getView = function () {
    return QuestsGroupIconView;
};

QuestsGroupIcon.prototype.countOfSlots = function () {
    return 3;
};

QuestsGroupIcon.prototype.handleClickOutside = cleverapps.accumulate(0, function (touch) {
    if (this.activeInfoView && !this.activeInfoView.isCompletion()) {
        var isClickToInfoView = cc.rectContainsPoint(cc.rect(0, 0, this.activeInfoView.width, this.activeInfoView.height), this.activeInfoView.convertTouchToNodeSpace(touch));

        var icon = this.activeInfoView.questIcon;
        var isClickToIcon = cc.rectContainsPoint(cc.rect(0, 0, icon.width, icon.height), icon.convertTouchToNodeSpace(touch));

        if (!isClickToInfoView && !isClickToIcon) {
            this.activeInfoView.hide();
            delete this.activeInfoView;
        }
    }
});
