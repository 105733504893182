/**
 * Created by vladislav on 09.08.2022
 */

var TravelBook = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.TRAVEL_BOOK) || {};

    this.lastForce = data.force || 0;

    this.pages = [new ExpeditionPage("main")];
};

TravelBook.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.TRAVEL_BOOK, {
        force: this.lastForce
    });
};

TravelBook.prototype.init = function () {
    if (cleverapps.config.lives || cleverapps.config.energy) {
        Meta.SLOTS.forEach(function (slot) {
            LivesHelper.GetInstance(slot).onFullListener = this.onLivesFull.bind(this, slot);
        }, this);
    }

    var pages = cleverapps.meta.listAvailableLocations().filter(function (locationId) {
        return locationId !== "main";
    }).map(function (locationId) {
        return new ExpeditionPage(locationId);
    });

    var main = this.pages.find(function (page) {
        return page.isMain();
    });

    this.pages = [main].concat(pages);

    this.updatePages();
};

TravelBook.prototype.listAllPages = function () {
    return this.pages;
};

TravelBook.prototype.listDisplayedPages = function () {
    var getOrder = function (page) {
        if (page.isPermanent()) {
            return 100;
        }
        return page.isSeasonal() ? 10 : 1;
    };

    var expeditions = this.pages.filter(function (page) {
        return !page.isMain() && page.isActive();
    }).sort(function (a, b) {
        return getOrder(b) - getOrder(a);
    });

    var main = this.pages.find(function (page) {
        return page.isMain();
    });
    var pages = [main].concat(expeditions);

    if (pages.length < TravelBook.DISPLAYED_PAGES) {
        pages = pages.concat(this.listUpcoming().slice(0, TravelBook.DISPLAYED_PAGES - pages.length));
    }

    return pages;
};

TravelBook.prototype.onLivesFull = function (slot) {
    this.pages.filter(function (page) {
        return page.slot === slot;
    }).forEach(function (page) {
        page.planEvent(ExpeditionPage.EVENT_ENERGY, Date.now());
        page.setAttentionEvent(ExpeditionPage.EVENT_ENERGY);
    });
};

TravelBook.prototype.getPageById = function (id) {
    return this.pages.find(function (page) {
        return page.id === id;
    });
};

TravelBook.prototype.getCurrentPage = function () {
    var location = cleverapps.meta.getSelectedLocation();
    return this.getPageById(location.locationId);
};

TravelBook.prototype.listAvailablePages = function () {
    return this.pages.filter(function (page) {
        return !page.available || cleverapps.user.checkAvailable(page.available);
    });
};

TravelBook.prototype.isExpedition = function () {
    return !this.getCurrentPage().isMain();
};

TravelBook.prototype.updatePages = function () {
    this.pages.forEach(function (page) {
        page.updateState();
    });
};

TravelBook.prototype.onUpdateExpedition = function () {
    this.updateBuilt();
    this.getCurrentPage().setNew(false);
};

TravelBook.prototype.onNewLocationStarted = function (locationId) {
    this.startNewExpedition(this.getPageById(locationId));
};

TravelBook.prototype.startNewExpedition = function (newPage) {
    this.pages.filter(function (page) {
        return page.slot === newPage.slot;
    }).forEach(function (page) {
        page.resetEvents();
    });

    newPage.setNew(true);
    newPage.setAttentionEvent(ExpeditionPage.EVENT_START);

    cleverapps.forces.clearForce(Forces.TRAVEL_BOOK.id);
};

TravelBook.prototype.updateBuilt = function () {
    var page = this.getCurrentPage();

    var worker = Map2d.currentMap.workers.findLeastBusy(Buildable);
    if (worker) {
        var timeReady = Date.now() + worker.getTimeLeft();

        page.planEvent(ExpeditionPage.EVENT_BUILT, timeReady);
        page.setAttentionEvent(ExpeditionPage.EVENT_BUILT);
    } else {
        page.planEvent(ExpeditionPage.EVENT_BUILT, 0);
    }
};

TravelBook.prototype.listUpcoming = function () {
    var activeSlots = this.pages.filter(function (page) {
        return page.isActive();
    }).map(function (page) {
        return page.slot;
    });

    var permanent = this.pages.find(function (page) {
        return page.isUpcoming() && !activeSlots.includes(page.slot) && page.isPermanent();
    });
    var seasonal = this.pages.find(function (page) {
        return page.isUpcoming() && !activeSlots.includes(page.slot) && page.isSeasonal();
    });
    var regular = this.pages.find(function (page) {
        return page.isUpcoming() && !activeSlots.includes(page.slot) && !page.isSeasonal() && !page.isPermanent();
    });

    return [permanent, regular, seasonal].filter(Boolean);
};

TravelBook.prototype.needsAttention = function () {
    return this.pages.some(function (page) {
        return page.attention;
    });
};

TravelBook.prototype.markForceShown = function () {
    this.lastForce = Date.now();
    this.save();
};

TravelBook.prototype.chooseForce = function () {
    var pages = this.listDisplayedPages().filter(function (page) {
        return page.isActive() && !page.isMain();
    }).sort(function (page1, page2) {
        return page2.getStartTime() - page1.getStartTime();
    });

    if (!pages.length) {
        return;
    }

    var selectedForce;
    var selectedPage = pages.find(function (page) {
        return page.isNew;
    });
    if (selectedPage && !cleverapps.forces.isShown(Forces.TRAVEL_BOOK.id)) {
        selectedForce = cleverapps.clone(Forces.TRAVEL_BOOK, true);
        selectedForce.text = selectedPage.forceText;
        selectedForce.page = selectedPage.id;
        return selectedForce;
    }

    if (this.lastForce + TravelBook.FORCE_TIMEOUT > Date.now()) {
        return;
    }

    selectedPage = pages.find(function (page) {
        return !page.isPermanent();
    });
    if (selectedPage && selectedPage.isCompleted()) {
        selectedForce = cleverapps.clone(Forces.TRAVEL_BOOK_HINT, true);
        selectedForce.text = Messages.get("ExpeditionProgressHint.completed", { name: selectedPage.title });
        selectedForce.page = selectedPage.id;
        return selectedForce;
    }

    if (selectedPage && selectedPage.getTimeLeft() < cleverapps.parseInterval("3 day")) {
        selectedForce = cleverapps.clone(Forces.TRAVEL_BOOK_HINT, true);
        selectedForce.text = Messages.get("ExpeditionProgressHint.remaining", { name: selectedPage.title, remaining: Math.ceil(selectedPage.getTimeLeft() / cleverapps.parseInterval("1 day")) });
        selectedForce.page = selectedPage.id;
        return selectedForce;
    }
};

TravelBook.DISPLAYED_PAGES = 4;
TravelBook.FORCE_TIMEOUT = cleverapps.parseInterval("1 day");

TravelBook.AVAILABLE = {
    level: 6
};
