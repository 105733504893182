/**
 * Created by Andrey Popov on 07.06.2021
 */

var WorkersMenuBarItemModel = function (config) {
    MenuBarItem.call(this, config);

    this.timerShowHours = true;
};

WorkersMenuBarItemModel.prototype = Object.create(MenuBarItem.prototype);
WorkersMenuBarItemModel.prototype.constructor = WorkersMenuBarItemModel;

WorkersMenuBarItemModel.prototype.customInit = function () {
    Map2d.currentMap.workers.onChangeStatusListener = this.changeText.bind(this);
    Map2d.currentMap.workers.onChangeTotalAmount = function () {
        this.changeText();
        this.updateTimer();
        this.onUpdateSticker();
        this.updateAttention();
    }.bind(this);

    cleverapps.adsLimits.on("update", this.updateAttention.bind(this), this);

    this.updateTimer();
};

WorkersMenuBarItemModel.prototype.getCurrentValue = function () {
    return {
        amount: Map2d.currentMap.workers.countRegularFree(),
        total: Map2d.currentMap.workers.countRegularTotal()
    };
};

WorkersMenuBarItemModel.prototype.highlightTotal = function () {
    return (typeof Map2d !== "undefined" && Map2d.currentMap.workers.isBonusWorkerBuyed())
        || (Merge.currentMerge && Merge.currentMerge.isMainGame() && Subscription.IsAvailable() && cleverapps.subscription.isActive());
};

WorkersMenuBarItemModel.prototype.updateTimer = function () {
    var timer = this.timer;
    this.stopTimer();

    if (typeof Map2d !== "undefined" && Map2d.currentMap && Map2d.currentMap.workers.isBonusWorkerBuyed()) {
        this.setTimer(new cleverapps.CountDown(Map2d.currentMap.workers.bonusWorkerLeftTime(), { onFinish: this.updateTimer.bind(this) }), true);
    }

    if (this.onChangeTimer) {
        this.onChangeTimer(!!this.timer === !!timer);
    }
    if (this.onRefreshTimer && this.timer) {
        this.onRefreshTimer();
    }
};