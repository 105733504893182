/**
 * Created by andrey on 22.01.2021.
 */

var MergeAdvice = function () {
    cleverapps.EventEmitter.call(this);

    this.logics = [];

    Map2d.currentMap.on("changeDragging", this.onChangeDragging.bind(this), this);

    cleverapps.userStatus.on("active", this.stopInactiveHint.bind(this), this);
    cleverapps.userStatus.on("inactive_very_short", this.startInactiveHint.bind(this, { type: "very_short" }), this);
    cleverapps.userStatus.on("inactive_long", this.startInactiveHint.bind(this, { type: "long" }), this);
    cleverapps.eventBus.on("openInfoView", this.boo.bind(this), this);

    this.listeners = [];
    this.listeners.push(cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, this.onShow.bind(this)));
    this.listeners.push(cc.eventManager.addCustomListener(cc.game.EVENT_HIDE, this.onHide.bind(this)));
};

MergeAdvice.prototype = Object.create(cleverapps.EventEmitter.prototype);
MergeAdvice.prototype.constructor = MergeAdvice;

MergeAdvice.prototype.setLogic = function (type, options) {
    var Logic;
    var logics = [];

    switch (type) {
        case Map2d.MERGE: Logic = MergeAdviceMergeLogic; break;
        case Map2d.BUY_FREE_UNIT:
        case Map2d.EXCHANGE:
        case Map2d.SPAWN: Logic = MergeAdviceSpawnLogic; break;
        case Map2d.FACTORY_SPEEDUP:
        case Map2d.REFILL: Logic = MergeAdviceRefillLogic; break;
        case Map2d.HARVEST: Logic = MergeAdviceHarvestLogic; break;
        case Map2d.OPENFOG: Logic = MergeAdviceOpenFogLogic; break;
        case Map2d.START_MINING: Logic = MergeAdviceMineLogic; break;
        case Map2d.SPEED_UP: Logic = MergeAdviceSpeedupLogic; break;
        case Map2d.START_BUILDING: Logic = MergeAdviceBuildLogic; break;
        case Map2d.CLICK_UNIT:
        case Map2d.OPENCHEST:
        case Map2d.ENTER_UNITS_SHOP:
        case Map2d.MISSION_TREE_CLOSED: Logic = MergeAdviceClickLogic; break;
        case Map2d.FEED_HERO:
        case Map2d.DRAG: Logic = MergeAdviceDragLogic; break;
        case Map2d.FIELD_PLANT:
        case Map2d.FIELD_HARVEST:
            Logic = MergeAdviceFieldLogic; break;
    }

    if (Logic) {
        logics.push(new Logic(options));
    }

    this.logics = logics;
};

MergeAdvice.prototype.onChangeDragging = function (unit) {
    if (unit) {
        this.boo();
    }
};

MergeAdvice.prototype.destructor = function () {
    runCleaners(this);
    this.stopHint();
    this.listeners.forEach(function (listener) {
        cc.eventManager.removeListener(listener);
    });
    this.listeners = [];
};

MergeAdvice.prototype.onHide = function () {
    if (!this.paused) {
        this.paused = true;
        clearTimeout(this.showHintTimeout);
        this.hideHint();
    }
};

MergeAdvice.prototype.onShow = function () {
    if (this.paused) {
        delete this.paused;
        this.showHint();
    }
};

MergeAdvice.prototype.boo = function () {
    if (!this.hintActive) {
        return;
    }

    this.hideHint();
    clearTimeout(this.showHintTimeout);
    this.showHintTimeout = setTimeout(this.showHint.bind(this), MergeAdvice.SCARED_TIMEOUT);
};

MergeAdvice.prototype.hideHint = function () {
    this.move = undefined;
    this.trigger("hideHint");
};

MergeAdvice.prototype.showHint = function () {
    if (!this.hintActive) {
        return;
    }

    var move = Map2d.currentMap.dragging ? undefined : this.findMove();
    if (!this.isMoveEqual(this.move, move)) {
        this.move = move;
        if (move) {
            this.trigger("showHint", move, this.hintOptions);
            Merge.currentMerge.tutorial.onShowHint();
        } else {
            this.hideHint();
        }
    }

    var duration = this.getShowHintTimeout();
    clearTimeout(this.showHintTimeout);
    this.showHintTimeout = setTimeout(this.showHint.bind(this), duration);
};

MergeAdvice.prototype.isMoveEqual = function (move, newMove) {
    if (Boolean(move) !== Boolean(newMove)) {
        return false;
    }

    var hasChanged = function (a, b) {
        if (typeof a !== "object" || typeof b !== "object") {
            return a !== b;
        }
        if (a instanceof Unit || b instanceof Unit) {
            return a !== b;
        }

        var keys = cleverapps.createSet(Object.keys(a).concat(Object.keys(b)));
        for (var key in keys) {
            if (hasChanged(a[key], b[key])) {
                return true;
            }
        }
        return false;
    };

    return !hasChanged(move, newMove);
};

MergeAdvice.prototype.getShowHintTimeout = function () {
    var logic = this.logics[0];
    if (logic && logic.getShowHintTimeout) {
        return logic.getShowHintTimeout(this.move);
    }
    return MergeAdvice.ONE_ITERATION_DURATION;
};

MergeAdvice.prototype.startHint = function (options) {
    if (this.hintActive) {
        return;
    }
    this.hintActive = true;
    this.hintOptions = options || {};

    var start = function () {
        this.showHint();

        clearTimeout(this.stopHintTimeout);
        if (options.callback) {
            this.stopHintTimeout = setTimeout(options.callback, 2 * this.getShowHintTimeout());
        }
    }.bind(this);

    if (options.delay) {
        clearTimeout(this.stopHintTimeout);
        this.stopHintTimeout = setTimeout(start, options.delay);
    } else {
        start();
    }
};

MergeAdvice.prototype.startHintOnce = function () {
    this.startHint({
        callback: this.stopHint.bind(this)
    });
};

MergeAdvice.prototype.stopHint = function () {
    if (this.hintActive) {
        delete this.hintOptions;
        delete this.inactiveHintRunning;
        delete this.hintActive;
        clearTimeout(this.showHintTimeout);
        clearTimeout(this.stopHintTimeout);
        this.hideHint();
    }
};

MergeAdvice.prototype.findMove = function () {
    for (var i = 0; i < this.logics.length; ++i) {
        var move = this.logics[i].findMove();

        if (move) {
            return move;
        }
    }
};

MergeAdvice.prototype.hasHints = function () {
    return this.logics.length > 0;
};

MergeAdvice.prototype.stopInactiveHint = function () {
    if (this.inactiveHintRunning) {
        this.stopHint();
    }
};

MergeAdvice.prototype.startInactiveHint = function (options) {
    options = options || {};

    if (cleverapps.focusManager.isFocused() || this.hintActive || this.paused
        || Merge.currentMerge.tutorial.isActive() || Merge.currentMerge.counter.isActive()) {
        return;
    }

    if (Map2dScroller.currentScroller.isScrollProcessing()) {
        cleverapps.userStatus.reportUserAction();
        return;
    }

    var level = cleverapps.gameLevel.getLevel();
    if ((options.type === "long" && level <= 5 || options.type === "very_short" && level > 5 || level > 15) && !options.forceHint) {
        return;
    }

    var move;
    var startHint = function (scrollTo) {
        this.inactiveHintRunning = !options.forceHint;
        this.startHint({
            callback: options.once !== false && function () {
                cleverapps.userStatus.reportUserAction();
            },
            delay: options.delay
        });

        if (scrollTo) {
            Map2d.currentMap.focusOnUnit(scrollTo, {
                duration: 2,
                visibleBox: {
                    left: 0.6,
                    right: 0.6,
                    top: 0.5,
                    bottom: 0.6
                }
            });
        }
    }.bind(this);

    if (options.logic) {
        this.setLogic(options.logic);
        startHint();
        return;
    }

    var readyFog = Map2d.currentMap.fogs.findFogReadyToOpen();
    if (readyFog) {
        this.setLogic(Map2d.OPENFOG, {
            targets: readyFog.id
        });
        startHint();
        return;
    }

    var fog = Map2d.currentMap.fogs.findBlockWithProgress();
    var wandsValue = Map2d.currentMap.listAvailableUnits({ code: "wands" }).reduce(function (sum, wand) {
        return sum + wand.getData().value;
    }, 0);

    if (fog && Merge.currentMerge.wands + wandsValue >= fog.getOpenPrice()) {
        this.setLogic(Map2d.HARVEST, {
            targets: Unit.getAllStagesTargets("wands")
        });

        move = this.findMove();
        if (move) {
            startHint(move.cells && move.cells[0]);
            return;
        }
    }

    if (cleverapps.meta.selectedLocationId() === "dragonia") {
        this.setLogic(Map2d.DRAG, {
            targets: [].concat(
                Unit.getPreviousStagesTargets("drgiftaxe", 2),
                Unit.getPreviousStagesTargets("drgiftcuirass", 2),
                Unit.getPreviousStagesTargets("drgifthelm", 2),
                Unit.getPreviousStagesTargets("drgiftshield", 2)
            )
        });

        move = this.findMove();
        if (move) {
            startHint(move.cells && move.cells[0]);
            return;
        }
    }

    if (cleverapps.meta.selectedLocationId() === "dragonia2") {
        this.setLogic(Map2d.DRAG, {
            targets: [].concat(
                Unit.getPreviousStagesTargets("drgiftaxe", 2),
                Unit.getPreviousStagesTargets("drgiftcuirass", 2),
                Unit.getPreviousStagesTargets("drgifthelm", 2),
                Unit.getPreviousStagesTargets("drgiftshield", 2)
            )
        });

        move = this.findMove();
        if (move) {
            startHint(move.cells && move.cells[0]);
            return;
        }
    }

    if (cleverapps.meta.selectedLocationId() === "undersea") {
        this.setLogic(Map2d.DRAG, {
            targets: [].concat(
                Unit.getPreviousStagesTargets("seaanchor", 2),
                Unit.getPreviousStagesTargets("seawheel", 2),
                Unit.getPreviousStagesTargets("seaspyglass", 2),
                Unit.getPreviousStagesTargets("seapiratehook", 2)
            )
        });

        move = this.findMove();
        if (move) {
            startHint(move.cells && move.cells[0]);
            return;
        }
    }

    if (!Map2d.currentMap.fogs.isOpened("fog3")) {
        this.setLogic(Map2d.START_MINING);

        move = this.findMove();
        if (move) {
            startHint();
            return;
        }
    }

    this.setLogic(Map2d.MERGE);

    if (connector.info.source === "playable" || (cleverapps.wysiwyg && cleverapps.wysiwyg.playable)) {
        this.logics.push(new MergeAdviceSpawnLogic());
        this.logics.push(new MergeAdviceHarvestLogic());
        this.logics.push(new MergeAdviceMineLogic());
        this.logics.push(new MergeAdviceSpeedupLogic());
        this.logics.push(new MergeAdviceBuildLogic());
        startHint();
        return;
    }

    move = this.findMove();
    if (!move) {
        this.setLogic(Map2d.START_MINING);

        if (!this.findMove()) {
            this.setLogic(Map2d.SPAWN);
        }
    }
    startHint();
};

MergeAdvice.SCARED_TIMEOUT = 1000;
MergeAdvice.ONE_ITERATION_DURATION = 2000;
