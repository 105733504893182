/**
 * Created by r4zi4l on 17.09.2021
 */

var OrderIcon = cc.Node.extend({
    ctor: function (maker, orders) {
        var styles = cleverapps.styles.OrderIcon;

        this._super();
        this.maker = maker;
        this.orders = orders;

        var background = this.background = cleverapps.UI.createScale9Sprite(bundles.ordericon.frames.order_sheet, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(styles);
        background.setPositionRound(background.width / 2, background.height / 2);

        if (bundles.orderswindow.frames.order_icon_tape) {
            var tape = new cc.Sprite(bundles.orderswindow.frames.order_icon_tape);
            tape.setPositionRound(styles.tape);
            background.addChild(tape);
        }

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(background.width, background.height);
        this.setRotation(styles.rotation);
        this.addChild(this.background);

        this.createCustomOrderGuideButton();

        this.hideAction = new cc.Sequence(
            new cc.FadeOut(0.2),
            new cc.RemoveSelf()
        );

        this.showAction = new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.FadeIn(0.2)
        );

        this.createUnitsLayer();
        this.updateState(maker);

        this.orders.on("makerSelected", this.updateSelection.bind(this), this);
        this.orders.on("orderStateChanged", this.updateState.bind(this), this);
    },

    createUnitsLayer: function () {
        var unitsLayer = this.unitsLayer = new cc.Node();
        unitsLayer.setContentSize(this.getContentSize());
        unitsLayer.setAnchorPoint(0.5, 0.5);
        unitsLayer.setLocalZOrder(1);
        unitsLayer.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(unitsLayer);

        var self = this;

        unitsLayer.scheduleUpdate();
        unitsLayer.update = cleverapps.extendFunc(unitsLayer.update, function () {
            this._super.apply(this, arguments);

            unitsLayer.setScaleX(self.getScaleX());
            unitsLayer.setScaleY(self.getScaleY());
        });
    },

    updatePin: function () {
        if (this.pin) {
            this.pin.stopAllActions();
            this.pin.runAction(new cc.Sequence(
                new cc.ScaleTo(0.2, 0),
                new cc.RemoveSelf()
            ));
            this.pin = undefined;
        }

        if ([Customer.STATE_RECIPE, Customer.STATE_READY].indexOf(this.maker.getState()) === -1) {
            return;
        }

        var styles = cleverapps.styles.OrderIcon.pin;

        var image = bundles.ordericon.frames.order_pin_off;

        if (this.maker.getState() === Customer.STATE_READY) {
            image = bundles.ordericon.frames.order_pin_on;
        } else if (this.maker.canCook()) {
            image = bundles.ordericon.frames.order_pin;
        }

        var pin = new cc.Sprite(image);

        if (bundles.ordericon.frames.order_pin_background) {
            var background = new cc.Sprite(bundles.ordericon.frames.order_pin_background);
            pin.setPositionRound(styles.icon);
            background.addChild(pin);

            pin = background;
        }

        this.pin = pin;
        pin.setPositionRound(styles);
        pin.setRotation(styles.rotation);
        this.background.addChild(pin);

        pin.setScale(0);
        pin.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.ScaleTo(0.2, 1)
        ));
    },

    updateIngredients: function () {
        if (this.ingredients) {
            this.ingredients.stopAllActions();
            this.ingredients.runAction(this.hideAction.clone());
            this.ingredients = undefined;
        }

        if (this.maker.getState() !== Customer.STATE_RECIPE) {
            return;
        }

        var styles = cleverapps.styles.OrderIcon.ingredients;

        var icons = [];
        this.maker.recipe.ingredients.forEach(function (ingredient) {
            var icon = CustomerRecipe.getIngredientIcon(ingredient);
            cleverapps.UI.fitToBox(icon, styles.icon);

            var text = cleverapps.UI.generateImageText("x" + ingredient.amount, cleverapps.styles.FONTS.ORDERICON_AMOUNT_TEXT);

            if (Map2d.currentMap.customers.getStockAmount(ingredient) < ingredient.amount) {
                icon.setColor(new cc.Color(180, 180, 180, 255));
                icon.setOpacity(160);
                text.setOpacity(160);
            }

            icons.push(icon, text);
        }, this);

        this.ingredients = new cleverapps.GridLayout(icons, {
            columns: 2
        });
        this.ingredients.setPositionRound(styles);
        this.ingredients.setCascadeOpacityEnabledRecursively(true);
        this.unitsLayer.addChild(this.ingredients);

        this.ingredients.setOpacity(0);
        this.ingredients.runAction(this.showAction.clone());
    },

    updateTimer: function () {
        if (this.timer) {
            this.timer.stopAllActions();
            this.timer.runAction(this.hideAction.clone());
            this.timer = undefined;
        }

        if (this.maker.getState() !== Customer.STATE_COOKING) {
            return;
        }

        var styles = cleverapps.styles.OrderIcon.timer;

        var timeLeft = this.maker.getCookingTimeLeft();
        var animation = "done";

        if (this.maker.getState() === Customer.STATE_COOKING) {
            timeLeft = new cleverapps.CountDown(timeLeft);
            animation = "animation";
        }

        var timer = this.timer = new cleverapps.CountDownView(timeLeft, {
            font: cleverapps.styles.FONTS.ORDERICON_TIMER_TEXT,
            background: bundles.ordericon.frames.order_timer_background && {
                frame: bundles.ordericon.frames.order_timer_background,
                width: styles.width
            },
            icon: {
                json: bundles.ordericon.jsons.order_timer_icon,
                animation: animation,
                offsetMultiplier: 0.95
            }
        });

        timer.setCascadeOpacityEnabledRecursively(true);
        timer.setPositionRound(styles);
        timer.setRotation(styles.rotation);

        if (bundles.ordericon.frames.order_clock_background || bundles.ordericon.frames.order_pin_background) {
            var background;
            if (bundles.ordericon.frames.order_clock_background) {
                var leftBg = new cc.Sprite(bundles.ordericon.frames.order_clock_background);
                var rightBg = new cc.Sprite(bundles.ordericon.frames.order_clock_background);
                rightBg.setScaleX(-1);
                background = new cleverapps.Layout([leftBg, rightBg], { direction: cleverapps.UI.HORIZONTAL, margin: styles.background.margin });
            } else {
                background = new cc.Scale9Sprite(bundles.ordericon.frames.order_pin_background);
                background.setContentSize2(styles.background.width, background.height);
            }

            background.setPositionRound(styles.background);
            background.setLocalZOrder(-1);
            timer.addChild(background);
        }

        this.background.addChild(timer);

        timer.setOpacity(0);
        timer.icon.setScale(0);
        timer.runAction(this.showAction.clone());
        timer.icon.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.ScaleTo(0.2, 1)
        ));
    },

    updateRewards: function () {
        if (this.rewards) {
            this.rewards.stopAllActions();
            this.rewards.runAction(this.hideAction.clone());
            this.rewards = undefined;
        }

        if ([Customer.STATE_READY, Customer.STATE_COOKING].indexOf(this.maker.getState()) === -1) {
            return;
        }

        var styles = cleverapps.styles.OrderIcon.rewards;

        var orderUnits = this.maker.recipe.order.reduce(function (acc, item) {
            for (var i = 0; i < item.amount; i++) {
                acc.push(item.unit);
            }
            return acc;
        }, []);

        var icons = orderUnits.map(function (unit) {
            var icon = UnitView.getUnitImage(unit, { preferStatic: true });
            icon.setAnchorPoint(0.5, 0);
            return icon;
        });

        this.rewards = new cleverapps.Layout(icons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        this.rewards.setPositionRound(styles);
        this.rewards.setCascadeOpacityEnabledRecursively(true);
        cleverapps.UI.fitToBox(this.rewards, {
            width: styles.width,
            height: styles.height
        });
        this.unitsLayer.addChild(this.rewards);

        this.rewards.setOpacity(0);
        this.rewards.runAction(this.showAction.clone());
    },

    updateButton: function () {
        if (this.button) {
            this.button.stopAllActions();
            this.button.disabled = true;
            this.button.runAction(this.hideAction.clone());
            this.button = undefined;
        }

        if ([Customer.STATE_READY, Customer.STATE_COOKING].indexOf(this.maker.getState()) === -1) {
            return;
        }

        var styles = cleverapps.styles.OrderIcon.button;

        var Button = cleverapps.UI.Button;
        var buttonOptions = {
            width: styles.width,
            height: styles.height,
            text: "Claim",
            type: styles.type,
            onClicked: this.orders.processOrder.bind(this.orders, this.maker)
        };

        if (this.maker.getState() === Customer.STATE_COOKING) {
            Button = UseGoldButton;

            buttonOptions.canCoins = true;
            buttonOptions.price = SpeedUpWindow.CalcPrice(this.maker.getCookingTimeLeft());

            buttonOptions.eventName = cleverapps.EVENTS.SPENT.ORDER;
            buttonOptions.freeIcon = TextWithIcon.ICONS_BY_NAME.speedup;
            buttonOptions.speedUp = true;
            buttonOptions.type = styles.type || cleverapps.styles.UI.Button.Images.button_blue;
            delete buttonOptions.text;

            if (this.maker.recipe && this.maker.recipe.cookingDuration && ConfirmSpeedUpWindow.isAvailable(buttonOptions.price)) {
                buttonOptions.confirmWindowOptions = {
                    timeLeft: this.maker.getCookingTimeLeft(),
                    totalDuration: this.maker.recipe.cookingDuration
                };
            }
        }

        this.button = new Button(buttonOptions);
        this.button.setPositionRound(styles);
        this.button.setCascadeOpacityEnabledRecursively(true);
        this.background.addChild(this.button);

        this.button.setOpacity(0);
        this.button.runAction(this.showAction.clone());
    },

    setFreeze: function (value) {
        this.freeze = value;
    },

    updateSelection: function (maker) {
        var styles = cleverapps.styles.OrderIcon.highlight;

        if (this.maker === maker) {
            if (!this.highlight) {
                this.highlight = cleverapps.UI.createScale9Sprite(bundles.ordericon.frames.order_light);
                this.highlight.setContentSize2(this.width + styles.border, this.height + styles.border);
                this.highlight.setPositionRound(styles);
                this.highlight.setLocalZOrder(-1);
                this.highlight.setOpacity(0);
                this.highlight.runAction(new cc.FadeIn(0.3));
                this.addChild(this.highlight);
            }

            this.updateButton();

            if (this.clickHandler) {
                this.clickHandler.remove();
                delete this.clickHandler;

                this.hoverHandler.remove();
                delete this.hoverHandler;
            }
        } else {
            if (this.highlight) {
                this.highlight.runAction(new cc.Sequence(
                    new cc.FadeOut(0.2),
                    new cc.RemoveSelf()
                ));
                this.highlight = undefined;
            }

            if (!this.clickHandler) {
                this.clickHandler = cleverapps.UI.onClick(this, function () {
                    cleverapps.audio.playSound(bundles.main.urls.click_effect);
                    this.orders.selectMaker(this.maker);
                }.bind(this));

                this.hoverHandler = cleverapps.UI.applyHover(this);
            }
        }
    },

    updateState: function (maker) {
        var canCook = this.maker.canCook();

        if (this.freeze || this.maker !== maker && this.canCook === canCook) {
            return;
        }
        this.canCook = canCook;

        this.updatePin();
        this.updateIngredients();
        this.updateTimer();
        this.updateRewards();
        this.updateButton();
    },

    createCustomOrderGuideButton: function () {
        var guideBundle = bundles.hero_food_guide_window;

        if (!this.maker.isProcessingCustomOrder() || !guideBundle) {
            return;
        }

        var styles = cleverapps.styles.OrderIcon.guideButton;

        var guideButton = new cleverapps.UI.HelpButton({
            type: cleverapps.styles.UI.Button.Images.help_button_light,
            callback: function () {
                new GuideWindow({
                    name: "HeroFoodGuideWindow",
                    bundle: guideBundle
                });
                if (this.finger) {
                    FingerView.remove(this.finger);
                }
            }.bind(this) 
        });

        guideButton.setPositionRound(styles);
        this.addChild(guideButton);

        if (Merge.currentMerge.tutorial.isActive() && Merge.currentMerge.tutorial.runningTutorialId === "hungry_customer") {
            guideButton.runAction(new cc.Sequence(
                new cc.DelayTime(Merge.currentMerge.tutorial.isActive() ? 0.3 : 2.5),
                new cc.CallFunc(function () {
                    this.finger = FingerView.hintClick(guideButton, {
                        delay: 2.5,
                        repeatDelay: 2.5
                    });
                }.bind(this))
            ));
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ORDERICON_AMOUNT_TEXT: {
        name: "nostroke",
        size: 46,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    ORDERICON_TIMER_TEXT: {
        name: "nostroke",
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.OrderIcon = {
    width: 234,
    height: 244,
    rotation: 0,

    highlight: {
        x: { align: "center" },
        y: { align: "center" },
        border: 35
    },

    pin: {
        x: { align: "center", dx: 20 },
        y: { align: "top", dy: 15 },
        rotation: 0
    },

    ingredients: {
        x: { align: "center" },
        y: { align: "center" },
        width: 200,
        height: 200,

        icon: {
            width: 65,
            maxScale: 1.5
        }
    },

    timer: {
        x: { align: "center", dx: -10 },
        y: { align: "center", dy: 92 },
        width: 200,
        rotation: 0
    },

    rewards: {
        x: { align: "center" },
        y: { align: "center", dy: 25 },
        width: 200,
        height: 100,
        margin: 0
    },

    button: {
        x: { align: "center", dx: -3 },
        y: { align: "center", dy: -67 },
        width: 180,
        height: 72,
        padding: {
            left: 14,
            right: 14,
            top: 10,
            bottom: 10
        },
        margin: 4
    },

    fly: {
        height: 300
    },

    guideButton: {
        x: { align: "right", dx: 10 },
        y: { align: "top", dy: 10 }
    }
};
