/**
 * Created by r4zi4l on 26.01.2021
 */

var WandsAttackButton = cleverapps.UI.Button.extend({
    ctor: function (options) {
        this.price = options.wands;

        this._super({
            width: options.width,
            height: options.height,
            text: this.price === 0 ? "FREE" : "++" + this.price,
            textVariant: this.price > Merge.currentMerge.wands ? cleverapps.UI.Button.TEXT_EMPHASIS : cleverapps.UI.Button.TEXT_REGULAR,
            onClicked: options.onClicked,
            type: options.type
        });
    }
});

cleverapps.styles.WandsAttackButton = {
    iconOffsetY: -3
};
