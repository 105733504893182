/**
 * Created by razial on 27.04.2024.
 */

Harvested.CONFIG.adventure = {
    capacity: 250,
    price: 250,
    exchangeMinimum: 50,

    resources: {
        advgrass: {
            icon: bundles.resource_icons.frames.resource_advgrass,
            smallIcon: bundles.resource_icons.frames.resource_advgrass_small
        },
        advlog: {
            icon: bundles.resource_icons.frames.resource_advlog,
            smallIcon: bundles.resource_icons.frames.resource_advlog_small
        },
        advstick: {
            icon: bundles.resource_icons.frames.resource_advstick,
            smallIcon: bundles.resource_icons.frames.resource_advstick_small
        },
        advstone: {
            icon: bundles.resource_icons.frames.resource_advstone,
            smallIcon: bundles.resource_icons.frames.resource_advstone_small
        },
        advdoubloon: {
            icon: bundles.resource_icons.frames.resource_advdoubloon,
            smallIcon: bundles.resource_icons.frames.resource_advdoubloon_small
        }
    }
};

Landmarks.WORLDS.adventure = {
    fog: "fog1",
    force: Forces.LANDMARK_DONOR_SLOT2,
    landmarks: [{
        code: "landmark",
        stage: 16,
        targetExpedition: "main",
        donors: ["advcustomerbridge0", "advcustomerbridge1", "advcustomerbridge2", "advcustomermain1", "advcustomermain2"],
        dialogue: [{
            person: "dwarf",
            person2: {
                role: "king",
                orientation: "right"
            },
            text: "Landmarks.landmark_16.step1"
        }, {
            person: {
                role: "king",
                orientation: "right"
            },
            person2: "dwarf",
            text: "Landmarks.landmark_16.step2"
        }]
    }]
};

Mines.advsource = Mines.advprize = function (level, unit) {
    return Families[unit.code].units[unit.stage].mineable[level].prizes;
};

CustomerRecipes.adventure = {
    advcustomerbridge0_0: [{
        ingredients: [
            {
                ingredient: "advstick",
                amount: 3
            }
        ],
        order: [
            {
                code: "energy",
                stage: 1,
                amount: 1
            }
        ]
    }],

    advcustomermain1_0: [{
        cookingDuration: "15 minutes",
        ingredients: [
            {
                ingredient: "advdoubloon",
                amount: 5
            }, {
                ingredient: "advgrass",
                amount: 25
            }, {
                ingredient: "advlog",
                amount: 20
            }
        ],
        order: [
            {
                code: "energy",
                stage: 1,
                amount: 1
            }
        ]
    }],

    advcustomerbridge1_0: [{
        cookingDuration: "10 minutes",
        ingredients: [
            {
                ingredient: "advdoubloon",
                amount: 5
            }, {
                ingredient: "advstone",
                amount: 10
            }, {
                ingredient: "advgrass",
                amount: 15
            }
        ],
        order: [
            {
                code: "energy",
                stage: 1,
                amount: 1
            }
        ]
    }],

    advcustomerbridge2_0: [{
        cookingDuration: "20 minutes",
        ingredients: [
            {
                ingredient: "advdoubloon",
                amount: 15
            }, {
                ingredient: "advstick",
                amount: 50
            }, {
                ingredient: "advlog",
                amount: 30
            }
        ],
        order: [
            {
                code: "energy",
                stage: 1,
                amount: 1
            }
        ]
    }],

    advcustomermain2_0: [{
        cookingDuration: "5 minutes",
        ingredients: [
            {
                ingredient: "advdoubloon",
                amount: 10
            }, {
                ingredient: "advgrass",
                amount: 50
            }, {
                ingredient: "advstone",
                amount: 25
            }
        ],
        order: [
            {
                code: "energy",
                stage: 1,
                amount: 1
            }
        ]
    }]
};
