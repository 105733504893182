/**
 * Created by vladislav on 31/10/2022
 */

var Customers = function () {
    cleverapps.EventEmitter.call(this);

    this.customers = [];

    this.stock = {};
};

Customers.prototype = Object.create(cleverapps.EventEmitter.prototype);
Customers.prototype.constructor = Customers;

Customers.prototype.addCustomer = function (customer) {
    this.customers.push(customer);
};

Customers.prototype.removeCustomer = function (customer) {
    var index = this.customers.indexOf(customer);
    if (index !== -1) {
        this.customers.splice(index, 1);
    }
};

Customers.prototype.listActive = function () {
    return this.customers.filter(function (customer) {
        return customer.getCurrentRecipe();
    });
};

Customers.prototype.onUnitAvailable = function (unit) {
    if (Customers.IGNORES_STOCK.indexOf(unit.code) !== -1 || !Buildable.IsBuilt(unit) || !Creatable.IsCreated(unit)) {
        return;
    }

    if (unit.stocked > 0) {
        cleverapps.throwAsync("Unit added second time " + Unit.GetKey(unit));
        return;
    }

    var key = Unit.GetKey(unit);
    this.stock[key] = (this.stock[key] || 0) + 1;

    unit.stocked = 1;

    this.updateMarks();
    this.onRecipesUpdated();
};

Customers.prototype.onUnitRemoved = function (unit) {
    if (unit.stocked < 1) {
        cleverapps.throwAsync("Unit removed second time " + Unit.GetKey(unit));
        return;
    }

    if (!unit.stocked) {
        return;
    }

    var key = Unit.GetKey(unit);
    this.stock[key] = (this.stock[key] || 0) - 1;

    unit.stocked -= 1;

    this.updateMarks();
    this.onRecipesUpdated();
};

Customers.prototype.onRecipesUpdated = function () {
    this.trigger("onRecipesUpdated");
};

Customers.prototype.updateMarks = function () {
    this.needUpdateMarks = true;

    Map2d.currentMap.counter.trigger();
};

Customers.prototype.clearWantsWindow = function () {
    this.customers.forEach(function (customer) {
        customer.wantsWindow = undefined;
    });
};

Customers.prototype.process = function () {
    if (cleverapps.focusManager.isFocused() || Map2d.currentMap.dragging) {
        return;
    }

    var wantingWindowCustomer;
    var requiredUnits = {};
    
    this.customers.forEach(function (customer) {
        if (customer.getState() === Customer.STATE_EMPTY && !customer.getCooldownTimeLeft() && customer.hasSomeRecipes()) {
            customer.order();
        }

        customer.replaceOrderItemsWithCoins();

        var recipe = customer.getCurrentRecipe();
        if (recipe) {
            var wasReadyForCooking = recipe.wasReadyForCooking;
            recipe.wasReadyForCooking = recipe.isEnoughIngredients();

            if (wasReadyForCooking === false && recipe.wasReadyForCooking) {
                if (!wantingWindowCustomer && Map2d.currentMap.getUnit(customer.unit.x, customer.unit.y)) {
                    wantingWindowCustomer = customer;
                }
            }

            recipe.getIngredients().forEach(function (ingredient) {
                if (ingredient.unit) {
                    requiredUnits[Unit.GetKey(ingredient.unit)] = true;
                }
            });
        }
    });

    if (this.needUpdateMarks) {
        this.needUpdateMarks = false;

        Map2d.currentMap.listAvailableUnits(function (unit) {
            unit.setCustomerMark(unit.stocked && requiredUnits[Unit.GetKey(unit)]);
        });
    }

    if (wantingWindowCustomer && !cleverapps.gameModes.silentCustomers) {
        wantingWindowCustomer.openWindow();
    }
};

Customers.prototype.findCustomerForHint = function (options) {
    var codes = options.type ? cleverapps.unitsLibrary.listCodesByType(options.type) : [options.code];
    var stage = options.stage;

    var producesType = function (customerCode) {
        var recipes = CustomerRecipes[cleverapps.meta.selectedLocationId()];
        return recipes[customerCode] && recipes[customerCode].some(function (template) {
            return template.order.some(function (order) {
                var orderCodes = order.type ? cleverapps.unitsLibrary.listCodesByType(order.type) : [order.code];
                return orderCodes.some(function (orderCode) {
                    return codes.some(function (code) {
                        return code === orderCode && (stage === undefined || order.stage === stage || (!Families[code].units[stage].unmergeable && stage > order.stage));
                    });
                });
            });
        });
    };

    var priority = Object.keys(Map2d.currentMap.fogs.config);
    var fakeUnits = Map2d.currentMap.fogs.listFakeUnits({ type: cleverapps.unitsLibrary.getExpeditionUnitType("customer") });

    fakeUnits.sort(function (a, b) {
        var fogA = Map2d.currentMap.getFog(a.x, a.y);
        var fogB = Map2d.currentMap.getFog(b.x, b.y);
        return priority.indexOf(fogA && fogA.fogBlock && fogA.fogBlock.id) - priority.indexOf(fogB && fogB.fogBlock && fogB.fogBlock.id);
    });

    for (var i = 0; i < fakeUnits.length; i++) {
        var fakeUnit = fakeUnits[i];
        for (var unitStage = 0; unitStage < Families[fakeUnit.code].units.length; unitStage++) {
            if (producesType(Unit.GetKey({ code: fakeUnit.code, stage: unitStage }))) {
                var real = this.customers.find(function (customer) {
                    return customer.unit.code === fakeUnit.code;
                });
                return real && real.unit || fakeUnit;
            }
        }
    }
};

Customers.prototype.takeIngredients = function (ingredients, options) {
    options = options || {};
    var units = [];
    var resources = {};
    var customer = options.customer;
    var callback = options.callback || function () {};

    var unitsToRemove = {};

    ingredients.forEach(function (ingredient) {
        if (ingredient.unit) {
            unitsToRemove[Unit.GetKey(ingredient.unit)] = ingredient.amount;
        }

        if (ingredient.ingredient) {
            resources[ingredient.ingredient] = (resources[ingredient.ingredient] || 0) + ingredient.amount;
        }
    });

    Map2d.currentMap.listAvailableUnits(function (unit) {
        var key = Unit.GetKey(unit);
        if (unit.stocked && unitsToRemove[key]) {
            unitsToRemove[key] -= 1;
            units.push(unit);
        }
    });

    Merge.currentMerge.harvested.takeList(resources);

    callback = cleverapps.wait(units.length, callback);

    units.forEach(function (unit) {
        unit.setCustomerMark(false);
        unit.takePrizes();
        unit.setPrizes();

        if (unit.points) {
            unit.claimPoints();
        }

        var pulsing = unit.findComponent(Pulsing);
        if (pulsing) {
            pulsing.setRemoved(true);
        }

        if (customer) {
            customer.collectUnit(unit, callback);
        } else {
            unit.remove();
            callback();
        }
    });
};

Customers.prototype.getStockAmount = function (ingredient) {
    if (!ingredient) {
        return 0;
    }

    if (ingredient.ingredient) {
        return Merge.currentMerge.harvested.get(ingredient.ingredient) || 0;
    }

    if (ingredient.unit) {
        return this.stock[Unit.GetKey(ingredient.unit)] || 0;
    }

    return 0;
};

Customers.prototype.getLackingAmount = function (ingredient) {
    return Math.max(0, ingredient.amount - this.getStockAmount(ingredient));
};

Customers.prototype.canSpawnIngredients = function (ingredients) {
    var unitsAmount = ingredients.reduce(function (total, ingredient) {
        if (ingredient.unit) {
            return total + ingredient.amount;
        }
        return total;
    }, 0);

    var cellsToFree = unitsAmount - Map2d.currentMap.countEmptySlots();

    if (cellsToFree > 0) {
        cleverapps.centerHint.createTextHint("Spawn.nospace", { left: cellsToFree });
        return false;
    }
    return true;
};

Customers.prototype.spawnIngredients = function (ingredients, lacking) {
    var cell = Map2d.currentMap.getScreenCenterCell();

    for (var i = 0; i < ingredients.length; i++) {
        var ingredient = ingredients[i];
        var amount = lacking ? this.getLackingAmount(ingredient) : ingredient.amount;

        if (ingredient.unit) {
            for (var j = 0; j < amount; ++j) {
                var slot = Map2d.currentMap.findEmptySlot(cell.x, cell.y);

                var unit = new Unit(ingredient.unit);
                unit.setPosition(slot.x, slot.y);
                Map2d.currentMap.add(Map2d.LAYER_UNITS, unit.x, unit.y, unit);
                Map2d.currentMap.onAddUnit(unit.x, unit.y, unit);
                Map2d.currentMap.onUnitAvailable(unit);
            }
        }

        if (ingredient.ingredient) {
            Merge.currentMerge.harvested.add(ingredient.ingredient, amount);
        }
    }
};

Customers.prototype.canTakeIngredients = function (ingredients) {
    for (var i = 0; i < ingredients.length; i++) {
        var ingredient = ingredients[i];

        if (this.getStockAmount(ingredient) < ingredient.amount) {
            return false;
        }
    }
    return true;
};

Customers.prototype.listCustomers = function () {
    return this.customers;
};

Customers.prototype.listCustomersWithRecipe = function () {
    return this.customers.filter(function (customer) {
        return customer.recipe;
    });
};

Customers.prototype.findCanCook = function () {
    var customers = this.customers;
    for (var i = 0; i < customers.length; i++) {
        if (customers[i].canCook()) {
            return customers[i];
        }
    }

    return undefined;
};

Customers.prototype.findReady = function () {
    var customers = this.customers;
    for (var i = 0; i < customers.length; i++) {
        if (customers[i].getState() === Customer.STATE_READY) {
            return customers[i];
        }
    }

    return undefined;
};

Customers.prototype.requiredIngredientAmount = function (ingredient) {
    ingredient = ingredient.unit || ingredient.ingredient;
    var totalAmount = 0;
    this.customers.forEach(function (customer) {
        if (customer.getState() !== Customer.STATE_RECIPE) {
            return;
        }
        customer.recipe.ingredients.forEach(function (item) {
            if (
                (item.ingredient && item.ingredient === ingredient.code)
                || (item.unit && item.unit.code === ingredient.code && item.unit.stage === ingredient.stage)
            ) {
                totalAmount += item.amount;
            }
        });
    });
    return totalAmount;
};

Customers.prototype.getCustomRecipeIngredientCode = function () {
    var requiredCodes = {};
    this.customers.forEach(function (customer) {
        if (customer.isProcessingCustomOrder()) {
            customer.recipe.ingredients.forEach(function (ingredient) {
                if (this.getStockAmount(ingredient) < ingredient.amount) {
                    requiredCodes[ingredient.ingredient || ingredient.unit.code] = true;
                }
            }.bind(this));
        }
    }.bind(this));

    var neededCodes = Object.keys(requiredCodes);
    return neededCodes.length && cleverapps.Random.choose(neededCodes);
};

Customers.prototype.onUpdateOrder = function (customer) {
    this.trigger("onUpdateOrder", customer);
};

Customers.IGNORES_STOCK = ["unknown", "multiCellBody", "thirdelement", "barrel", "caravanship", "drgrowing", "seagrowing"];
