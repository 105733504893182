/**
 * Created by evgenijsenkevic on 13/2/25
 */

var FriendPixelsPlanner = function () {
    this.onCreatePixel = function () {};
    this.friendPixels = [];
    this.init();

    levels.friendRequests.on("load", this.createFriendPixels.bind(this));
    this.createFriendPixels();
};

FriendPixelsPlanner.prototype.init = function () {
    if (!this.isAvailable() || this.inited || cleverapps.config.editorMode || cleverapps.config.adminMode || cleverapps.config.wysiwygMode) {
        return;
    }

    this.inited = true;
};

FriendPixelsPlanner.prototype.destructor = function () {
    this.friendPixels = undefined;
    this.inited = false;
};

FriendPixelsPlanner.prototype.isAvailable = function () {
    if (!Merge.currentMerge.isMainGame()) {
        return false;

    }

    return cleverapps.user.checkAvailable(cleverapps.Availables.FRIENDBALLOON);
};

FriendPixelsPlanner.prototype.createFriendPixels = cleverapps.accumulate(5000, function () {
    if (!this.inited) {
        return;
    }

    var requests = levels.friendRequests.getNotProcessedIdsByType(
        levels.FriendRequest.TYPE_SENT_COINS_UNIT
    );

    requests = requests.filter(function (request) {
        return !this.friendPixels.some(function (pixel) {
            return pixel.friendRequest.id === request.id;
        });
    }.bind(this));

    var maxPixelsAmount = Math.min(requests.length, FriendPixelsPlanner.MAX_FRIEND_PIXELS - this.friendPixels.length);

    var pixels = [];
    for (var i = 0; i < maxPixelsAmount; i++) {
        pixels.push(new Pixel({
            type: PixelsSwarm.FRIEND,
            friendRequest: requests[i],
            order: i,
            amountPixels: requests.length,
            onDiePixel: this.onDiePixel.bind(this),
            collectPrize: function () {}
        }));
    }

    pixels = pixels.sort(function (p1, p2) {
        return p2.order - p1.order;
    });

    pixels.forEach(function (pixel) {
        this.friendPixels.push(pixel);
        this.onCreatePixel(pixel);
    }.bind(this));
});

FriendPixelsPlanner.prototype.onDiePixel = function (diePixel) {
    if (this.friendPixels) {
        this.friendPixels = this.friendPixels.filter(function (pixel) {
            return diePixel.friendRequest.id !== pixel.friendRequest.id;
        });
    }

    this.createFriendPixels();
};

FriendPixelsPlanner.MAX_FRIEND_PIXELS = 5;