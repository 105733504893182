/**
 * Created by razial on 16.01.2025.
 */

var MergeLogic = function () {
    this.reset();
};

MergeLogic.prototype.reset = function () {
    this.locations = {
        main: {
            slot: Meta.SLOT_MAIN
        }
    };
};

MergeLogic.prototype.getMainLocation = function () {
    return "main";
};

MergeLogic.prototype.getLocationClass = function () {
    return MergeLocation;
};

MergeLogic.prototype.getMainScene = function () {
    return MergeScene;
};

MergeLogic.prototype.listAvailableLocations = function () {
    return MergeLogic.PAGES.filter(function (page) {
        var available = page.available || {};

        if (available.feature && (cleverapps.config.features || []).indexOf(available.feature) === -1) {
            return false;
        }

        available = available[cleverapps.config.name] || available;

        if (available.projectName && cleverapps.toArray(available.projectName).indexOf(cleverapps.config.name) === -1) {
            return false;
        }

        if (available.debugMode && !cleverapps.config.debugMode) {
            return false;
        }

        return true;
    }).map(function (page) {
        return page.id;
    });
};

MergeLogic.prototype.listActiveLocations = function () {
    return Object.keys(this.locations).filter(function (locationId) {
        return this.locations[locationId].finished === undefined;
    }.bind(this));
};

MergeLogic.prototype.canRemoveSilently = function (locationId) {
    if (this.getLocationFinishTime(locationId) + MergeLogic.AUTO_REMOVE_TIMEOUT < Date.now()) {
        return true;
    }

    var page = MergeLogic.findPageById(locationId);
    var save = cleverapps.GameSaver.load(page.slot) || {};
    return save.expedition !== locationId;
};

MergeLogic.prototype.canCreateLocation = function (locationId) {
    var page = MergeLogic.findPageById(locationId);

    if (Object.values(this.locations).some(function (location) {
        return !location.finished && location.slot === page.slot;
    })) {
        return false;
    }

    if (page.feature && !cleverapps.eventManager.isActive(page.feature)) {
        return false;
    }

    var location = this.locations[page.id];
    if (location && location.finished && page.oneShot) {
        return false;
    }

    return !page.available || cleverapps.user.checkAvailable(page.available);
};

MergeLogic.prototype.getLocationStartTime = function (locationId) {
    var location = this.locations[locationId];
    return location && location.started || 0;
};

MergeLogic.prototype.getLocationFinishTime = function (locationId) {
    var location = this.locations[locationId];
    if (!location) {
        return 0;
    }

    var page = MergeLogic.findPageById(locationId);

    if (page.feature) {
        var event = cleverapps.eventManager.getFeatureEvent(page.feature)
            || cleverapps.eventManager.getLastFeatureEvent(page.feature);
        return event ? event.finish.getTime() : 0;
    }

    if (!page.duration) {
        return 0;
    }

    return location.started + cleverapps.parseInterval(page.duration);
};

MergeLogic.prototype.getLocationTimeLeft = function (locationId) {
    return Math.max(0, this.getLocationFinishTime(locationId) - Date.now());
};

MergeLogic.prototype.isLocationPassed = function (locationId) {
    var page = MergeLogic.findPageById(locationId);
    var location = this.locations[locationId];

    return location && location.finished && page.oneShot;
};

MergeLogic.prototype.getNextLocationFeatureEvent = function (locationId) {
    var page = MergeLogic.findPageById(locationId);
    return page.feature !== undefined && cleverapps.eventManager.getNextFeatureEvent(page.feature);
};

MergeLogic.prototype.isLocationCompleted = function (locationId) {
    var page = MergeLogic.findPageById(locationId);
    return page.id !== "main" && !page.permanent && this.getLocationTimeLeft(locationId) <= 0;
};

MergeLogic.prototype.isCompleted = function () {
    return false;
};

MergeLogic.prototype.listNextLocations = function () {
    return cleverapps.meta.listAvailableLocations().filter(function (locationId) {
        return this.canCreateLocation(locationId);
    }.bind(this));
};

MergeLogic.prototype.startLocation = function (locationId) {
    var page = MergeLogic.findPageById(locationId);
    if (page) {
        cleverapps.GameSaver.saveInfo(page.slot, {});

        LivesHelper.Reset(page.slot);

        UnitsLibrary.resetLocationFamilies(locationId);

        if (page.missionType) {
            cleverapps.offerManager.clearForceByMission(page.missionType);
        }

        Landmarks.clearForce(locationId);

        if (page.missionType) { // todo remove when MergeMetaAdapter will be removed
            cleverapps.missionManager.add(page.missionType);
            cleverapps.missionManager.save();
            cleverapps.missionManager.runManualMissions();
        } else {
            this.addLocation(locationId, page.slot, Date.now());
        }

        cleverapps.travelBook.onNewLocationStarted(locationId);
    }
};

MergeLogic.prototype.finishLocation = function (locationId) {
    var page = MergeLogic.findPageById(locationId);

    var mission = cleverapps.missionManager.findByType(page.missionType);
    if (mission) { // todo remove when MergeMetaAdapter will be removed
        cleverapps.missionManager.remove(mission);
    } else {
        this.removeLocation(locationId, Date.now());
    }

    cleverapps.travelBook.updatePages();
};

MergeLogic.prototype.addLocation = function (locationId, slot, started) {
    this.locations[locationId] = {
        slot: slot,
        started: started
    };

    cleverapps.playSession.set(cleverapps.EVENTS.LOCATION.ACTIVE, true, locationId);
};

MergeLogic.prototype.removeLocation = function (locationId, finished) {
    this.locations[locationId] = {
        finished: finished
    };
};

MergeLogic.prototype.processChatMessage = function () {

};

MergeLogic.AUTO_REMOVE_TIMEOUT = cleverapps.parseInterval("14 days");
