/**
 * Created by Denis Kuzin on 25 july 2022
 */

var RuinsPrizeWindow = CleverappsWindow.extend({
    onWindowLoaded: function (rewards, tierName, bundle) {
        this.rewards = rewards;
        this.tierName = tierName;
        this.bundle = bundle;

        this._super({
            title: (cleverapps.meta.selectedLocationId() === "xmas" ? "RuinsWindow.xmas.plateName." : "RuinsPrizeWindow.title.") + tierName,
            name: "RuinsPrizeWindow",
            content: this.createContent(),
            styles: cleverapps.styles.RuinsPrizeWindow.window
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.RuinsPrizeWindow;

        var row = this.rewards.map(function (unit) {
            var icon = UnitView.getUnitImage(unit);
            cleverapps.UI.fitToBox(icon, styles.icon);

            var amount = cleverapps.UI.generateImageText("x" + unit.amount, cleverapps.styles.FONTS.RUINS_PRIZE_AMOUNT_TEXT);

            var reward = new cleverapps.Layout([icon, amount], {
                direction: cleverapps.UI.HORIZONTAL
            });

            var bg = cleverapps.UI.createScale9Sprite(bundles.merge.frames.icon_background_light, cleverapps.UI.Scale9Rect.TwoPixelXY);
            bg.setContentSize2(styles.icon.bg);
            reward.setPositionRound(bg.width / 2, bg.height / 2);
            bg.addChild(reward);

            return bg;
        });

        var text = cleverapps.UI.generateOnlyText("RuinsPrizeWindow.text", cleverapps.styles.FONTS.RUINS_PRIZE_TEXT);
        var prizes = new cleverapps.Layout(row, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.prizesMargin
        });
        var prizesBlock = new cleverapps.Layout([text, prizes], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.prizesBlockMargin
        });

        var animationNode = new cc.Node();
        animationNode.setAnchorPoint(0.5, 0.5);
        animationNode.setContentSize(styles.animation);

        var animationStyle = this.tierName === "cheap" ? styles.animation.cheap : styles.animation.costly;
        var animation;

        if (cleverapps.meta.selectedLocationId() === "xmas") {
            animation = new cc.Sprite(this.tierName === "cheap" ? this.bundle.frames.tier1_tool : this.bundle.frames.tier2_tool);
            animation.setPositionRound(animationNode.width / 2, animationNode.height / 2);
        } else {
            animation = new cleverapps.Spine(this.tierName === "cheap" ? this.bundle.jsons.bottle_cheap : this.bundle.jsons.bottle_costly);
            animation.setAnimation(0, "idle", true);
            animation.setPositionRound(animationNode.width / 2 + animationStyle.dx, animationNode.height / 2 + animationStyle.dy);
        }

        animationNode.addChild(animation);

        return new cleverapps.Layout([animationNode, prizesBlock], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    RUINS_PRIZE_TEXT: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    RUINS_PRIZE_AMOUNT_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    }
});

cleverapps.styles.RuinsPrizeWindow = {
    margin: 50,
    prizesMargin: 20,
    prizesBlockMargin: 10,

    window: {
        padding: {
            left: 100,
            right: 100,
            top: 150,
            bottom: 100
        }
    },

    icon: {
        width: 90,
        height: 90,
        maxScale: 1.3,

        bg: {
            width: 190,
            height: 130
        }
    },

    animation: {
        width: 400,
        height: 550,

        cheap: {
            dx: 260,
            dy: 43
        },
        costly: {
            dx: -337,
            dy: 40
        }
    }
};