/**
 * Created by evgeny on 18.07.2024
 */

var HomefixIdComponent = function (unit, options, name) {
    UnitComponent.call(this, unit);

    if (cleverapps.environment.isAdministratorScene() || cleverapps.environment.isEditorScene()) {
        return;
    }

    cleverapps.meta.getSelectedLocation().getFurnitureByName(name).bindUnit(unit);
};

HomefixIdComponent.prototype = Object.create(UnitComponent.prototype);
HomefixIdComponent.prototype.constructor = HomefixIdComponent;