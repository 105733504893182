/**
 * Created by andrey on 09.02.2021.
 */

// eslint-disable-next-line no-unused-vars
var EditorMap2dDecoratorView = Map2dDecoratorView.extend({
    ctor: function (decorator) {
        this._super(decorator);

        this.baseScaleX = decorator.scaleX || 1;
        this.baseScaleY = decorator.scaleY || 1;
       
        if (!decorator.isDynamic()) {
            this.addHover();

            cleverapps.UI.onClick(this, function () {
                if (this.isOrangeryEraser() || cleverapps.keyboardController.isPressed(cc.KEY.Delete)) {
                    this.onDeletePressed(true);
                    return;
                }

                if (cleverapps.sceneDebugger.selectedNode === this) {
                    this.unselect();
                } else {
                    this.select();
                }
            }.bind(this), {
                filter: EditorMap2dDecoratorView.FILTER
            });

            this.onDeletePressed = cleverapps.accumulate(0, this.onDeletePressed.bind(this));
        }

        decorator.updateLargeBox = this.createListener(this.updateLargeBox.bind(this));
    },

    onEnterTransitionDidFinish: function () {
        this._super();

        this.decorator.updateLargeBox();
    },

    addHover: function () {
        if (this.hoverHandler) {
            return;
        }

        this.hoverHandler = cleverapps.UI.applyHover(this, {
            filter: EditorMap2dDecoratorView.FILTER,
            onMouseOver: this.onMouseOver.bind(this)
        });
    },

    onMouseOver: function () {
        if (this.isOrangeryEraser() && cleverapps.keyboardController.isPressed(cc.KEY.b)) {
            this.onDeletePressed(true);
        }
    },

    isOrangeryEraser: function () {
        var orangery = Editor.currentEditor.orangery;
        return orangery && orangery.selected && orangery.selected.eraser && orangery.selected.id === MergeOrangery.DECORATOR_ID;
    },

    onChangeScale: function (scaleDeltaX, scaleDeltaY) {
        this.baseScaleX += scaleDeltaX;
        this.baseScaleY += scaleDeltaY;
        this.decorator.updateScale(this.baseScaleX, this.baseScaleY);
    },

    onChangeRotation: function (rotation) {
        this.decorator.setupChildren(this.decorator.dx, this.decorator.dy, rotation);
    },

    onDeletePressed: function (silent) {
        // eslint-disable-next-line no-restricted-globals
        var isConfirmed = silent || confirm("Delete decorator \"" + this.decorator.code + "'?");

        if (!isConfirmed) {
            return;
        }

        if (cleverapps.sceneDebugger.selectedNode === this) {
            this.unselect();
        }

        Map2d.currentMap.decorators.remove(this.decorator);
        this.removeFromParent();
    },

    select: function () {
        if (this.hoverHandler) {
            this.hoverHandler.remove();
            this.hoverHandler = undefined;
        }

        this.debugBorder({ showPosition: true });

        if (this.point) {
            this.point.removeFromParent();
        }

        var image = new cc.Sprite(bundles.editor.frames.cell_center_png);
        image.setColor(cc.color(255, 0, 0, 255));

        var text = cleverapps.UI.generateOnlyText("Cell", cleverapps.styles.FONTS.SYSTEM);

        this.point = new cleverapps.Layout([text, image], {
            direction: cleverapps.UI.VERTICAL
        });
        this.point.setAnchorPoint(0.5, 0.5);
        this.point.setLocalZOrder(100);

        var mapView = Map2d.currentMap.getMapView();
        mapView.alignViewInGrid(this.decorator.x, this.decorator.y, this.point);
        mapView.animations.addChild(this.point);

        EditorMap2dDecoratorView.ToggleHint(true);
    },

    unselect: function () {
        this.cleanupBorder();

        if (this.point) {
            this.point.removeFromParent();
            this.point = undefined;
        }

        this.addHover();

        cleverapps.toolModel.hide(true);
        cleverapps.toolModel.backToRoot();
        EditorMap2dDecoratorView.ToggleHint(false);
    },

    updateLargeBox: function () {
        var map2dView = Map2d.currentMap.getMapView();
        var position = map2dView.alignInGrid(this.decorator.x, this.decorator.y);
        var cellRect = cc.rect(
            position.x - cleverapps.styles.Map2dView.cell.width / 2,
            position.y - cleverapps.styles.Map2dView.cell.height / 2,
            cleverapps.styles.Map2dView.cell.width,
            cleverapps.styles.Map2dView.cell.height
        );

        var largeBox = cc.rectUnion(cellRect, this.getBoundingBox());

        var isLarge = largeBox.width > cleverapps.styles.Map2dView.cell.width * 2
            || largeBox.height > cleverapps.styles.Map2dView.cell.height * 2;

        largeBox = cc.rect(
            Math.floor(largeBox.x / resolutionScale),
            Math.floor(largeBox.y / resolutionScale),
            Math.ceil(largeBox.width / resolutionScale + 1),
            Math.ceil(largeBox.height / resolutionScale + 1)
        );

        this.decorator.setLargeBox(isLarge ? largeBox : undefined);
    },

    debugGetName: function () {
        return this.decorator.code;
    }

});

EditorMap2dDecoratorView.ToggleHint = function (active) {
    if (active) {
        var keyToLetter = {};
        for (var keyName in cc.KEY) {
            keyToLetter[cc.KEY[keyName]] = keyName;
        }

        var commands = [];
        Object.keys(cleverapps.NodeDebugCommands).forEach(function (name) {
            var command = cleverapps.NodeDebugCommands[name];
            if ((!command.filter || command.filter.call(cleverapps.sceneDebugger.selectedNode))) {
                commands.push({
                    key: (command.key !== undefined ? keyToLetter[command.key] + " + " : "") + "⬅⬆",
                    description: command.description.trim()
                });
            }
        });

        if (!EditorMap2dDecoratorView.activeHint) {
            EditorMap2dDecoratorView.activeHint = new EditorHintView();
            cleverapps.scenes.getRunningScene().addChild(EditorMap2dDecoratorView.activeHint);
        }

        EditorMap2dDecoratorView.activeHint.refresh(commands);
    } else if (EditorMap2dDecoratorView.activeHint) {
        EditorMap2dDecoratorView.activeHint.hide();
    }
};

EditorMap2dDecoratorView.FILTER = function () {
    var editor = Editor.currentEditor;
    var isOrangeryEraser = editor.selected && editor.selected.eraser && editor.selected.id === MergeOrangery.DECORATOR_ID;
    return !editor || !editor.selected || isOrangeryEraser;
};
