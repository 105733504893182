/**
 * Created by mac on 12/23/20
 */

var ChestView = cc.Node.extend({
    ctor: function (chest, unitView) {
        this._super();

        this.chest = chest;
        this.unitView = unitView;

        this.createChestAnimation();

        this.restoreState();
    },

    restoreState: function (animated) {
        if (this.chest.isEnergyAvailable()) {
            this.createEnergyIcon(animated);
        } else {
            this.removeEnergyIcon(animated);
        }
    },

    openChest: function () {
        this.unitView.openAnimation();

        if (this.chest.isEnergyAvailable()) {
            Merge.currentMerge.addReward("energy", this.chest.getEnergyAmount(), this.energyIcon || this, {
                withoutDelta: true,
                event: "chest_energy"
            });
        }
    },

    createChestAnimation: function () {
        if (this.chest.unit.stage <= 0 || !this.chest.unit.getData().animation) {
            return;
        }

        this.animation = new cleverapps.Spine(bundles.chests.jsons.chest_animation_json);
        this.animation.setAnimation(0, "stage" + (this.chest.unit.stage + 1), true);
        this.addChild(this.animation);
    },

    createEnergyIcon: function (animated) {
        if (this.energyIcon) {
            return;
        }

        var styles = cleverapps.styles.ChestView.energyIcon;

        var energyIcon = this.energyIcon = new cc.Sprite(bundles.merge.frames["energy_star_" + Chest.classifyEnergy(this.chest.getEnergyAmount())]);
        energyIcon.setAnchorPoint(0.5, 0.5);
        energyIcon.setPositionRound(styles);
        this.addChild(energyIcon);

        energyIcon.x += this.unitView.width * (this.unitView.anchorX - 0.5);

        if (animated) {
            energyIcon.setScale(0.1);
            energyIcon.runAction(new cc.ScaleTo(0.2, 1));
        }
    },

    removeEnergyIcon: function (animated) {
        if (!this.energyIcon) {
            return;
        }

        if (!animated) {
            this.energyIcon.removeFromParent();
            return;
        }

        this.energyIcon.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 0.1),
            new cc.RemoveSelf()
        ));
        this.energyIcon = undefined;
    }
});

cleverapps.styles.ChestView = {
    energyIcon: {
        width: 50,
        height: 50,
        x: { align: "center", dx: 24 },
        y: { align: "center", dy: 17 },
        offsetY: 50
    }
};
