/**
 * Created by Andrey Popov on 09.02.21.
 */

var FakeMerge = function (level) {
    Merge.currentMerge = this;

    this.counter = {
        isActive: function () {
            return true; 
        },
        registerStage: function () {},
        trigger: function () {},
        setTimeout: function () {}
    };

    var bundle = bundles[level.name || AdminEpisode.BundleId(level.episodeNo)];
    var meta = bundle.meta.episode ? Object.assign({}, bundle.meta.episode.levels && bundle.meta.episode.levels[level.levelNo] || {}) : bundle.meta;

    if (!meta.expedition) {
        meta.expedition = "main";
    }

    this.levelContent = level;
    this.level = {
        quick: true,
        content: level,
        episodeNo: level.episodeNo,
        meta: meta,
        getHumanReadableNumber: function () {
            return cleverapps.humanReadableNumber(level.episodeNo, level.levelNo);
        },
        getLevelEnemies: function () {
            var enemies = {};
            if (meta.enemies) {
                meta.enemies.forEach(function (enemy) {
                    enemies[enemy] = Game.GOAL_ENEMY_TYPES[enemy];
                });
            }
            return enemies;
        },
        isBonusRound: function () {
            return false;
        },
        isBonusWorldLevel: function () {
            return false;
        },
        bundleId: function () {
            return AdminEpisode.BundleId(level.episodeNo);
        },
        getTag: function () {
            return meta.tag;
        },
        isHard: function () {
            return this.getTag() === Level.TAGS.HARD;
        },
        isBonus: function () {
            return this.getTag() === Level.TAGS.BONUS;
        },
        isTricky: function () {
            return this.getTag() === Level.TAGS.TRICKY;
        },
        getImageBundle: function () {
            return meta.bundleName;
        }
    };

    Object.assign(this, {
        level: Object.assign(this.level, {
            families: Families.listExpeditionCodes(this.level.meta.expedition),
            meta: Object.assign(this.level.meta, {
                mapSize: meta.mapSize,
                units: this.levelContent.units,
                tiles: this.levelContent.tiles
            })
        }),
        tutorial: {
            isActive: function () {}
        },
        orders: {
            updateOrder: function () {},
            findCanCook: function () {
                return true;
            }
        },
        gameLevel: {
            value: 0,
            getLevel: function () {
                return 1;
            },
            nextLevelThreshold: function () {
                return 1000;
            }
        },
        harvested: {
            canTakeList: function () {},
            on: function () {}
        },
        workers: {
            regular: [],
            findAssigned: function () {},
            countRegularFree: function () {
                return 2; 
            },
            countRegularTotal: function () {
                return 2; 
            },
            isBonusWorkerBuyed: function () {
                return false; 
            },
            removeInstant: function () {}
        },
        energyLottery: {
            isAvailable: function () {
                return false; 
            },
            isReady: function () {
                return false; 
            }
        },
        pushes: {
            updateFruits: function () {}
        },
        spawn: function () {
            return [];
        },
        storeSave: function () {
        },
        seasonalOperator: {
        },
        on: function () {
        },
        isExpedition: function () {
        },
        thirdElementPlanner: {
            thirdElements: []
        },
        needToReplaceWithCoins: function () {
        },
        customers: {
            removeCustomer: function () {}
        },
        unitGreeters: {
            addGreeter: function () {},
            removeGreeter: function () {}
        },
        isMainGame: function () {
            return false;
        }
    });
};

FakeMerge.prototype.stop = function () {
    if (this.stopped) {
        return;
    }
    this.stopped = true;

    InfoView.Clear();
};

FakeMerge.prototype.on = function () {

};