/**
 * Created by razial on 28.10.2021
 */

var ExpeditionPassRulesOptions = {
    name: "ExpeditionPassRulesWindow",

    getGuideBundle: function () {
        if (["undersea", "undersea2"].indexOf(cleverapps.meta.selectedLocationId()) !== -1) {
            return bundles.undersea_expeditionguidewindow_old;
        }
        return bundles[cleverapps.skins.getSlot("skinName") + "_expeditionguidewindow"];
    },

    stagesContent: function () {
        var styles = cleverapps.styles.ExpeditionPassRulesWindow;
        var unitCode = cleverapps.unitsLibrary.listCodesByType(cleverapps.unitsLibrary.getExpeditionUnitType("resource"))[0];

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            var ground = this.addSprite(container, this.bundle.frames.expedition_ground, styles.ground);

            [this.bundle.frames.highlight_dark, this.bundle.frames.highlight_light, this.bundle.frames.highlight_light].map(function (image, index) {
                return this.addSprite(ground, image, styles.highlight[index]);
            }, this);
            [{ code: unitCode, stage: 0 }, { code: unitCode, stage: 0 }, { code: unitCode, stage: 0 }].forEach(function (unit, index) {
                this.addUnitIcon(ground, unit.code, unit.stage, styles.units[index]);
            }, this);
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.expedition_grave, styles.ground);

            var star = new cleverapps.Spine(bundles.merge.jsons.buildpass_point);
            star.setScale(styles.star.scale || 1.0);
            star.setAnchorPoint(0.5, 0.5);
            star.setPositionRound(styles.star);
            star.setAnimation(0, "idle", true);
            container.addChild(star);

            star.runAction(new cc.Sequence(
                new cc.DelayTime(0.1),
                new cc.CallFunc(star.clearTrack.bind(star, 0))
            ));

            if (unitCode) {
                this.addUnitIcon(container, unitCode, 1, styles.unit);
            }
            this.addSprite(star, bundles.finger.frames.tutorial_hand_png, styles.finger);
        }.bind(this));

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.yellow_light, styles.light);
            if (cleverapps.config.name === "mergecraft") {
                this.addSprite(container, this.bundle.frames.expedition_ground, styles.ground);
            }
            this.addSprite(container, this.bundle.frames.prizes_heap, styles.prizes);
        }.bind(this));

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.ExpeditionPassRulesWindow = {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -40 }
        },

        ground: {
            x: 0,
            y: 0
        },

        highlight: [{
            x: 192,
            y: 182
        }, {
            x: 104,
            y: 133
        }, {
            x: 278,
            y: 133
        }],

        units: [{
            x: 197,
            y: 193
        }, {
            x: 108,
            y: 144
        }, {
            x: 284,
            y: 143
        }]
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        ground: {
            x: -10,
            y: -120
        },

        light: {
            x: 0,
            y: -70
        },

        unit: {
            x: -14,
            y: -103,
            scale: 1.2
        },

        star: {
            x: -15,
            y: 75,
            scale: 1.5
        },

        finger: {
            x: 160,
            y: -50
        }
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: 20 }
        },

        light: {
            x: 0,
            y: 0
        },

        ground: {
            x: 0,
            y: -50
        },

        prizes: {
            x: 0,
            y: 0
        }
    }
};
