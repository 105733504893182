/**
 * Created by mac on 12/23/20
 */

var MultiCell = function (unit) {
    UnitComponent.call(this, unit);

    this.shape = unit.getShape();

    this.bodyUnits = this.shape.map(function (cell) {
        if (cell.x === 0 && cell.y === 0) {
            return unit;
        }

        var body = new Unit({
            code: "multiCellBody",
            stage: 0
        });
        body.head = unit;
        return body;
    });
};

MultiCell.prototype = Object.create(UnitComponent.prototype);
MultiCell.prototype.constructor = MultiCell;

MultiCell.prototype.getCenter = function () {
    var X = 0, Y = 0;
    for (var i = 0; i < this.shape.length; i++) {
        X += this.shape[i].x + this.shape[i].y;
        Y += this.shape[i].x - this.shape[i].y;
    }
    X /= this.shape.length;
    Y /= this.shape.length;
    return { x: cleverapps.styles.Map2dView.cell.width * X / 2, y: cleverapps.styles.Map2dView.cell.height * Y / 2 };
};

MultiCell.prototype.getSize = function () {
    var minX = Infinity, minY = Infinity;
    var maxX = -Infinity, maxY = -Infinity;

    for (var i = 0; i < this.shape.length; i++) {
        minX = Math.min(minX, this.shape[i].x);
        maxX = Math.max(maxX, this.shape[i].x);
        minY = Math.min(minY, this.shape[i].y);
        maxY = Math.max(maxY, this.shape[i].y);
    }

    return {
        width: maxX - minX + 1,
        height: maxY - minY + 1
    };
};

MultiCell.prototype.restore = function () {
    var map = Map2d.currentMap;
    var body, unit, tx, ty;
    for (var i = 0; i < this.shape.length; i++) {
        if (!this.shape[i].x && !this.shape[i].y) {
            continue;
        }

        tx = this.unit.x + this.shape[i].x;
        ty = this.unit.y + this.shape[i].y;

        body = this.bodyUnits[i];
        unit = map.getUnit(tx, ty);

        if (body === unit) {
            continue;
        }

        if (unit) {
            if (body.canShareBodyCell(unit)) {
                continue;
            }

            var empty = map.findEmptySlot(tx, ty);
            if (empty) {
                unit.move(empty.x, empty.y);
            } else {
                unit.remove();
                Merge.currentMerge.pocket.addUnits(unit);
            }
        }

        body.setPosition(tx, ty);
        map.add(Map2d.LAYER_UNITS, body.x, body.y, body);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.RESTORE_MULTICELL_BODY);
    }
};

MultiCell.prototype.handleRemove = function () {
    for (var i = 0; i < this.shape.length; i++) {
        var body = this.bodyUnits[i];
        if (body !== this.unit) {
            body.remove();
        }
    }
};

MultiCell.prototype.canMoveThrough = function (x, y) {
    x -= this.unit.x;
    y -= this.unit.y;

    return this.shape.some(function (part) {
        return part.x === x && part.y === y && part.passable;
    });
};
