/**
 * Created by r4zi4l on 20.04.2022
 */

var ClanHelp = function (data) {
    this.helpId = data.helpId;
    this.messageId = data.messageId;
    this.messageType = data.messageType;
    this.status = data.status || ClansConfig.HELP_READY;

    this.senderId = data.senderId;
    this.senderName = data.senderName;
    this.senderAvatar = data.senderAvatar;
};

ClanHelp.prototype.getInfo = function () {
    return {
        helpId: this.helpId,
        messageId: this.messageId,
        messageType: this.messageType,
        status: this.status,
        senderId: this.senderId,
        senderName: this.senderName,
        senderAvatar: this.senderAvatar
    };
};

ClanHelp.prototype.getSenderName = function () {
    return this.senderName || "Player_" + this.senderId.substr(-3);
};

ClanHelp.prototype.isReceived = function () {
    return this.status === ClansConfig.HELP_RECEIVED;
};

ClanHelp.prototype.collect = function (source) {
    this.status = ClansConfig.HELP_RECEIVED;

    var config = ClansConfig.MESSAGES[this.messageType];
    if (!config || !config.help) {
        return;
    }

    var energy = config.help.energy;
    if (energy) {
        if (cleverapps.config.type === "merge") {
            Merge.currentMerge.addReward("energy", energy, source, { event: "help" });
        } else {
            cleverapps.lives.give(energy, "help");
        }
    }
};
