/**
 * Created by razial on 27.04.2024.
 */

var WarehouseWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            name: "WarehouseWindow",
            content: this.createContent(),
            title: false,
            noBackground: true,
            notCloseByTouchInShadow: true,
            openSound: bundles.warehousewindow.urls.open_effect
        });

        this.createScroll();
        this.createBackground();
        this.createCapacity();
        this.onWarehouseChanged();

        cleverapps.focusManager.showControlsWhileFocused(["MenuBarGameLevelItem", "MenuBarGoldItem"]);

        Merge.currentMerge.harvested.on("onChangeItems", this.onWarehouseChanged.bind(this), this);
    },

    onShow: function () {
        if (Merge.currentMerge.harvested.wantsToShowTip) {
            var rects = [this.capacity.getSceneBoundingBox(), this.window.getSceneBoundingBox(), this.closeButton.getSceneBoundingBox()];

            var dialogue = new MinimalDialogue({
                text: "WarehouseWindow.notEnoughSpace",
                rects: rects
            });
            this.addChild(dialogue);
            dialogue.display();

            this.runAction(new cc.Sequence(
                new cc.DelayTime(6),
                new cc.CallFunc(function () {
                    dialogue.remove();
                })
            ));
        }
    },

    createContent: function () {
        return new cc.Node();
    },

    createScroll: function () {
        var styles = cleverapps.styles.WarehouseWindow;

        this.window.removeFromParent();
        this.window = new cc.Node();
        this.window.setAnchorPoint(0.5, 0);
        this.addChild(this.window);

        var tiles = Merge.currentMerge.harvested.listResources().map(function (ingredient, index, tiles) {
            var tile = new WarehouseTile(ingredient.ingredient, this.selectTile.bind(this));
            tile.setLocalZOrder(tiles.length - index);
            return tile;
        }.bind(this));

        if (!tiles.length) {
            tiles.push(this.createEmptyTile());
        }

        var layout = new cleverapps.Layout(tiles, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.scroll.margin,
            padding: styles.scroll.padding
        });

        this.scroll = new cleverapps.UI.ScrollView(layout, {
            direction: cleverapps.UI.ScrollView.DIR_HORIZONTAL,
            clipping: false,
            scrollBarEnabled: false
        });
        this.scroll.setContentSize(this.width, layout.height);
        this.scroll.setAnchorPoint(0.5, 0);
        this.scroll.scrollTo(cleverapps.UI.ScrollView.SCROLLS.UPPER_LEFT);
        this.window.addChild(this.scroll, 1);
    },

    createBackground: function () {
        this.background = cleverapps.UI.createScale9Sprite(bundles.warehousewindow.frames.background, cleverapps.UI.Scale9Rect.TwoPixelXY);
        this.background.setAnchorPoint(0.5, 0);
        this.background.setLocalZOrder(-1);
        this.window.addChild(this.background);
    },

    createCapacity: function () {
        var styles = cleverapps.styles.WarehouseWindow.capacity;

        var title = cleverapps.UI.generateOnlyText("WarehouseWindow.capacity", cleverapps.styles.FONTS.WAREHOUSE_CAPACITY_TEXT);

        var current = Merge.currentMerge.harvested.getItemsAmount();
        var total = Merge.currentMerge.harvested.getCapacity();

        var value = cleverapps.UI.generateOnlyText(current + "/" + total, cleverapps.styles.FONTS.WAREHOUSE_CAPACITY_TEXT);

        var capacityBlock = new cleverapps.Layout([title, value], {
            direction: cleverapps.UI.VERTICAL
        });

        var price = Merge.currentMerge.harvested.getUpgradePrice();

        var content = new cleverapps.Layout([
            cleverapps.UI.generateOnlyText("WarehouseWindow.upgrade", cleverapps.styles.FONTS.BUTTON_TEXT),
            new TextWithIcon("$$" + price, { font: cleverapps.styles.FONTS.BUTTON_TEXT })
        ], {
            direction: cleverapps.UI.VERTICAL,
            padding: styles.button.padding
        });

        var upgradeButton = new cleverapps.UI.Button({
            content: content,
            width: styles.button.width,
            height: styles.button.height,
            onClicked: function () {
                if (cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.WAREHOUSE, price)) {
                    Merge.currentMerge.harvested.upgradeCapacity();
                }
            }
        });

        this.capacity = new cleverapps.Layout([upgradeButton, capacityBlock], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });
        this.capacity.setAnchorPoint(1, 0);
        this.window.addChild(this.capacity);

        this.capacity.value = value;
    },

    createEmptyTile: function () {
        var styles = cleverapps.styles.WarehouseWindow.emptyTile;

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize(styles);

        var background = cleverapps.UI.createScale9Sprite(bundles.warehousewindow.frames.tile_background);
        background.setContentSize(styles.background);
        background.setPositionRound(styles.background);
        container.addChild(background);

        var text = cleverapps.UI.generateOnlyText("WarehouseWindow.empty", cleverapps.styles.FONTS.WAREHOUSE_TILE_TITLE_TEXT);
        text.fitTo(styles.text.width);
        text.setPositionRound(styles.text);
        background.addChild(text);

        return container;
    },

    inflateWindow: function () {
        var bgSize = cleverapps.resolution.getBgSize();

        var safePadding = cleverapps.resolution.getSafePadding();
        this.window.setContentSize(bgSize.width, this.scroll.height);
        this.window.setPosition(bgSize.width / 2 - safePadding.left, 0);

        this.scroll.setContentSize2(bgSize.width, this.scroll.height);
        this.scroll.setPosition(this.window.width / 2, 0);

        this.updateBackground();
        this.updateCapacity();
    },

    updateBackground: function () {
        var styles = cleverapps.styles.WarehouseWindow;

        var width = this.scroll.width;
        var height = this.scroll.height;
        var safePadding = cleverapps.resolution.getSafePadding();

        this.background.setContentSize2(
            width + 2 * styles.background.padding,
            height + styles.background.padding + safePadding.bottom
        );
        this.background.setPosition(width / 2, -styles.background.padding - safePadding.bottom);

        this.background.removeAllChildren();

        var lines = Math.floor(this.background.width / styles.background.linesMargin);

        for (var i = 0; i < lines - 1; i++) {
            var line = cleverapps.UI.createScale9Sprite(bundles.warehousewindow.frames.background_line, cleverapps.UI.Scale9Rect.TwoPixelXY);
            line.setContentSize(this.background.width, line.height);
            line.setAnchorPoint(0.5, 0.5);
            line.setPosition(this.background.width / 2, this.background.height - styles.background.linesPadding - (i + 1) * styles.background.linesMargin);
            this.background.addChild(line);
        }
    },

    updateCapacity: function () {
        this.capacity.setPosition(this.scroll.width, this.scroll.height);
    },

    onWarehouseChanged: function () {
        var current = Merge.currentMerge.harvested.getItemsAmount();
        var total = Merge.currentMerge.harvested.getCapacity();

        if (Merge.currentMerge.harvested.isFull()) {
            this.capacity.value.setColor(cleverapps.styles.COLORS.COLOR_RED);
        } else {
            this.capacity.value.setColor(cleverapps.styles.COLORS.WHITE);
        }

        this.capacity.value.setString(current + "/" + total);
        this.capacity.reshape();
    },

    selectTile: function (tile) {
        if (this.selectedTile) {
            this.selectedTile.unselectTile();
        }

        this.selectedTile = tile;

        if (this.selectedTile) {
            this.selectedTile.selectTile();
        }
    },

    chooseAnimation: function () {
        return WINDOW_ANIMATIONS.fromBelow;
    },

    listBundles: function () {
        return ["warehousewindow"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    WAREHOUSE_TILE_TITLE_TEXT: {
        name: "nostroke",
        size: 42,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    WAREHOUSE_TILE_AMOUNT_TEXT: {
        name: "nostroke",
        size: 74,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    WAREHOUSE_CAPACITY_TEXT: {
        size: 64,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.WarehouseWindow = {
    background: {
        linesPadding: 30,
        linesMargin: 70,
        padding: 150
    },

    scroll: {
        margin: 30,
        padding: {
            x: 60,
            top: 70,
            bottom: 20
        }
    },

    capacity: {
        margin: 20,
        padding: {
            x: 20,
            y: 20
        },

        button: {
            width: 300,
            height: 140,

            padding: {
                top: 10
            }
        }
    },

    emptyTile: {
        width: 650,
        height: 540,

        background: {
            x: { align: "center" },
            y: { align: "center" },
            width: 650,
            height: 180
        },

        text: {
            x: { align: "center" },
            y: { align: "center" },
            width: 500
        }
    }
};