/**
 * Created by r4zi4l on 20.01.2021
 */

var LevelUpWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            name: "LevelUpWindow",
            content: this.createContent(),
            noBackground: true,
            closeButtonDelay: true,
            fireworks: cleverapps.config.name === "fairy" && {
                type: "fireworks",
                zOrder: 3
            },
            openSound: bundles.levelupwindow.urls.levelup_effect
        });
    },

    getPerson: function () {
        var firstHero = "dwarf";
        var secondHero = undefined;

        if (cleverapps.meta.selectedLocationId() === "collections") {
            firstHero = "keeper";
        } else if (cleverapps.gameLevel.getLevel() > 1) {
            firstHero = cleverapps.unitsLibrary.getActiveHero();
            secondHero = {
                role: "dwarf",
                emotion: "happy"
            };

            if (!firstHero || firstHero === "dwarf") {
                firstHero = "worker";
            }
        }

        return {
            left: {
                role: firstHero,
                emotion: "happy"
            },
            right: secondHero
        };
    },

    createContent: function () {
        var styles = cleverapps.styles.LevelUpWindow[cleverapps.meta.selectedLocationId()] || cleverapps.styles.LevelUpWindow["default"];

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles);

        var animation = new cleverapps.Spine(cleverapps.skins.getSlot("levelup_json") || bundles.levelupwindow.jsons.levelup_json);
        animation.setPositionRound(styles.animation);
        animation.setAnimationAndIdleAfter("animation", "idle");
        content.addChild(animation);

        if (styles.title) {
            var title = this.title = cleverapps.UI.generateOnlyText(Messages.get("LevelUpWindow.title"), cleverapps.styles.FONTS.LEVELUPWINDOW_TITLE_TEXT);
            title.setPositionRound(styles.title);
            title.fitTo(styles.title.width, styles.title.height);
            content.addChild(title);
        }

        var value = this.value = cleverapps.UI.generateOnlyText(cleverapps.gameLevel.getNextLevel(), cleverapps.skins.getSlot("levelUpWindowValueFont") || cleverapps.styles.FONTS.LEVELUPWINDOW_VALUE_TEXT);
        value.setPositionRound(styles.value);
        content.addChild(value);

        var rewardsTitle = cleverapps.UI.generateOnlyText("RewardsListComponent.rewardTitle", cleverapps.styles.FONTS.LEVELUPWINDOW_REWARD_TEXT);
        var rewardsList = this.rewardsList = new RewardsListComponent(cleverapps.gameLevel.getReward(), {
            font: cleverapps.styles.FONTS.LEVELUPWINDOW_REWARD_TEXT,
            columns: 6,
            noPrefix: true,
            small: styles.rewards.small,
            textDirection: cleverapps.UI.HORIZONTAL,
            event: cleverapps.EVENTS.EARN.LEVEL_REWARD
        });

        var rewards = this.rewards = new cleverapps.Layout([rewardsTitle, rewardsList], {
            direction: styles.rewards.direction,
            margin: styles.rewards.margin,
            padding: styles.rewards.padding
        });
        rewards.setPositionRound(styles.rewards);
        content.addChild(rewards);

        if (styles.rewardsBackground) {
            var rewardsBackground = this.rewardsBackground = cleverapps.UI.createScale9Sprite(bundles.levelupwindow.frames.rewards_background, cleverapps.UI.Scale9Rect.TwoPixelXY);
            rewardsBackground.setContentSize(rewards.width, rewardsBackground.height);
            rewardsBackground.setPositionRound(rewards.x, rewards.y + styles.rewardsBackground.dy);
            rewardsBackground.setLocalZOrder(-1);
            content.addChild(rewardsBackground);
        }

        return content;
    },

    onShow: function () {
        var styles = cleverapps.styles.LevelUpWindow[cleverapps.meta.selectedLocationId()] || cleverapps.styles.LevelUpWindow["default"];

        if (this.fireworks) {
            this.fireworks.start(1.8);
        }

        this._super();

        var showup = function (node, delay, duration) {
            node.setCascadeOpacityEnabledRecursively(true);
            node.setOpacity(0);
            var baseScale = node.scale;
            node.setScale(0);

            node.runAction(new cc.Sequence(
                new cc.DelayTime(delay || 0),
                new cc.ScaleTo(duration || 0, baseScale).easing(cc.easeBackOut())
            ));

            node.runAction(new cc.Sequence(
                new cc.DelayTime(delay || 0),
                new cc.FadeIn(duration || 0)
            ));
        };

        if (["wondermerge", "fairy"].indexOf(cleverapps.config.name) !== -1) {
            showup = function (node, delay, duration) {
                node.setCascadeOpacityEnabledRecursively(true);
                node.setOpacity(0);
                node.runAction(new cc.Sequence(
                    new cc.DelayTime(delay || 0),
                    new cc.FadeIn(duration || 0)
                ));
            };
        }

        if (this.title) {
            showup(this.title, styles.title.delay, styles.title.duration);
        }

        showup(this.value, styles.value.delay, styles.value.duration);
        showup(this.rewards, styles.rewards.delay, styles.rewards.duration);

        if (this.rewardsBackground) {
            var baseSize = this.rewardsBackground.getContentSize();

            this.rewardsBackground.setVisible(false);
            this.rewardsBackground.setOpacity(0);
            this.rewardsBackground.setContentSize(styles.rewardsBackground.minWidth, this.rewardsBackground.height);
            this.rewardsBackground.runAction(new cc.Sequence(
                new cc.DelayTime(styles.rewardsBackground.delay || 0),
                new cc.Show(),
                new cc.Spawn(
                    new Scale9SpriteResizeTo(0.5, baseSize),
                    new cc.FadeIn(0.5)
                )
            ));
        }
    },

    beforeCloseAnimation: function (callback) {
        cleverapps.gameLevel.moveToNextLevel();

        this.rewardsList.receiveRewards();
        this.rewardsList.receiveRewardsAnimation({
            callback: callback
        });
    },

    listBundles: function () {
        return ["levelupwindow"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LEVELUPWINDOW_TITLE_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    LEVELUPWINDOW_VALUE_TEXT: {
        size: 220,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    LEVELUPWINDOW_REWARD_TEXT: {
        name: "big_digits",
        size: 90,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.LevelUpWindow = {};
cleverapps.styles.LevelUpWindow["default"] = {
    width: 1100,
    height: 980,

    animation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 50 }
    },

    title: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 274 },
        width: 500,
        height: 0,

        delay: 0.15,
        duration: 0.4
    },

    value: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 30 },

        delay: 0,
        duration: 0.5
    },

    rewards: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 },
        direction: cleverapps.UI.VERTICAL,
        margin: 0,

        delay: 0.4,
        duration: 0.4
    }
};

cleverapps.styles.LevelUpWindow["collections"] = {
    width: 1100,
    height: 1000,

    animation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 50 }
    },

    title: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 385 },
        width: 500,
        height: 0,

        delay: 0,
        duration: 0.5
    },

    value: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 90 },

        delay: 0.9,
        duration: 0.5
    },

    rewards: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 },
        direction: cleverapps.UI.VERTICAL,
        margin: 0,

        delay: 0.4,
        duration: 0.4
    }
};
