/**
 * Created by Andrey Popov on 10.11.22
 */

var Climbable = function (unit) {
    UnitComponent.call(this, unit);

    if (!cleverapps.config.adminMode) {
        this.checkClimbTriggers();
    }
};

Climbable.prototype = Object.create(UnitComponent.prototype);
Climbable.prototype.constructor = Climbable;

Climbable.prototype.getActionInfo = function () {
    if (Map2d.currentMap.unitGreeters.greeters.includes(this.unit)) {
        var trigger = Climbable.TRIGGERS[cleverapps.meta.selectedLocationId()][Unit.GetKey(this.unit)];
        return {
            title: {
                code: trigger.code,
                stage: trigger.stage,
                icon: true
            },
            buttons: {
                locate: {
                    action: function () {
                        UnitLocator.find(Map2d.OPEN_UNIT, trigger);
                    }
                }
            }
        };
    }
};

Climbable.prototype.checkClimbTriggers = function () {
    var trigger = Climbable.TRIGGERS[cleverapps.meta.selectedLocationId()];
    if (trigger && trigger[Unit.GetKey(this.unit)]) {
        this.timeout = Merge.currentMerge.setTimeout(function () {
            Map2d.currentMap.unitGreeters.addGreeter(this.unit);
        }.bind(this), 0);
    }
};

Climbable.prototype.checkDependantClimbers = function (newUnit) {
    var triggers = Climbable.TRIGGERS[cleverapps.meta.selectedLocationId()];
    if (!triggers) {
        return;
    }

    var triggerKey = Object.keys(triggers).filter(function (triggerKey) {
        return Unit.Equals(triggers[triggerKey], newUnit);
    })[0];

    if (triggerKey) {
        var dependant = Map2d.currentMap.listAvailableUnits(Unit.ParseKey(triggerKey));
        dependant.forEach(function (unitToChangeStage) {
            var climbable = unitToChangeStage.findComponent(Climbable);
            if (climbable) {
                climbable.gotoNextStage();
            }
        });
    }
};

Climbable.prototype.getGreeting = function () {
    var trigger = Climbable.TRIGGERS[cleverapps.meta.selectedLocationId()];
    trigger = trigger && trigger[Unit.GetKey(this.unit)];

    if (trigger && Map2d.currentMap.listAvailableUnits(trigger).length > 0) {
        return function (f) {
            this.gotoNextStage(f);
        }.bind(this);
    }
};

Climbable.prototype.gotoNextStage = function (f) {
    f = f || function () {};

    if (this.climbed) {
        f();
        return;
    }
    this.climbed = true;
    this.unit.movable = false;

    var newUnit = new Unit({
        code: this.unit.code,
        stage: this.unit.stage + 1
    });

    var replace = function () {
        this.unit.takePrizes();
        this.unit.remove(true);

        newUnit.setPosition(this.unit.x, this.unit.y);
        Map2d.currentMap.onAddUnit(newUnit.x, newUnit.y, newUnit);
        Map2d.currentMap.onUnitAvailable(newUnit);
        Merge.currentMerge.counter.setTimeout(Map2d.mapEvent.bind(Merge.currentMerge, Map2d.SPAWN, { unit: newUnit }), 0);

        Map2d.currentMap.blockedGrounds.updateBlockedGrounds();
    }.bind(this);

    var view = this.view;

    if (view) {
        view.beforeStageUpdate();
        replace();
        view.updateStage(newUnit, function () {
            this.checkDependantClimbers(newUnit);
            f();
        }.bind(this));
    } else {
        replace();
        this.checkDependantClimbers(newUnit);
        f();
    }
};

Climbable.prototype.destructor = function () {
    Map2d.currentMap.unitGreeters.removeGreeter(this.unit);
    clearTimeout(this.timeout);
};

Climbable.TRIGGERS = {};