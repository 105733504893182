/**
 * Created by vtbelo on 17/03/21
 */

var PixelsPlanner = function (options) {
    if (options.isNewGame) {
        this.level = 0;
        this.time = Date.now();
        this.save(true);
    }

    this.stored = cleverapps.dataLoader.load(this.saveId());
    if (!this.stored && Merge.currentMerge.isMainGame()) {
        this.stored = cleverapps.GameSaver.loadProperty(Meta.SLOT_MAIN, "pixels");
    }

    this.onCreatePixel = function () {};

    this.init();
};

PixelsPlanner.prototype.destructor = function () {
    clearTimeout(this.timeout);
    this.timeout = undefined;
    this.inited = false;
};

PixelsPlanner.prototype.restart = function () {
    if (!this.inited) {
        return;
    }

    this.level = 0;
    this.save();

    if (this.timeout !== undefined) {
        this.planNextSwarm();
    }
};

PixelsPlanner.prototype.isAvailable = function () {
    if (Merge.currentMerge.isMainGame()) {
        return cleverapps.user.checkAvailable(cleverapps.Availables.PIXELS);
    }

    var available = PixelsPlanner.AVAILABLE[cleverapps.meta.selectedLocationId()];
    if (!available) {
        return false;
    }

    if (available.fog && !Map2d.currentMap.fogs.isOpened(available.fog)) {
        return false;
    }

    return true;
};

PixelsPlanner.prototype.init = function () {
    if (this.inited || cleverapps.config.editorMode || cleverapps.config.adminMode || cleverapps.config.wysiwygMode) {
        return;
    }

    if (!this.isAvailable()) {
        clearTimeout(this.timeout);
        this.timeout = new cleverapps.LongTimeout(this.init.bind(this), cleverapps.parseInterval(PixelsPlanner.BETWEEN_INIT_TIMEOUT));
        return;
    }

    this.inited = true;

    if (this.stored) {
        this.level = this.stored.level || 0;
        this.time = Math.min(this.stored.time || 0, Date.now());

        if (Date.now() > this.time + cleverapps.parseInterval(PixelsPlanner.CLEAR_TIMEOUT)) {
            this.level = 0;
            this.time = Date.now();
        }
    } else {
        this.level = 2;
        this.time = Date.now();
        this.firstTime = true;
    }

    this.save();
    this.planNextSwarm();
};

PixelsPlanner.prototype.saveId = function () {
    return DataLoader.TYPES.PIXELS_PLANNER + Merge.currentMerge.slot;
};

PixelsPlanner.prototype.save = function (force) {
    if (!this.inited && !force) {
        return;
    }

    cleverapps.dataLoader.save(this.saveId(), {
        level: this.level,
        time: this.time
    });
};
PixelsPlanner.prototype.createSwarm = function (options) {
    if (!this.inited) {
        return;
    }

    options = options || { amount: cleverapps.Random.choose(PixelsPlanner.LEVELS[this.level].amount) };

    new PixelsSwarm(this, options);
};

PixelsPlanner.prototype.nextLevel = function () {
    if (this.level < PixelsPlanner.LEVELS.length - 1) {
        this.level++;
    }

    this.time = Date.now();
    this.save();
};

PixelsPlanner.prototype.startCurrentLevel = function () {
    if (!this.inited || this.timeout !== undefined) {
        return;
    }

    this.time = Date.now();
    this.save();

    this.planNextSwarm();
};

PixelsPlanner.prototype.planNextSwarm = function () {
    var waitTime = PixelsPlanner.LEVELS[this.level].timeout;
    if (this.firstTime) {
        this.firstTime = undefined;
        waitTime = PixelsPlanner.FIRST_TIMEOUT;
        if (Merge.currentMerge.isMainGame() && cleverapps.unitsLibrary.listAvailableByType("fruit").length > 2) {
            waitTime = PixelsPlanner.FIRST_LONG_TIMEOUT;
        }
    }

    clearTimeout(this.timeout);
    this.timeout = new cleverapps.LongTimeout(function () {
        this.timeout = undefined;
        this.createSwarm();
    }.bind(this), this.time + cleverapps.parseInterval(waitTime) - Date.now());
};

PixelsPlanner.LEVELS = [{
    amount: [4, 5, 5, 6],
    timeout: "30 seconds"
}, {
    amount: [3, 4],
    timeout: "3 minutes"
}, {
    amount: [2, 3, 3, 4],
    timeout: "4 minutes"
}, {
    amount: [2, 3],
    timeout: "5 minutes"
}, {
    amount: [2],
    timeout: "10 minutes"
}];

PixelsPlanner.AVAILABLE = {
    xmas: {
        fog: "fog5"
    },
    halloween: {
        fog: "fog6"
    },
    dragonia: {
        fog: "fog5"
    },
    dragonia2: {
        fog: "fog3"
    },
    dragonia3: {
        fog: "fog3"
    },
    undersea: {
        fog: "fog3"
    },
    undersea2: {
        fog: "fog3"
    },
    undersea3: {
        fog: "fog3"
    },
    blackfriday: {
        fog: "fog3"
    },
    rapunzel: {
        fog: "fog3"
    },
    rapunzel2: {
        fog: "fog3"
    },
    rapunzel3: {
        fog: "fog3"
    },
    easter: {
        fog: "fog3"
    },
    china: {
        fog: "fog3"
    },
    spring: {
        fog: "fog3"
    }
};

PixelsPlanner.BETWEEN_INIT_TIMEOUT = "30 seconds";
PixelsPlanner.FIRST_TIMEOUT = "2 minutes";
PixelsPlanner.FIRST_LONG_TIMEOUT = "10 minutes";
PixelsPlanner.CLEAR_TIMEOUT = "1 hour";
