/**
 * Created by Anastasiya on 20.06.2024
 */

var PixelOfferLogic = function (offer) {
    cleverapps.EventEmitter.call(this);
    this.offer = offer;
};

PixelOfferLogic.prototype = Object.create(cleverapps.EventEmitter.prototype);
PixelOfferLogic.prototype.constructor = PixelOfferLogic;

PixelOfferLogic.prototype.getStage = function () {
    return this.offer.stage || 0;
};

PixelOfferLogic.prototype.getReward = function () {
    return PixelOfferLogic.LEVELS[this.getStage()].reward;
};

PixelOfferLogic.prototype.getPrice = function () {
    return PixelOfferLogic.LEVELS[this.getStage()].price;
};

PixelOfferLogic.prototype.getProduct = function () {
    return Product.CreateByHardPrice(this.getPrice(), {
        type: "offers",
        expedition: cleverapps.meta.selectedLocationId()
    });
};

PixelOfferLogic.prototype.buyProduct = function () {
    var product = this.getProduct();

    if (product) {
        product.buy(this.processProductBought.bind(this));
    } else {
        this.processProductBought(false);
    }
};

PixelOfferLogic.prototype.processProductBought = function (success) {
    if (success) {
        this.trigger("onProductBought");
        if (this.offer.stage === PixelOfferLogic.LEVELS.length - 1) {
            this.offer.stage = -1;
        }
        this.offer.incStage();
        this.offer.complete();
    }
};

PixelOfferLogic.prototype.getTimeLeft = function () {
    return Math.max(0, (this.offer.started || 0) + cleverapps.parseInterval(Offers[this.offer.type].duration) - Date.now());
};

PixelOfferLogic.prototype.isIconAvailable = function () {
    if (cleverapps.gameModes.skipSpecialOffer) {
        return false;
    }
    return true;
};

PixelOfferLogic.prototype.hasIconAttention = function () {
    return false;
};

PixelOfferLogic.LEVELS = [
    {
        price: 99,
        reward: {
            energy: 200,
            hard: 50
        }
    }, {
        price: 299,
        reward: {
            wands: 250,
            unit: { code: "herochest", stage: 2, amount: 1 }
        }
    }, {
        price: 999,
        reward: {
            energy: 500,
            unit: { code: "crystal", stage: 3, amount: 2 }
        }
    }, {
        price: 375,
        reward: {
            unit: { code: "worker", stage: 4, amount: 6 }
        }
    }, {
        price: 299,
        reward: {
            wands: 100,
            unit: { code: "crystal", stage: 3, amount: 1 }
        }
    }, {
        price: 999,
        reward: {
            energy: 700,
            unit: { code: "worker", stage: 4, amount: 3 }
        }
    }
];