/**
 * Created by mac on 12/15/20
 */

var FogTileView = cc.Node.extend({
    ctor: function (fogTile) {
        this._super();
        this.fogTile = fogTile;

        this.setCascadeColorEnabled(true);
        this.setCascadeOpacityEnabled(true);

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.styles.Map2dView.cell);

        this.borders = {};

        this.tileView = new cc.Node();
        this.tileView.setCascadeColorEnabled(true);
        this.tileView.setCascadeOpacityEnabled(true);
        this.tileView.setContentSize2(this.getContentSize());
        this.tileView.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.tileView);

        fogTile.setView(this);

        this.onUpdateState(fogTile.state, true);

        if (fogTile.isHead && !cleverapps.config.editorMode && !fogTile.fogBlock.options.headless) {
            var mapView = Map2d.currentMap.getMapView();
            var fogBlockView = this.fogBlockView = new FogBlockView(fogTile.fogBlock);
            fogBlockView.setPositionRound(mapView.alignInGrid(fogTile.x, fogTile.y));
            fogBlockView.setLocalZOrder(mapView.getFogViewLocalZOrder(fogBlockView.x, fogBlockView.y));
            mapView.cloudsAbove.addChild(fogBlockView);

            fogBlockView.onUpdateBlocker(fogTile.fogBlock.state, true);
            fogBlockView.onUpdateState(fogTile.fogBlock.state, true);
            fogBlockView.onWandsChanged(true);
        }
    },

    cleanup: function () {
        this._super();

        this.fogTile.removeView();
    },

    onOpenFogBlock: function () {
        delete this.fogBlockView;
    },

    removeFromParent: function (cleanup) {
        this._super(cleanup);

        [this.fogBlockView, this.fogUnit, this.balloon, this.unitNode].forEach(function (child) {
            if (child) {
                child.removeFromParent(cleanup);
            }
        });

        delete this.fogBlockView;
        delete this.fogUnit;
        delete this.balloon;
        delete this.unitNode;
    },

    checkTouchInside: function (touch) {
        if (this.fogUnit) {
            if (!this.isDisplayed()) {
                return false;
            }

            if (cleverapps.UI.targetContainPoint(this.fogUnit, this.fogUnit.convertTouchToNodeSpace(touch))) {
                return true;
            }
        }
        return false;
    },

    pointOfInterestsSqueeze: function () {
        if (this.fogUnit) {
            this.fogUnit.runAction(new cc.Sequence(
                new cc.ScaleTo(0.1, 1.1, 0.95),
                new cc.ScaleTo(0.1, 1, 1)
            ));
        }
    },

    onClick: function () {
        if (this.fogTile.fogBlock.isMoney() && this.fogTile.state === FogBlock.CANOPEN) {
            this.fogTile.fogBlock.showBuyWindow();
        } else {
            this.clickAnimation();
            cleverapps.audio.playSound(bundles.click_sounds.urls.fog);
        }
    },

    onUpdateState: function (state, silent) {
        var styles = this.getStyles();

        if (state === FogBlock.CANOPEN) {
            this.addBalloon();
        }

        var oldTileState = this.state;

        if (state === FogBlock.CANTOPEN || state === FogBlock.CANOPEN) {
            if (this.state !== FogTileView.GROUND_STATE) {
                this.state = FogTileView.GROUND_STATE;

                if (styles.groundStateOnly && oldTileState === FogTileView.FULL_STATE) {
                    this.playFogDisappearEffect();
                } else {
                    this.showGroundStateAnimation(silent);
                }
            }
        } else if (this.state !== FogTileView.FULL_STATE) {
            this.state = FogTileView.FULL_STATE;

            if (styles.groundStateOnly) {
                this.showGroundStateAnimation(silent);
            } else {
                this.showFullStateAnimation();
            }

            this.updateBorders(silent);
        }

        if (state === FogBlock.NOTREADY) {
            this.updateBorders(silent);
        }
    },

    showPointOfInterest: function () {
        var styles = this.getStyles();

        var visibleUnit = this.fogTile.getFakeUnit();
        if (visibleUnit.head) {
            return;
        }

        if (this.sprite) {
            this.sprite.removeFromParent();
            this.sprite = undefined;
        }

        this.fogUnit = new cc.Node();
        this.fogUnit.setCascadeOpacityEnabled(true);
        this.fogUnit.setCascadeColorEnabled(true);

        var poiAnimation = styles.pointsOfInterest.animation || cleverapps.skins.getSlot("getPoiAnimation", visibleUnit);
        var unitImage = this.fogUnit.unitImage = UnitView.getUnitImage(visibleUnit, {
            keepLazy: true,
            defaultAnimation: poiAnimation,
            alignAnchorX: true,
            alignAnchorY: true
        });
        this.fogUnit.setAnchorPoint(unitImage.anchorX, unitImage.anchorY);
        this.fogUnit.setContentSize(unitImage.getContentSize());
        unitImage.setAnchorPoint(0.5, 0.5);
        unitImage.setPositionRound(this.fogUnit.width / 2, this.fogUnit.height / 2);
        this.fogUnit.addChild(unitImage);

        if (styles.pointsOfInterest.center && !this.noPoiFog()) {
            var mapView = Map2d.currentMap.getMapView();
            this.fogUnit.setPositionRound(mapView.alignInGrid(this.fogTile.x, this.fogTile.y));
            var zDelta = this.fogTile.y * Map2d.MAXSIZE - this.fogTile.x;
            this.fogUnit.setLocalZOrder(-1000000 + zDelta);
            mapView.cloudsAbove.addChild(this.fogUnit);
            this.additionalClickView = this.fogUnit;
        } else {
            this.fogUnit.setPositionRound(this.tileView.width * styles.anchorX, this.tileView.height * styles.anchorY);
            this.fogUnit.setLocalZOrder(styles.borders.center.zOrder);
            this.tileView.addChild(this.fogUnit);
        }

        if (styles.pointsOfInterest.likeGroundState) {
            var unitStyles = this.getUnitStyles(visibleUnit);
            this.fogUnit.setOpacity(styles.pointsOfInterest.unitOpacity ? styles.pointsOfInterest.unitOpacity : unitStyles.opacity);
            this.fogUnit.setColor(unitStyles.color);
            if (unitImage.clearTrack) {
                unitImage.clearTrack(0);
            }
        }

        MultiCellView(visibleUnit, this.fogUnit);
    },

    noPoiFog: function () {
        return cleverapps.skins.getSlot("noPoiFog", this.fogTile.getFakeUnit());
    },

    showFullStateAnimation: function () {
        var styles = this.getStyles();

        if (cleverapps.config.editorMode) {
            styles = cleverapps.styles.EditorFogTileView;
        }

        this.tileView.setAnchorPoint(styles.anchorX, styles.anchorY);

        var isPoi = this.fogTile.isPointOfInterest();
        if (isPoi && !cleverapps.config.editorMode) {
            this.showPointOfInterest();
        }

        if (cleverapps.config.editorMode || !isPoi || (!this.noPoiFog() && styles.pointsOfInterest.center)) {
            if (!this.fogTile.fake) {
                this.sprite = new cc.Sprite(this.getFogImage());
            } else {
                this.sprite = new cc.Node();
                this.sprite.setContentSize2(this.getContentSize());
                this.sprite.setAnchorPoint(0.5, 0.5);
            }
            this.sprite.setLocalZOrder(styles.borders.center.zOrder);
            this.sprite.setPositionRound(styles.borders.center);

            this.tileView.addChild(this.sprite);
        }
    },

    showGroundStateAnimation: function (silent) {
        if (silent) {
            this.startFogDisappearAnimation(silent);
            return;
        }

        this.stopAllActions();
        this.fogDisappearAction = this.runAction(new cc.Sequence(
            new cc.DelayTime(this.fogTile.getOpenDelay() / 1000),
            new cc.CallFunc(function () {
                this.startFogDisappearAnimation(silent);
            }.bind(this))
        ));

        var styles = this.getStyles();
        if (styles && styles.whirlwind) {
            return;
        }

        Object.keys(this.borders).forEach(function (dir) {
            cleverapps.audio.playSound(this.fogTile.getFogBundle().urls["hide_borders_" + this.fogTile.getFogType()]);

            this.borders[dir].runAction(new cc.Spawn(
                new cc.MoveBy(0.2, 0, -15),
                new cc.FadeOut(0.2)
            ));
        }, this);
    },

    getUnitStyles: function (unit) {
        var styles = this.getStyles();
        if (styles.unitStyles) {
            return styles.unitStyles;
        }
        var unitStyles = cleverapps.styles.FogTileView.fogUnits[Unit.GetKey(unit)] || cleverapps.styles.FogTileView.fogUnits.default;
        if (this.fogTile.fogBlock.isMoney()) {
            unitStyles = cleverapps.styles.FogTileView.moneyFogUnits[Unit.GetKey(unit)] || cleverapps.styles.FogTileView.moneyFogUnits.default;
        }
        return unitStyles;
    },

    oldFogDisappearAnimation: function (oldFog) {
        this.customDisappearFogUnitAnimation(oldFog);

        var styles = this.getStyles();
        if (styles && styles.dispelling) {
            this.dispellingDisappearAnimation(oldFog, styles.dispelling);
        } else if (styles && styles.whirlwind) {
            this.windDisappearAnimation(oldFog, styles.whirlwind);
        } else {
            this.plantsDisappearAnimation(oldFog);
        }

        if (this.unitNode && this.unitNode.unitImage instanceof cleverapps.Spine) {
            this.unitNode.unitImage.setVisible(false);
        }

        this.tileView.setOpacity(0);
        this.tileView.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.FadeIn(0.3),
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                this.unitNode && this.unitNode.unitImage.setVisible(true);
            }.bind(this))
        ));

        this.playFogDisappearEffect();
    },

    playFogDisappearEffect: function () {
        var styles = this.getStyles();

        var sound = cleverapps.skins.getSlot("fog_disappear_effect") || this.fogTile.getFogBundle().urls["open_effect_" + this.fogTile.getFogType()];

        cleverapps.audio.playSound(sound, { throttle: styles && styles.dispelling && styles.dispelling.duration });
    },

    customDisappearFogUnitAnimation: function (oldFog) {
        if (oldFog.fogUnit && oldFog.fogUnit.unitImage instanceof cleverapps.Spine) {
            oldFog.fogUnit.setCascadeOpacityEnabled(false);

            var unitStyles = this.getUnitStyles(this.fogTile.getFakeUnit());

            oldFog.fogUnit.unitImage.runAction(new cc.Sequence(
                new cc.DelayTime(0.1),
                new cc.FadeTo(0.5, unitStyles.opacity).easing(cc.easeIn(1.5))
            ));
        }
    },

    dispellingDisappearAnimation: function (oldFog, styles) {
        if (this.fogTile.isPointOfInterest()) {
            if (oldFog.fogUnit && oldFog.fogUnit.unitImage) {
                oldFog.fogUnit.unitImage.stopAllActions();
            }

            oldFog.runAction(new cc.Sequence(
                new cc.DelayTime(1),
                new cc.RemoveSelf()
            ));
            return;
        }

        oldFog.fog.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.ScaleTo(0.17, 1.1),
            new cc.ScaleTo(0.17, 0)
        ));

        oldFog.runAction(new cc.Sequence(
            new cc.DelayTime(0.8),
            new cc.FadeOut(0.17),
            new cc.RemoveSelf()
        ));

        var animation = new cleverapps.Spine(bundles.fog_animations.jsons.dispelling);
        animation.setPositionRound(styles);
        animation.setCompleteListenerRemove();
        this.addChild(animation);
        animation.replaceParentSamePlace(Map2d.currentMap.getMapView().animations);
        animation.setAnimation(0, cleverapps.Random.choose(animation.listAnimations(), this.fogTile.id), false);
    },

    windDisappearAnimation: function (oldFog, styles) {
        oldFog.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.FadeOut(0.5),
            new cc.RemoveSelf()
        ));

        oldFog.fog.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.ScaleTo(0.5, 1.3)
        ));

        oldFog.borders.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.ScaleTo(0.5, 1.3)
        ));

        var animation = new cleverapps.Spine(cleverapps.skins.getSlot("whirlwind"));
        if (styles.timeScale) {
            animation.setTimeScale(styles.timeScale);
        }
        animation.setAnimation(0, "animation", false);
        animation.setPositionRound(styles);
        animation.setCompleteListenerRemove();
        this.addChild(animation);
        animation.replaceParentSamePlace(Map2d.currentMap.getMapView().animations);
    },

    plantsDisappearAnimation: function (oldFog) {
        oldFog.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.FadeOut(0.5),
            new cc.RemoveSelf()
        ));

        oldFog.fog.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.Spawn(
                new cc.MoveBy(0.3, 0, 15),
                new cc.ScaleTo(0.3, 1.2).easing(cc.easeBackOut())
            ),
            new cc.MoveBy(0.2, 0, -15)
        ));
    },

    addFakeUnitNode: function (fakeUnit) {
        var unitNode = this.unitNode = new cc.Node();

        unitNode.setPositionRound(this.tileView.width / 2, this.tileView.height / 2);
        unitNode.setCascadeOpacityEnabled(true);
        unitNode.setCascadeColorEnabled(true);

        var unitStyles = this.getUnitStyles(fakeUnit);
        unitNode.setOpacity(unitStyles.opacity);
        unitNode.setColor(unitStyles.color);
        unitNode.setLocalZOrder(unitStyles.zOrder || 0);
        this.tileView.addChild(unitNode);

        var unitImage = UnitView.getUnitImage(fakeUnit, {
            keepLazy: true,
            alignAnchorX: true,
            alignAnchorY: true
        });
        unitNode.setAnchorPoint(unitImage.anchorX, unitImage.anchorY);
        unitNode.setContentSize(unitImage.getContentSize());
        unitImage.setAnchorPoint(0.5, 0.5);
        unitImage.setPositionRound(unitNode.width / 2, unitNode.height / 2);

        if (unitImage.clearTrack) {
            unitImage.clearTrack(0);
        }

        this.unitNode.unitImage = unitImage;
        unitNode.addChild(unitImage);

        if (fakeUnit.grounded) {
            var grounded = new cc.Sprite(GroundedView.getImage(fakeUnit.x, fakeUnit.y));
            grounded.setOpacity(unitStyles.opacity * 130 % 255);
            grounded.setColor(unitStyles.color);
            grounded.setLocalZOrder((unitStyles.zOrder - 1) || 0);
            this.tileView.addChild(grounded);
            grounded.setPositionRound(this.tileView.width / 2, this.tileView.height / 2);
        }

        MultiCellView(fakeUnit, unitNode);
    },

    startFogDisappearAnimation: function (silent) {
        if (this.fogTile.fake) {
            return;
        }

        var styles = this.getStyles();

        if (!silent) {
            var oldFog = new cc.Node();
            oldFog.setCascadeOpacityEnabled(true);
            oldFog.setContentSize(this.tileView.getContentSize());
            oldFog.setPositionRound(this.tileView.width / 2, this.tileView.height / 2);
            oldFog.setAnchorPoint(this.tileView.getAnchorPoint());
            this.addChild(oldFog);
            oldFog.fog = new cc.Node();
            oldFog.fog.setCascadeOpacityEnabled(true);
            oldFog.fog.setAnchorPoint(0.5, 0.5);
            oldFog.fog.setContentSize(oldFog.getContentSize());
            oldFog.fog.setPositionRound(oldFog.width / 2, oldFog.height / 2);
            oldFog.addChild(oldFog.fog);
            oldFog.borders = new cc.Node();
            oldFog.borders.setCascadeOpacityEnabled(true);
            oldFog.borders.setAnchorPoint(0.5, 0.5);
            oldFog.borders.setContentSize(oldFog.getContentSize());
            oldFog.borders.setPositionRound(oldFog.width / 2, oldFog.height / 2);
            oldFog.borders.setLocalZOrder(-1);
            oldFog.addChild(oldFog.borders);
        }

        if (this.sprite) {
            if (!silent) {
                this.sprite.replaceParent(oldFog.fog);
            } else {
                this.sprite.removeFromParent();
            }
            this.sprite = undefined;
        }
        Object.keys(this.borders).forEach(function (dir) {
            if (!silent) {
                this.borders[dir].replaceParentSamePlace(oldFog.borders);
            } else {
                this.borders[dir].removeFromParent();
            }
        }, this);
        this.borders = {};
        if (this.fogUnit) {
            if (!silent) {
                oldFog.fogUnit = this.fogUnit;
                this.fogUnit.replaceParentSamePlace(oldFog);
            } else {
                this.fogUnit.removeFromParent();
            }
            this.fogUnit = undefined;
            this.additionalClickView = undefined;
        }

        this.tileView.setAnchorPoint(0.5, 0.5);

        if (Map2d.currentMap.isWaterUnit(this.fogTile.x, this.fogTile.y)) {
            this.sprite = new cc.Node();
        } else {
            this.sprite = new cc.Sprite(this.getGroundFogImage());
        }

        this.tileView.addChild(this.sprite);
        this.sprite.setPositionRound(styles.groundFog);
        this.sprite.setLocalZOrder(-1);

        if (this.fogTile.fogBlock.isMoney()) {
            this.sprite.setColor(new cc.Color(255, 150, 255, 255));
            var spine = new cleverapps.Spine(bundles.fog.jsons.buy_fog_tile_json);
            spine.setTimeScale(0.5 + Math.random());
            spine.setPositionRound(this.sprite.width / 2, this.sprite.height / 2);
            this.sprite.addChild(spine);
            spine.setAnimation(0, "animation", true);
        }

        var fakeUnit = this.fogTile.getFakeUnit();

        if (fakeUnit && !fakeUnit.head) {
            this.addFakeUnitNode(fakeUnit);
        }

        var fogAsColorForUnits = cleverapps.skins.getSlot("fogAsColorForUnits");

        if (this.fogTile.state === FogBlock.CANTOPEN && fogAsColorForUnits) {
            this.sprite.setVisible(false);
            if (this.unitNode) {
                this.unitNode.setColor(fogAsColorForUnits.color);
                this.unitNode.setOpacity(fogAsColorForUnits.opacity);
            }
        }

        if (!silent && oldFog) {
            this.oldFogDisappearAnimation(oldFog);
        }
    },

    getStyles: function () {
        return cleverapps.styles.FogTileView[this.fogTile.getFogType()];
    },

    getFogImage: function () {
        var images;

        if (cleverapps.environment.isEditorScene()) {
            images = [bundles.fog.frames.fog_editor_png];
        } else {
            var textures = [1, 2, 3, 4].map(function (num) {
                return "fog" + num + "_png_" + this.fogTile.getFogType();
            }.bind(this));
            images = textures.map(function (texture) {
                return this.fogTile.getFogBundle().frames[texture];
            }.bind(this)).filter(Boolean);
        }

        if (images.length === 1) {
            return images[0];
        }

        return images[cleverapps.Random.random(0, images.length - 1, this.fogTile.x * 1000 + this.fogTile.y)];
    },

    getGroundFogImage: function () {
        var bundle = this.fogTile.getFogBundle();
        var type = this.fogTile.getFogType();

        var images = ["ground_fog_png_", "ground_fog1_png_", "ground_fog2_png_", "ground_fog3_png_"].map(function (frame) {
            return bundle.frames[frame + type];
        }).filter(Boolean);

        if (images.length === 1) {
            return images[0];
        }

        return images[cleverapps.Random.random(0, images.length - 1, this.fogTile.x * 1000 + this.fogTile.y)];
    },

    updateBorders: function (silent) {
        var styles = this.getStyles();

        if (cleverapps.config.editorMode || this.fogTile.fake || styles.noBorder) {
            return;
        }

        var images = this.fogTile.getFogBundle().frames;
        var effect = this.fogTile.getFogBundle().urls["show_borders_" + this.fogTile.getFogType()];

        var dirs = {
            down_left: { x: -1, y: 0 },
            down_right: { x: 0, y: 1 },
            up_left: { x: 0, y: -1 },
            up_right: { x: 1, y: 0 }
        };
        for (var dir in dirs) {
            var image = images["fog_" + dir + "_png_" + this.fogTile.getFogType()];

            if (this.borders[dir] || !image || !this.needsBorder(styles, dirs[dir])) {
                continue;
            }

            var border = this.border = new cc.Sprite(image);
            this.tileView.addChild(border);
            border.setPositionRound(styles.borders[dir]);
            border.setLocalZOrder(styles.borders[dir].zOrder);
            this.borders[dir] = border;

            if (!silent) {
                cleverapps.audio.playSound(effect);
            }
        }
    },

    needsBorder: function (styles, offset) {
        var isPoi = this.fogTile.isPointOfInterest();
        if (isPoi && (this.noPoiFog() || !styles.bordersEverywhere)) {
            return false;
        }

        if (!styles.bordersEverywhere && this.fogTile.state !== FogBlock.NOTREADY) {
            return false;
        }

        var x = this.fogTile.x + offset.x;
        var y = this.fogTile.y + offset.y;
        var fog = Map2d.currentMap.getFog(x, y);

        if (fog === undefined) {
            return true;
        }

        if (fog.fogBlock === this.fogTile.fogBlock) {
            return false;
        }

        return true;
    },

    clickAnimation: function () {
        var clikView = this.clickView = this;
        if (this.state === FogTileView.GROUND_STATE) {
            clikView = this.clickView = this.sprite;
        }
        this.tileView.stopAllActions();
        var state = this.fogTile.state;
        var tintColor = 230;
        if (state === FogBlock.CANTOPEN || state === FogBlock.CANOPEN) {
            tintColor = 210;
        }
        var iteration1 = new cc.Sequence(
            new cc.TintTo(0.3, tintColor, tintColor, tintColor),
            new cc.TintTo(0.3, 255, 255, 255)
        );
        var iteration2 = new cc.Sequence(
            new cc.TintTo(0.3, tintColor, tintColor, tintColor),
            new cc.TintTo(0.3, 255, 255, 255)
        );

        this.stopClickAction();
        this.stopAdditionalClickAction();

        this.clickAction = clikView.runAction(new cc.Sequence(
            iteration1,
            iteration1,
            iteration1,
            iteration1,
            iteration1
        ));

        if (this.additionalClickView) {
            this.additionalClickAction = this.additionalClickView.runAction(new cc.Sequence(
                iteration2,
                iteration2,
                iteration2,
                iteration2,
                iteration2
            ));
        }
    },

    onOpen: function (callback, silent) {
        callback = callback || function () {};

        var duration = 0.6;

        this.fogTile.clear();

        if (silent) {
            callback();
            this.removeFromParent();
            return;
        }

        if (this.sprite) {
            this.sprite.stopAllActions();
            this.sprite.runAction(new cc.FadeOut(duration));
        }

        if (this.border) {
            this.border.stopAllActions();
            this.border.runAction(new cc.FadeOut(duration));
        }

        this.openFogAction = this.runAction(new cc.Sequence(
            new cc.DelayTime(duration),
            new cc.RemoveSelf()
        )).setFinalize(callback);
    },

    destructFog: function () {
        var animation = new cleverapps.Spine(bundles.merge_animations.jsons.destruction_json);
        animation.setPositionRound(this.width / 2, this.height / 2);
        animation.setAnimation(0, "animation", false);
        this.addChild(animation);

        animation.replaceParentSamePlace(this);

        cleverapps.audio.playSound(bundles.merge.urls.unit_desturction_effect);

        this.destructFogAction = this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 1.3, 1.3).easing(cc.easeIn(1)),
            new cc.ScaleTo(0.2, 0.5, 0.5).easing(cc.easeOut(1)),
            new cc.RemoveSelf()
        ));
    },

    addBalloon: function () {
        if (this.balloon || !this.fogTile.balloon) {
            return;
        }

        var FogBalloonViewClass = FogBalloonView;
        if (cleverapps.config.editorMode && typeof EditorFogBalloonView !== "undefined") {
            FogBalloonViewClass = EditorFogBalloonView;
        }

        var mapView = Map2d.currentMap.getMapView();
        var balloon = this.balloon = new FogBalloonViewClass(this.fogTile.balloon);

        mapView.cloudsAbove.addChild(balloon);
    },

    clearAnimations: function () {
        this.stopClickAction();
        this.stopAdditionalClickAction();

        if (this.openFogAction && !this.openFogAction.isDone()) {
            this.stopAction(this.openFogAction);
            delete this.openFogAction;
        }
        if (this.fogDisappearAction && !this.fogDisappearAction.isDone()) {
            this.stopAction(this.fogDisappearAction);
            delete this.fogDisappearAction;
        }
        if (this.destructFogAction && !this.destructFogAction.isDone()) {
            this.stopAction(this.destructFogAction);
            delete this.destructFogAction;
        }
    },

    stopClickAction: function () {
        if (this.clickView && this.clickAction && !this.clickAction.isDone()) {
            this.clickView.stopAction(this.clickAction);
            this.clickView.setColor(new cc.Color(255, 255, 255, 255));
        }
    },

    stopAdditionalClickAction: function () {
        if (this.additionalClickView && this.additionalClickAction && !this.additionalClickAction.isDone()) {
            this.additionalClickView.stopAction(this.additionalClickAction);
            this.additionalClickView.setColor(new cc.Color(255, 255, 255, 255));
        }
    }
});

FogTileView.FULL_STATE = 0;
FogTileView.GROUND_STATE = 1;

cleverapps.styles.FogTileView = {
    fogUnits: {
        "default": {
            color: new cc.Color(0, 150, 255, 255),
            opacity: 120
        }
    },

    moneyFogUnits: {
        "default": {
            color: new cc.Color(255, 100, 100, 255),
            opacity: 120
        }
    }
};

cleverapps.styles.FogTileView["medieval"] = {
    anchorX: 0.5,
    anchorY: 0.22,

    bordersEverywhere: true,

    pointsOfInterest: {
        center: true,
        animation: "idle_fog"
    },

    groundFog: {
        x: { align: "center" },
        y: { align: "center" }
    },

    borders: {
        center: {
            x: { align: "center", dx: 1 },
            y: { align: "center", dy: -13 },
            zOrder: 0
        },
        down_left: {
            x: { align: "left", dx: -12 },
            y: { align: "bottom", dy: -32 },
            zOrder: 0
        },
        down_right: {
            x: { align: "right", dx: 10 },
            y: { align: "bottom", dy: -36 },
            zOrder: 0
        },
        up_left: {
            x: { align: "left", dx: -12 },
            y: { align: "top", dy: 4 },
            zOrder: 0
        },
        up_right: {
            x: { align: "right", dx: 16 },
            y: { align: "top", dy: 10 },
            zOrder: 0
        }
    },

    whirlwind: {
        duration: 500,
        timeScale: 1.5,
        x: { align: "center", dx: 35 },
        y: { align: "center", dy: 25 }
    }
};

cleverapps.styles.FogTileView["tropicislands"] = {
    anchorX: 0.5,
    anchorY: 0.22,

    pointsOfInterest: {
        center: false,
        animation: false
    },

    groundFog: {
        x: { align: "center" },
        y: { align: "center" }
    },

    borders: {
        center: {
            x: { align: "center", dx: -5 },
            y: { align: "center", dy: -10 },
            zOrder: 10
        },
        down_left: {
            x: { align: "left", dx: -10 },
            y: { align: "bottom", dy: -33 },
            zOrder: 11
        },
        down_right: {
            x: { align: "right", dx: 12 },
            y: { align: "bottom", dy: -34 },
            zOrder: 11
        },
        up_left: {
            x: { align: "left", dx: -9 },
            y: { align: "top", dy: 16 },
            zOrder: 9
        },
        up_right: {
            x: { align: "right", dx: 12 },
            y: { align: "top", dy: 16 },
            zOrder: 9
        }
    }
};

cleverapps.styles.FogTileView["fairyland"] = {
    anchorX: 0.5,
    anchorY: 0.22,

    pointsOfInterest: {
        center: false,
        animation: false,
        likeGroundState: true,
        unitOpacity: 180
    },

    groundFog: {
        x: { align: "center" },
        y: { align: "center" }
    },

    borders: {
        center: {
            x: { align: "center", dx: -2 },
            y: { align: "center", dy: -12 },
            zOrder: 10
        },
        down_left: {
            x: { align: "left", dx: -18 },
            y: { align: "bottom", dy: -29 },
            zOrder: 11
        },
        down_right: {
            x: { align: "right", dx: 9 },
            y: { align: "bottom", dy: -30 },
            zOrder: 11
        },
        up_left: {
            x: { align: "left", dx: -17 },
            y: { align: "top", dy: 36 },
            zOrder: 9
        },
        up_right: {
            x: { align: "right", dx: 9 },
            y: { align: "top", dy: 36 },
            zOrder: 9
        }
    },

    dispelling: {
        duration: 1200,
        x: { align: "center", dx: -2 },
        y: { align: "center", dy: 30 }
    }
};

cleverapps.styles.FogTileView["cloudislands"] = {
    anchorX: 0.49,
    anchorY: 0.9,

    pointsOfInterest: {
        center: false
    },

    groundFog: {
        x: { align: "center" },
        y: { align: "center" }
    },

    borders: {
        center: {
            x: { align: "center" },
            y: { align: "center", dy: 55 },
            zOrder: 0
        }
    }
};

cleverapps.styles.FogTileView["seabottom"] = {
    anchorX: 0.5,
    anchorY: 0.5,

    pointsOfInterest: {
        center: false
    },

    groundFog: {
        x: { align: "center" },
        y: { align: "center" }
    },

    borders: {
        center: {
            x: { align: "center" },
            y: { align: "center", dy: 10 },
            zOrder: 0
        }
    }
};

cleverapps.styles.FogTileView["heroes"] = {
    anchorX: 0.5,
    anchorY: 0.22,

    pointsOfInterest: {
        center: true,
        animation: "idle_fog"
    },

    groundFog: {
        x: { align: "center" },
        y: { align: "center" }
    },

    borders: {
        center: {
            x: { align: "center", dx: -2 },
            y: { align: "center", dy: -12 },
            zOrder: 10
        },
        down_left: {
            x: { align: "left", dx: -18 },
            y: { align: "bottom", dy: -29 },
            zOrder: 11
        },
        down_right: {
            x: { align: "right", dx: 9 },
            y: { align: "bottom", dy: -30 },
            zOrder: 11
        },
        up_left: {
            x: { align: "left", dx: -17 },
            y: { align: "top", dy: 36 },
            zOrder: 9
        },
        up_right: {
            x: { align: "right", dx: 9 },
            y: { align: "top", dy: 36 },
            zOrder: 9
        }
    },

    dispelling: {
        duration: 1200,
        x: { align: "center", dx: -2 },
        y: { align: "center", dy: 30 }
    }
};

cleverapps.styles.FogTileView["adventure"] = {
    anchorX: 0.5,
    anchorY: 0.5,

    groundStateOnly: true,

    pointsOfInterest: {
        center: false,
        animation: false
    },

    unitStyles: {
        color: new cc.Color(255, 255, 255, 255),
        opacity: 255,
        zOrder: -2
    },

    groundFog: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 30 }
    },

    borders: {
        center: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 },
            zOrder: 0
        }
    }
};

cleverapps.styles.FogTileView["homefix"] = {
    anchorX: 0.5,
    anchorY: 0.22,

    groundStateOnly: true,
    noBorder: true,
    bordersEverywhere: true,

    pointsOfInterest: {
        center: true,
        animation: "idle_fog"
    },

    groundFog: {
        x: { align: "center" },
        y: { align: "center" }
    }
};

cleverapps.styles.FogTileView["hearts"] = {
    anchorX: 0.5,
    anchorY: 0.22,

    bordersEverywhere: true,

    pointsOfInterest: {
        center: true,
        animation: "idle_fog"
    },

    groundFog: {
        x: { align: "center" },
        y: { align: "center" }
    },

    borders: {
        center: {
            x: { align: "center", dx: 1 },
            y: { align: "center", dy: -13 },
            zOrder: 0
        },
        down_left: {
            x: { align: "left", dx: -12 },
            y: { align: "bottom", dy: -32 },
            zOrder: 0
        },
        down_right: {
            x: { align: "right", dx: 10 },
            y: { align: "bottom", dy: -36 },
            zOrder: 0
        },
        up_left: {
            x: { align: "left", dx: -12 },
            y: { align: "top", dy: 4 },
            zOrder: 0
        },
        up_right: {
            x: { align: "right", dx: 16 },
            y: { align: "top", dy: 10 },
            zOrder: 0
        }
    },

    whirlwind: {
        duration: 500,
        timeScale: 1.5,
        x: { align: "center", dx: 35 },
        y: { align: "center", dy: 25 }
    }
};

cleverapps.styles.FogTileView["garden"] = {
    anchorX: 0.5,
    anchorY: 0.5,

    noBorder: true,

    groundFog: {
        x: { align: "center" },
        y: { align: "center" }
    },

    borders: {
        center: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 },
            zOrder: 0
        }
    }
};