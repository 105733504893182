/**
 * Created by mac on 12/23/20
 */

var UpgradableView = cc.Node.extend({
    ctor: function (upgradable, unitView) {
        this.unitView = unitView;
        this.upgradable = upgradable;

        this._super();

        this.restoreState();
    },

    restoreState: function () {
        var styles = cleverapps.styles.UpgradableView;
        this.setPositionRound(this.unitView.width / 2, this.unitView.height / 2);

        if (!cleverapps.gameModes.hideGuideAndProgress && this.upgradable.unit.isBuilt()) {
            if (!this.progress) {
                this.createProgress();
            }

            if (!this.level && this.upgradable.level) {
                this.createLevel();
            }
            if (!this.guideButton && cleverapps.config.name !== "hearts") {
                this.createGuideButton();
            }
        }

        if (this.progress) {
            this.progress.setPositionRound(styles.progress);
            if (this.progress.text) {
                this.progress.text.setString(this.upgradable.progress + "/" + this.upgradable.stages);
            }
        }

        if (this.level) {
            this.level.setPositionRound(styles.level);
            this.level.text.setString(this.upgradable.level);
            this.level.text.fitTo(styles.level.text.maxWidth);
        }

        if (this.guideButton) {
            this.guideButton.setPositionRound(styles.guideButton);
        }
    },

    createProgress: function () {
        var styles = cleverapps.styles.UpgradableView.progress;

        this.progress = new cc.Node();
        this.progress.setAnchorPoint(0.5, 0);

        if (cleverapps.config.name === "hustlemerge") {
            this.progress.setContentSize(styles);

            this.progress.stars = [];
            for (var i = 0; i < 5; i++) {
                var star = new cleverapps.Spine(bundles.merge.jsons.progress_star_json);
                star.setPositionRound(styles.stars[i]);
                this.progress.addChild(star);
                this.progress.stars.push(star);

                var percent = 100 * this.upgradable.progress / this.upgradable.stages;
                var starAnimation = "idle_0";
                if (percent >= (i + 1) * 20) {
                    star.filled = true;
                    starAnimation = "idle_2";
                } else if (percent >= i * 20 + 10) {
                    star.halffilled = true;
                    starAnimation = "idle_1";
                }

                star.setAnimation(0, starAnimation, false);
            }
        } else {
            this.progress.bar = new ScaledProgressBar({
                type: styles.type
            });
            this.progress.bar.setLength(styles.width);
            this.progress.bar.setPositionRound(this.progress.bar.width / 2, this.progress.bar.height / 2);
            this.progress.bar.setGoal(this.upgradable.stages);
            this.progress.bar.setPercentage(this.upgradable.progress);

            this.progress.setContentSize2(this.progress.bar.width, this.progress.bar.height);
            this.progress.addChild(this.progress.bar);

            this.progress.textContainer = new cc.Node();
            this.progress.textContainer.setAnchorPoint(0.5, 0.5);
            this.progress.textContainer.setPositionRound(styles.text);
            this.progress.addChild(this.progress.textContainer);

            this.progress.text = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.UPGRADABLE_PROGRESS_TEXT);
            this.progress.textContainer.addChild(this.progress.text);

            if (cleverapps.config.name === "hearts") {
                var icon = this.icon = new cc.Sprite(bundles.merge.frames.hammer_icon);
                icon.setPositionRound(cleverapps.styles.UpgradableView.level);
                icon.setLocalZOrder(1);
                this.progress.addChild(icon);
            }
        }
        this.unitView.addChild(this.progress);

        if (cleverapps.config.debugMode && !cleverapps.environment.isEditorScene()) {
            cleverapps.UI.onClick(this.progress.bar || this.progress.stars[2], function () {
                this.upgradable.incProgress();
            }.bind(this));
        }
    },

    createLevel: function () {
        this.level = new cc.Sprite(bundles.merge.frames.upgradable_level_png);
        this.level.setLocalZOrder(1);
        this.progress.addChild(this.level);

        this.level.text = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.UPGRADABLE_LEVEL_TEXT);
        this.level.text.setAnchorPoint(0.5, 0.5);
        this.level.text.setPositionRound(cleverapps.styles.UpgradableView.level.text);
        this.level.addChild(this.level.text);
    },

    createGuideButton: function () {
        this.guideButton = new cleverapps.UI.HelpButton({
            callback: function () {
                cleverapps.focusManager.display({
                    focus: "CastleWindow",
                    action: function (f) {
                        new GuideWindow(CastleGuideOptions, {
                            code: this.upgradable.unit.code,
                            stage: Families[this.upgradable.unit.code].units.length - 1
                        });
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }.bind(this)
                });
            }.bind(this)
        });
        this.addChild(this.guideButton);
    },

    onBeforeChangeUnit: function () {
        this.restoreState();
    },

    onChangeStageImage: function () {
        this.unitView.stopAllActions();
        var basePosition = this.unitView.getPosition();
        this.unitView.runAction(AnimationsLibrary.upgrade(this.unitView, this.unitView, {
            cloud: true
        })).setFinalize(function () {
            this.unitView.setPositionRound(basePosition);
        }.bind(this));
        cleverapps.audio.playSound(bundles.merge.urls.upgradable_build_effect, {
            throttle: 2000
        });
    },

    onChangeProgress: function (silent) {
        this.restoreState();

        if (this.progress) {
            if (this.progress.bar) {
                if (!silent) {
                    this.progress.textContainer.runAction(new cc.Sequence(
                        new cc.ScaleTo(0.15, 1.4),
                        new cc.ScaleTo(0.15, 1)
                    ));
                }

                this.progress.bar.setGoal(this.upgradable.stages);
                this.progress.bar.setPercentage(this.upgradable.progress, {
                    animated: !silent
                });
            } else if (this.progress.stars) {
                var percent = 100 * this.upgradable.progress / this.upgradable.stages;

                this.progress.stars.forEach(function (star, i) {
                    if (star.filled) {
                        return;
                    }
                    if (percent >= (i + 1) * 20) {
                        if (star.halffilled) {
                            star.setAnimationAndIdleAfter("to_2", "idle_2");
                        } else {
                            star.setAnimation(0, "to_1", false);
                            star.setCompleteListenerOnce(function () {
                                star.setAnimationAndIdleAfter("to_2", "idle_2");
                            });
                        }
                        star.filled = true;
                    } else if (percent >= i * 20 + 10 && !star.halffilled) {
                        star.setAnimationAndIdleAfter("to_1", "idle_1");
                        star.halffilled = true;
                    }
                });
            }
        }

        if (!silent) {
            this.onChangeStageImage();
        }
    },

    onUpdateLevel: function (silent) {
        if (silent) {
            this.restoreState();
        } else {
            this.onBuild();
        }
    },

    onBuild: function () {
        this.restoreState();

        if (this.progress) {
            this.progress.setScale(0);
            this.progress.runAction(new cc.ScaleTo(0.3, 1));

            if (this.progress.stars) {
                this.progress.stars.forEach(function (star) {
                    delete star.halffilled;
                    delete star.filled;

                    star.setAnimation(0, "idle_0", false);
                });
            }
        }

        if (this.guideButton) {
            this.guideButton.setScale(0);
            this.guideButton.runAction(new cc.ScaleTo(0.3, 1));
        }
    },

    onStartUpdateLevel: function () {
        this.restoreState();

        if (this.progress) {
            this.progress.runAction(new cc.ScaleTo(0.3, 0));
        }

        if (this.guideButton) {
            this.guideButton.runAction(new cc.ScaleTo(0.3, 0));
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    UPGRADABLE_PROGRESS_TEXT: {
        name: "default",
        size: 25
    },

    UPGRADABLE_LEVEL_TEXT: {
        name: "default",
        size: 40
    }
});

cleverapps.styles.UpgradableView = {
    guideButton: {
        x: { align: "right", dx: 100 },
        y: { align: "top", dy: 100 }
    },

    level: {
        x: { align: "left", dx: -30 },
        y: { align: "center", dy: 0 },
        text: {
            x: { align: "center", dx: 1 },
            y: { align: "center", dy: 2 },
            maxWidth: 40
        }
    },

    progress: {
        type: ScaledProgressBar.Types.green,
        x: { align: "center" },
        y: { align: "bottom", dy: 30 },
        width: 180,

        text: {
            x: { align: "center" },
            y: { align: "center", dy: 2 }
        }
    }
};