/**
 * Created by r4zi4l on 11.11.2021
 */

var Orders = function (options) {
    cleverapps.EventEmitter.call(this);

    this._maker = options && options.maker || Map2d.currentMap.customers.findReady() || Map2d.currentMap.customers.findCanCook() || Map2d.currentMap.customers.listCustomersWithRecipe()[0];

    Map2d.currentMap.customers.on("onUpdateOrder", this.updateOrder.bind(this), this);
    Map2d.currentMap.customers.on("onRecipesUpdated", this.updateOrder.bind(this), this);
};
Orders.prototype = Object.create(cleverapps.EventEmitter.prototype);
Orders.prototype.constructor = Orders;

Orders.prototype.updateOrder = function (maker) {
    this.trigger("orderStateChanged", maker);
};

Orders.prototype.selectMaker = function (maker) {
    this.trigger("makerSelected", maker);
};

Orders.prototype.scrollToSelectedOrderByMaker = function () {
    this.trigger("scrollToSelected", this._maker);
};

Orders.prototype.processOrder = function (maker) {
    if (maker.getState() === Customer.STATE_RECIPE) {
        if (!maker.canCook()) {
            new BuyIngredientsWindow(maker.recipe.getShoppingList());
            return;
        }

        this.trigger("ingredientsConsumed", maker);
        maker.cook();
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.MAKE_ORDER);
    } else if (maker.getState() === Customer.STATE_COOKING) {
        maker.enjoy();
    } else if (maker.getState() === Customer.STATE_READY) {
        this.trigger("orderClaimed", maker);
    }
};

Orders.prototype.getCurrentMaker = function () {
    return this._maker;
};

Orders.prototype.destructor = function () {
    runCleaners(this);
};