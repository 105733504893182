/**
 * Created by r4zi4l on 19.01.2021
 */

var RefillTankWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.tank = options.tank;

        this._super({
            title: "RefillTankWindow.title",
            name: "RefillTankWindow",
            content: this.createContent(),
            buttons: [this.createButton()],
            closeButton: true,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true
        });

        if (!this.tank.getTimeLeft()) {
            this.close();
        }
    },

    createContent: function () {
        var styles = cleverapps.styles.RefillTankWindow;

        var description = cleverapps.UI.generateOnlyText("RefillTankWindow." + cleverapps.meta.selectedLocationId() + ".description", cleverapps.styles.FONTS.REFILTANKWINDOW_DESCRIPTION);
        description.setDimensions(styles.width, 0);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var icon = new UnitOnCell(this.tank.unit);
        icon.setScale(1.4);

        return new cleverapps.Layout([icon, description], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createButton: function () {
        var styles = cleverapps.styles.RefillTankWindow.button;

        return new UseGoldButton({
            width: styles.width,
            height: styles.height,
            price: this.tank.getRefillPrice(),
            onClicked: this.refill.bind(this),
            eventName: cleverapps.EVENTS.SPENT.REFILL_TANK,
            adviceTarget: Map2d.REFILL
        });
    },

    refill: function () {
        this.tank.refill();
        this.close();
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    REFILTANKWINDOW_DESCRIPTION: {
        size: 42,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.RefillTankWindow = {
    width: 600,
    margin: 30,

    button: {
        width: 300,
        height: 110,
        margin: 4
    }
};
