/**
 * Created by vladislav on 13.04.2022
 */

var ShipsPlanner = function () {
    this.unit = undefined;
};

ShipsPlanner.prototype.init = function () {
    if (!Map2d.currentMap.regions.ships) {
        return;
    }

    if (cleverapps.config.editorMode || cleverapps.config.adminMode) {
        return;
    }

    this.inited = true;

    var mission = cleverapps.missionManager.findByType(Mission.TYPE_CARAVAN);
    if (mission && MissionManager.hasProperParent(mission.type)) {
        this.spawnUnit();
    }
};

ShipsPlanner.prototype.spawnUnit = function () {
    if (!this.inited && !this.unit) {
        return;
    }

    var slot = cleverapps.Random.choose(Map2d.currentMap.regions.ships.positions);

    var unit = new Unit({
        code: "caravanship",
        stage: 0
    });
    var ship = unit.findComponent(Ship);

    unit.setPosition(slot.x, slot.y);
    Map2d.currentMap.add(Map2d.LAYER_UNITS, unit.x, unit.y, unit);

    this.unit = unit;
    this.next = undefined;

    Map2d.currentMap.onAddUnit(unit.x, unit.y, unit);
    Map2d.currentMap.onUnitAvailable(unit);
    ship.onCustomSpawn();

    return unit;
};

ShipsPlanner.prototype.removeUnit = function () {
    if (!this.inited || !this.unit) {
        return;
    }

    var unit = this.unit;

    unit.onRemove();
    unit.remove(true);

    this.unit = undefined;
};