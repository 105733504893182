/**
 * Created by andrey on 24.12.2020.
 */

var FogsConfig = {};

FogsConfig.initialize = function () {
    FogsConfig.halloween = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 5,
            exp: 0,
            parentFogId: "fog0"
        }, // wood
        fog2: {
            id: 2,
            price: 5,
            exp: 0,
            parentFogId: "fog1"
        },
        fog3: {
            id: 3,
            price: 25,
            exp: 0,
            parentFogId: "fog2",
            conditions: {
                family: "rpcustomerbridge0"
            }
        },
        fog4: {
            id: 4,
            price: 25,
            exp: 0,
            parentFogId: "fog3"
        }, // stone
        fog5: {
            id: 5,
            price: 25,
            exp: 0,
            parentFogId: "fog4"
        },
        fog6: {
            id: 6,
            price: 25,
            exp: 0,
            parentFogId: "fog5",
            conditions: {
                family: "rpcustomerbridge1"
            }
        },
        fog7: {
            id: 7,
            price: 25,
            exp: 0,
            parentFogId: "fog6",
            conditions: {
                family: "drwood"
            }
        },
        fog8: {
            id: 8,
            price: 25,
            exp: 0,
            parentFogId: "fog7",
            conditions: {
                family: "drwood"
            }
        }, // sapphire
        fog9: {
            id: 9,
            price: 25,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "drstone"
            }
        },
        fog10: {
            id: 10,
            price: 30,
            exp: 0,
            parentFogId: "fog9",
            conditions: {
                family: "drstone"
            }
        },
        fog11: {
            id: 11,
            price: 30,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "drstone"
            }
        },
        fog12: {
            id: 12,
            price: 30,
            exp: 0,
            parentFogId: "fog11",
            conditions: {
                family: ["drstone", "rpcustomerbridge2"]
            }
        },
        fog13: {
            id: 13,
            price: 50,
            exp: 0,
            parentFogId: "fog12",
            conditions: {
                family: "drstone"
            }
        }, // sled
        fog14: {
            id: 14,
            price: 50,
            exp: 0,
            parentFogId: "fog13",
            conditions: {
                family: "drsapphire"
            }
        },
        fog15: {
            id: 15,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog16: {
            id: 16,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog17: {
            id: 17,
            price: 70,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog18: {
            id: 18,
            price: 70,
            exp: 0,
            parentFogId: "fog17",
            conditions: {
                family: "drsapphire"
            }
        },
        fog19: {
            id: 19,
            price: 80,
            exp: 0,
            parentFogId: "fog18",
            conditions: {
                family: ["drsled", "rpcustomerbridge3"]
            }
        }, // castle
        fog20: {
            id: 20,
            price: 80,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: "drsled"
            }
        },
        fog21: {
            id: 21,
            price: 90,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: "drsled"
            }
        },
        fog22: {
            id: 22,
            price: 90,
            exp: 0,
            parentFogId: "fog21",
            conditions: {
                family: "drsled"
            }
        },
        fog23: {
            id: 23,
            price: 100,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        fog24: {
            id: 24,
            price: 100,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        fog25: {
            id: 25,
            price: 110,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        halloweenFogCave: {
            id: 26,
            price: 0,
            exp: 10,
            conditions: {
                family: ["rpcustomermain1a", "rpcustomermain2a", "rpcustomermain3a"]
            }
        },

        moneyFogHalloween1: {
            id: 61,
            price: 99,
            money: true,
            parentFogId: "fog2",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 1$ - big sale
        moneyFogHalloween2: {
            id: 62,
            price: 499,
            money: true,
            parentFogId: "fog5",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }
            ]
        }, // 5$
        moneyFogHalloween3: {
            id: 63,
            price: 949,
            money: true,
            parentFogId: "fog11",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 10$ - big sale
        moneyFogHalloween4: {
            id: 64,
            price: 1899,
            money: true,
            parentFogId: "fog13",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, // 20$
        moneyFogHalloween5: {
            id: 65,
            price: 1499,
            money: true,
            parentFogId: "fog19",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, // 15$ - big sale
        moneyFogHalloween6: {
            id: 66,
            price: 4999,
            money: true,
            parentFogId: "fog10",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 50$ - have portal
        moneyFogHalloween7: {
            id: 67,
            price: 2499,
            money: true,
            parentFogId: "fog24",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 2
            }]
        }, // 30$
        moneyFogHalloween8: {
            id: 68,
            price: 8499,
            money: true,
            parentFogId: "fog21",
            windowSkin: "blue",
            bubbles: [{
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 85$ - have x2 portal
        moneyFogHalloween9: {
            id: 69,
            price: 1699,
            money: true,
            parentFogId: "fog18",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "sacks",
                stage: 2,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 15$
        moneyFogHalloween10: {
            id: 70,
            price: 400,
            money: true,
            parentFogId: "fog12",
            windowSkin: "blue",
            conditions: {
                family: "drtournament"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        } // 5$ - snail
    };

    FogsConfig.blackfriday = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 0,
            exp: 0,
            parentFogId: "fog0",
            conditions: {
                family: "dr2dragonblue"
            }
        }, // wood
        fog2: {
            id: 2,
            price: 5,
            exp: 0,
            parentFogId: "fog1"
        },
        fog3: {
            id: 3,
            price: 25,
            exp: 0,
            parentFogId: "fog2",
            conditions: {
                family: "rpcustomerbridge0"
            }
        },
        fog4: {
            id: 4,
            price: 25,
            exp: 0,
            parentFogId: "fog3"
        }, // stone
        fog5: {
            id: 5,
            price: 25,
            exp: 0,
            parentFogId: "fog4"
        },
        fog6: {
            id: 6,
            price: 25,
            exp: 0,
            parentFogId: "fog5",
            conditions: {
                family: "rpcustomerbridge1"
            }
        },
        fog7: {
            id: 7,
            price: 25,
            exp: 0,
            parentFogId: "fog6",
            conditions: {
                family: "drwood"
            }
        },
        fog8: {
            id: 8,
            price: 25,
            exp: 0,
            parentFogId: "fog7",
            conditions: {
                family: "drwood"
            }
        }, // sapphire
        fog9: {
            id: 9,
            price: 25,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "drstone"
            }
        },
        fog10: {
            id: 10,
            price: 30,
            exp: 0,
            parentFogId: "fog9",
            conditions: {
                family: "drstone"
            }
        },
        fog11: {
            id: 11,
            price: 30,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "drstone"
            }
        },
        fog12: {
            id: 12,
            price: 30,
            exp: 0,
            parentFogId: "fog11",
            conditions: {
                family: ["drstone", "rpcustomerbridge2"]
            }
        },
        fog13: {
            id: 13,
            price: 50,
            exp: 0,
            parentFogId: "fog12",
            conditions: {
                family: "drstone"
            }
        }, // sled
        fog14: {
            id: 14,
            price: 50,
            exp: 0,
            parentFogId: "fog13",
            conditions: {
                family: "drsapphire"
            }
        },
        fog15: {
            id: 15,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog16: {
            id: 16,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog17: {
            id: 17,
            price: 70,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog18: {
            id: 18,
            price: 70,
            exp: 0,
            parentFogId: "fog17",
            conditions: {
                family: "drsapphire"
            }
        },
        fog19: {
            id: 19,
            price: 80,
            exp: 0,
            parentFogId: "fog18",
            conditions: {
                family: ["drsled", "rpcustomerbridge3"]
            }
        }, // castle
        fog20: {
            id: 20,
            price: 80,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: "drsled"
            }
        },
        fog21: {
            id: 21,
            price: 90,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: "drsled"
            }
        },
        fog22: {
            id: 22,
            price: 90,
            exp: 0,
            parentFogId: "fog21",
            conditions: {
                family: "drsled"
            }
        },
        fog23: {
            id: 23,
            price: 100,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        fog24: {
            id: 24,
            price: 100,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        fog25: {
            id: 25,
            price: 110,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        blackfridayFogCave: {
            id: 26,
            price: 0,
            exp: 10,
            conditions: {
                family: ["rpcustomermain1", "rpcustomermain2", "rpcustomermain3"]
            }
        },

        moneyFogBlackfriday1: {
            id: 61,
            price: 99,
            money: true,
            parentFogId: "fog2",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 1$ - big sale
        moneyFogBlackfriday2: {
            id: 62,
            price: 499,
            money: true,
            parentFogId: "fog5",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }
            ]
        }, // 5$
        moneyFogBlackfriday3: {
            id: 63,
            price: 949,
            money: true,
            parentFogId: "fog11",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 10$ - big sale
        moneyFogBlackfriday4: {
            id: 64,
            price: 1899,
            money: true,
            parentFogId: "fog13",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, // 20$
        moneyFogBlackfriday5: {
            id: 65,
            price: 1499,
            money: true,
            parentFogId: "fog19",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, // 15$ - big sale
        moneyFogBlackfriday6: {
            id: 66,
            price: 4999,
            money: true,
            parentFogId: "fog10",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 50$ - have portal
        moneyFogBlackfriday7: {
            id: 67,
            price: 2499,
            money: true,
            parentFogId: "fog24",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 2
            }]
        }, // 30$
        moneyFogBlackfriday8: {
            id: 68,
            price: 8499,
            money: true,
            parentFogId: "fog21",
            windowSkin: "blue",
            bubbles: [{
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 85$ - have x2 portal
        moneyFogBlackfriday9: {
            id: 69,
            price: 1699,
            money: true,
            parentFogId: "fog18",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "sacks",
                stage: 2,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 15$
        moneyFogBlackfriday10: {
            id: 70,
            price: 400,
            money: true,
            parentFogId: "fog12",
            windowSkin: "blue",
            conditions: {
                family: "drtournament"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        } // 5$ - snail
    };

    FogsConfig.rapunzel = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 0,
            exp: 10,
            parentFogId: "fog0",
            conditions: {
                family: "rpdoll"
            }
        },
        fog2: {
            id: 2,
            price: 0,
            exp: 10,
            parentFogId: "fog1",
            conditions: {
                family: "rpchameleon"
            }
        },
        fog3: {
            id: 3,
            price: 0,
            exp: 10,
            parentFogId: "fog2",
            conditions: {
                family: "rpcandle"
            }
        },
        fog4: {
            id: 4,
            price: 0,
            exp: 10,
            parentFogId: "fog3",
            conditions: {
                family: "rpcomb"
            }
        },
        fog5: {
            id: 5,
            price: 0,
            exp: 10,
            parentFogId: "fog3",
            conditions: {
                family: "rpjewelry"
            }
        },
        fog6: {
            id: 6,
            price: 0,
            exp: 10,
            parentFogId: "fog5",
            conditions: {
                family: "rpdress"
            }
        },
        fog7: {
            id: 7,
            price: 0,
            exp: 10,
            parentFogId: "fog5",
            conditions: {
                family: "rpanimals"
            }
        },

        fog8: {
            id: 8,
            price: 20,
            exp: 10,
            parentFogId: "fog7"
        },
        fog9: {
            id: 9,
            price: 20,
            exp: 10,
            parentFogId: "fog7"
        },
        fog10: {
            id: 10,
            price: 20,
            exp: 10,
            parentFogId: "fog7"
        },

        moneyFogRapunzel1: {
            id: 11,
            price: 99,
            money: true,
            parentFogId: "fog2",
            conditions: {
                family: "rpshop"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        },
        moneyFogRapunzel2: {
            id: 12,
            price: 599,
            money: true,
            parentFogId: "fog3",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }]
        },
        moneyFogRapunzel3: {
            id: 13,
            price: 3649,
            money: true,
            parentFogId: "fog5",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        },
        moneyFogRapunzel4: {
            id: 14,
            price: 3649,
            money: true,
            parentFogId: "fog5",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        },
        moneyFogRapunzel5: {
            id: 15,
            price: 3649,
            money: true,
            parentFogId: "fog5",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        }
    };

    FogsConfig.rapunzel2 = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 0,
            exp: 10,
            parentFogId: "fog0",
            conditions: {
                family: "rpdoll"
            }
        },
        fog2: {
            id: 2,
            price: 0,
            exp: 10,
            parentFogId: "fog1",
            conditions: {
                family: "rpchameleon"
            }
        },
        fog3: {
            id: 3,
            price: 0,
            exp: 10,
            parentFogId: "fog2",
            conditions: {
                family: "rpcandle"
            }
        },
        fog4: {
            id: 4,
            price: 0,
            exp: 10,
            parentFogId: "fog3",
            conditions: {
                family: "rpjewelry"
            }
        },
        fog5: {
            id: 5,
            price: 0,
            exp: 10,
            parentFogId: "fog4",
            conditions: {
                family: "rpdress"
            }
        },
        fog6: {
            id: 6,
            price: 0,
            exp: 10,
            parentFogId: "fog5",
            conditions: {
                family: "rpcomb"
            }
        },
        fog7: {
            id: 7,
            price: 0,
            exp: 10,
            parentFogId: "fog6",
            conditions: {
                family: "rpspider"
            }
        },
        fog8: {
            id: 8,
            price: 0,
            exp: 10,
            parentFogId: "fog7"
        },
        fog9: {
            id: 9,
            price: 0,
            exp: 10,
            parentFogId: "fog8",
            conditions: {
                family: "rpmushroom"
            }
        },
        fog10: {
            id: 10,
            price: 0,
            exp: 10,
            parentFogId: "fog9",
            conditions: {
                family: "rpanimals"
            }
        },
        fog11: {
            id: 11,
            price: 0,
            exp: 10,
            parentFogId: "fog10",
            conditions: {
                family: "rpbat"
            }
        },
        fog12: {
            id: 12,
            price: 0,
            exp: 10,
            parentFogId: "fog11"
        },

        fog13: {
            id: 13,
            price: 0,
            exp: 0,
            headless: true,
            parentFogId: "fog3"
        },
        fog14: {
            id: 14,
            price: 0,
            exp: 0,
            headless: true,
            parentFogId: "fog4"
        },
        fog15: {
            id: 15,
            price: 0,
            exp: 10,
            parentFogId: "fog14"
        },

        secretRoomFog: {
            id: 16,
            exp: 10,
            price: 0,
            parentFogId: "fog5",
            conditions: {
                family: "rpdoorway"
            }
        },

        moneyFogRapunzel1: {
            id: 17,
            price: 99,
            money: true,
            parentFogId: "fog2",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        },
        moneyFogRapunzel2: {
            id: 18,
            price: 240,
            money: true,
            parentFogId: "fog4",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            }]
        },
        moneyFogRapunzel3: {
            id: 19,
            price: 999,
            money: true,
            parentFogId: "fog8",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }]
        },
        moneyFogRapunzel4: {
            id: 20,
            price: 749,
            money: true,
            parentFogId: "fog9",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        },
        moneyFogRapunzel5: {
            id: 21,
            price: 3499,
            money: true,
            parentFogId: "fog10",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            }]
        }
    };

    FogsConfig.rapunzel3 = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 0,
            exp: 10,
            parentFogId: "fog0",
            conditions: {
                family: "rpplate"
            }
        },
        fog2: {
            id: 2,
            price: 0,
            exp: 10,
            parentFogId: "fog1",
            conditions: {
                family: "rpcustomerbridge0"
            }
        },
        fog3: {
            id: 3,
            price: 0,
            exp: 10,
            parentFogId: "room0",
            conditions: {
                family: "rpcustomermain0b"
            }
        },
        fog4: {
            id: 4,
            price: 0,
            exp: 10,
            parentFogId: "fog3",
            conditions: {
                family: "rpcustomerbridge1"
            }
        },
        fog5: {
            id: 5,
            price: 0,
            exp: 10,
            parentFogId: "fog4",
            conditions: {
                family: "rpcustomermain1b"
            }
        },
        fog6: {
            id: 6,
            price: 0,
            exp: 10,
            parentFogId: "fog5",
            conditions: {
                family: "rpcustomerbridge2"
            }
        },
        fog7: {
            id: 7,
            price: 0,
            exp: 10,
            parentFogId: "fog6",
            conditions: {
                family: "rpcustomermain2a"
            }
        },
        fog8: {
            id: 8,
            price: 0,
            exp: 10,
            parentFogId: "fog7",
            conditions: {
                family: "rppans"
            }
        },
        fog9: {
            id: 9,
            price: 0,
            exp: 10,
            parentFogId: "fog7",
            conditions: {
                family: "rpcustomermain2d"
            }
        },
        fog10: {
            id: 16,
            price: 0,
            exp: 10,
            parentFogId: "fog9",
            conditions: {
                family: "rpcustomerbridge3"
            }
        },
        fog11: {
            id: 17,
            price: 0,
            exp: 10,
            parentFogId: "rpmugs",
            conditions: {
                family: "rpmugs"
            }
        },
        fog12: {
            id: 18,
            price: 0,
            exp: 10,
            parentFogId: "fog11",
            conditions: {
                family: "rpcustomermain3a"
            }
        },
        fog13: {
            id: 19,
            price: 0,
            exp: 10,
            parentFogId: "fog12",
            conditions: {
                family: "rpcustomermain3c"
            }
        },
        fog14: {
            id: 20,
            price: 20,
            exp: 10,
            parentFogId: "fog7"
        },
        fog15: {
            id: 21,
            price: 20,
            exp: 10,
            parentFogId: "fog7"
        },

        room0: {
            id: 22,
            price: 0,
            exp: 10,
            parentFogId: "fog1",
            conditions: {
                family: "rpcustomerbridge0"
            }
        },
        room1: {
            id: 23,
            price: 0,
            exp: 10,
            parentFogId: "fog3",
            conditions: {
                family: "rpcustomerbridge1"
            }
        },
        room2: {
            id: 24,
            price: 0,
            exp: 10,
            parentFogId: "fog5",
            conditions: {
                family: "rpcustomerbridge2"
            }
        },
        room3: {
            id: 25,
            price: 0,
            exp: 10,
            parentFogId: "fog9",
            conditions: {
                family: "rpcustomerbridge3"
            }
        },

        moneyFogRapunzel1: {
            id: 11,
            price: 99,
            money: true,
            parentFogId: "fog2",
            conditions: {
                family: "rpshop"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        },
        moneyFogRapunzel2: {
            id: 12,
            price: 299,
            money: true,
            parentFogId: "fog4",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }]
        },
        moneyFogRapunzel3: {
            id: 13,
            price: 599,
            money: true,
            parentFogId: "fog6",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        },
        moneyFogRapunzel4: {
            id: 14,
            price: 899,
            money: true,
            parentFogId: "fog8",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        },
        moneyFogRapunzel5: {
            id: 15,
            price: 1299,
            money: true,
            parentFogId: "fog10",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        }
    };

    FogsConfig.xmas = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 5,
            exp: 0,
            parentFogId: "fog0"
        }, // wood
        fog2: {
            id: 2,
            price: 5,
            exp: 0,
            parentFogId: "fog1"
        },
        fog3: {
            id: 3,
            price: 25,
            exp: 0,
            parentFogId: "fog2",
            conditions: {
                family: "rpcustomerbridge0"
            }
        },
        fog4: {
            id: 4,
            price: 25,
            exp: 0,
            parentFogId: "fog3"
        }, // stone
        fog5: {
            id: 5,
            price: 25,
            exp: 0,
            parentFogId: "fog4"
        },
        fog6: {
            id: 6,
            price: 25,
            exp: 0,
            parentFogId: "fog5",
            conditions: {
                family: "rpcustomerbridge1"
            }
        },
        fog7: {
            id: 7,
            price: 25,
            exp: 0,
            parentFogId: "fog6",
            conditions: {
                family: "drwood"
            }
        },
        fog8: {
            id: 8,
            price: 25,
            exp: 0,
            parentFogId: "fog7",
            conditions: {
                family: "drwood"
            }
        }, // sapphire
        fog9: {
            id: 9,
            price: 25,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "drstone"
            }
        },
        fog10: {
            id: 10,
            price: 30,
            exp: 0,
            parentFogId: "fog9",
            conditions: {
                family: "drstone"
            }
        },
        fog11: {
            id: 11,
            price: 30,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "drstone"
            }
        },
        fog12: {
            id: 12,
            price: 30,
            exp: 0,
            parentFogId: "fog11",
            conditions: {
                family: ["drstone", "rpcustomerbridge2"]
            }
        },
        fog13: {
            id: 13,
            price: 50,
            exp: 0,
            parentFogId: "fog12",
            conditions: {
                family: "drstone"
            }
        }, // sled
        fog14: {
            id: 14,
            price: 50,
            exp: 0,
            parentFogId: "fog13",
            conditions: {
                family: "drsapphire"
            }
        },
        fog15: {
            id: 15,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog16: {
            id: 16,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog17: {
            id: 17,
            price: 70,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog18: {
            id: 18,
            price: 70,
            exp: 0,
            parentFogId: "fog17",
            conditions: {
                family: "drsapphire"
            }
        },
        fog19: {
            id: 19,
            price: 80,
            exp: 0,
            parentFogId: "fog18",
            conditions: {
                family: ["drsled", "rpcustomerbridge3"]
            }
        }, // castle
        fog20: {
            id: 20,
            price: 80,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: "drsled"
            }
        },
        fog21: {
            id: 21,
            price: 90,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: "drsled"
            }
        },
        fog22: {
            id: 22,
            price: 90,
            exp: 0,
            parentFogId: "fog21",
            conditions: {
                family: "drsled"
            }
        },
        fog23: {
            id: 23,
            price: 100,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        fog24: {
            id: 24,
            price: 100,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        fog25: {
            id: 25,
            price: 110,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        xmasFogCave: {
            id: 26,
            price: 0,
            exp: 10,
            conditions: {
                family: ["rpcustomermain1", "rpcustomermain2", "rpcustomermain3"]
            }
        },

        moneyFogXmas1: {
            id: 61,
            price: 99,
            money: true,
            parentFogId: "fog2",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 1$ - big sale
        moneyFogXmas2: {
            id: 62,
            price: 499,
            money: true,
            parentFogId: "fog5",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            }, {
                code: "crystal",
                stage: 2,
                amount: 1
            }]
        }, // 5$
        moneyFogXmas3: {
            id: 63,
            price: 949,
            money: true,
            parentFogId: "fog11",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 10$ - big sale
        moneyFogXmas4: {
            id: 64,
            price: 1899,
            money: true,
            parentFogId: "fog13",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            }, {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        }, // 20$
        moneyFogXmas5: {
            id: 65,
            price: 1499,
            money: true,
            parentFogId: "fog19",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            }, {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        }, // 15$ - big sale
        moneyFogXmas6: {
            id: 66,
            price: 4999,
            money: true,
            parentFogId: "fog10",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            }, {
                code: "crystal",
                stage: 3,
                amount: 1
            }, {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 50$ - have portal
        moneyFogXmas7: {
            id: 67,
            price: 2499,
            money: true,
            parentFogId: "fog24",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            }, {
                code: "crystal",
                stage: 3,
                amount: 2
            }, {
                code: "worker",
                stage: 4,
                amount: 2
            }]
        }, // 30$
        moneyFogXmas8: {
            id: 68,
            price: 8499,
            money: true,
            parentFogId: "fog21",
            windowSkin: "blue",
            bubbles: [{
                code: "crystal",
                stage: 3,
                amount: 1
            }, {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 85$ - have x2 portal
        moneyFogXmas9: {
            id: 69,
            price: 1699,
            money: true,
            parentFogId: "fog18",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            }, {
                code: "sacks",
                stage: 2,
                amount: 2
            }, {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 15$
        moneyFogXmas10: {
            id: 70,
            price: 400,
            money: true,
            parentFogId: "fog12",
            windowSkin: "blue",
            conditions: {
                family: "drtournament"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        } // 5$ - snail
    };

    FogsConfig.dragonia = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 4,
            exp: 0,
            parentFogId: "fog0"
        },
        fog2: {
            id: 2,
            price: 6,
            exp: 0,
            parentFogId: "fog1"
        },
        fog3: {
            id: 3,
            price: 10,
            exp: 0,
            parentFogId: "fog2"
        },
        fog4: {
            id: 4,
            price: 15,
            exp: 0,
            parentFogId: "fog3"
        },
        fog5: {
            id: 5,
            price: 20,
            exp: 0,
            parentFogId: "fog4"
        },

        fog6: {
            id: 6,
            price: 25,
            exp: 0,
            parentFogId: "fog5"
        },
        fog7: {
            id: 7,
            price: 25,
            exp: 0,
            parentFogId: "fog6"
        },
        fog8: {
            id: 8,
            price: 30,
            exp: 0,
            parentFogId: "fog7"
        },
        fog9: {
            id: 9,
            price: 35,
            exp: 0,
            parentFogId: "fog7"
        },
        fog10: {
            id: 10,
            price: 35,
            exp: 0,
            parentFogId: "fog9"
        },

        fog11: {
            id: 11,
            price: 40,
            exp: 0,
            parentFogId: "fog5"
        },
        fog12: {
            id: 12,
            price: 40,
            exp: 0,
            parentFogId: "fog11"
        },
        fog13: {
            id: 13,
            price: 40,
            exp: 0,
            parentFogId: "fog11"
        },
        fog14: {
            id: 14,
            price: 45,
            exp: 0,
            parentFogId: "fog11"
        },
        fog15: {
            id: 15,
            price: 45,
            exp: 0,
            parentFogId: "fog14"
        },
        fog16: {
            id: 16,
            price: 45,
            exp: 0,
            parentFogId: "fog13"
        },
        fog17: {
            id: 17,
            price: 55,
            exp: 0,
            parentFogId: "fog14"
        },

        fog18: {
            id: 18,
            price: 60,
            exp: 0,
            parentFogId: "fog17"
        },
        fog19: {
            id: 19,
            price: 65,
            exp: 0,
            parentFogId: "fog18"
        },
        fog20: {
            id: 20,
            price: 70,
            exp: 0,
            parentFogId: "fog19"
        },
        fog21: {
            id: 21,
            price: 75,
            exp: 0,
            parentFogId: "fog19"
        },
        fog22: {
            id: 22,
            price: 85,
            exp: 0,
            parentFogId: "fog20"
        },
        fog23: {
            id: 23,
            price: 90,
            exp: 0,
            parentFogId: "fog21"
        },
        fog24: {
            id: 24,
            price: 90,
            exp: 0,
            parentFogId: "fog23"
        },
        fog25: {
            id: 25,
            price: 95,
            exp: 0,
            parentFogId: "fog24"
        },
        fog26: {
            id: 26,
            price: 100,
            exp: 0,
            parentFogId: "fog24"
        },
        fog27: {
            id: 27,
            price: 110,
            exp: 0,
            parentFogId: "fog26"
        },
        fog28: {
            id: 28,
            price: 120,
            exp: 0,
            parentFogId: "fog27"
        },
        fog29: {
            id: 29,
            price: 130,
            exp: 0,
            parentFogId: "fog26"
        },
        fog30: {
            id: 30,
            price: 150,
            exp: 0,
            parentFogId: "fog29"
        },

        fog31: {
            id: 31,
            price: 175,
            exp: 0,
            parentFogId: "fog21"
        },
        fog32: {
            id: 32,
            price: 200,
            exp: 0,
            parentFogId: "fog31"
        },
        fog33: {
            id: 33,
            price: 225,
            exp: 0,
            parentFogId: "fog31"
        },
        fog34: {
            id: 34,
            price: 250,
            exp: 0,
            parentFogId: "fog31"
        },
        fog35: {
            id: 35,
            price: 300,
            exp: 0,
            parentFogId: "fog34"
        },
        fog36: {
            id: 36,
            price: 350,
            exp: 0,
            parentFogId: "fog35"
        },
        fog37: {
            id: 37,
            price: 450,
            exp: 0,
            parentFogId: "fog35"
        },
        fog38: {
            id: 38,
            price: 600,
            exp: 0,
            parentFogId: "fog36"
        },
        fog39: {
            id: 39,
            price: 850,
            exp: 0,
            parentFogId: "fog37"
        },
        fog40: {
            id: 40,
            price: 1000,
            exp: 100,
            parentFogId: "fog39"
        },

        moneyFogDragonia1: {
            id: 41,
            price: 99,
            money: true,
            parentFogId: "fog2",
            windowSkin: "green",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        },
        moneyFogDragonia2: {
            id: 42,
            price: 400,
            money: true,
            parentFogId: "fog6",
            windowSkin: "yellow",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }
            ]
        },
        moneyFogDragonia3: {
            id: 43,
            price: 599,
            money: true,
            parentFogId: "fog7",
            windowSkin: "green",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        },
        moneyFogDragonia4: {
            id: 44,
            price: 1499,
            money: true,
            parentFogId: "fog10",
            windowSkin: "green",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        },
        moneyFogDragonia5: {
            id: 45,
            price: 2899,
            money: true,
            parentFogId: "fog11",
            windowSkin: "green",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }
            ]
        },
        moneyFogDragonia6: {
            id: 46,
            price: 1659,
            money: true,
            parentFogId: "fog12",
            windowSkin: "yellow",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }
            ]

        },
        moneyFogDragonia7: {
            id: 47,
            price: 5399,
            money: true,
            parentFogId: "fog18",
            windowSkin: "yellow",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 2
            }
            ]
        },
        moneyFogDragonia8: {
            id: 48,
            price: 1499,
            money: true,
            parentFogId: "fog19",
            windowSkin: "yellow",
            bubbles: [{
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }
            ]
        },
        moneyFogDragonia9: {
            id: 49,
            price: 1999,
            money: true,
            parentFogId: "fog19",
            windowSkin: "yellow",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 2
            },
            {
                code: "sacks",
                stage: 2,
                amount: 1
            },
            {
                code: "worker",
                stage: 3,
                amount: 2
            }
            ]
        },
        moneyFogDragonia10: {
            id: 50,
            price: 3499,
            money: true,
            parentFogId: "fog24",
            windowSkin: "green",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "sacks",
                stage: 2,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }
            ]
        },
        moneyFogDragonia11: {
            id: 53,
            price: 15999,
            money: true,
            parentFogId: "fog14",
            windowSkin: "yellow",
            bubbles: [{
                code: "sacks",
                stage: 2,
                amount: 3
            },
            {
                code: "crystal",
                stage: 3,
                amount: 3
            },
            {
                code: "worker",
                stage: 4,
                amount: 3
            }
            ]
        },

        factoryFogDragonia: {
            id: 51,
            price: 50,
            exp: 5,
            parentFogId: "fog10"
        },
        factory2FogDragonia: {
            id: 52,
            price: 29990,
            money: true,
            parentFogId: "factoryFogDragonia",
            windowSkin: "yellow",
            bubbles: [{
                code: "sacks",
                stage: 2,
                amount: 5
            },
            {
                code: "crystal",
                stage: 3,
                amount: 5
            },
            {
                code: "worker",
                stage: 4,
                amount: 5
            }]
        }
    };

    FogsConfig.dragonia2 = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 5,
            exp: 0,
            parentFogId: "fog0"
        }, // wood
        fog2: {
            id: 2,
            price: 25,
            exp: 0,
            parentFogId: "fog1"
        },
        fog3: {
            id: 3,
            price: 25,
            exp: 0,
            parentFogId: "fog2"
        },
        fog4: {
            id: 4,
            price: 25,
            exp: 0,
            parentFogId: "fog3"
        }, // stone
        fog5: {
            id: 5,
            price: 25,
            exp: 0,
            parentFogId: "fog4"
        },
        fog6: {
            id: 6,
            price: 25,
            exp: 0,
            parentFogId: "fog5"
        },
        fog7: {
            id: 7,
            price: 25,
            exp: 0,
            parentFogId: "fog6",
            conditions: {
                family: "dr2treasuremap"
            }
        },
        fog8: {
            id: 8,
            price: 25,
            exp: 0,
            parentFogId: "fog7",
            conditions: {
                family: "drwood"
            }
        }, // sapphire
        fog9: {
            id: 9,
            price: 25,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "drwood"
            }
        },
        fog10: {
            id: 10,
            price: 30,
            exp: 0,
            parentFogId: "fog9",
            conditions: {
                family: "drwood"
            }
        },
        fog11: {
            id: 11,
            price: 30,
            exp: 0,
            parentFogId: "fog10",
            conditions: {
                family: "drwood"
            }
        },
        fog12: {
            id: 12,
            price: 30,
            exp: 0,
            parentFogId: "fog11",
            conditions: {
                family: "drwood"
            }
        },
        fog13: {
            id: 13,
            price: 50,
            exp: 0,
            parentFogId: "fog12",
            conditions: {
                family: "drstone"
            }
        }, // sled
        fog14: {
            id: 14,
            price: 50,
            exp: 0,
            parentFogId: "fog13",
            conditions: {
                family: "drstone"
            }
        },
        fog15: {
            id: 15,
            price: 60,
            exp: 0,
            parentFogId: "fog13",
            conditions: {
                family: "drstone"
            }
        },
        fog16: {
            id: 16,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drstone"
            }
        },
        fog17: {
            id: 17,
            price: 70,
            exp: 0,
            parentFogId: "fog16",
            conditions: {
                family: "drstone"
            }
        },
        fog18: {
            id: 18,
            price: 70,
            exp: 0,
            parentFogId: "fog16",
            conditions: {
                family: "drstone"
            }
        },
        fog19: {
            id: 19,
            price: 80,
            exp: 0,
            parentFogId: "fog18",
            conditions: {
                family: "drsapphire"
            }
        }, // castle
        fog20: {
            id: 20,
            price: 80,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: "drsapphire"
            }
        },
        fog21: {
            id: 21,
            price: 90,
            exp: 0,
            parentFogId: "fog20",
            conditions: {
                family: "drsapphire"
            }
        },
        fog22: {
            id: 22,
            price: 90,
            exp: 0,
            parentFogId: "fog21",
            conditions: {
                family: "drsapphire"
            }
        },
        fog23: {
            id: 23,
            price: 100,
            exp: 0,
            parentFogId: "fog20",
            conditions: {
                family: "drsapphire"
            }
        },
        fog24: {
            id: 24,
            price: 100,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsapphire"
            }
        },
        fog25: {
            id: 25,
            price: 110,
            exp: 0,
            parentFogId: "fog24",
            conditions: {
                family: "drsapphire"
            }
        },
        fog26: {
            id: 26,
            price: 110,
            exp: 0,
            parentFogId: "fog25",
            conditions: {
                family: "drsled"
            }
        }, // purplecrystal
        fog27: {
            id: 27,
            price: 120,
            exp: 0,
            parentFogId: "fog26",
            conditions: {
                family: "drsled"
            }
        },
        fog28: {
            id: 28,
            price: 130,
            exp: 0,
            parentFogId: "fog27",
            conditions: {
                family: "drsled"
            }
        },
        fog29: {
            id: 29,
            price: 140,
            exp: 0,
            parentFogId: "fog28",
            conditions: {
                family: "drsled"
            }
        },
        fog30: {
            id: 30,
            price: 150,
            exp: 0,
            parentFogId: "fog28",
            conditions: {
                family: "drsled"
            }
        },
        fog31: {
            id: 31,
            price: 170,
            exp: 0,
            parentFogId: "fog29",
            conditions: {
                family: "drsled"
            }
        },
        fog32: {
            id: 32,
            price: 180,
            exp: 0,
            parentFogId: "fog31",
            conditions: {
                family: "drsled"
            }
        },
        fog33: {
            id: 33,
            price: 190,
            exp: 0,
            parentFogId: "fog32",
            conditions: {
                family: "drsled"
            }
        },
        fog34: {
            id: 34,
            price: 230,
            exp: 0,
            parentFogId: "fog33",
            conditions: {
                family: "drcastle"
            }
        }, // bamboo
        fog35: {
            id: 35,
            price: 240,
            exp: 0,
            parentFogId: "fog34",
            conditions: {
                family: "drcastle"
            }
        },
        fog36: {
            id: 36,
            price: 250,
            exp: 0,
            parentFogId: "fog34",
            conditions: {
                family: "drcastle"
            }
        },
        fog37: {
            id: 37,
            price: 260,
            exp: 0,
            parentFogId: "fog35",
            conditions: {
                family: "drcastle"
            }
        },
        fog38: {
            id: 38,
            price: 270,
            exp: 0,
            parentFogId: "fog36",
            conditions: {
                family: "drcastle"
            }
        },
        fog39: {
            id: 39,
            price: 280,
            exp: 0,
            parentFogId: "fog35",
            conditions: {
                family: "drcastle"
            }
        },
        fog40: {
            id: 40,
            price: 300,
            exp: 100,
            parentFogId: "fog35",
            conditions: {
                family: "drcastle"
            }
        },
        fog41: {
            id: 41,
            price: 320,
            exp: 0,
            parentFogId: "fog38",
            conditions: {
                family: "drcastle"
            }
        },
        fog42: {
            id: 42,
            price: 340,
            exp: 0,
            parentFogId: "fog39",
            conditions: {
                family: "drcastle"
            }
        },
        fog43: {
            id: 43,
            price: 400,
            exp: 0,
            parentFogId: "fog42",
            conditions: {
                family: "drpurplecrystal"
            }
        }, // feathers
        fog44: {
            id: 44,
            price: 500,
            exp: 0,
            parentFogId: "fog43",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog45: {
            id: 45,
            price: 600,
            exp: 0,
            parentFogId: "fog44",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog46: {
            id: 46,
            price: 700,
            exp: 0,
            parentFogId: "fog45",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog47: {
            id: 47,
            price: 800,
            exp: 0,
            parentFogId: "fog46",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog48: {
            id: 48,
            price: 1000,
            exp: 0,
            parentFogId: "fog45",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog49: {
            id: 49,
            price: 1250,
            exp: 0,
            parentFogId: "fog47",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog50: {
            id: 50,
            price: 1500,
            exp: 0,
            parentFogId: "fog48",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog51: {
            id: 51,
            price: 1800,
            exp: 100,
            parentFogId: "fog49",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog52: {
            id: 52,
            price: 2350,
            exp: 0,
            parentFogId: "fog50",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog53: {
            id: 53,
            price: 600,
            exp: 0,
            parentFogId: "fog52"
        },
        fog54: {
            id: 54,
            price: 750,
            exp: 0,
            parentFogId: "fog53"
        },
        dr2FogIsland: {
            id: 55,
            price: 0,
            exp: 10,
            parentFogId: "fog5",
            conditions: {
                family: "dr2airship"
            },
            treasureMapTarget: true
        },

        moneyFogDragonia1: {
            id: 56,
            price: 99,
            money: true,
            parentFogId: "fog3",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 1$
        moneyFogDragonia2: {
            id: 57,
            price: 499,
            money: true,
            parentFogId: "fog7",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }
            ]
        }, // 3$
        moneyFogDragonia3: {
            id: 58,
            price: 400,
            money: true,
            parentFogId: "fog10",
            windowSkin: "blue",
            conditions: {
                family: "drtournament"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // snail
        moneyFogDragonia4: {
            id: 59,
            price: 1899,
            money: true,
            parentFogId: "fog12",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, //
        moneyFogDragonia5: {
            id: 60,
            price: 1499,
            money: true,
            parentFogId: "fog15",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, // 5$
        moneyFogDragonia6: {
            id: 61,
            price: 5399,
            money: true,
            parentFogId: "fog18",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 45$
        moneyFogDragonia7: {
            id: 62,
            price: 12699,
            money: true,
            parentFogId: "fog24",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 2
            }]
        }, // 100$
        moneyFogDragonia8: {
            id: 64,
            price: 2799,
            money: true,
            parentFogId: "fog29",
            windowSkin: "blue",
            bubbles: [{
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 15$
        moneyFogDragonia9: {
            id: 65,
            price: 3999,
            money: true,
            parentFogId: "fog42",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "sacks",
                stage: 2,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 25$
        moneyFogDragonia10: {
            id: 66,
            price: 3499,
            money: true,
            parentFogId: "fog27",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "sacks",
                stage: 2,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }
    };

    FogsConfig.undersea = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 4,
            exp: 0,
            parentFogId: "fog0"
        },
        fog2: {
            id: 2,
            price: 6,
            exp: 0,
            parentFogId: "fog1"
        },
        fog3: {
            id: 3,
            price: 10,
            exp: 0,
            parentFogId: "fog2"
        },
        fog4: {
            id: 4,
            price: 15,
            exp: 0,
            parentFogId: "fog3"
        },
        fog5: {
            id: 5,
            price: 20,
            exp: 0,
            parentFogId: "fog4"
        },

        fog6: {
            id: 6,
            price: 25,
            exp: 0,
            parentFogId: "fog5"
        },
        fog7: {
            id: 7,
            price: 25,
            exp: 0,
            parentFogId: "fog6"
        },
        fog8: {
            id: 8,
            price: 30,
            exp: 0,
            parentFogId: "fog6"
        },
        fog9: {
            id: 9,
            price: 35,
            exp: 0,
            parentFogId: "fog8"
        },

        fog10: {
            id: 10,
            price: 35,
            exp: 0,
            parentFogId: "fog9"
        },
        fog11: {
            id: 11,
            price: 40,
            exp: 0,
            parentFogId: "fog10"
        },
        fog12: {
            id: 12,
            price: 40,
            exp: 0,
            parentFogId: "fog11"
        },
        fog13: {
            id: 13,
            price: 40,
            exp: 0,
            parentFogId: "fog12"
        },
        fog14: {
            id: 14,
            price: 45,
            exp: 0,
            parentFogId: "fog13"
        },
        fog15: {
            id: 15,
            price: 45,
            exp: 0,
            parentFogId: "fog13"
        },
        fog16: {
            id: 16,
            price: 45,
            exp: 0,
            parentFogId: "fog15"
        },

        fog17: {
            id: 17,
            price: 55,
            exp: 0,
            parentFogId: "fog12"
        },
        fog18: {
            id: 18,
            price: 60,
            exp: 0,
            parentFogId: "fog17"
        },
        fog19: {
            id: 19,
            price: 65,
            exp: 0,
            parentFogId: "fog17"
        },
        fog20: {
            id: 20,
            price: 70,
            exp: 0,
            parentFogId: "fog19"
        },
        fog21: {
            id: 21,
            price: 75,
            exp: 0,
            parentFogId: "fog19"
        },
        fog22: {
            id: 22,
            price: 85,
            exp: 0,
            parentFogId: "fog18"
        },
        fog23: {
            id: 23,
            price: 90,
            exp: 0,
            parentFogId: "fog20"
        },
        fog24: {
            id: 24,
            price: 90,
            exp: 0,
            parentFogId: "fog20"
        },
        fog25: {
            id: 25,
            price: 95,
            exp: 0,
            parentFogId: "fog24"
        },
        fog26: {
            id: 26,
            price: 100,
            exp: 0,
            parentFogId: "fog25"
        },

        fog27: {
            id: 27,
            price: 110,
            exp: 0,
            parentFogId: "fog20"
        },
        fog28: {
            id: 28,
            price: 120,
            exp: 0,
            parentFogId: "fog27"
        },
        fog29: {
            id: 29,
            price: 130,
            exp: 0,
            parentFogId: "fog27"
        },
        fog30: {
            id: 30,
            price: 150,
            exp: 0,
            parentFogId: "fog28"
        },
        fog31: {
            id: 31,
            price: 175,
            exp: 0,
            parentFogId: "fog29"
        },
        fog32: {
            id: 32,
            price: 200,
            exp: 0,
            parentFogId: "fog28"
        },
        fog33: {
            id: 33,
            price: 225,
            exp: 0,
            parentFogId: "fog31"
        },
        fog34: {
            id: 34,
            price: 250,
            exp: 0,
            parentFogId: "fog32"
        },
        fog35: {
            id: 35,
            price: 300,
            exp: 0,
            parentFogId: "fog27"
        },
        fog36: {
            id: 36,
            price: 350,
            exp: 0,
            parentFogId: "fog29"
        },
        fog37: {
            id: 37,
            price: 450,
            exp: 0,
            parentFogId: "fog28"
        },
        fog38: {
            id: 38,
            price: 600,
            exp: 0,
            parentFogId: "fog31"
        },
        fog39: {
            id: 39,
            price: 850,
            exp: 0,
            parentFogId: "fog33"
        },
        fog40: {
            id: 40,
            price: 1000,
            exp: 100,
            parentFogId: "fog35"
        },

        moneyFogUndersea1: {
            id: 41,
            price: 99,
            money: true,
            parentFogId: "fog2",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        },
        moneyFogUndersea2: {
            id: 42,
            price: 400,
            money: true,
            parentFogId: "fog6",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }
            ]
        },
        moneyFogUndersea3: {
            id: 43,
            price: 599,
            money: true,
            parentFogId: "fog7",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        },
        moneyFogUndersea4: {
            id: 44,
            price: 1499,
            money: true,
            parentFogId: "fog10",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        },
        moneyFogUndersea5: {
            id: 45,
            price: 2899,
            money: true,
            parentFogId: "fog12",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }
            ]
        },
        moneyFogUndersea6: {
            id: 46,
            price: 1659,
            money: true,
            parentFogId: "fog13",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }
            ]

        },
        moneyFogUndersea7: {
            id: 47,
            price: 5399,
            money: true,
            parentFogId: "fog17",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 2
            }
            ]
        },
        moneyFogUndersea8: {
            id: 48,
            price: 1499,
            money: true,
            parentFogId: "fog20",
            windowSkin: "blue",
            bubbles: [{
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }
            ]
        },
        moneyFogUndersea9: {
            id: 49,
            price: 1999,
            money: true,
            parentFogId: "fog22",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 2
            },
            {
                code: "sacks",
                stage: 2,
                amount: 1
            },
            {
                code: "worker",
                stage: 3,
                amount: 2
            }
            ]
        },
        moneyFogUndersea10: {
            id: 50,
            price: 3499,
            money: true,
            parentFogId: "fog13",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "sacks",
                stage: 2,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }
            ]
        },
        moneyFogUndersea11: {
            id: 53,
            price: 15999,
            money: true,
            parentFogId: "fog14",
            windowSkin: "blue",
            bubbles: [{
                code: "sacks",
                stage: 2,
                amount: 3
            },
            {
                code: "crystal",
                stage: 3,
                amount: 3
            },
            {
                code: "worker",
                stage: 4,
                amount: 3
            }
            ]
        },

        factoryFogUndersea: {
            id: 51,
            price: 50,
            exp: 5,
            parentFogId: "fog10"
        },
        factory2FogUndersea: {
            id: 52,
            price: 29990,
            money: true,
            parentFogId: "factoryFogUndersea",
            windowSkin: "blue",
            bubbles: [{
                code: "sacks",
                stage: 2,
                amount: 5
            },
            {
                code: "crystal",
                stage: 3,
                amount: 5
            },
            {
                code: "worker",
                stage: 4,
                amount: 5
            }
            ]
        }
    };

    FogsConfig.easter = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 0,
            exp: 10,
            parentFogId: "fog0",
            conditions: {
                family: "eaeggs"
            }
        },
        fog2: {
            id: 2,
            price: 0,
            exp: 10,
            parentFogId: "fog1",
            conditions: {
                family: "earabbit"
            }
        },
        fog3: {
            id: 3,
            price: 0,
            exp: 10,
            parentFogId: "fog2",
            conditions: {
                family: "eabaskets"
            }
        },
        fog4: {
            id: 4,
            price: 0,
            exp: 10,
            parentFogId: "fog3",
            conditions: {
                family: "eacustomerbridge0a"
            }
        },
        fog5: {
            id: 5,
            price: 0,
            exp: 10,
            parentFogId: "fog4",
            conditions: {
                family: "eabread"
            }
        },
        fog6: {
            id: 6,
            price: 0,
            exp: 10,
            parentFogId: "fog5",
            conditions: {
                family: "eacustomermain0a"
            }
        },
        fog7: {
            id: 7,
            price: 0,
            exp: 10,
            parentFogId: "fog6",
            conditions: {
                family: "eacustomerbridge0b"
            }
        },
        fog8: {
            id: 8,
            price: 0,
            exp: 10,
            parentFogId: "fog7",
            conditions: {
                family: "eatulips"
            }
        },
        fog9: {
            id: 9,
            price: 0,
            exp: 10,
            parentFogId: "fog7",
            conditions: {
                family: "eacustomermain0b"
            }
        },
        fog10: {
            id: 10,
            price: 20,
            exp: 10,
            parentFogId: "fog7"
        },

        moneyFogEaster1: {
            id: 11,
            price: 169,
            money: true,
            parentFogId: "fog1",
            conditions: {
                family: "earabbit"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        },
        moneyFogEaster2: {
            id: 12,
            price: 599,
            money: true,
            parentFogId: "fog3",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }]
        },
        moneyFogEaster3: {
            id: 13,
            price: 1199,
            money: true,
            parentFogId: "fog5",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        },
        moneyFogEaster4: {
            id: 14,
            price: 2399,
            money: true,
            parentFogId: "fog7",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        },
        moneyFogEaster5: {
            id: 15,
            price: 3649,
            money: true,
            parentFogId: "fog9",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        }
    };

    FogsConfig.undersea2 = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },

        fog1: {
            id: 1,
            price: 5,
            exp: 0,
            parentFogId: "fog0"
        }, // crab
        fog2: {
            id: 2,
            price: 25,
            exp: 0,
            parentFogId: "fog1"
        },
        fog3: {
            id: 3,
            price: 25,
            exp: 0,
            parentFogId: "fog2"
        },

        fog4: {
            id: 4,
            price: 25,
            exp: 0,
            parentFogId: "fog3",
            conditions: {
                family: "seacrab"
            }
        }, // fishes
        fog5: {
            id: 5,
            price: 25,
            exp: 0,
            parentFogId: "fog4",
            conditions: {
                family: "seacrab"
            }
        },
        fog6: {
            id: 6,
            price: 25,
            exp: 0,
            parentFogId: "fog5",
            conditions: {
                family: "seacrab"
            }
        },
        fog7: {
            id: 7,
            price: 25,
            exp: 0,
            parentFogId: "fog6",
            conditions: {
                family: "seacrab"
            }
        },

        fog8: {
            id: 8,
            price: 25,
            exp: 0,
            parentFogId: "fog6",
            conditions: {
                family: "seafishes"
            }
        }, // shark
        fog9: {
            id: 9,
            price: 25,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "seafishes"
            }

        },
        fog10: {
            id: 10,
            price: 30,
            exp: 0,
            parentFogId: "fog9",
            conditions: {
                family: "seafishes"
            }

        },
        fog11: {
            id: 11,
            price: 30,
            exp: 0,
            parentFogId: "fog9",
            conditions: {
                family: "seafishes"
            }

        },
        fog12: {
            id: 12,
            price: 30,
            exp: 0,
            parentFogId: "fog11",
            conditions: {
                family: "seafishes"
            }

        },

        fog13: {
            id: 13,
            price: 50,
            exp: 0,
            parentFogId: "fog10",
            conditions: {
                family: "seashark"
            }
        }, // octopus
        fog14: {
            id: 14,
            price: 50,
            exp: 0,
            parentFogId: "fog13",
            conditions: {
                family: "seashark"
            }

        },
        fog15: {
            id: 15,
            price: 60,
            exp: 0,
            parentFogId: "fog13",
            conditions: {
                family: "seashark"
            }

        },
        fog16: {
            id: 16,
            price: 60,
            exp: 0,
            parentFogId: "fog15",
            conditions: {
                family: "seashark"
            }

        },
        fog17: {
            id: 17,
            price: 70,
            exp: 0,
            parentFogId: "fog16",
            conditions: {
                family: "seashark"
            }

        },
        fog18: {
            id: 18,
            price: 70,
            exp: 0,
            parentFogId: "fog17",
            conditions: {
                family: "seashark"
            }

        },

        fog19: {
            id: 19,
            price: 80,
            exp: 0,
            parentFogId: "fog15",
            conditions: {
                family: "seaoctopus"
            }

        }, // jellyfish
        fog20: {
            id: 20,
            price: 80,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: "seaoctopus"
            }

        },
        fog21: {
            id: 21,
            price: 90,
            exp: 0,
            parentFogId: "fog20",
            conditions: {
                family: "seaoctopus"
            }

        },
        fog22: {
            id: 22,
            price: 90,
            exp: 0,
            parentFogId: "fog20",
            conditions: {
                family: "seaoctopus"
            }

        },
        fog23: {
            id: 23,
            price: 100,
            exp: 0,
            parentFogId: "fog21",
            conditions: {
                family: "seaoctopus"
            }

        },
        fog24: {
            id: 24,
            price: 100,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "seaoctopus"
            }

        },
        fog25: {
            id: 25,
            price: 110,
            exp: 0,
            parentFogId: "fog24",
            conditions: {
                family: "seaoctopus"
            }

        },

        fog26: {
            id: 26,
            price: 110,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "seahorse"
            }
        }, // lochness
        fog27: {
            id: 27,
            price: 120,
            exp: 0,
            parentFogId: "fog26",
            conditions: {
                family: "seahorse"
            }

        },
        fog28: {
            id: 28,
            price: 130,
            exp: 0,
            parentFogId: "fog29",
            conditions: {
                family: "seahorse"
            }

        },
        fog29: {
            id: 29,
            price: 140,
            exp: 0,
            parentFogId: "fog26",
            conditions: {
                family: "seahorse"
            }

        },
        fog30: {
            id: 30,
            price: 150,
            exp: 0,
            parentFogId: "fog29",
            conditions: {
                family: "seahorse"
            }

        },
        fog31: {
            id: 31,
            price: 170,
            exp: 0,
            parentFogId: "fog27",
            conditions: {
                family: "seahorse"
            }

        },
        fog32: {
            id: 32,
            price: 180,
            exp: 0,
            parentFogId: "fog31",
            conditions: {
                family: "seahorse"
            }

        },
        fog33: {
            id: 33,
            price: 190,
            exp: 0,
            parentFogId: "fog31",
            conditions: {
                family: "seahorse"
            }

        },

        fog34: {
            id: 34,
            price: 230,
            exp: 0,
            parentFogId: "fog27",
            conditions: {
                family: "sealochness"
            }
        }, // turtle
        fog35: {
            id: 35,
            price: 240,
            exp: 0,
            parentFogId: "fog34",
            conditions: {
                family: "sealochness"
            }

        },
        fog36: {
            id: 36,
            price: 250,
            exp: 0,
            parentFogId: "fog34",
            conditions: {
                family: "sealochness"
            }

        },
        fog37: {
            id: 37,
            price: 260,
            exp: 0,
            parentFogId: "fog35",
            conditions: {
                family: "sealochness"
            }

        },
        fog38: {
            id: 38,
            price: 270,
            exp: 0,
            parentFogId: "fog37",
            conditions: {
                family: "sealochness"
            }

        },
        fog39: {
            id: 39,
            price: 280,
            exp: 0,
            parentFogId: "fog36",
            conditions: {
                family: "sealochness"
            }

        },
        fog40: {
            id: 40,
            price: 300,
            exp: 100,
            parentFogId: "fog39",
            conditions: {
                family: "sealochness"
            }

        },
        fog41: {
            id: 41,
            price: 320,
            exp: 0,
            parentFogId: "fog38",
            conditions: {
                family: "sealochness"
            }

        },
        fog42: {
            id: 42,
            price: 340,
            exp: 0,
            parentFogId: "fog41",
            conditions: {
                family: "sealochness"
            }

        },

        fog43: {
            id: 43,
            price: 400,
            exp: 0,
            parentFogId: "fog37",
            conditions: {
                family: "seaturtle"
            }
        }, // shrimps
        fog44: {
            id: 44,
            price: 500,
            exp: 0,
            parentFogId: "fog43",
            conditions: {
                family: "seaturtle"
            }

        },
        fog45: {
            id: 45,
            price: 600,
            exp: 0,
            parentFogId: "fog44",
            conditions: {
                family: "seaturtle"
            }

        },
        fog46: {
            id: 46,
            price: 700,
            exp: 0,
            parentFogId: "fog44",
            conditions: {
                family: "seaturtle"
            }

        },
        fog47: {
            id: 47,
            price: 800,
            exp: 0,
            parentFogId: "fog44",
            conditions: {
                family: "seaturtle"
            }

        },
        fog48: {
            id: 48,
            price: 1000,
            exp: 0,
            parentFogId: "fog46",
            conditions: {
                family: "seaturtle"
            }

        },
        fog49: {
            id: 49,
            price: 1250,
            exp: 0,
            parentFogId: "fog48",
            conditions: {
                family: "seaturtle"
            }

        },
        fog50: {
            id: 50,
            price: 1500,
            exp: 0,
            parentFogId: "fog49",
            conditions: {
                family: "seaturtle"
            }

        },
        fog51: {
            id: 51,
            price: 1800,
            exp: 100,
            parentFogId: "fog50",
            conditions: {
                family: "seaturtle"
            }

        },
        fog52: {
            id: 52,
            price: 2350,
            exp: 0,
            parentFogId: "fog50",
            conditions: {
                family: "seaturtle"
            }

        },

        fog53: {
            id: 53,
            price: 600,
            exp: 0,
            parentFogId: "fog52"
        },
        fog54: {
            id: 54,
            price: 750,
            exp: 0,
            parentFogId: "fog53"
        },
        fog55: {
            id: 55,
            price: 1000,
            exp: 0,
            parentFogId: "fog54"
        },

        moneyFogUndersea1: {
            id: 56,
            price: 99,
            money: true,
            parentFogId: "fog2",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 1$
        moneyFogUndersea2: {
            id: 57,
            price: 499,
            money: true,
            parentFogId: "fog6",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }
            ]
        }, // 3$
        moneyFogUndersea3: {
            id: 58,
            price: 3899,
            money: true,
            parentFogId: "fog10",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, // 25$
        moneyFogUndersea4: {
            id: 59,
            price: 1449,
            money: true,
            parentFogId: "fog12",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, // 5$
        moneyFogUndersea5: {
            id: 60,
            price: 5399,
            money: true,
            parentFogId: "fog17",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }
            ]
        }, // 45$
        moneyFogUndersea7: {
            id: 62,
            price: 12699,
            money: true,
            parentFogId: "fog19",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 2
            }]
        }, // 100$
        moneyFogUndersea6: {
            id: 61,
            price: 2899,
            money: true,
            parentFogId: "fog24",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 15$
        moneyFogUndersea8: {
            id: 63,
            price: 2799,
            money: true,
            parentFogId: "fog30",
            windowSkin: "blue",
            bubbles: [{
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 15$
        moneyFogUndersea9: {
            id: 64,
            price: 400,
            money: true,
            parentFogId: "fog11",
            windowSkin: "blue",
            conditions: {
                family: "seatournament"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // snail

        moneyFogUndersea10: {
            id: 65,
            price: 3499,
            money: true,
            parentFogId: "fog27",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "sacks",
                stage: 2,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }
    };

    FogsConfig.collections = {
        fog0: {
            id: 0,
            price: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "clhydra"
            }
        },
        fog2: {
            id: 2,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "clcyclops"
            }
        },
        fog3: {
            id: 3,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "clcerberus"
            }
        },
        fog4: {
            id: 4,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "cljapanleon"
            }
        },
        fog5: {
            id: 5,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "cldemon"
            }
        },
        fog6: {
            id: 6,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "clchinesedragon"
            }
        },
        fog7: {
            id: 7,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "clcrystaldragon"
            }
        },
        fog8: {
            id: 8,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "clsalamandra"
            }
        },
        fog9: {
            id: 9,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "clmothcat"
            }
        },
        fog10: {
            id: 10,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "clmanticore"
            }
        },
        fog11: {
            id: 11,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "clmushroomdragon"
            }
        },
        fog12: {
            id: 12,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "clgriffin"
            }
        },
        fog13: {
            id: 13,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "clorc"
            }
        },
        fog14: {
            id: 14,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "clent"
            }
        },
        fog15: {
            id: 15,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                family: "cldeerdragon"
            }
        },
        fog16: {
            id: 16,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 16,
                family: "clfishdragon"
            }
        },
        fog17: {
            id: 17,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 17,
                family: "clkoidragon"
            }
        },
        fog18: {
            id: 18,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 18,
                family: "clgorgon"
            }
        },
        fog19: {
            id: 19,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 19,
                family: "clchameleon"
            }
        },
        fog20: {
            id: 20,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 20,
                family: "clvasilisk"
            }
        },
        fog21: {
            id: 21,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 21,
                family: "clpegasus"
            }
        },
        fog22: {
            id: 22,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 22,
                family: "clphoenix"
            }
        },
        fog23: {
            id: 23,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 23,
                family: "clowldragon"
            }
        },
        fog24: {
            id: 24,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 24,
                family: "clkitsune"
            }
        },
        fog25: {
            id: 25,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 25,
                family: "cltirex"
            }
        },
        fog26: {
            id: 26,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 26,
                family: "clminotaur"
            }
        },
        fog27: {
            id: 27,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 27,
                family: "clbeedragon"
            }
        },
        fog28: {
            id: 28,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 28,
                family: "clsnowbeast"
            }
        },
        fog29: {
            id: 29,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 29,
                family: "clsnowdragon"
            }
        },
        fog30: {
            id: 30,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 30,
                family: "cllongneck"
            }
        },
        fog31: {
            id: 60,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 31,
                family: "clwyvern"
            }
        },
        fog32: {
            id: 61,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 32,
                family: "clray"
            }
        },
        fog33: {
            id: 62,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 33,
                family: "clbat"
            }
        },
        fog34: {
            id: 63,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 34,
                family: "clsnail"
            }
        },
        fog35: {
            id: 64,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 35,
                family: "clbuckbeak"
            }
        },
        fog36: {
            id: 65,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 36,
                family: "clmantis"
            }
        },
        fog37: {
            id: 66,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 37,
                family: "clfrog"
            }
        },
        fog38: {
            id: 67,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 38,
                family: "clmonkeyking"
            }
        },
        fog39: {
            id: 68,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 39,
                family: "clturtle"
            }
        },
        fog40: {
            id: 69,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 40,
                family: "cltechnosphinx"
            }
        },
        fog41: {
            id: 89,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 41,
                family: "clbunnydragon"
            }
        },
        fog42: {
            id: 90,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 42,
                family: "clzebra"
            }
        },
        fog43: {
            id: 91,
            price: 0,
            parentFogId: "fog0",
            conditions: {
                level: 43,
                family: "cllavadragon"
            }
        },

        fog99: {
            id: 99,
            available: false,
            conditions: {
                level: 100
            }
        },

        stickFog1: {
            id: 31,
            price: 5,
            parentFogId: "fog0",
            conditions: {
                level: 2
            }
        },
        stickFog2: {
            id: 32,
            price: 5,
            parentFogId: "stickFog1",
            conditions: {
                level: 3
            }
        },
        stickFog3: {
            id: 33,
            price: 5,
            parentFogId: "stickFog2",
            conditions: {
                level: 4
            }
        },
        stickFog4: {
            id: 34,
            price: 5,
            parentFogId: "stickFog3",
            conditions: {
                level: 5
            }
        },
        stickFog6: {
            id: 35,
            price: 5,
            parentFogId: "stickFog4",
            conditions: {
                level: 6
            }
        },
        stickFog7: {
            id: 36,
            price: 5,
            parentFogId: "stickFog6",
            conditions: {
                level: 7
            }
        },
        stickFog8: {
            id: 37,
            price: 5,
            parentFogId: "stickFog7",
            conditions: {
                level: 8
            }
        },
        stickFog9: {
            id: 38,
            price: 5,
            parentFogId: "stickFog8",
            conditions: {
                level: 9
            }
        },
        stickFog10: {
            id: 39,
            price: 5,
            parentFogId: "stickFog9",
            conditions: {
                level: 10
            }
        },
        stickFog11: {
            id: 40,
            price: 5,
            parentFogId: "stickFog10",
            conditions: {
                level: 11
            }
        },
        stickFog12: {
            id: 41,
            price: 5,
            parentFogId: "stickFog11",
            conditions: {
                level: 12
            }
        },
        stickFog13: {
            id: 42,
            price: 5,
            parentFogId: "stickFog12",
            conditions: {
                level: 13
            }
        },
        stickFog14: {
            id: 43,
            price: 5,
            parentFogId: "stickFog13",
            conditions: {
                level: 14
            }
        },
        stickFog15: {
            id: 44,
            price: 5,
            parentFogId: "stickFog14",
            conditions: {
                level: 15
            }
        },
        stickFog16: {
            id: 45,
            price: 5,
            parentFogId: "stickFog15",
            conditions: {
                level: 16
            }
        },
        stickFog17: {
            id: 46,
            price: 5,
            parentFogId: "stickFog16",
            conditions: {
                level: 17
            }
        },
        stickFog18: {
            id: 47,
            price: 5,
            parentFogId: "stickFog17",
            conditions: {
                level: 18
            }
        },
        stickFog19: {
            id: 48,
            price: 5,
            parentFogId: "stickFog18",
            conditions: {
                level: 19
            }
        },
        stickFog20: {
            id: 49,
            price: 5,
            parentFogId: "stickFog19",
            conditions: {
                level: 20
            }
        },
        stickFog21: {
            id: 50,
            price: 5,
            parentFogId: "stickFog20",
            conditions: {
                level: 21
            }
        },
        stickFog22: {
            id: 51,
            price: 5,
            parentFogId: "stickFog21",
            conditions: {
                level: 22
            }
        },
        stickFog23: {
            id: 52,
            price: 5,
            parentFogId: "stickFog22",
            conditions: {
                level: 23
            }
        },
        stickFog24: {
            id: 53,
            price: 5,
            parentFogId: "stickFog23",
            conditions: {
                level: 24
            }
        },
        stickFog25: {
            id: 54,
            price: 5,
            parentFogId: "stickFog24",
            conditions: {
                level: 25
            }
        },
        stickFog26: {
            id: 55,
            price: 5,
            parentFogId: "stickFog25",
            conditions: {
                level: 26
            }
        },
        stickFog27: {
            id: 56,
            price: 5,
            parentFogId: "stickFog26",
            conditions: {
                level: 27
            }
        },
        stickFog28: {
            id: 57,
            price: 5,
            parentFogId: "stickFog27",
            conditions: {
                level: 28
            }
        },
        stickFog29: {
            id: 58,
            price: 5,
            parentFogId: "stickFog28",
            conditions: {
                level: 29
            }
        },
        stickFog30: {
            id: 59,
            price: 5,
            parentFogId: "stickFog29",
            conditions: {
                level: 30
            }
        },
        stickFog31: {
            id: 70,
            price: 5,
            parentFogId: "stickFog30",
            conditions: {
                level: 31
            }
        },
        stickFog32: {
            id: 78,
            price: 5,
            parentFogId: "stickFog31",
            conditions: {
                level: 32
            }
        },
        stickFog33: {
            id: 79,
            price: 5,
            parentFogId: "stickFog32",
            conditions: {
                level: 33
            }
        },
        stickFog34: {
            id: 80,
            price: 5,
            parentFogId: "stickFog33",
            conditions: {
                level: 34
            }
        },
        stickFog35: {
            id: 81,
            price: 5,
            parentFogId: "stickFog34",
            conditions: {
                level: 35
            }
        },
        stickFog36: {
            id: 82,
            price: 5,
            parentFogId: "stickFog35",
            conditions: {
                level: 36
            }
        },
        stickFog37: {
            id: 83,
            price: 5,
            parentFogId: "stickFog36",
            conditions: {
                level: 37
            }
        },
        stickFog38: {
            id: 84,
            price: 5,
            parentFogId: "stickFog37",
            conditions: {
                level: 38
            }
        },
        stickFog39: {
            id: 85,
            price: 5,
            parentFogId: "stickFog38",
            conditions: {
                level: 39
            }
        },
        stickFog40: {
            id: 86,
            price: 5,
            parentFogId: "stickFog39",
            conditions: {
                level: 40
            }
        },
        stickFog41: {
            id: 92,
            price: 5,
            parentFogId: "stickFog40",
            conditions: {
                level: 41
            }
        },
        stickFog42: {
            id: 93,
            price: 5,
            parentFogId: "stickFog41",
            conditions: {
                level: 42
            }
        },
        stickFog43: {
            id: 94,
            price: 5,
            parentFogId: "stickFog42",
            conditions: {
                level: 43
            }
        },

        moneyFogCollections1: {
            id: 71,
            price: 199,
            money: true,
            parentFogId: "stickFog6",
            windowSkin: "green",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        },
        moneyFogCollections2: {
            id: 72,
            price: 999,
            money: true,
            parentFogId: "stickFog9",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            }]
        },
        moneyFogCollections3: {
            id: 73,
            price: 599,
            money: true,
            windowSkin: "green",
            parentFogId: "stickFog12",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            }]
        },
        moneyFogCollections4: {
            id: 74,
            price: 999,
            money: true,
            windowSkin: "green",
            parentFogId: "stickFog16",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            }]
        },
        moneyFogCollections5: {
            id: 75,
            price: 1499,
            money: true,
            windowSkin: "blue",
            parentFogId: "stickFog24",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            }]
        },
        moneyFogCollections6: {
            id: 76,
            price: 1499,
            money: true,
            windowSkin: "blue",
            parentFogId: "stickFog7",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            }]
        },
        moneyFogCollections7: {
            id: 77,
            price: 2699,
            money: true,
            windowSkin: "blue",
            parentFogId: "stickFog18",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            }]
        },
        moneyFogCollections8: {
            id: 87,
            price: 2399,
            money: true,
            windowSkin: "blue",
            parentFogId: "stickFog33",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            }]
        },
        moneyFogCollections9: {
            id: 88,
            price: 4499,
            money: true,
            windowSkin: "blue",
            parentFogId: "stickFog37",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            }]
        },
        moneyFogCollections10: {
            id: 95,
            price: 2499,
            money: true,
            windowSkin: "blue",
            parentFogId: "stickFog41",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 2
            },
            {
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            }]
        }
    };

    FogsConfig.dragonia3 = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 5,
            exp: 0,
            parentFogId: "fog0"
        }, // wood
        fog2: {
            id: 2,
            price: 25,
            exp: 0,
            parentFogId: "fog1"
        },
        fog3: {
            id: 3,
            price: 25,
            exp: 0,
            parentFogId: "fog2"
        },
        fog4: {
            id: 4,
            price: 25,
            exp: 0,
            parentFogId: "fog3"
        }, // stone
        fog5: {
            id: 5,
            price: 25,
            exp: 0,
            parentFogId: "fog4"
        },
        fog6: {
            id: 6,
            price: 25,
            exp: 0,
            parentFogId: "fog5"
        },
        fog7: {
            id: 7,
            price: 25,
            exp: 0,
            parentFogId: "fog6",
            conditions: {
                family: "drwood"
            }
        },
        fog8: {
            id: 8,
            price: 25,
            exp: 0,
            parentFogId: "fog7",
            conditions: {
                family: "drwood"
            }
        }, // sapphire
        fog9: {
            id: 9,
            price: 25,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "drstone"
            }
        },
        fog10: {
            id: 10,
            price: 30,
            exp: 0,
            parentFogId: "fog9",
            conditions: {
                family: "drstone"
            }
        },
        fog11: {
            id: 11,
            price: 30,
            exp: 0,
            parentFogId: "fog10",
            conditions: {
                family: "drcustomerbridge1"
            }
        },
        fog12: {
            id: 12,
            price: 30,
            exp: 0,
            parentFogId: "fog11",
            conditions: {
                family: "drstone"
            }
        },
        fog13: {
            id: 13,
            price: 50,
            exp: 0,
            parentFogId: "fog12",
            conditions: {
                family: "drstone"
            }
        }, // sled
        fog14: {
            id: 14,
            price: 50,
            exp: 0,
            parentFogId: "fog13",
            conditions: {
                family: "drsapphire"
            }
        },
        fog15: {
            id: 15,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog16: {
            id: 16,
            price: 60,
            exp: 0,
            parentFogId: "fog13",
            conditions: {
                family: "drproducer1b"
            }
        },
        fog17: {
            id: 17,
            price: 70,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog18: {
            id: 18,
            price: 70,
            exp: 0,
            parentFogId: "fog17",
            conditions: {
                family: "drsapphire"
            }
        },
        fog19: {
            id: 19,
            price: 80,
            exp: 0,
            parentFogId: "fog18",
            conditions: {
                family: "drsled"
            }
        }, // castle
        fog20: {
            id: 20,
            price: 80,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: "drsled"
            }
        },
        fog21: {
            id: 21,
            price: 90,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: "dr2dragonred"
            }
        },
        fog22: {
            id: 22,
            price: 90,
            exp: 0,
            parentFogId: "fog21",
            conditions: {
                family: "drsled"
            }
        },
        fog23: {
            id: 23,
            price: 100,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        fog24: {
            id: 24,
            price: 100,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        fog25: {
            id: 25,
            price: 110,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        fog26: {
            id: 26,
            price: 110,
            exp: 0,
            parentFogId: "fog23",
            conditions: {
                family: "drcastle"
            }
        }, // purplecrystal
        fog27: {
            id: 27,
            price: 120,
            exp: 0,
            parentFogId: "fog26",
            conditions: {
                family: "drcastle"
            }
        },
        fog28: {
            id: 28,
            price: 130,
            exp: 0,
            parentFogId: "fog27",
            conditions: {
                family: "drcastle"
            }
        },
        fog29: {
            id: 29,
            price: 140,
            exp: 0,
            parentFogId: "fog28",
            conditions: {
                family: "drcastle"
            }
        },
        fog30: {
            id: 30,
            price: 150,
            exp: 0,
            parentFogId: "fog29",
            conditions: {
                family: "drcastle"
            }
        },
        fog31: {
            id: 31,
            price: 170,
            exp: 0,
            parentFogId: "fog27",
            conditions: {
                family: "drcastle"
            }
        },
        fog32: {
            id: 32,
            price: 180,
            exp: 0,
            parentFogId: "fog28",
            conditions: {
                family: "drcastle"
            }
        },
        fog33: {
            id: 33,
            price: 190,
            exp: 0,
            parentFogId: "fog28",
            conditions: {
                family: "drcastle"
            }
        },
        fog34: {
            id: 34,
            price: 230,
            exp: 0,
            parentFogId: "fog30",
            conditions: {
                family: "drpurplecrystal"
            }
        }, // bamboo
        fog35: {
            id: 35,
            price: 240,
            exp: 0,
            parentFogId: "fog34",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog36: {
            id: 36,
            price: 250,
            exp: 0,
            parentFogId: "fog35",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog37: {
            id: 37,
            price: 260,
            exp: 0,
            parentFogId: "fog35",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog38: {
            id: 38,
            price: 270,
            exp: 0,
            parentFogId: "fog37",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog39: {
            id: 39,
            price: 280,
            exp: 0,
            parentFogId: "fog38",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog40: {
            id: 40,
            price: 300,
            exp: 100,
            parentFogId: "fog37",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog41: {
            id: 41,
            price: 320,
            exp: 0,
            parentFogId: "fog38",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog42: {
            id: 42,
            price: 340,
            exp: 0,
            parentFogId: "fog40",
            conditions: {
                family: "drpurplecrystal"
            }
        },
        fog43: {
            id: 43,
            price: 400,
            exp: 0,
            parentFogId: "fog42",
            conditions: {
                family: "drbamboo"
            }
        }, // feathers
        fog44: {
            id: 44,
            price: 500,
            exp: 0,
            parentFogId: "fog43",
            conditions: {
                family: "drbamboo"
            }
        },
        fog45: {
            id: 45,
            price: 600,
            exp: 0,
            parentFogId: "fog44",
            conditions: {
                family: "drbamboo"
            }
        },
        fog46: {
            id: 46,
            price: 700,
            exp: 0,
            parentFogId: "fog45",
            conditions: {
                family: "drbamboo"
            }
        },
        fog47: {
            id: 47,
            price: 800,
            exp: 0,
            parentFogId: "fog46",
            conditions: {
                family: "drbamboo"
            }
        },
        fog48: {
            id: 48,
            price: 1000,
            exp: 0,
            parentFogId: "fog47",
            conditions: {
                family: "drbamboo"
            }
        },
        fog49: {
            id: 49,
            price: 1250,
            exp: 0,
            parentFogId: "fog48",
            conditions: {
                family: "drbamboo"
            }
        },
        fog50: {
            id: 50,
            price: 1500,
            exp: 0,
            parentFogId: "fog48",
            conditions: {
                family: "drbamboo"
            }
        },
        fog51: {
            id: 51,
            price: 1800,
            exp: 100,
            parentFogId: "fog48",
            conditions: {
                family: "drbamboo"
            }
        },
        fog52: {
            id: 52,
            price: 2350,
            exp: 0,
            parentFogId: "fog50",
            conditions: {
                family: "drbamboo"
            }
        },
        fog53: {
            id: 53,
            price: 600,
            exp: 0,
            parentFogId: "fog52"
        },
        fog54: {
            id: 54,
            price: 750,
            exp: 0,
            parentFogId: "fog53"
        },
        dr3FogCave: {
            id: 55,
            price: 0,
            exp: 10,
            conditions: {
                family: ["drcustomermain0", "drcustomermain1", "drcustomermain2", "drcustomermain3"]
            }
        },

        moneyFogDragonia1: {
            id: 56,
            price: 99,
            money: true,
            parentFogId: "fog2",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 1$ - big sale
        moneyFogDragonia2: {
            id: 57,
            price: 499,
            money: true,
            parentFogId: "fog5",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }
            ]
        }, // 5$
        moneyFogDragonia3: {
            id: 58,
            price: 949,
            money: true,
            parentFogId: "fog11",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 10$ - big sale
        moneyFogDragonia4: {
            id: 59,
            price: 1899,
            money: true,
            parentFogId: "fog13",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, // 20$
        moneyFogDragonia5: {
            id: 60,
            price: 1499,
            money: true,
            parentFogId: "fog19",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, // 15$ - big sale
        moneyFogDragonia6: {
            id: 61,
            price: 4999,
            money: true,
            parentFogId: "fog10",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 50$ - have portal
        moneyFogDragonia7: {
            id: 62,
            price: 2499,
            money: true,
            parentFogId: "fog24",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 2
            }]
        }, // 25$
        moneyFogDragonia8: {
            id: 64,
            price: 8499,
            money: true,
            parentFogId: "fog21",
            windowSkin: "blue",
            bubbles: [{
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 85$ - have x2 portal
        moneyFogDragonia9: {
            id: 65,
            price: 1699,
            money: true,
            parentFogId: "fog18",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "sacks",
                stage: 2,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 15$
        moneyFogDragonia10: {
            id: 66,
            price: 400,
            money: true,
            parentFogId: "fog12",
            windowSkin: "blue",
            conditions: {
                family: "drtournament"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        } // 3$ - snail
    };

    FogsConfig.china = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 5,
            exp: 0,
            parentFogId: "fog0"
        }, // wood
        fog2: {
            id: 2,
            price: 5,
            exp: 0,
            parentFogId: "fog1"
        },
        fog3: {
            id: 3,
            price: 25,
            exp: 0,
            parentFogId: "fog2",
            conditions: {
                family: "rpcustomerbridge0"
            }
        },
        fog4: {
            id: 4,
            price: 25,
            exp: 0,
            parentFogId: "fog3"
        }, // stone
        fog5: {
            id: 5,
            price: 25,
            exp: 0,
            parentFogId: "fog4"
        },
        fog6: {
            id: 6,
            price: 25,
            exp: 0,
            parentFogId: "fog5",
            conditions: {
                family: "rpcustomerbridge1"
            }
        },
        fog7: {
            id: 7,
            price: 25,
            exp: 0,
            parentFogId: "fog6",
            conditions: {
                family: "drwood"
            }
        },
        fog8: {
            id: 8,
            price: 25,
            exp: 0,
            parentFogId: "fog7",
            conditions: {
                family: "drwood"
            }
        }, // sapphire
        fog9: {
            id: 9,
            price: 25,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "drstone"
            }
        },
        fog10: {
            id: 10,
            price: 30,
            exp: 0,
            parentFogId: "fog9",
            conditions: {
                family: "drstone"
            }
        },
        fog11: {
            id: 11,
            price: 30,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "drstone"
            }
        },
        fog12: {
            id: 12,
            price: 30,
            exp: 0,
            parentFogId: "fog11",
            conditions: {
                family: ["drstone", "rpcustomerbridge2"]
            }
        },
        fog13: {
            id: 13,
            price: 50,
            exp: 0,
            parentFogId: "fog12",
            conditions: {
                family: "drstone"
            }
        }, // sled
        fog14: {
            id: 14,
            price: 50,
            exp: 0,
            parentFogId: "fog13",
            conditions: {
                family: "drsapphire"
            }
        },
        fog15: {
            id: 15,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog16: {
            id: 16,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog17: {
            id: 17,
            price: 70,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog18: {
            id: 18,
            price: 70,
            exp: 0,
            parentFogId: "fog17",
            conditions: {
                family: "drsapphire"
            }
        },
        chinaFogCave: {
            id: 26,
            price: 0,
            exp: 10,
            conditions: {
                family: ["rpcustomermain1", "rpcustomermain2"]
            }
        },

        moneyFogChina1: {
            id: 61,
            price: 99,
            money: true,
            parentFogId: "fog2",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 1$ - big sale
        moneyFogChina2: {
            id: 62,
            price: 499,
            money: true,
            parentFogId: "fog5",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            }, {
                code: "crystal",
                stage: 2,
                amount: 1
            }]
        }, // 5$
        moneyFogChina3: {
            id: 63,
            price: 949,
            money: true,
            parentFogId: "fog8",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 10$ - big sale
        moneyFogChina4: {
            id: 64,
            price: 1899,
            money: true,
            parentFogId: "fog11",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            }, {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        }, // 20$
        moneyFogChina5: {
            id: 65,
            price: 1499,
            money: true,
            parentFogId: "fog19",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            }, {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        }, // 15$ - big sale
        moneyFogChina6: {
            id: 66,
            price: 4999,
            money: true,
            parentFogId: "fog10",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            }, {
                code: "crystal",
                stage: 3,
                amount: 1
            }, {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 50$ - have portal
        moneyFogChina7: {
            id: 67,
            price: 2499,
            money: true,
            parentFogId: "fog13",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            }, {
                code: "crystal",
                stage: 3,
                amount: 2
            }, {
                code: "worker",
                stage: 4,
                amount: 2
            }]
        }, // 30$
        moneyFogChina8: {
            id: 68,
            price: 8499,
            money: true,
            parentFogId: "fog21",
            windowSkin: "blue",
            bubbles: [{
                code: "crystal",
                stage: 3,
                amount: 1
            }, {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 85$ - have x2 portal
        moneyFogChina9: {
            id: 69,
            price: 1699,
            money: true,
            parentFogId: "fog15",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            }, {
                code: "sacks",
                stage: 2,
                amount: 2
            }, {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 15$
        moneyFogChina10: {
            id: 70,
            price: 400,
            money: true,
            parentFogId: "fog17",
            windowSkin: "blue",
            conditions: {
                unit: { code: "drtournament", stage: 0 }
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        } // 5$ - snail
    };

    FogsConfig.adventure = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 0,
            exp: 0,
            parentFogId: "fog0",
            conditions: {
                family: "advcustomerbridge0"
            }
        },
        fog2: {
            id: 2,
            price: 0,
            exp: 0,
            parentFogId: "fog1",
            conditions: {
                family: "advcustomerbridge1"
            }
        },
        fog3: {
            id: 3,
            price: 0,
            exp: 0,
            parentFogId: "fog2",
            conditions: {
                family: "advcustomermain1"
            }
        },
        fog4: {
            id: 4,
            price: 0,
            exp: 0,
            parentFogId: "fog3",
            conditions: {
                family: "advcustomerbridge2"
            }
        },
        fog99: {
            id: 57,
            available: false,
            price: 0,
            exp: 0
        },
        moneyFog1: {
            id: 8,
            price: 199,
            money: true,
            parentFogId: "fog1",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        },
        moneyFog2: {
            id: 9,
            price: 999,
            money: true,
            parentFogId: "fog1",
            conditions: {
                family: "advcustomerbridge1"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }]
        },
        moneyFog3: {
            id: 10,
            price: 2999,
            money: true,
            parentFogId: "fog3",
            conditions: {
                unit: { code: "advcustomerbridge2", stage: 0 }
            },
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        }
    };

    FogsConfig.adventure2 = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 0,
            exp: 0,
            parentFogId: "fog0"
        },
        fog2: {
            id: 2,
            price: 0,
            exp: 0,
            parentFogId: "fog1",
            conditions: {
                family: "rpcustomerbridge0"
            }
        },
        fog3: {
            id: 3,
            price: 0,
            exp: 0,
            parentFogId: "fog2"
        },
        fog4: {
            id: 4,
            price: 0,
            exp: 0,
            parentFogId: "fog3",
            conditions: {
                family: "rpcustomermain0"
            }
        },
        fog5: {
            id: 5,
            price: 0,
            exp: 0,
            parentFogId: "fog4",
            conditions: {
                family: "rpcustomerbridge1"
            }
        },
        fog6: {
            id: 6,
            price: 0,
            exp: 0,
            parentFogId: "fog5"
        },
        fog7: {
            id: 7,
            price: 0,
            exp: 0,
            parentFogId: "fog6",
            conditions: {
                family: "rpcustomermain1"
            }
        },
        fog8: {
            id: 8,
            price: 0,
            exp: 0,
            parentFogId: "fog7",
            conditions: {
                family: "rpcustomerbridge2"
            }
        },
        fog9: {
            id: 9,
            price: 0,
            exp: 0,
            parentFogId: "fog8"
        },
        fog10: {
            id: 10,
            price: 0,
            exp: 0,
            parentFogId: "fog9",
            conditions: {
                family: ["rpcustomermain2", "rpcustomerbridge3"]
            }
        },
        fog11: {
            id: 11,
            price: 0,
            exp: 0,
            parentFogId: "fog10"
        },
        fog12: {
            id: 12,
            price: 0,
            exp: 0,
            parentFogId: "fog11",
            conditions: {
                family: "rpcustomermain3"
            }
        },
        fog13: {
            id: 13,
            price: 0,
            exp: 0,
            parentFogId: "fog12",
            conditions: {
                family: "rpcustomerbridge4"
            }
        },
        fog14: {
            id: 14,
            price: 0,
            exp: 0,
            parentFogId: "fog13"
        },
        fog99: {
            id: 57,
            available: false,
            price: 0,
            exp: 0
        },
        moneyFog1: {
            id: 20,
            price: 499,
            money: true,
            parentFogId: "fog2",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        },
        moneyFog2: {
            id: 21,
            price: 999,
            money: true,
            parentFogId: "fog5",
            conditions: {
                family: "advcustomerbridge1"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }]
        },
        moneyFog3: {
            id: 22,
            price: 1499,
            money: true,
            parentFogId: "fog7",
            conditions: {
                unit: { code: "advcustomerbridge2", stage: 0 }
            },
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        },
        moneyFog4: {
            id: 23,
            price: 2999,
            money: true,
            parentFogId: "fog9",
            conditions: {
                unit: { code: "advcustomerbridge2", stage: 0 }
            },
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        },
        moneyFog5: {
            id: 24,
            price: 4999,
            money: true,
            parentFogId: "fog12",
            conditions: {
                unit: { code: "advcustomerbridge2", stage: 0 }
            },
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }]
        }
    };

    FogsConfig.undersea3 = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 5,
            exp: 0,
            parentFogId: "fog0"
        },
        fog2: {
            id: 2,
            price: 15,
            exp: 0,
            parentFogId: "fog1"
        },
        fog3: {
            id: 3,
            price: 20,
            exp: 0,
            parentFogId: "fog2"
        },
        fog4: {
            id: 4,
            price: 25,
            exp: 0,
            parentFogId: "fog3",
            conditions: {
                family: "rpcustomerbridge0"
            }
        },
        fog5: {
            id: 5,
            price: 25,
            exp: 0,
            parentFogId: "fog4",
            conditions: {
                family: ["seacrab", "rpcustomerbridge1"]
            }
        },
        fog6: {
            id: 6,
            price: 25,
            exp: 0,
            parentFogId: "fog5",
            conditions: {
                family: "seacrab"
            }
        },
        fog7: {
            id: 7,
            price: 25,
            exp: 0,
            parentFogId: "fog6",
            conditions: {
                family: "seacrab"
            }
        },
        fog8: {
            id: 8,
            price: 25,
            exp: 0,
            parentFogId: "fog7",
            conditions: {
                family: "seacrab"
            }
        },
        fog9: {
            id: 9,
            price: 25,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "seafishes"
            }
        },
        fog10: {
            id: 10,
            price: 30,
            exp: 0,
            parentFogId: "fog9",
            conditions: {
                family: "seafishes"
            }
        },
        fog11: {
            id: 11,
            price: 30,
            exp: 0,
            parentFogId: "fog10",
            conditions: {
                family: "seafishes"
            }

        },
        fog12: {
            id: 12,
            price: 30,
            exp: 0,
            parentFogId: "fog10",
            conditions: {
                family: "seafishes"
            }
        },
        fog13: {
            id: 13,
            price: 50,
            exp: 0,
            parentFogId: "fog12",
            conditions: {
                family: "seafishes"
            }
        },
        fog14: {
            id: 14,
            price: 50,
            exp: 0,
            parentFogId: "fog13",
            conditions: {
                family: ["seashark", "rpcustomerbridge2"]
            }
        },
        fog15: {
            id: 15,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "seashark"
            }
        },
        fog16: {
            id: 16,
            price: 60,
            exp: 0,
            parentFogId: "fog15",
            conditions: {
                family: "seashark"
            }
        },
        fog17: {
            id: 17,
            price: 70,
            exp: 0,
            parentFogId: "fog15",
            conditions: {
                family: "seashark"
            }
        },
        fog18: {
            id: 18,
            price: 70,
            exp: 0,
            parentFogId: "fog17",
            conditions: {
                family: "seashark"
            }
        },
        fog19: {
            id: 19,
            price: 80,
            exp: 0,
            parentFogId: "fog17",
            conditions: {
                family: "seashark"
            }
        },
        fog20: {
            id: 20,
            price: 80,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: ["seaoctopus", "rpcustomerbridge3"]
            }
        },
        fog21: {
            id: 21,
            price: 90,
            exp: 0,
            parentFogId: "fog20",
            conditions: {
                family: "seaoctopus"
            }
        },
        fog22: {
            id: 22,
            price: 90,
            exp: 0,
            parentFogId: "fog20",
            conditions: {
                family: "seaoctopus"
            }
        },
        fog23: {
            id: 23,
            price: 100,
            exp: 0,
            parentFogId: "fog21",
            conditions: {
                family: "seaoctopus"
            }
        },
        fog24: {
            id: 24,
            price: 100,
            exp: 0,
            parentFogId: "fog23",
            conditions: {
                family: "seaoctopus"
            }
        },
        fog25: {
            id: 25,
            price: 110,
            exp: 0,
            parentFogId: "fog24",
            conditions: {
                family: "seaoctopus"
            }
        },
        fog26: {
            id: 26,
            price: 110,
            exp: 0,
            parentFogId: "fog24",
            conditions: {
                family: "seaoctopus"
            }
        },
        fog27: {
            id: 27,
            price: 120,
            exp: 0,
            parentFogId: "fog24",
            conditions: {
                family: "seahorse"
            }
        },
        fog28: {
            id: 28,
            price: 130,
            exp: 0,
            parentFogId: "fog27",
            conditions: {
                family: "seahorse"
            }
        },
        fog29: {
            id: 29,
            price: 140,
            exp: 0,
            parentFogId: "fog27",
            conditions: {
                family: "seahorse"
            }
        },
        fog30: {
            id: 30,
            price: 150,
            exp: 0,
            parentFogId: "fog28",
            conditions: {
                family: "seahorse"
            }
        },
        fog31: {
            id: 31,
            price: 160,
            exp: 0,
            parentFogId: "fog29",
            conditions: {
                family: "seahorse"
            }
        },
        fog32: {
            id: 32,
            price: 170,
            exp: 0,
            parentFogId: "fog30",
            conditions: {
                family: "seahorse"
            }
        },
        fog33: {
            id: 33,
            price: 180,
            exp: 0,
            parentFogId: "fog32",
            conditions: {
                family: "seahorse"
            }
        },
        fog34: {
            id: 34,
            price: 190,
            exp: 0,
            parentFogId: "fog33",
            conditions: {
                family: "seahorse"
            }
        },
        fog35: {
            id: 35,
            price: 230,
            exp: 0,
            parentFogId: "fog28",
            conditions: {
                family: "sealochness"
            }
        },
        fog36: {
            id: 36,
            price: 240,
            exp: 0,
            parentFogId: "fog35",
            conditions: {
                family: "sealochness"
            }
        },
        fog37: {
            id: 37,
            price: 250,
            exp: 0,
            parentFogId: "fog35",
            conditions: {
                family: "sealochness"
            }
        },
        fog38: {
            id: 38,
            price: 260,
            exp: 0,
            parentFogId: "fog36",
            conditions: {
                family: "sealochness"
            }
        },
        fog39: {
            id: 39,
            price: 270,
            exp: 0,
            parentFogId: "fog37",
            conditions: {
                family: "sealochness"
            }
        },
        fog40: {
            id: 40,
            price: 280,
            exp: 0,
            parentFogId: "fog38",
            conditions: {
                family: "sealochness"
            }
        },
        fog41: {
            id: 41,
            price: 300,
            exp: 100,
            parentFogId: "fog38",
            conditions: {
                family: "sealochness"
            }
        },
        fog42: {
            id: 42,
            price: 320,
            exp: 0,
            parentFogId: "fog40",
            conditions: {
                family: "sealochness"
            }
        },
        fog43: {
            id: 43,
            price: 340,
            exp: 0,
            parentFogId: "fog42",
            conditions: {
                family: "sealochness"
            }
        },
        fog44: {
            id: 44,
            price: 400,
            exp: 0,
            parentFogId: "fog38",
            conditions: {
                family: "seaturtle"
            }
        },
        fog45: {
            id: 45,
            price: 500,
            exp: 0,
            parentFogId: "fog44",
            conditions: {
                family: "seaturtle"
            }
        },
        fog46: {
            id: 46,
            price: 600,
            exp: 0,
            parentFogId: "fog44",
            conditions: {
                family: "seaturtle"
            }
        },
        fog47: {
            id: 47,
            price: 700,
            exp: 0,
            parentFogId: "fog46",
            conditions: {
                family: "seaturtle"
            }
        },
        fog48: {
            id: 48,
            price: 800,
            exp: 0,
            parentFogId: "fog44",
            conditions: {
                family: "seaturtle"
            }
        },
        fog49: {
            id: 49,
            price: 1000,
            exp: 0,
            parentFogId: "fog47",
            conditions: {
                family: "seaturtle"
            }
        },
        fog50: {
            id: 50,
            price: 1250,
            exp: 0,
            parentFogId: "fog48",
            conditions: {
                family: "seaturtle"
            }
        },
        fog51: {
            id: 51,
            price: 1500,
            exp: 0,
            parentFogId: "fog49",
            conditions: {
                family: "seaturtle"
            }
        },
        fog52: {
            id: 52,
            price: 1800,
            exp: 0,
            parentFogId: "fog46",
            conditions: {
                family: "seaturtle"
            }
        },
        fog53: {
            id: 53,
            price: 2350,
            exp: 100,
            parentFogId: "fog49",
            conditions: {
                family: "seaturtle"
            }
        },

        moneyFogUndersea1: {
            id: 54,
            price: 99,
            money: true,
            parentFogId: "fog3",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 1$
        moneyFogUndersea2: {
            id: 55,
            price: 499,
            money: true,
            parentFogId: "fog7",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }
            ]
        }, // 5$
        moneyFogUndersea3: {
            id: 56,
            price: 399,
            money: true,
            parentFogId: "fog12",
            windowSkin: "blue",
            conditions: {
                family: "seatournament"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // snail - 399hard
        moneyFogUndersea4: {
            id: 57,
            price: 1249,
            money: true,
            parentFogId: "fog9",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, // 15$
        moneyFogUndersea5: {
            id: 58,
            price: 5199,
            money: true,
            parentFogId: "fog14",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }
            ]
        }, // 45$
        moneyFogUndersea6: {
            id: 59,
            price: 3799,
            money: true,
            parentFogId: "fog20",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 45$
        moneyFogUndersea7: {
            id: 60,
            price: 5399,
            money: true,
            parentFogId: "fog21",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 2
            }]
        }, // 45$
        moneyFogUndersea8: {
            id: 61,
            price: 2899,
            money: true,
            parentFogId: "fog24",
            windowSkin: "blue",
            bubbles: [{
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 30$
        moneyFogUndersea9: {
            id: 62,
            price: 2999,
            money: true,
            parentFogId: "fog33",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        } // 30$
    };

    FogsConfig.spring = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 5,
            exp: 0,
            parentFogId: "fog0"
        }, // wood
        fog2: {
            id: 2,
            price: 5,
            exp: 0,
            parentFogId: "fog1"
        },
        fog3: {
            id: 3,
            price: 25,
            exp: 0,
            parentFogId: "fog2",
            conditions: {
                family: "rpcustomerbridge0"
            }
        },
        fog4: {
            id: 4,
            price: 25,
            exp: 0,
            parentFogId: "fog3"
        }, // stone
        fog5: {
            id: 5,
            price: 25,
            exp: 0,
            parentFogId: "fog4",
            conditions: {
                family: "rpcustomermain0"
            }
        },
        fog6: {
            id: 6,
            price: 25,
            exp: 0,
            parentFogId: "fog5",
            conditions: {
                family: "rpcustomerbridge1"
            }
        },
        fog7: {
            id: 7,
            price: 25,
            exp: 0,
            parentFogId: "fog6",
            conditions: {
                family: "drwood"
            }
        },
        fog8: {
            id: 8,
            price: 25,
            exp: 0,
            parentFogId: "fog7",
            conditions: {
                family: "drwood"
            }
        }, // sapphire
        fog9: {
            id: 9,
            price: 25,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: "drstone"
            }
        },
        fog10: {
            id: 10,
            price: 30,
            exp: 0,
            parentFogId: "fog9",
            conditions: {
                family: "drstone"
            }
        },
        fog11: {
            id: 11,
            price: 30,
            exp: 0,
            parentFogId: "fog8",
            conditions: {
                family: ["drstone", "rpcustomermain1"]
            }
        },
        fog12: {
            id: 12,
            price: 30,
            exp: 0,
            parentFogId: "fog11",
            conditions: {
                family: ["drstone", "rpcustomerbridge2"]
            }
        },
        fog13: {
            id: 13,
            price: 50,
            exp: 0,
            parentFogId: "fog12",
            conditions: {
                family: "drstone"
            }
        }, // sled
        fog14: {
            id: 14,
            price: 50,
            exp: 0,
            parentFogId: "fog13",
            conditions: {
                family: "drsapphire"
            }
        },
        fog15: {
            id: 15,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog16: {
            id: 16,
            price: 60,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog17: {
            id: 17,
            price: 70,
            exp: 0,
            parentFogId: "fog14",
            conditions: {
                family: "drsapphire"
            }
        },
        fog18: {
            id: 18,
            price: 70,
            exp: 0,
            parentFogId: "fog17",
            conditions: {
                family: ["drsapphire", "rpcustomermain2"]
            }
        },
        fog19: {
            id: 19,
            price: 80,
            exp: 0,
            parentFogId: "fog18",
            conditions: {
                family: ["drsled", "rpcustomerbridge3"]
            }
        }, // castle
        fog20: {
            id: 20,
            price: 80,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: "drsled"
            }
        },
        fog21: {
            id: 21,
            price: 90,
            exp: 0,
            parentFogId: "fog19",
            conditions: {
                family: "drsled"
            }
        },
        fog22: {
            id: 22,
            price: 90,
            exp: 0,
            parentFogId: "fog21",
            conditions: {
                family: "drsled"
            }
        },
        fog23: {
            id: 23,
            price: 100,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        fog24: {
            id: 24,
            price: 100,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        fog25: {
            id: 25,
            price: 110,
            exp: 0,
            parentFogId: "fog22",
            conditions: {
                family: "drsled"
            }
        },
        springFogCave: {
            id: 26,
            price: 0,
            exp: 10,
            conditions: {
                family: ["rpcustomermain1", "rpcustomermain2", "rpcustomermain3"]
            }
        },

        moneyFogSpring1: {
            id: 61,
            price: 99,
            money: true,
            parentFogId: "fog2",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 1$ - big sale
        moneyFogSpring2: {
            id: 62,
            price: 499,
            money: true,
            parentFogId: "fog5",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 2,
                amount: 1
            }
            ]
        }, // 5$
        moneyFogSpring3: {
            id: 63,
            price: 949,
            money: true,
            parentFogId: "fog11",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        }, // 10$ - big sale
        moneyFogSpring4: {
            id: 64,
            price: 1899,
            money: true,
            parentFogId: "fog13",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, // 20$
        moneyFogSpring5: {
            id: 65,
            price: 1499,
            money: true,
            parentFogId: "fog19",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            }
            ]
        }, // 15$ - big sale
        moneyFogSpring6: {
            id: 66,
            price: 4999,
            money: true,
            parentFogId: "fog10",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 50$ - have portal
        moneyFogSpring7: {
            id: 67,
            price: 2499,
            money: true,
            parentFogId: "fog24",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 2
            },
            {
                code: "crystal",
                stage: 3,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 2
            }]
        }, // 30$
        moneyFogSpring8: {
            id: 68,
            price: 8499,
            money: true,
            parentFogId: "fog21",
            windowSkin: "blue",
            bubbles: [{
                code: "crystal",
                stage: 3,
                amount: 1
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 85$ - have x2 portal
        moneyFogSpring9: {
            id: 69,
            price: 1699,
            money: true,
            parentFogId: "fog18",
            windowSkin: "blue",
            bubbles: [{
                code: "treasure",
                stage: 2,
                amount: 1
            },
            {
                code: "sacks",
                stage: 2,
                amount: 2
            },
            {
                code: "worker",
                stage: 4,
                amount: 1
            }]
        }, // 15$
        moneyFogSpring10: {
            id: 70,
            price: 400,
            money: true,
            parentFogId: "fog12",
            windowSkin: "blue",
            conditions: {
                family: "drtournament"
            },
            bubbles: [{
                code: "treasure",
                stage: 0,
                amount: 2
            }]
        } // 5$ - snail
    };

    FogsConfig.afterInitialize();
};

FogsConfig.afterInitialize = function () {
    FogsConfig.codes = Object.keys(FogsConfig).filter(function (code) {
        return typeof FogsConfig[code] === "object" && ["codes", "ads"].indexOf(code) === -1;
    });

    if (!cleverapps.config.debugMode) {
        return;
    }

    FogsConfig.codes.forEach(function (code) {
        var config = FogsConfig[code];

        var ids = {};
        for (var key in config) {
            var fog = config[key];

            var error;

            if (typeof fog.id !== "number") {
                error = "Fog need id for sync - " + key;
            } else if (fog.id >= Fogs.SYNCED_BITS) {
                error = "Fog id " + fog.id + " is big";
            } else if (ids[fog.id]) {
                error = "Not unique fog id - " + fog.id;
            }

            if (error) {
                throw error;
            }
            ids[fog.id] = true;
        }
    });
};

if (typeof cc === "undefined") {
    module.exports = FogsConfig;
}
