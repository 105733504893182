/**
 * Created by vtbelo on 17/03/21
 */

var PixelsSwarm = function (pixelsPlanner, options) {
    this.pixelsPlanner = pixelsPlanner;
    this.amountPixels = options.amount;
    this.type = options.type;
    this.leftPixels = 0;

    this.createPixels();
};

PixelsSwarm.prototype.onDiePixel = function () {
    this.leftPixels--;
    if (!this.leftPixels) {
        this.pixelsPlanner.startCurrentLevel();
    }
};

PixelsSwarm.prototype.createPixels = function () {
    var pixels = [];

    var types = this.type ? [this.type] : Object.keys(PixelsSwarmConfig);

    types.forEach(function (type) {
        var config = PixelsSwarmConfig[type];

        if (config.pages && config.pages.indexOf(cleverapps.meta.selectedLocationId()) === -1) {
            return;
        }

        if (type === PixelsSwarm.SEASON && (!Merge.currentMerge.season || !Season.isRunning())) {
            return;
        }

        if (config.mission) {
            var mission = cleverapps.missionManager.findByType(config.mission);
            if (!mission || !mission.isRunning()) {
                return;
            }
        }

        if (config.limit) {
            if (cleverapps.adsLimits.state(config.limit) !== AdsLimits.STATE_READY) {
                return;
            }
            cleverapps.adsLimits.watch(config.limit);
        }

        if (type === PixelsSwarm.FRIEND) {
            return;
        }

        var amount = Math.ceil(config.amount || this.amountPixels * (config.amountCoef || 1));

        for (var j = 0; j < amount; j++) {
            pixels.push(new Pixel({
                type: type,
                order: j,
                amountPixels: this.amountPixels,
                onDiePixel: this.onDiePixel.bind(this),
                collectPrize: this.pixelCollectPrize.bind(this)
            }));
        }
    }, this);

    var offer = cleverapps.offerManager.findOffer({ type: Offers.TYPES.PIXEL_OFFER });
    if (offer) {
        this.pixelsPlanner.onCreatePixelOffer(new PixelOffer(offer));
    }

    pixels = cleverapps.Random.shuffle(pixels);
    pixels.forEach(function (pixel) {
        this.pixelsPlanner.onCreatePixel(pixel);
    }, this);

    this.leftPixels = pixels.length;

    cleverapps.audio.playSound(bundles.pixels.urls.pixel_spawn_effect);
};

PixelsSwarm.prototype.pixelCollectPrize = function () {
    if (!this.pixelCollected) {
        this.pixelCollected = true;
        this.pixelsPlanner.nextLevel();
    }

    cleverapps.eventBus.trigger("taskEvent", DailyTasks.COLLECT_PIXEL);
};

PixelsSwarm.FRUIT = "fruit";
PixelsSwarm.HALLOWEEN = "halloween";
PixelsSwarm.BLACKFRIDAY = "blackfriday";
PixelsSwarm.XMAS = "xmas";
PixelsSwarm.DRAGONIA = "dragonia";
PixelsSwarm.DRAGONIA2 = "dragonia2";
PixelsSwarm.ENERGY = "energy";
PixelsSwarm.COINS = "coins";
PixelsSwarm.ADS = "ads";
PixelsSwarm.SEASON = "seasonitem";
PixelsSwarm.UNDERSEA = "undersea";
PixelsSwarm.UNDERSEA2 = "undersea2";
PixelsSwarm.RAPUNZEL = "rapunzel";
PixelsSwarm.EASTER = "easter";
PixelsSwarm.CHINA = "china";
PixelsSwarm.FRIEND = "friend";
PixelsSwarm.SPRING = "spring";

var PixelsSwarmConfig = {};

PixelsSwarmConfig[PixelsSwarm.FRUIT] = {
    pages: ["main"],
    reward: [{
        units: [{ type: "fruit", stage: 0 }]
    }]
};

PixelsSwarmConfig[PixelsSwarm.ENERGY] = {
    amountCoef: 0.5,
    mission: Mission.TYPE_LIVESFEAST,
    pages: ["main"],
    reward: {
        resource: "energy",
        amount: [2, 8]
    }
};

PixelsSwarmConfig[PixelsSwarm.COINS] = {
    amountCoef: 0.5,
    mission: Mission.TYPE_SOFTFEAST,
    pages: ["main"],
    reward: {
        resource: "coins",
        amount: [10, 20]
    }
};

PixelsSwarmConfig[PixelsSwarm.ADS] = {
    amount: 1,
    limit: AdsLimits.TYPES.PIXEL,
    pages: ["main", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3"],
    reward: [{
        units: [{ code: "telly", stage: 0 }]
    }]
};

PixelsSwarmConfig[PixelsSwarm.SEASON] = {
    amount: 1,
    reward: [{
        units: [{ currentType: "seasonitem", stage: 0 }]
    }]
};

PixelsSwarmConfig[PixelsSwarm.DRAGONIA] = {
    pages: ["dragonia"],
    reward: [{
        units: [
            { code: "drmagicplant", stage: 0 },
            { code: "drgrowing", stage: 0 },
            { code: "worker", stage: 0 }
        ]
    }]
};

PixelsSwarmConfig[PixelsSwarm.DRAGONIA2] = {
    pages: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas"],
    reward: [{
        units: [
            { code: "drmagicplant", stage: 0 },
            { code: "drgrowing", stage: 0 },
            { code: "worker", stage: 0 },
            { code: "drfruit", stage: 0 }
        ]
    }]
};

PixelsSwarmConfig[PixelsSwarm.CHINA] = {
    pages: ["china"],
    reward: [{
        units: [
            { code: "drmagicplant", stage: 0 },
            { code: "drgrowing", stage: 0 },
            { code: "worker", stage: 0 },
            { code: "drfruit", stage: 0 }
        ]
    }]
};

PixelsSwarmConfig[PixelsSwarm.UNDERSEA] = {
    pages: ["undersea"],
    reward: [{
        units: [
            { code: "seamagicplant", stage: 0 },
            { code: "seagrowing", stage: 0 },
            { code: "worker", stage: 0 }
        ]
    }]
};

PixelsSwarmConfig[PixelsSwarm.UNDERSEA2] = {
    pages: ["undersea2", "undersea3"],
    reward: [{
        units: [
            { currentType: "searesource", stage: 0 },
            { currentType: "seahero", stage: 0 },
            { code: "seamagicplant", stage: 0 },
            { code: "seastrawberry", stage: 0 }
        ]
    }]
};

PixelsSwarmConfig[PixelsSwarm.RAPUNZEL] = {
    pages: ["rapunzel", "rapunzel2", "rapunzel3"],
    reward: [{
        units: [
            { currentType: "rpresource", stage: 0 }
        ]
    }]
};

PixelsSwarmConfig[PixelsSwarm.EASTER] = {
    pages: ["easter"],
    reward: [{
        units: [
            { currentType: "earesource", stage: 0 }
        ]
    }]
};

PixelsSwarmConfig[PixelsSwarm.SPRING] = {
    pages: ["spring"],
    reward: [{
        units: [
            { code: "drmagicplant", stage: 0 },
            { code: "drgrowing", stage: 0 },
            { code: "worker", stage: 0 },
            { code: "drfruit", stage: 0 }
        ]
    }]
};

if (cleverapps.config.debugMode) {
    PixelsSwarmConfig[PixelsSwarm.FRIEND] = {
        pages: ["main"],
        reward: [{
            units: [
                { type: "energycup", stage: 0 }
            ]
        }],
        maxAmount: 5
    };
}
