/**
 * Created by Reda on 24.01.2025
 */

QuestsConfig.spring = cleverapps.clone(QuestsConfig.universal, true);

delete QuestsConfig.spring.rpcustomerbridge4_0;
delete QuestsConfig.spring.rpcustomerbridge4_1;
delete QuestsConfig.spring.rpcustomermain4_0;
delete QuestsConfig.spring.rpcustomermain4_1;
delete QuestsConfig.spring.rpproducer4;
delete QuestsConfig.spring.rpproduct4;

CustomerRecipes.spring = cleverapps.clone(CustomerRecipes.universal, true);

Ruins.TIERS.spring = cleverapps.clone(Ruins.TIERS.universal, true);

GrowingsPlanner.CONFIG.spring = cleverapps.clone(GrowingsPlanner.CONFIG.universal, true);
GrowingsPlanner.CONFIG.spring.betweenTimeouts = ["24 hours"];
GrowingsPlanner.CONFIG.spring.maxAmount = 1;

MissionTree.PRIZES.spring = [];

Landmarks.WORLDS.spring = {
    fog: "fog3",
    force: Forces.LANDMARK_DONOR_SLOT1,
    landmarks: [{
        code: "landmark",
        stage: 23,
        targetExpedition: "main",
        donors: ["rpcustomermain0", "rpcustomermain1", "rpcustomermain2", "rpcustomermain3"]
    }]
};

cleverapps.styles.spring = {
    fogAsColorForUnits: {
        color: new cc.Color(0, 150, 255, 255),
        opacity: 120
    },
    fogBlockerLock: {
        width: 100,
        height: 100,
        margin: 25,

        content: {
            width: 68,
            height: 68
        },
        position: {
            x: { align: "center" },
            y: { align: "center", dy: 50 }
        },
        text: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        icon: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        cloud: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -39 }
        },
        lock: {
            x: { align: "center" },
            y: { align: "center" }
        }
    }
};