/**
 * Created by razial on 26.10.2021
 */

var PocketBubbleView = cc.Node.extend({
    ctor: function (bubble, visibleRect) {
        this._super();

        var styles = cleverapps.styles.PocketBubbleView;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);

        var animation = this.animation = new cleverapps.Spine(bundles.merge.jsons.recipe_bubble_json);
        animation.setPositionRound(styles.bubble);
        animation.setLocalZOrder(3);
        animation.setAnimation(0, "idle", true);
        this.addChild(animation);

        if (bubble instanceof PocketBubble) {
            this.bubble = bubble;
            if (bubble.x || bubble.y) {
                this.setPositionRound(bubble.x, bubble.y);
            } else {
                this.setPositionRound(visibleRect.x + visibleRect.width / 2, visibleRect.y + visibleRect.height / 2);
                bubble.setPosition(this.x, this.y);
            }

            this.clickHandler = cleverapps.UI.onDrag(this, {
                onClick: this.bubble.spawn.bind(this.bubble, this),
                followPointer: true,
                onDragStart: this.onDragStart.bind(this),
                onDragEnd: this.onDragEnd.bind(this)
            });

            this.bubble.onGetView = this.createListener(function () {
                return this;
            }.bind(this));
            this.bubble.onChanged = this.createListener(this.updateUnitView.bind(this));
            this.bubble.onRemoved = this.createListener(this.removeBubble.bind(this));
            this.bubble.onShowFinger = this.createListener(this.showFinger.bind(this));

            Merge.currentMerge.pushingComponent.add(this, "bubbles");

            Merge.currentMerge.on("stop", this.onGameStop.bind(this), this);
        } else {
            this.bubble = new PocketBubble(bubble);
        }

        this.updateUnitView();
    },

    onGameStop: function () {
        if (this.clickHandler) {
            this.clickHandler.setEnabled(false);
        }

        if (this.dragging) {
            this.onDragEnd();
        }
    },

    onDragStart: function () {
        this.dragging = true;
        this.setLocalZOrder(1);
        this.pausePushing();
        return true;
    },

    onDragEnd: function () {
        this.dragging = false;
        this.setLocalZOrder(0);
        this.resumePushing();
        this.bubble.setPosition(this.x, this.y);
    },

    updateUnitView: function () {
        if (this.unitView) {
            this.unitView.removeFromParent();
        }

        var styles = cleverapps.styles.PocketBubbleView.unit;
        var unitView = this.unitView = UnitView.getUnitImage(this.bubble, { alignAnchorX: true, preferStatic: true });
        cleverapps.UI.fitToBox(unitView, {
            width: this.width * 0.7,
            height: this.height * 0.7
        });
        unitView.setLocalZOrder(2);
        unitView.setAnchorPoint(0.5, 0.5);

        unitView.setPositionRound(styles);
        this.addChild(unitView);
    },

    removeBubble: function () {
        if (this.clickHandler) {
            this.clickHandler.remove();
            this.clickHandler = undefined;
        }

        this.unitView.removeFromParent();
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                this.animation.setAnimation(0, "disappearance", false);
                this.animation.setCompleteListener(this.removeFromParent.bind(this));
            }.bind(this))
        ));
    },

    showFinger: function () {
        this.pausePushing();

        FingerView.hintClick(this, {
            callback: this.resumePushing.bind(this)
        });
    }
});

cleverapps.styles.PocketBubbleView = {
    width: 80,
    height: 80,

    bubble: {
        x: { align: "center" },
        y: { align: "center" }
    },

    unit: {
        x: { align: "center" },
        y: { align: "center" }
    }
};
