/**
 * Created by mac on 12/22/20
 */

var Fruit = function (unit, options) {
    UnitComponent.call(this, unit);

    this.sinceLastHarvest = options.sinceLastHarvest || 0;

    var data = unit.getData();
    this.exp = data.fruit.exp || Fruit.HARVESTED_EXP;
    this.harvestDuration = this.calcHarvestDuration();
    this.localPush = data.fruit.localPush;

    this.prize = data.fruit.prize;
    this.energy = data.fruit.energy;

    // migration
    if (data.magicplant && options.lastHarvested) {
        this.sinceLastHarvest = options.lastHarvested;
    }

    if (this.sinceLastHarvest > Date.now()) {
        this.sinceLastHarvest = Date.now();
        this.unit.wantsSave = true;
    }

    if (cleverapps.gameModes.initFruitWithoutPrizes) {
        this.sinceLastHarvest = Date.now();
    }

    if (unit.isBuilt() && Breakable.IsIntact(unit)) {
        var timeToReady = Merge.currentMerge.tutorial.isActive() && ["drfruit", "seafruit", "clfruit"].indexOf(unit.getType()) === -1
            ? Customer.COOLDOWN_DELAY.ORDERS_WINDOW.TUTORIAL
            : this.timeToReady();
        this.timeout = new cleverapps.LongTimeout(this.makeReady.bind(this), timeToReady);
    }
};

Fruit.prototype = Object.create(UnitComponent.prototype);
Fruit.prototype.constructor = Fruit;

Fruit.prototype.onPrizeHarvested = function () {
    this.restartGrowth();
};

Fruit.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

Fruit.prototype.calcHarvestDuration = function () {
    return cleverapps.parseInterval(this.unit.getData().fruit.duration);
};

Fruit.prototype.isGrowing = function () {
    return this.timeToReady() > 0;
};

Fruit.prototype.save = function (data) {
    data.sinceLastHarvest = this.sinceLastHarvest;
};

Fruit.prototype.destructor = function () {
    clearTimeout(this.timeout);
};

Fruit.prototype.buildingComplete = function () {
    var delay = 2000;
    if (cleverapps.gameModes.skipBuildingStage) {
        delay = 1000;
    }

    this.finishBuildAnimation = true;
    Merge.currentMerge.counter.setTimeout(function () {
        this.finishBuildAnimation = undefined;
        this.makeReady();
    }.bind(this), delay);
};

Fruit.prototype.getActionInfo = function () {
    if (this.finishBuildAnimation) {
        return;
    }

    var info = {
        title: this.unit,
        countdown: this.timeToReady()
    };

    if (this.energy) {
        info.buttons = {
            energy: {
                action: function () {
                    if (Merge.currentMerge.useEnergy(this.energy, this.unit)) {
                        this.makeReady();
                        return true;
                    }
                }.bind(this),
                energy: this.energy
            }
        };
    } else {
        info.buttons = {
            speedup: {
                unit: this.unit,
                canCoins: ["fruit", "seafruit"].indexOf(this.unit.getType()) !== -1,
                action: this.makeReady.bind(this),
                duration: this.timeToReady()
            }
        };
    }

    if (["fruit", "seafruit"].indexOf(this.unit.getType()) !== -1) {
        info.title = {
            code: this.unit.code,
            stage: 4,
            icon: true
        };
    }

    var breakable = this.unit.findComponent(Breakable);
    if (breakable) {
        info.progress = {
            value: breakable.value,
            total: breakable.getLimit()
        };
    }

    return info;
};

Fruit.prototype.timeToReady = function () {
    return Math.max(0, this.sinceLastHarvest + this.harvestDuration - Date.now());
};

Fruit.prototype.listPrize = function () {
    this.unit.seedRandom(this.sinceLastHarvest);

    var breakable = this.unit.findComponent(Breakable);
    var prizeData = breakable && breakable.getPrizeData() || this.prize;

    var upgradable = this.unit.findComponent(Upgradable);
    if (upgradable) {
        var amount = prizeData[0].amount;
        if (Array.isArray(amount)) {
            amount = cleverapps.Random.random(amount[0], amount[1]);
        }
        prizeData = [{
            amount: Math.round(amount * Math.sqrt(upgradable.level)),
            units: prizeData[0].units
        }];

        if (cleverapps.config.wysiwygMode) {
            prizeData[0].amount *= 3;
            prizeData[0].units = prizeData[0].units.concat([
                { code: "treasure", stage: 2, amount: 5 },
                { code: "crystal", stage: 3, amount: 5 },
                { code: "worker", stage: 4, amount: 5 },
                { code: "coins", stage: 5, amount: 3 },
                { code: "coinstree", stage: 5, amount: 1 },
                { code: "coins", stage: 7, amount: 3 },
                { code: "rubies", stage: 5, amount: 3 },
                { code: "rubies", stage: 7, amount: 5 }
            ]);
        }
    }

    var prizes = Prizes.Generate(prizeData);

    for (var i = 0; i < prizes.length; ++i) {
        var prize = prizes[i];

        if (prize.resource || prize.ingredient) {
            return prize;
        }

        var level = Merge.currentMerge && Merge.currentMerge.level;
        if (level && !level.families[prize.code]) {
            cleverapps.throwAsync("Unit from other world in prizes : " + JSON.stringify({
                prize: Unit.GetKey(prize),
                unit: Unit.GetKey(this.unit),
                position: Unit.GetPositionKey(this.unit),
                unitHasView: Boolean(this.unit.onGetView()),
                mapHasUnit: Map2d.currentMap.getUnit(this.unit.x, this.unit.y) === this.unit
            }));
        }
    }

    return prizes;
};

Fruit.prototype.restartGrowth = function () {
    this.harvestDuration = this.calcHarvestDuration();

    this.sinceLastHarvest = Date.now();
    this.unit.save();

    clearTimeout(this.timeout);
    var reportLeak = cleverapps.once(function () {
        cleverapps.throwAsync("Fruit countdown tick on empty Merge.currentMerge");
    });
    this.timeout = new cleverapps.CountDown(this.timeToReady(), {
        onFinish: this.makeReady.bind(this),
        onTick: function () {
            if (!Merge.currentMerge) {
                reportLeak();
            }
        }
    });

    this.onUpdateState();

    if (this.localPush) {
        Merge.currentMerge.pushes.updateFruits(this.localPush);
    }
};

Fruit.prototype.makeReady = function () {
    if (this.isGrowing()) {
        this.sinceLastHarvest = Date.now() - this.harvestDuration;
    }

    clearTimeout(this.timeout);

    this.unit.setPrizes(this.listPrize(), this.exp);
    this.onUpdateState();

    if (this.localPush) {
        Merge.currentMerge.pushes.updateFruits(this.localPush);
    }
};

Fruit.HARVESTED_EXP = 3;
