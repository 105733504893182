/**
 * Created by vtbelo on 05.05.2021
 */

var UnitOnCell = cc.Node.extend({
    ctor: function (unit, options) {
        options = options || {};
        var styles = cleverapps.skins.getSlot("unitOnCell") || cleverapps.styles.UnitOnCell;
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles.width, styles.height);

        var ground = new cc.Sprite(bundles.merge.frames.stand_ground);
        ground.setAnchorPoint(0.5, 0.5);
        ground.setPositionRound(this.width / 2 + styles.ground.x, this.height / 2 + styles.ground.y);
        this.addChild(ground);

        var icon = this.icon = UnitView.getUnitImage(unit, Object.assign({
            alignAnchorX: true,
            alignAnchorY: true
        }, options.unitImgOptions));

        cleverapps.UI.fitToBox(icon, {
            width: styles.width,
            height: styles.height
        });
        icon.setPositionRound(this.width / 2 + styles.icon.x, this.height / 2 + styles.icon.y);
        icon.setLocalZOrder(2);
        this.addChild(icon);

        if (options.shining) {
            var rays = new cleverapps.Spine(bundles.merge.jsons.prize_shining_json);
            rays.setPositionRound(this.width / 2 + styles.icon.x, styles.height / 2);
            rays.setLocalZOrder(1);
            rays.setAnimationAndIdleAfter("open", "idle");
            this.addChild(rays);
        }

        if (options.stripe) {
            this.icon.runAction(
                new cc.RepeatForever(
                    new cc.Sequence(
                        new cc.DelayTime(2.5),
                        AnimationsLibrary.shiningLine(this.icon)
                    )
                )
            );
        }

        if (options.energy) {
            var energyIcon = new cc.Sprite(bundles.merge.frames["energy_star_" + Chest.classifyEnergy(options.energy)]);
            energyIcon.setPositionRound(styles.energyIcon);
            this.icon.addChild(energyIcon);

            var chestTimeMission = cleverapps.missionManager.findByType(Mission.TYPE_CHESTTIME);
            if (chestTimeMission && MissionManager.hasProperParent(chestTimeMission.type)) {
                cleverapps.UI.onClick(energyIcon, function () {
                    new ChestTimeMissionWindow(chestTimeMission);
                });
            }
        }

        if (options.points) {
            var salePassMission = cleverapps.missionManager.findByType(Mission.TYPE_SALEPASS);
            var isSalePass = salePassMission && MissionManager.hasProperParent(salePassMission.type);
            var pointIcon = new cleverapps.Spine(isSalePass ? bundles.merge.jsons.salepass_point : bundles.merge.jsons.buildpass_point);
            pointIcon.setAnchorPoint(0.5, 0.5);
            pointIcon.setPositionRound(styles.pointsIcon);
            pointIcon.setAnimation(0, isSalePass ? PassMissionLogic.classifyAmount(options.points) + "_idle" : "idle", true);
            this.addChild(pointIcon, 2);
        }

        if (!options.noWrap) {
            cleverapps.UI.wrap(this);
        }
    },

    pulsate: function () {
        var initialScale = this.icon.scale;

        this.icon.runAction(
            new cc.RepeatForever(
                new cc.Sequence(
                    new cc.DelayTime(0.2),

                    new cc.ScaleTo(0.3, initialScale * 1.05).easing(cc.easeIn(2)),
                    new cc.ScaleTo(0.3, initialScale).easing(cc.easeOut(2)),

                    new cc.ScaleTo(0.5, initialScale * 1.1).easing(cc.easeIn(2)),
                    new cc.ScaleTo(0.5, initialScale).easing(cc.easeOut(2))
                )
            )
        );
    }
});

cleverapps.styles.UnitOnCell = {
    width: 169,
    height: 131,
    icon: {
        x: 0,
        y: -22
    },
    shine: {
        x: 0,
        y: -9,
        scale: 0.75
    },
    ground: {
        x: 3,
        y: -24
    },
    energyIcon: {
        x: { align: "center", dx: 15 },
        y: { align: "center", dy: 20 }
    },
    pointsIcon: {
        x: { align: "center" },
        y: { align: "top", dy: 40 }
    }
};
