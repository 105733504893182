/**
 * Created by andrey on 10.02.2021.
 */

var Quest = function (data, isNew) {
    cleverapps.EventEmitter.call(this);

    var pageId = cleverapps.meta.selectedLocationId();
    this.config = QuestsConfig[pageId][data.id].quest;

    this.triggerUnit = QuestsConfig[pageId][data.id].trigger && QuestsConfig[pageId][data.id].trigger.unit;
    this.highlightTargets = QuestsConfig[pageId][data.id].highlightTargets;
    this.campaign = QuestsConfig[pageId][data.id].campaign;

    this.id = data.id;
    this.type = this.config.type;
    this.progress = data.progress || 0;
    this.fresh = data.fresh;

    this.recipe = data.recipe;

    this.dynamic = QuestsConfig[pageId][data.id].dynamic || {};

    if (!data.unit && this.dynamic.getUnit && this.dynamic.getUnit()) {
        this.unit = this.dynamic.getUnit();
    } else {
        this.unit = data.unit || this.config.unit;
    }

    if (Array.isArray(data.goal)) {
        this.goal = cleverapps.Random.random(data.goal[0], data.goal[1]);
    } else {
        this.goal = data.goal || data.amount || 1;
    }

    if (isNew) {
        this.goal = this.dynamic.getGoal ? this.dynamic.getGoal() : this.goal;
    }
};

Quest.prototype = Object.create(cleverapps.EventEmitter.prototype);
Quest.prototype.constructor = Quest;

Quest.prototype.addProgress = function (amount) {
    this.progress += (amount || 1);

    if (this.progress > this.goal) {
        this.progress = this.goal;
    }

    if (this.isFinished()) {
        Merge.currentMerge.counter.setTimeout(function () {
        }, 1000);
    }

    this.trigger("updateProgress", this);
    Merge.currentMerge.quests.save();
};

Quest.prototype.onClick = function () {
    Merge.currentMerge.quests.select(this);
};

Quest.prototype.setFresh = function (fresh) {
    this.fresh = fresh;
};

Quest.prototype.isFresh = function () {
    return this.fresh;
};

Quest.prototype.select = function () {
    if (!this.selected) {
        this.selected = true;
        this.trigger("select", this);
    }
};

Quest.prototype.unselect = function () {
    if (this.selected || this.selected === undefined) {
        this.selected = false;
        this.trigger("unselect", this);
    }
};

Quest.prototype.find = function () {
    if (this.campaign) {
        return UnitLocator.find(this.type, {
            code: this.unit.code,
            stage: this.unit.stage - 1
        }, {
            useStageForCustomerSearch: true,
            allowScrollWithFocus: true
        });
    }

    var findOptions = Object.assign({}, this.config, {
        allowScrollWithFocus: true
    });

    if (this.unit && this.unit.code && Families[this.unit.code].locators) {
        findOptions.getFindTargets = function () {
            return Families[this.unit.code].locators;
        }.bind(this);
    }

    return UnitLocator.find(this.type, this.unit, findOptions);
};

Quest.prototype.dynamicFinished = function () {
    if (this.isFresh()) {
        return false;
    }

    if (this.dynamic.finished && this.dynamic.finished()) {
        return true;
    }

    switch (this.type) {
        case Map2d.OPEN_UNIT:
            return cleverapps.unitsLibrary.isOpened(this.unit);
        default:
            return false;
    }
};

Quest.prototype.isFinished = function () {
    return this.progress >= this.goal;
};

Quest.prototype.getTitle = function () {
    if (this.dynamic.getTitle) {
        return this.dynamic.getTitle(this.unit);
    }

    var toReplace = {};

    if (this.unit) {
        toReplace.name = cleverapps.unitsLibrary.getUnitName(this.unit);

        if (this.unit.code === "wands") {
            return Messages.get("Quest.wands.title");
        }

        var family = Families[this.unit.code];
        if (family && ["hero", "rphero"].includes(family.type) && this.unit.stage === family.units.length - 1) {
            return Messages.get("Quest.hero.title");
        }
    }

    if (Messages.has("Quest." + this.id + ".title")) {
        return Messages.get("Quest." + this.id + ".title", toReplace);
    }

    return Messages.get("Quest." + this.type + ".title", toReplace);
};

Quest.prototype.save = function () {
    var info = {
        id: this.id,
        goal: this.goal,
        progress: this.progress
    };

    if (this.fresh) {
        info.fresh = 1;
    }
    if (this.unit && this.unit !== this.config.unit) {
        info.unit = this.unit;
    }
    if (this.recipe) {
        info.recipe = this.recipe;
    }

    return info;
};

Quest.prototype.getDialogue = function () {
    return QuestsConfig[cleverapps.meta.selectedLocationId()][this.id].dialogue;
};

Quest.prototype.getTutorial = function (onFinish) {
    var questConfig = QuestsConfig[cleverapps.meta.selectedLocationId()][this.id];
    if (!questConfig) {
        return;
    }

    var tutor = onFinish ? questConfig.finishTutorial : questConfig.tutorial;
    if (tutor && MergeTutorials[tutor]) {
        tutor = MergeTutorials[tutor];
        if (!tutor.filter || tutor.filter()) {
            return tutor;
        }
    }
};

Quest.prototype.showTutorial = function (f, onFinish) {
    var tutorial = this.getTutorial(onFinish);

    if (!tutorial) {
        f();
        return;
    }

    Merge.currentMerge.tutorial.showTutorial(tutorial, f);
};

Quest.prototype.replayTutorial = function () {
    if (this.isFinished()) {
        return;
    }

    var tutorial = this.getTutorial();
    if (tutorial && tutorial.replayOnReload) {
        tutorial.replayed = true;
        Merge.currentMerge.tutorial.triggerTutorial(tutorial);
    }
};

Quest.prototype.isApplicable = function (type, options) {
    options = options || {};

    if (this.config.type === Map2d.BUILD && type === Map2d.SPAWN && (options.debug
        || (options.affected && options.affected.code === "thirdelement"))) {
        return Unit.IsApplicable(this.unit, options.unit);
    }

    if (this.config.type !== type) {
        return false;
    }

    var eventUnit = options.unit || options.affected;
    if (Array.isArray(eventUnit)) {
        eventUnit = eventUnit[0];
    }

    switch (type) {
        case Map2d.MERGE:
            return (!this.config.minUnits || options.affected.length >= this.config.minUnits)
                && (!this.config.unit || options.affected.some(Unit.Equals.bind(Unit, this.config.unit)));
        case Map2d.SPAWN:
        case Map2d.OPEN_UNIT:
        case Map2d.MINE:
        case Map2d.COMPLETE_MINING:
        case Map2d.BUILD:
        case Map2d.HARVEST:
        case Map2d.PRIZE_HARVESTED:
            return Unit.IsApplicable(this.unit, eventUnit);
        case Map2d.BUY_FREE_UNIT:
            return options.tab === this.config.tab;
        case Map2d.OPENFOG:
            return !this.config.target || options.fog === this.config.target;
        case Map2d.RECIPE_READY:
            return options.recipe === this.recipe;
    }

    return true;
};

Quest.prototype.getIcon = function () {
    var icon;
    if (this.dynamic.getIcon) {
        icon = this.dynamic.getIcon(this.unit);
        if (icon) {
            return icon;
        }
    }

    icon = bundles.quest_icons.frames[cleverapps.skins.getSlot("skinName") + "_" + this.id];
    if (icon) {
        return new cc.Sprite(icon);
    }

    if (this.campaign) {
        icon = Campaign.getCampaignQuestIcon(this.unit);
        if (icon) {
            return icon;
        }
    }

    switch (this.type) {
        case Map2d.SPAWN: case Map2d.OPEN_UNIT: case Map2d.BUILD: case Map2d.MINE: case Map2d.HARVEST:
        case Map2d.PRIZE_HARVESTED: case Map2d.COMPLETE_MINING: case Map2d.BUY_FREE_UNIT:
            return UnitView.getUnitImage(this.unit, { alignAnchorX: true, preferStatic: true });

        case Map2d.OPENFOG:
            return new cc.Sprite(bundles.unit_icons.frames.fog_icon_png);
    }
};

Quest.GetId = function (quest) {
    return quest.id;
};
