/**
 * Created by vladislav on 26/10/2022
 */

var CustomerIngredientView = cc.Node.extend({
    ctor: function (ingredient, type) {
        this._super();
        this.type = type || CustomerIngredientView.TYPE_EXCHANGE;
        this.ingredient = ingredient;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(this.getStyles());

        this.createIngredient();
        this.createCheckMark();

        if (this.type !== CustomerIngredientView.TYPE_INFOVIEW) {
            this.createHelp();
        }

        if (this.type !== CustomerIngredientView.TYPE_INFOVIEW && cleverapps.config.debugMode) {
            cleverapps.UI.onClick(this, function () {
                Map2d.currentMap.customers.spawnIngredients([this.ingredient], true);
            }.bind(this));
        }

        this.onAmountChanged();
    },

    getStyles: function () {
        switch (this.type) {
            case CustomerIngredientView.TYPE_EXCHANGE: return cleverapps.styles.CustomerIngredientViewExchange;
            case CustomerIngredientView.TYPE_INFOVIEW: return cleverapps.styles.CustomerIngredientViewInfoView;
            case CustomerIngredientView.TYPE_UPGRADE: return cleverapps.styles.CustomerIngredientViewUpgrade;
        }
    },

    createIngredient: function () {
        var styles = this.getStyles();

        if (this.ingredient.ingredient) {
            this.icon = Merge.currentMerge.harvested.getIngredientSmallIcon(this.ingredient.ingredient);
        } else {
            this.icon = UnitView.getUnitImage(this.ingredient.unit, { preferStatic: true, useLibraryAnchors: true });
        }

        if (this.type === CustomerIngredientView.TYPE_EXCHANGE) {
            var bg = new cc.Scale9Sprite(bundles.customer_window.frames.customer_ingredient_bg);
            bg.setContentSize2(this.width, this.height - styles.bgPaddingY);
            bg.setAnchorPoint(0.5, 1);
            bg.setPositionRound(this.width / 2, this.height);
            this.addChild(bg);

            cleverapps.UI.fitToBox(this.icon, { width: bg.width, height: bg.height });
            bg.addChild(this.icon);
        } else if (this.type === CustomerIngredientView.TYPE_INFOVIEW) {
            var readyBg = this.readyBg = new cc.Scale9Sprite(bundles.merge.frames.bg_collected);
            readyBg.setContentSize2(styles.bg);
            readyBg.setPositionRound(styles.bg);
            this.addChild(readyBg);

            cleverapps.UI.fitToBox(this.icon, { width: styles.bg.width, height: 1.2 * styles.bg.height, maxScale: 1.2 });
            this.addChild(this.icon);
        } else {
            cleverapps.UI.fitToBox(this.icon, { width: this.width, height: this.height - styles.bgPaddingY });
            this.addChild(this.icon);
        }
        this.icon.setPosition(this.width / 2, this.height / 2 + styles.icon.offsetY);
    },

    createCheckMark: function () {
        var img = this.type === CustomerIngredientView.TYPE_INFOVIEW ? bundles.merge.frames.customer_check_mark : bundles.customer_window.frames.customer_mark;
        this.checkMark = new cc.Sprite(img);
        this.addChild(this.checkMark);
        this.checkMark.setPositionRound(this.getStyles().checkMark);
        this.checkMarkShown = false;
    },

    createHelp: function () {
        var helpParams = this.getHelpParams();
        if (helpParams) {
            helpParams.allowScrollWithFocus = true;
            this.help = cleverapps.UI.createScale9Sprite(bundles.customer_window.frames.customer_magnifier);
            cleverapps.UI.applyHover(this.help);
            cleverapps.UI.onClick(this.help, function () {
                Map2dScroller.currentScroller.cancelZoomOut();
                cleverapps.windows.currentWindow().close();

                if (helpParams.ingredient) {
                    UnitLocator.findPrize(helpParams);
                } else if (helpParams.resource && !helpParams.getFindTargets) {
                    UnitLocator.find(Map2d.MERGE, { code: helpParams.unit.code }, helpParams);
                } else {
                    UnitLocator.find(Map2d.SPAWN, helpParams.unit, helpParams);
                }
            });
            this.addChild(this.help);
            this.help.setPositionRound(this.getStyles().help);
        }
    },

    onAmountChanged: function () {
        if (this.progress) {
            this.progress.removeFromParent();
            delete this.progress;
        }

        var isReady = Map2d.currentMap.customers.getLackingAmount(this.ingredient) === 0;

        if (!isReady || this.type !== CustomerIngredientView.TYPE_INFOVIEW) {
            this.progress = this.createProgress();
            this.progress.setCascadeOpacityEnabledRecursively(true);
            this.addChild(this.progress);

            this.progress.setPositionRound(this.getStyles().progress);
            cleverapps.UI.fitToBox(this.progress, {
                width: this.width * 0.9
            });
        }

        var justGotReady = false;
        this.readyBg && this.readyBg.setVisible(isReady);
        this.checkMark.setVisible(isReady);
        if (isReady) {
            if (!this.checkMarkShown) {
                this.checkMarkShown = true;
                justGotReady = true;

                this.checkMark.runAction(new cc.Sequence(
                    new cc.FadeOut(0),
                    new cc.ScaleTo(0, 2.65),
                    new cc.Spawn(
                        new cc.FadeIn(0.13),
                        new cc.ScaleTo(0.27, 1).easing(cc.easeInOut(1))
                    ),
                    new cc.ScaleTo(0.17, 1.2, 1.35).easing(cc.easeInOut(1)),
                    new cc.ScaleTo(0.13, 1).easing(cc.easeInOut(1))
                ));

                if (this.readyBg) {
                    this.readyBg.setScale(0);
                    this.icon.baseScale = this.icon.getScale();

                    [this.readyBg, this.icon].forEach(function (node) {
                        var nodeScale = node.baseScale || 1;
                        node.runAction(new cc.Sequence(
                            new cc.DelayTime(0.23),
                            new cc.ScaleTo(0.2, 1.37 * nodeScale).easing(cc.easeInOut(1)),
                            new cc.ScaleTo(0.17, nodeScale).easing(cc.easeInOut(1))
                        ));
                    });
                }
            }
        } else {
            this.checkMarkShown = false;
        }
        if (this.help) {
            this.help.setVisible(!isReady);
        }

        this.updateEnabled();

        return justGotReady;
    },

    updateEnabled: function () {
        if (this.type === CustomerIngredientView.TYPE_INFOVIEW) {
            return;
        }

        var enabled = Map2d.currentMap.customers.getLackingAmount(this.ingredient) === 0;

        [this.icon, this.progress].filter(Boolean).forEach(function (node) {
            if (enabled) {
                node.setOpacity(255);
                node.setColor(new cc.Color(255, 255, 255, 255));
            } else {
                node.setOpacity(160);
                node.setColor(new cc.Color(180, 180, 180, 255));
            }
        });
    },

    createProgress: function () {
        var lacking = Map2d.currentMap.customers.getLackingAmount(this.ingredient);
        if (this.type === CustomerIngredientView.TYPE_INFOVIEW) {
            return cleverapps.UI.generateImageText(lacking, cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_INFOVIEW);
        }

        var currentAmount = cleverapps.UI.generateImageText(Map2d.currentMap.customers.getStockAmount(this.ingredient), lacking === 0
            ? cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_READY_TEXT : cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_NOT_READY_TEXT);
        var font = this.type === CustomerIngredientView.TYPE_EXCHANGE ? cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_EXCHANGE_TEXT : cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_TEXT;
        var slash = cleverapps.UI.generateImageText("/", font);
        var requiredAmount = cleverapps.UI.generateImageText(this.ingredient.amount, font);

        return new cleverapps.Layout([currentAmount, slash, requiredAmount], {
            margin: this.getStyles().progress.margin,
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    showUp: function () {
        this.setScale(0.3);
        var markToShow = Map2d.currentMap.customers.getLackingAmount(this.ingredient) === 0 ? this.checkMark : this.help;
        if (markToShow) {
            markToShow.setScale(2);
            markToShow.setVisible(false);
        }

        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(0.3),
            new cc.Show(),
            new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut()),
            new cc.CallFunc(function () {
                if (markToShow) {
                    markToShow.runAction(new cc.Sequence(
                        new cc.DelayTime(0.7),
                        new cc.Show(),
                        new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut())
                    ));
                }
            })
        ));
    },

    getHelpParams: function () {
        if (this.ingredient.unit) {
            var helpParams = {
                unit: this.ingredient.unit
            };

            if (Families[this.ingredient.unit.code].type === "campaignorder") {
                helpParams.useStageForCustomerSearch = true;
            }

            if (Families[this.ingredient.unit.code].locators) {
                helpParams.getFindTargets = function () {
                    return Families[this.ingredient.unit.code].locators;
                }.bind(this);
            }

            if (Families[this.ingredient.unit.code].type.indexOf("resource") !== -1) {
                helpParams.resource = true;
            }

            return helpParams;
        }

        if (this.ingredient.ingredient) {
            return {
                ingredient: this.ingredient.ingredient
            };
        }
    }
});

CustomerIngredientView.TYPE_INFOVIEW = 1;
CustomerIngredientView.TYPE_EXCHANGE = 2;
CustomerIngredientView.TYPE_UPGRADE = 3;

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CUSTOMER_INGREDIENT_TEXT: {
        size: 42,
        name: "default",
        color: cleverapps.styles.COLORS.WHITE
    },

    CUSTOMER_INGREDIENT_EXCHANGE_TEXT: {
        size: 42,
        name: "nostroke",
        color: cleverapps.styles.COLORS.CUSTOMER_INGREDIENT_EXCHANGE_COLOR
    },

    CUSTOMER_INGREDIENT_NOT_READY_TEXT: {
        size: 42,
        name: "default",
        color: new cc.Color(249, 79, 75, 255)
    },

    CUSTOMER_INGREDIENT_READY_TEXT: {
        size: 42,
        name: "default",
        color: new cc.Color(48, 217, 18, 255)
    },

    CUSTOMER_INGREDIENT_INFOVIEW: {
        size: 41,
        name: "lightstroke"
    }
});

cleverapps.styles.CustomerIngredientViewExchange = {
    width: 150,
    height: 214,
    bgPaddingY: 30,

    icon: {
        offsetY: 20
    },

    progress: {
        margin: -5,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 38 }
    },

    help: {
        x: { align: "right", dx: 15 },
        y: { align: "top", dy: 10 }
    },

    checkMark: {
        x: { align: "right", dx: 15 },
        y: { align: "top", dy: 10 }
    }
};

cleverapps.styles.CustomerIngredientViewInfoView = {
    width: 80,
    height: 80,
    bg: {
        width: 100,
        height: 80,
        x: { align: "center", dx: -2 },
        y: { align: "center", dy: -1 }
    },

    icon: {
        offsetY: 6
    },

    progress: {
        margin: -4,

        x: { align: "right", dx: -6 },
        y: { align: "bottom", dy: -4 }
    },

    checkMark: {
        x: { align: "right", dx: 15 },
        y: { align: "bottom", dy: 4 }
    }
};

cleverapps.styles.CustomerIngredientViewUpgrade = {
    width: 150,
    height: 200,
    bgPaddingY: 0,

    icon: {
        offsetY: 20
    },

    progress: {
        margin: -5,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 18 }
    },

    help: {
        x: { align: "right", dx: 15 },
        y: { align: "top", dy: 20 }
    },

    checkMark: {
        x: { align: "right", dx: 24 },
        y: { align: "top", dy: 30 }
    }
};