/**
 * Created by razial on 26.10.2021
 */

var Pocket = function (slot, options) {
    options = options || {};

    this.slot = slot || Meta.SLOT_MAIN;
    this.bubbles = [];

    this.onAddBubble = function () {};

    if (options.isNewGame) {
        this.save();
    } else {
        this.load();
    }
};

Pocket.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.POCKET + this.slot, {
        bubbles: this.bubbles.map(function (bubble) {
            return {
                code: bubble.code,
                stage: bubble.stage,
                x: bubble.x,
                y: bubble.y
            };
        })
    });

    cleverapps.synchronizer.addUpdateTask("pocket" + this.slot);
};

Pocket.prototype.load = function () {
    var bubbles = [];
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.POCKET + this.slot);

    // migrate
    if (data && data.units) {
        var units = Pocket.expandUnits(data.units);
        units.forEach(function (unit) {
            bubbles.push(unit);
        });

        var positions = cleverapps.dataLoader.load(DataLoader.TYPES.BUBBLES + this.slot);
        if (positions && positions.bubbles) {
            positions.bubbles.forEach(function (position) {
                for (var i = 0; i < bubbles.length; ++i) {
                    var bubble = bubbles[i];
                    if (bubble.code === position.code && bubble.stage === position.stage && bubble.x === undefined && bubble.y === undefined) {
                        bubble.x = position.x;
                        bubble.y = position.y;
                        break;
                    }
                }
            });
        }
    }

    if (data && data.bubbles) {
        bubbles = data.bubbles;
    }

    this.bubbles = bubbles.map(function (bubble) {
        return new PocketBubble(bubble);
    });
};

Pocket.prototype.validate = function (level) {
    var validBubbles = [];
    var invalidBubbles = [];

    this.bubbles.forEach(function (bubble) {
        if (level.families[bubble.code]) {
            validBubbles.push(bubble);
        } else if (Families[bubble.code]) {
            invalidBubbles.push(bubble);
        }
    });

    if (this.bubbles.length !== validBubbles.length) {
        this.bubbles = validBubbles;
        this.save();

        if (invalidBubbles.length) {
            invalidBubbles = invalidBubbles.map(function (bubble) {
                return bubble.code;
            }).join(", ");

            cleverapps.throwAsync("units from other expedition in pocket slot:" + this.slot + " level:" + level.episodeNo + "_" + level.levelNo + " units:" + invalidBubbles);
        }
    }
};

Pocket.prototype.addUnits = function (units, parentUnit) {
    var position = {};

    if (parentUnit) {
        position = Map2d.currentMap.getMapView().alignInGrid(parentUnit.x, parentUnit.y);
    }

    var unitsArray = cleverapps.toArray(units).filter(function (unit) {
        return !UnitSyncer.DISABLED_CODES[unit.code] && unit.code !== "unknown";
    });

    var bubbles = [];
    unitsArray.forEach(function (unit) {
        for (var index = 0; index < (unit.amount || 1); index++) {
            if (unit.code === "landmark") {
                var msg = "PocketLandmark " + unit.stage;
                Map2d.currentMap.listAvailableUnits({ type: "landmark" }).forEach(function (unit) {
                    msg += " " + unit.stage + "_" + unit.x + "x" + unit.y;
                });
                msg += " " + new Error().stack;
                cleverapps.throwAsync(msg);
                continue;
            }

            bubbles.push(new PocketBubble({
                code: unit.code,
                stage: unit.stage,
                x: position.x,
                y: position.y
            }));
        }
    });

    if (!bubbles.length) {
        return;
    }

    this.bubbles = this.bubbles.concat(bubbles);

    this.save();

    bubbles.forEach(function (bubble) {
        this.onAddBubble(bubble);
    }, this);
};

Pocket.prototype.removeBubble = function (bubble) {
    var index = this.bubbles.indexOf(bubble);
    if (index > -1) {
        this.bubbles.splice(index, 1);
        this.save();
    }
};

Pocket.prototype.listUnits = function () {
    return this.bubbles.map(function (bubble) {
        return {
            code: bubble.code,
            stage: bubble.stage
        };
    });
};

Pocket.prototype.isEmpty = function () {
    return !this.bubbles.length;
};

Pocket.prototype.showHint = function () {
    if (this.bubbles.length <= Pocket.AMOUNT_TO_SHOW_HINT || this.hintShown) {
        return;
    }

    var bubble = this.bubbles[this.bubbles.length - 1];
    var bubbleView = bubble.onGetView();
    if (!bubbleView) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "ShowPocketHint",
        keepControls: true,
        action: function (f) {
            this.hintShown = true;

            Merge.currentMerge.counter.setTimeout(function () {}, Pocket.HINT_DURATION);

            Map2d.currentMap.focusOnTarget(bubbleView, {
                allowScrollWithFocus: true,
                duration: 0.8,
                callback: function () {
                    cleverapps.centerHint.createTextHint("PocketBubble.click");

                    bubble.showFinger();

                    f();
                }
            });
        }.bind(this)
    });
};

Pocket.AMOUNT_TO_SHOW_HINT = 60;
Pocket.HINT_DURATION = 3200;

Pocket.MAX_DATA_LENGTH = 200;
Pocket.DELIMITERS = ":,;.~_{}[]";

Pocket.reset = function () {
    Meta.SLOTS.forEach(function (slot) {
        cleverapps.dataLoader.save(DataLoader.TYPES.POCKET + slot, {});
        cleverapps.synchronizer.addUpdateTask("pocket" + slot);
    });
};

Pocket.compactUnits = function (units) {
    units = units.slice();
    if (typeof Merge !== "undefined") {
        units.sort(Map2d.LOAD_UNITS_COMPARATOR());
    }

    var data = "";
    var truncated = [];

    var code;
    units.forEach(function (unit) {
        if (code !== unit.code) {
            if (data.length + 3 > Pocket.MAX_DATA_LENGTH) {
                truncated.push(unit);
                return;
            }

            code = unit.code;

            var id = Families.getIdByCode(code);
            data += Pocket.DELIMITERS[Math.floor(id / 80)] + Base80.compactNumber(id % 80, 1);
        }

        if (data.length + 1 > Pocket.MAX_DATA_LENGTH) {
            truncated.push(unit);
            return;
        }

        data += Base80.compactNumber(unit.stage, 1);
    });

    if (truncated.length && typeof exports === "undefined") {
        truncated = truncated.map(function (unit) {
            return unit.code + "_" + unit.stage;
        });
        cleverapps.throwAsync({ group: "PocketStringTooLong", message: "pocket string is too long: truncated units " + truncated.join(" ") });
    }

    return data;
};

Pocket.expandUnits = function (data) {
    var units = [], i = 0, code, unknown = "";
    while (i < data.length) {
        var delimiter = Pocket.DELIMITERS.indexOf(data[i]);
        if (delimiter > -1) {
            code = Families.getCodeById(delimiter * 80 + Base80.expandNumber(data[i + 1]));
            if (!code) {
                unknown += data[i] + data[i + 1];
            }

            i += 2;
            continue;
        }

        if (!code) {
            unknown += data[i];
            i += 1;
            continue;
        }

        units.push({ code: code, stage: Base80.expandNumber(data[i]) });
        i += 1;
    }

    if (unknown.length) {
        cleverapps.throwAsync("expand unknown unit in pocket: " + unknown);
    }
    return units;
};

if (typeof exports === "undefined") {
    CustomSyncers.registerBySlots("pocket", function (slot) {
        var data = cleverapps.dataLoader.load(DataLoader.TYPES.POCKET + slot);
        return {
            units: data && data.bubbles && Pocket.compactUnits(data.bubbles) || ""
        };
    }, function (slot, serverData) {
        cleverapps.dataLoader.save(DataLoader.TYPES.POCKET + slot, {
            bubbles: Pocket.expandUnits(serverData && serverData.units || "")
        });
    });
}

Pocket.initialize = function () {
    var intersect = Pocket.DELIMITERS.split("").filter(function (ch) {
        return Base80.STR.includes(ch);
    }).join("");

    if (intersect) {
        throw ("Pocket.delimiters intersects with Base80.STR - " + intersect);
    }

    var ids = {};
    Families.codes.forEach(function (code) {
        var id = Families[code].id;

        var error = "";
        if (typeof id !== "number") {
            error = "No id specified for family: " + code;
        } else if (id >= Base80.STR.length * Pocket.DELIMITERS.length) {
            error = "Family id " + id + " is big";
        } else if (ids[id]) {
            error = "Not unique family id " + id;
        }

        if (error) {
            throw error;
        }
        ids[id] = true;
    });
};

if (typeof cc === "undefined") {
    module.exports = Pocket;
}
