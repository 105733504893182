/**
 * Created by andrey on 21.10.2021.
 */

var UnitPacker = {
    compactKey: function (key) {
        return UnitPacker.ENCODE_KEYS[key] || key;
    },

    expandKey: function (key) {
        return UnitPacker.DECODE_KEYS[key] || key;
    },

    compactSeconds: function (time) {
        return Math.ceil(time / 1000);
    },

    expandSeconds: function (time) {
        return Math.round(time * 1000);
    },

    compactCoordinates: function (number) {
        if (number < 80) {
            return Base80.compactNumber(number);
        }
        return UnitPacker.ENCODE_DELIMITERS[Math.floor(number / 80)] + Base80.compactNumber(number % 80);
    },

    expandCoordinates: function (string) {
        var index = UnitPacker.DECODE_DELIMITERS[string.charAt(0)];
        if (index === undefined) {
            return Base80.expandNumber(string);
        }
        return index * 80 + Base80.expandNumber(string.charAt(1));
    },

    compactUnits: function (units, code) {
        units = units.slice().sort(function (a, b) {
            return (b.stage - a.stage) || (a.y !== b.y) || (a.x - b.x);
        });

        var data = units.map(function (unit) {
            return unit._compact || UnitPacker.compactUnit(unit, code);
        }).join("");

        return PropertiesPacker.compactProperties(data, UnitPacker.splitUnits);
    },

    compactUnit: function (unit, code) {
        var info = cleverapps.clone(unit, true);

        if (info.x === undefined || info.y === undefined || info.stage === undefined || code === undefined && unit.code === undefined) {
            throw "Incorrect data";
        }

        var result = UnitPacker.compactCoordinates(info.x)
            + UnitPacker.compactCoordinates(info.y)
            + Base80.compactNumber(info.stage);

        code = code || info.code;

        var family = Families[code];
        var unitData = family && family.units[unit.stage] || {};

        delete info.x;
        delete info.y;
        delete info.stage;

        var properties = [];

        if (unit.unbuilt && unit.time) {
            delete info.unbuilt;
        }

        for (var key in info) {
            var value = info[key];

            if (value === undefined || value === null || UnitPacker.DISABLED_PROPERTIES[key]) {
                continue;
            }

            if (["mined", "state"].indexOf(key) !== -1 && value === 0 && unitData.mineable) {
                continue;
            }

            if (UnitPacker.TIME_KEYS[key]) {
                value = cleverapps.compactTime(value);
            }
            if (UnitPacker.SECONDS_KEYS[key]) {
                value = this.compactSeconds(value);
            }
            if (UnitPacker.NUMBER_KEYS[key]) {
                value = Base80.compactNumber(value);
            }

            if (key === "unbuilt" && value) {
                value = 1;
            }

            var encodedKey = this.compactKey(key);

            if (cleverapps.config.debugMode && encodedKey.length > 2) {
                UnitPacker.reportedKeys = UnitPacker.reportedKeys || {};
                if (!UnitPacker.reportedKeys[key]) {
                    UnitPacker.reportedKeys[key] = true;
                    cleverapps.throwAsync("UnitPacker.compactKey is long - " + key + ", unit - " + JSON.stringify(unit) + ", code - " + code);
                }
            }

            var delimiter = encodedKey.length > 1 ? ":" : "";
            var encoded = encodedKey + delimiter + value;

            properties.push(encoded);
        }

        if (properties.length) {
            result += "{" + properties.join(",") + "}";
        }

        return result;
    },

    expandUnits: function (data, code) {
        data = PropertiesPacker.expandProperties(data, UnitPacker.splitUnits);

        var list = UnitPacker.splitUnits(data);

        var units = [];

        list.forEach(function (item) {
            var unit = UnitPacker.expandUnit(item, code);
            if (unit) {
                unit._compact = item;
                units.push(unit);
            }
        }, this);

        return units;
    },

    expandUnit: function (data, code) {
        var unit = {};

        var coordinates = UnitPacker.splitCoordinates(data, 0);

        unit.x = UnitPacker.expandCoordinates(coordinates.x);
        unit.y = UnitPacker.expandCoordinates(coordinates.y);
        unit.stage = Base80.expandNumber(data.charAt(coordinates.x.length + coordinates.y.length));

        var family = Families[code];

        if (!family || !family.units[unit.stage]) {
            unit.code = code;
            unit._unknown = true;
            return unit;
        }

        data = data.substr(coordinates.x.length + coordinates.y.length + 1);

        if (data.length > 0 && data.charAt(0) === "{" && data.charAt(data.length - 1) === "}") {
            data = data.substr(1, data.length - 2);
            data.split(",").forEach(function (property) {
                var parts = property.split(":");
                var key = parts.length === 1 ? property.charAt(0) : parts[0];
                var value = parts.length === 1 ? property.substr(1) : parts[1];

                key = this.expandKey(key);

                if (UnitPacker.NUMBER_KEYS[key]) {
                    value = Base80.expandNumber(value);
                }
                if (UnitPacker.SECONDS_KEYS[key]) {
                    value = this.expandSeconds(value, key);
                }
                if (UnitPacker.TIME_KEYS[key]) {
                    value = cleverapps.expandTime(value);
                }

                unit[key] = cleverapps.castType(value);
            }, this);
        }

        code = unit.code = code || unit.code;

        var unitData = Families[code] && Families[code].units[unit.stage];
        if (unitData) {
            if (unitData.mineable) {
                unit.state = unit.state || 0;
                unit.mined = unit.mined || 0;
            }

            if (unitData.buildable && unit.time) {
                unit.unbuilt = true;
            }
        }

        return unit;
    },

    splitUnits: function (data) {
        var units = [];
        var index, length, coordinates, endIndex;
        for (index = 0; index < data.length;) {
            coordinates = UnitPacker.splitCoordinates(data, index);

            length = coordinates.x.length + coordinates.y.length + 1;

            if (data.charAt(index + length) === "{") {
                endIndex = data.indexOf("}", index + length);
                length = endIndex + 1 - index;
            }

            units.push(data.substr(index, length));
            index += length;
        }
        return units;
    },

    splitCoordinates: function (data, index) {
        var x = data.charAt(index);

        if (UnitPacker.DECODE_DELIMITERS[x] !== undefined) {
            x += data.charAt(index + 1);
        }

        var y = data.charAt(index + x.length);

        if (UnitPacker.DECODE_DELIMITERS[y] !== undefined) {
            y += data.charAt(index + x.length + 1);
        }

        return { x: x, y: y };
    }
};

UnitPacker.ENCODE_KEYS = {
    amount: "a",
    appearTime: "A",
    changedLevels: "c",
    code: "C",
    extraMined: "e", // was used by mineable
    episode: "E",
    harvested: "h",
    grounded: "g",
    sinceLastHarvest: "H",
    lastHarvested: "i",
    level: "l",
    limit: "L",
    mined: "m",
    minTime: "M",
    progress: "p",
    prizesCount: "P",
    points: "q",
    pointsValue: "Q",
    recipe: "r",
    seed: "R",
    stage: "s",
    state: "S",
    time: "t",
    timeToReady: "T",
    unbuilt: "u",
    lockTime: "k",
    value: "v",
    pending: "n",
    choice: "x" // was used by switchable
};

UnitPacker.TIME_KEYS = cleverapps.createSet(["sinceLastHarvest", "lastHarvested", "timeToReady", "lockTime", "appearTime"]);

UnitPacker.SECONDS_KEYS = cleverapps.createSet(["time", "limit"]);

UnitPacker.NUMBER_KEYS = cleverapps.createSet([
    "limit", "time", "sinceLastHarvest", "lastHarvested", "timeToReady", "harvested", "points", "pointsValue", "lockTime", "appearTime", "pending"
]);

UnitPacker.DECODE_KEYS = {};

UnitPacker.DISABLED_PROPERTIES = cleverapps.createSet(["_compact", "_unknown"]);

UnitPacker.DELIMITERS = ";~_'.";
UnitPacker.ENCODE_DELIMITERS = {};
UnitPacker.DECODE_DELIMITERS = {};

(function () {
    for (var key in UnitPacker.ENCODE_KEYS) {
        var encoded = UnitPacker.ENCODE_KEYS[key];
        if (UnitPacker.DECODE_KEYS[encoded]) {
            throw "Double keys encode to " + encoded;
        }
        UnitPacker.DECODE_KEYS[encoded] = key;
    }

    for (var index = 0; index < UnitPacker.DELIMITERS.length; ++index) {
        UnitPacker.ENCODE_DELIMITERS[index + 1] = UnitPacker.DELIMITERS.charAt(index);
        UnitPacker.DECODE_DELIMITERS[UnitPacker.DELIMITERS.charAt(index)] = index + 1;
    }
}());

if (typeof cc === "undefined") {
    module.exports = UnitPacker;
}
