/**
 * Created by vtbelo on 17/03/21
 */

var PixelView = cc.Node.extend({
    ctor: function (pixel) {
        this._super();
        this.pixel = pixel;

        var styles = this.getStyles();

        this.setAnchorPoint(0.5, 0.5);

        this.target = new cc.Node();
        this.target.setPositionRound(styles.collect);
        this.addChild(this.target);

        this.animation = new LazyAsset(bundles.pixels.jsons[this.pixel.type + "_pixels_json"] || bundles.pixels.jsons["pixels_json"], {
            preview: new cc.Sprite(bundles.merge.frames.pixel_preview)
        });
        this.animation.setAnimation(0, "animation", true);
        this.setContentSize2(this.animation.getContentSize());
        this.animation.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.animation);
        if (styles.timeScale) {
            this.animation.setTimeScale(styles.timeScale.base + Math.random() * styles.timeScale.random);
        }

        if (this.pixel.type === PixelsSwarm.FRIEND) {
            this.addFriendViews();
        }

        cleverapps.UI.onDrag(this, {
            onClick: this.pixel.collect.bind(this.pixel),
            followPointer: true,
            onDragStart: function () {
                this.pixel.setFlying(false);
                this.setLocalZOrder(1);

                return true;
            }.bind(this),
            onDragMove: function (touch) {
                this.setPositionRound(this.parent.convertTouchToNodeSpace(touch));
            }.bind(this),
            onDragEnd: function () {
                this.pixel.setFlying(true);
                this.setLocalZOrder(0);
            }.bind(this),
            filter: function () {
                return Merge.currentMerge && Merge.currentMerge.tutorial && !Merge.currentMerge.tutorial.isActive();
            }
        });

        this.pixel.onFlyingChanged = this.createListener(this.changeFlying.bind(this));
        this.pixel.onAnimateCollect = this.createListener(this.collect.bind(this));
        this.pixel.onDie = this.createListener(this.explode.bind(this));

        this.minFlyTime = cleverapps.parseInterval(PixelView.FLY_TIME);

        var cell = Map2d.currentMap.getMapView().alignInGrid(this.pixel.x, this.pixel.y);
        this.setPosition(cell.x, cell.y);

        this.speed = styles.speed;
        this.distanceDisappear = styles.distanceDisappear;

        Merge.currentMerge.pushingComponent.add(this, "pixels");

        if (typeof Merge !== "undefined" && Merge.currentMerge && Merge.currentMerge.tutorial) {
            Merge.currentMerge.tutorial.on("showTutorialStep", this.createListener(this.changeFlying.bind(this, false)));
            Merge.currentMerge.tutorial.on("finishTutorialStep", this.createListener(this.changeFlying.bind(this, true)));
        }

        this.flyStartTime = Date.now();
        this.pixel.setFlying(true);

        this.setScale(0);
        this.runAction(new cc.ScaleTo(0.2, 1));

        this.lastCheck = 0;
    },

    addAvatar: function (avatar, userId) {
        if (this.avatar) {
            this.avatar.removeFromParent();
        }

        this.avatar = new cleverapps.UI.Avatar(avatar, {
            userId: userId,
            frame: bundles.avatar.frames.avatar_frame2
        });

        var styles = this.getStyles();
        this.avatar.setPositionRound(styles.avatar.position);
        cleverapps.UI.fitToBox(this.avatar, styles.avatar);
        this.addChild(this.avatar);
    },

    addFriendViews: function () {
        var friend = cleverapps.friends.getById(this.pixel.friendRequest.from);
        if (friend) {
            this.addAvatar(friend);
        } else {
            this.addAvatar(undefined, this.pixel.friendRequest.from);
        }

        var styles = this.getStyles();
        this.bg = cleverapps.UI.createScale9Sprite(bundles.dialogues_minimal.frames.bg_minimal_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        this.bg.setContentSize2(styles.message.bg.width, styles.message.bg.height);
        this.bg.setPositionRound(styles.message.bg.position);
        this.bg.setScale(0.46);
        this.addChild(this.bg);

        this.bgArrow = new cc.Sprite(bundles.dialogues_minimal.frames.mini_arrow_bg_png);
        this.bgArrow.setPositionRound(styles.message.arrow.position);
        this.bgArrow.setAnchorPoint(0.5, 0);
        this.bgArrow.setRotation(-90);
        this.bg.addChild(this.bgArrow);

        this.text = cleverapps.UI.generateOnlyText("Pixels.friend", {
            size: 60,
            color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
        });
        this.text.setPositionRound(styles.message.position);
        cleverapps.UI.fitToBox(this.text, styles.message.width, styles.message.height);
        this.bg.addChild(this.text);

        if (cleverapps.config.debugMode) {
            this.debugText = cleverapps.UI.generateOnlyText(this.pixel.friendRequest.from, {
                size: 30,
                color: cleverapps.styles.COLORS.WHITE
            });

            this.addChild(this.debugText);
            this.debugText.setPositionRound(styles.debugText.position);
        }
    },


    getStyles: function () {
        return cleverapps.styles.PixelView[this.pixel.type] || cleverapps.styles.PixelView;
    },

    update: function (dt) {
        this.setPosition(this.x + this.speed.x * dt, this.y + this.speed.y * dt);
        var screenSize = cleverapps.resolution.getBgSize();

        var ct = Date.now();
        if (this.flyStartTime + this.minFlyTime < ct && this.lastCheck < ct - 3000) {
            this.lastCheck = ct;
            var pos = this.parent.convertToWorldSpace(this.getPosition());

            if (screenSize.width + this.distanceDisappear < pos.x || pos.y < -this.distanceDisappear) {
                this.pixel.die(true);
            }
        }

        var onScreen = cc.rectIntersectsRect(this.animation.getGlobalBoundingBox(), cc.rect(0, 0, screenSize.width, screenSize.height));
        this.setVisible(onScreen);
    },

    changeFlying: function (flying) {
        if (flying) {
            this.scheduleUpdate();
            this.resumePushing();
        } else {
            this.unscheduleUpdate();
            this.pausePushing();
        }
    },

    collect: function (callback) {
        var styles = this.getStyles().collect;

        callback({
            source: this.target,
            delay: styles.delay,
            sound: bundles.pixels.urls[this.pixel.type + "_pixel_collect_effect"] || bundles.pixels.urls["pixel_collect_effect"]
        });
    },

    explode: function (silent) {
        cc.eventManager.removeListeners(this);

        if (this.avatar) {
            this.avatar.removeFromParent();
        }

        if (this.bg) {
            this.bg.removeFromParent();
        }

        var callback = cleverapps.once(function () {
            this.removeFromParent();
        }.bind(this));

        if (this.animation.isLoaded()) {
            this.animation.setAnimation(0, "animation2", false);
            this.animation.setCompleteListener(callback);
        } else {
            var delay = this.getStyles().collect.delay * 0.001 || 0;
            this.animation.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.ScaleTo(0.1, 0.3),
                new cc.Hide(),
                new cc.CallFunc(callback)
            ));
        }

        if (silent) {
            callback();
        }
    }
});

PixelView.FLY_TIME = "90 seconds";

cleverapps.styles.PixelViewTemplate = {
    distanceDisappear: 1000,
    speed: { x: 35, y: -18 },
    collect: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 },
        delay: 150
    }
};

cleverapps.styles.PixelView = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);
cleverapps.styles.PixelView[PixelsSwarm.UNDERSEA] = cleverapps.styles.PixelView[PixelsSwarm.UNDERSEA2] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);
cleverapps.styles.PixelView[PixelsSwarm.DRAGONIA] = cleverapps.styles.PixelView[PixelsSwarm.DRAGONIA2] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);
cleverapps.styles.PixelView[PixelsSwarm.RAPUNZEL] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);
cleverapps.styles.PixelView[PixelsSwarm.XMAS] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);
cleverapps.styles.PixelView[PixelsSwarm.HALLOWEEN] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);
cleverapps.styles.PixelView[PixelsSwarm.BLACKFRIDAY] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);

cleverapps.styles.PixelView[PixelsSwarm.SPRING] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {

}, true);

cleverapps.styles.PixelView[PixelsSwarm.CHINA] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {
    collect: {
        y: { align: "center", dy: 20 },
        delay: 10
    }
}, true);

cleverapps.styles.PixelView[PixelsSwarm.EASTER] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {
    collect: {
        x: { align: "center", dx: 10 },
        y: { align: "center", dy: -195 },
        delay: 1350
    }
}, true);

cleverapps.styles.PixelView[PixelsSwarm.FRIEND] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {
    collect: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -350 },
        delay: 300
    },

    avatar: {
        width: 200,
        height: 200,
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 2 }
        }
    },
    message: {
        bg: {
            width: 486,
            height: 158,
            position: {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 127 }
            }
        },
        arrow: {
            width: 200,
            height: 200,
            position: {
                x: { align: "center", dx: 10 },
                y: { align: "bottom", dy: 0 }
            }
        },
        width: 200,
        height: 60,
        position: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },
    debugText: {
        position: {
            x: { align: "center" },
            y: { align: "top", dy: 30 }
        }
    }
}, true);