/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["repeatedQuests"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: 1,
    filter: function () {
        return cleverapps.environment.isMainScene() && Merge.currentMerge && Merge.currentMerge.quests.isRepeatedQuestsAvailable();
    },

    action: function () {
        Merge.currentMerge.quests.runRepeatedQuests();
    },

    interval: cleverapps.config.debugMode ? "10 seconds" : "1 hour"
};

Placements.ENTRIES["runSpecialEnergyOffer"] = {
    type: Placements.FREE_FOCUS,

    filter: function () {
        return cleverapps.environment.isMainScene()
            && Merge.currentMerge.specialEnergyOffer.showPlacement;
    },

    action: function () {
        Merge.currentMerge.specialEnergyOffer.showPlacement = false;

        Merge.currentMerge.specialEnergyOffer.displayWindow();
    }
};

Placements.ENTRIES["growthFund"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: -2,

    filter: function () {
        return cleverapps.growthFund && cleverapps.growthFund.wantsAttention && cleverapps.growthFund.isActive();
    },

    action: function () {
        cleverapps.focusManager.display({
            focus: "GrowthFundNudge",
            action: function (f) {
                new GrowthFundWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
    }
};

Placements.ENTRIES["showBonusWorkerWindow"] = {
    type: Placements.FREE_FOCUS,

    filter: function () {
        return Map2d.currentMap && Map2d.currentMap && Map2d.currentMap.workers.wantsToShowBonusWorkerWindow;
    },

    action: function () {
        cleverapps.focusManager.display({
            focus: "BonusWorkerWindow",
            actions: [
                function (f) {
                    new BonusWorkerWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            ]
        });
    }
};

Placements.ENTRIES["seasonWindow"] = {
    type: Placements.FREE_FOCUS_MAIN,

    filter: function () {
        return Merge.currentMerge && Merge.currentMerge.season && Merge.currentMerge.season.hasFresh();
    },

    action: function () {
        Merge.currentMerge.season.processFresh();
    }
};

Placements.ENTRIES["runCounterStages"] = {
    type: Placements.FREE_FOCUS,
    priority: 1,

    action: function () {
        if (Merge.currentMerge && Merge.currentMerge.showUpFinished && !Merge.currentMerge.counter.isActive()) {
            Merge.currentMerge.counter.trigger();
        }
    }
};
