/**
 * Created by andrey on 01.03.2021.
 */

var UnitSyncer = function (unitSaver, families) {
    this.unitSaver = unitSaver;
    this.slot = unitSaver.slot;
    this.syncers = {};
    this.index = {};

    CustomSyncers.importerData["units" + this.slot] = function (serverData) {
        this.updateInfo(serverData, true);
    }.bind(this);

    Object.keys(families).forEach(function (code) {
        this.createFamilySyncer(code);
    }.bind(this));
};

UnitSyncer.prototype.getKey = function (x, y) {
    return x + "_" + y;
};

UnitSyncer.prototype.createFamilySyncer = function (code) {
    if (code === "unknown" || UnitSyncer.DISABLED_CODES[code] || UnitSyncer.DELETED_CODES[code]) {
        return;
    }

    if (!this.syncers[code]) {
        this.syncers[code] = code === "intact" ? new IntactFamilySyncer(code, this.slot) : new FamilySyncer(code, this.slot);
    }

    return this.syncers[code];
};

UnitSyncer.prototype.reset = function (fromLoad) {
    this.index = {};

    Object.values(this.syncers).forEach(function (syncer) {
        syncer.reset(fromLoad);
    });
};

UnitSyncer.prototype.remove = function (x, y) {
    var key = this.getKey(x, y);
    var code = this.index[key];
    var syncer = this.syncers[code];
    if (syncer) {
        syncer.remove(x, y);
        delete this.index[key];
    }
};

UnitSyncer.prototype.save = function (x, y, data, fromLoad) {
    this.remove(x, y);

    var code = data && data.code;
    var syncer = this.createFamilySyncer(code);
    if (syncer) {
        var key = this.getKey(x, y);
        syncer.save(x, y, data, fromLoad);
        this.index[key] = code;
    }
};

UnitSyncer.prototype.updateInfo = function (serverData) {
    if (serverData === undefined) {
        return;
    }

    Object.keys(serverData).forEach(function (code) {
        this.createFamilySyncer(code);
    }, this);

    Object.values(this.syncers).forEach(function (syncer) {
        syncer.updateInfo(serverData[syncer.code] || {});
    }, this);

    var units = [];
    Object.values(this.syncers).forEach(function (syncer) {
        units = units.concat(syncer.listUnits());
    }, this);

    units = units.sort(UnitSyncer.UNITS_IMPORTANCE_ORDER);

    this.index = {};
    units.forEach(function (unit) {
        var key = this.getKey(unit.x, unit.y);
        if (!this.index[key]) {
            this.index[key] = unit.data.code;
        } else {
            unit.kickout = true;
        }
    }, this);

    this.unitSaver.updateInfo(units);
};

UnitSyncer.UNITS_IMPORTANCE_ORDER = function (unit1, unit2) {
    var data1 = Families[unit1.data.code] && Families[unit1.data.code].units[unit1.data.stage] || {};
    var data2 = Families[unit2.data.code] && Families[unit2.data.code].units[unit2.data.stage] || {};

    if ((data1.type === "hero" || data2.type === "hero") && data1.customer !== data2.customer) {
        return data1.customer ? -1 : 1;
    }

    if (data1.important !== data2.important) {
        return data1.important ? -1 : 1;
    }

    return unit2.data.stage - unit1.data.stage;
};

UnitSyncer.DISABLED_CODES = cleverapps.createSet(["multiCellBody", "thirdelement", "barrel", "caravanship"]);
UnitSyncer.DELETED_CODES = cleverapps.createSet([
    "seasonalspot", "hlseasonalitem", "salecup", "saleship",
    "seajellyfishchest", "dr2wood", "dr2stone", "dr2sapphire", "dr2woodsource", "dr2stonesource", "dr2sapphiresource",
    "dr2woodchest", "dr2stonechest", "dr2sapphirechest", "dr2stonegenerator", "dr2sapphiregenerator", "dr2shopchest",
    "dr2freechest", "dr2magicplant", "dr2battlepass", "dr2growing", "dr2house", "dr2portal",
    "driron", "dryellowcrystal", "drironsource", "dryellowcrystalsource", "drironchest", "dryellowcrystalchest",
    "drirongenerator", "dryellowcrystalgenerator", "dishes",
    "xmcookie", "xmsanta", "xmsnowman", "xmdeer", "xmyeti", "xmelf", "xmtoy", "xmsocks", "xmcandy", "xmsled", "xmcastle",
    "xmtoysource", "xmsockssource", "xmcandysource", "xmsledsource", "xmcastlesource", "xmtoychest", "xmsockschest",
    "xmcandychest", "xmsledchest", "xmcastlechest", "xmshopchest", "xmfreechest", "xmshop", "xmmagicplant", "xmbattlepass",
    "xmgrowing", "xmhouse", "xmfactory", "xmrudolf", "xmgiftball", "xmgiftdoll", "xmgiftbear", "xmhunter", "xmgnome", "xmplane",
    "xmorder", "xmlamberjack", "xmtoygenerator", "xmsocksgenerator", "xmcandygenerator", "xmsledgenerator", "xmcastlegenerator",
    "xmpack", "xmbread", "xmigloo", "xmbreadsource", "xmigloosource", "xmbreadchest", "xmigloochest", "xmbreadgenerator",
    "xmigloogenerator", "xmbridge", "xmproducertrash", "xmcustomerbridge1", "xmcustomerbridge2", "xmcustomerbridge3",
    "xmproduct0a", "xmproduct0b", "xmproduct1b", "xmproduct1a", "xmproduct2a", "xmproducer0a", "xmproducer1a", "xmproducer1b",
    "xmproducer2a", "xmproducer2b", "xmcustomermain0", "xmcustomermain1", "xmcustomermain2", "xmcustomermain3",
    "xmcustomermain4", "xmcustomermain5",
    "chbamboo", "chbamboosource", "chcastle", "chcastlesource", "chdragongreen", "chdragonpurple", "chdragonrainbow",
    "chdragonred", "chdragonyellow", "chfeathers", "chfeatherssource", "chfreechest", "chnarrator", "chsapphire",
    "chsapphiresource", "chshopchest", "chsourcegenerator", "chthrone", "chshop", "sea2crab", "sea2fishes", "sea2shark",
    "sea2octopus", "sea2jellyfish", "sea2lochness", "sea2turtle", "sea2shrimps",
    "sea2crabsource", "sea2fishessource", "sea2sharksource", "sea2jellyfishsource", "sea2lochnesssource",
    "sea2turtlesource", "sea2shrimpssource", "sea2octopussource", "sea2fishesgenerator", "sea2sharkgenerator",
    "sea2octopusgenerator", "sea2jellyfishgenerator", "sea2crabgenerator", "sea2lochnessgenerator",
    "sea2turtlegenerator", "sea2shrimpsgenerator", "sea2shopchest", "sea2freechest", "sea2sack", "sea2crabchest",
    "sea2fisheschest", "sea2sharkchest", "sea2octopuschest", "sea2jellyfishchest", "sea2lochnesschest",
    "sea2turtlechest", "sea2shrimpschest", "sea2shop", "sea2magicplant", "sea2portal", "sea2house", "sea2snail",
    "sea2tournament", "sea2strawberry", "sea2snailhouse", "sea2battlepass"
]);

if (typeof cc === "undefined") {
    module.exports = UnitSyncer;
}
