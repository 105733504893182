/**
 * Created by andrey on 01.02.2021.
 */

var HeroDetailsLayout = FamilyLayout.extend({
    ctor: function (hero, units, options) {
        if (hero.unknownHero) {
            options.title = "Units.unknownHero.title";
        }
        this.units = units;
        var styles = cleverapps.styles.HeroDetailsLayout;
        options.height = styles.height;

        this.searchUnit = options.searchUnit;

        var icons = units.map(function (unit) {
            var icon = new UnitLibraryIcon(unit);
            icon.skipSeparator = Families[unit.code].units[unit.stage].unmergeable;
            return icon;
        });

        this._super(hero.code, icons, options);

        this.hero = hero;

        var foreground = cleverapps.UI.createScale9Sprite(bundles.unitslibrarywindow.frames.horizontal_scroll_foreground, cleverapps.UI.Scale9Rect.TwoPixelXY);
        foreground.setAnchorPoint(0, 0);

        var foregroundRect = cc.rectSubPadding(cc.rect(0, 0, this.background.width, this.background.height), cc.padding(styles.foreground.padding));
        foreground.setContentSize2(foregroundRect);
        foreground.setPositionRound(foregroundRect);
        this.background.addChild(foreground);

        if (!hero.unknownHero) {
            this.createButtonInfo();
        }
        if (hero.unknownHero) {
            var unknownHeroText = cleverapps.UI.generateOnlyText("Units.unknownHero.details", cleverapps.styles.FONTS.HERODETAILS_TEXT);
            unknownHeroText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            unknownHeroText.setDimensions(this.background.width * 0.85, 0);
            unknownHeroText.setPositionRound(styles.unknownHeroText);
            this.addChild(unknownHeroText);
        }
        cleverapps.eventBus.on("unitStatusChanged", this.onUnitStatusChanged.bind(this), this);
    },

    onUnitStatusChanged: function (unit) {
        if (unit.code !== this.hero.code) {
            return;
        }

        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.DelayTime(1.0),
            new cc.CallFunc(this.scrollToFresh.bind(this))
        ));
    },

    scrollToFresh: function () {
        this.stopAllActions();

        var unit = this.searchUnit && Unit.findUnit(this.units, this.searchUnit)
            || cleverapps.unitsLibrary.findFreshUnit(this.units)
            || cleverapps.unitsLibrary.findLastOpenedUnit(this.units);
        var index = unit ? this.units.indexOf(unit) : this.units.length - 1;

        var icon = this.icons[index];

        if (icon && this.scroll) {
            this.scroll.runAction(new cc.Sequence(
                new cc.DelayTime(0.5),
                new cc.ScrollAction(icon, {
                    duration: 1.6,
                    visibleBox: {
                        left: 0.96,
                        right: 0.96
                    }
                }).easing(cc.easeBackInOut())
            ));
        }
    },

    createHeroInfoText: function () {
        this.heroInfoText = cleverapps.UI.generateOnlyText("Units." + this.hero.code + ".describe", cleverapps.styles.FONTS.HERODETAILS_TEXT);
        this.heroInfoText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.heroInfoText.setVerticalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.heroInfoText.setDimensions(this.background.width * 0.85, 0);
        this.heroInfoText.fitTo(undefined, this.background.height * 0.7);
        this.heroInfoText.setPositionRound(cleverapps.styles.HeroDetailsLayout.infoHeroText);
        this.background.addChild(this.heroInfoText);

        this.grid.setCascadeOpacityEnabledRecursively(true);

        this.setCascadeOpacityEnabledRecursively(true);

        this.heroInfoText.setVisible(false);
    },

    createButtonInfo: function () {
        var styles = cleverapps.styles.HeroDetailsLayout.buttonInfo;

        if (!styles || cleverapps.flags.largeTextHotfix) {
            return;
        }

        var info = false;

        var button = new cleverapps.UI.HelpButton({
            type: cleverapps.styles.UI.Button.Images.help_button_light,
            callback: function () {
                if (!this.heroInfoText) {
                    this.createHeroInfoText();
                }

                var toShow = info ? this.grid : this.heroInfoText;
                var toHide = info ? this.heroInfoText : this.grid;

                info = !info;

                toShow.stopAllActions();
                toShow.setOpacity(0);
                toShow.setVisible(true);
                toShow.runAction(new cc.Sequence(
                    new cc.DelayTime(0.15),
                    new cc.FadeTo(0.15, 255)
                ));

                toHide.stopAllActions();
                toHide.setOpacity(255);
                toHide.runAction(new cc.Sequence(
                    new cc.FadeTo(0.15, 0),
                    new cc.Hide()
                ));
            }.bind(this) 
        });
        button.setLocalZOrder(1);
        button.setPositionRound(styles);
        this.addChild(button);
    },

    createGrid: function (icons) {
        var styles = cleverapps.styles.HeroDetailsLayout;

        var grid = new cleverapps.GridLayout(icons, {
            columns: icons.length,
            align: {
                y: cleverapps.GridLayout.ALIGN_END
            },
            padding: styles.grid.padding,
            margin: styles.grid.margin,
            separator: this.gridSeparator()
        });

        if (grid.width <= styles.grid.width) {
            grid.setPositionRound(styles.grid);
            return grid;
        }

        var scroll = this.scroll = new cleverapps.UI.ScrollView(grid, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            scrollBarEnabled: false,
            direction: cleverapps.UI.ScrollView.DIR_HORIZONTAL
        });
        scroll.setContentSize(styles.grid.width, grid.height);
        scroll.setPositionRound(styles.grid);

        scroll.scrollTo(cleverapps.UI.ScrollView.SCROLLS.LOWER_RIGHT);
        this.scrollToFresh();
        return scroll;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    HERODETAILS_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.HeroDetailsLayout = {
    height: 610,

    unknownHeroText: {
        x: { align: "center" },
        y: { align: "center" }
    },

    infoHeroText: {
        x: { align: "center" },
        y: { align: "center" }
    },

    buttonInfo: {
        x: { align: "right", dx: -50 },
        y: { align: "top", dy: -50 }
    },

    grid: {
        width: 1868,

        x: { align: "left", dx: 4 },
        y: { align: "center", dy: -15 },

        padding: {
            x: 50,
            top: 30,
            bottom: 70
        },

        margin: {
            x: 54,
            y: 20
        }
    },

    foreground: {
        padding: {
            top: 80,
            bottom: 65
        }
    }
};
