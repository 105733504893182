/**
 * Created by mac on 12/30/20
 */

var GameWorker = function (skin, options) {
    this.options = this.load(options);

    this.skin = skin;
    this.position = undefined;
    this.unit = undefined;
    this.start = undefined;
    this.duration = undefined;

    this.bonus = undefined;

    this.onDestructor = function () {};
};

GameWorker.prototype.save = function () {
    var res = {};

    if (this.unit) {
        res[this.unit.x + "_" + this.unit.y] = cleverapps.compactTime(this.start, { seconds: true });
    } else if (this.position) {
        res[this.position.x + "_" + this.position.y] = 0;
    }

    return res;
};

GameWorker.prototype.load = function (stored) {
    if (!stored || stored.unit) {
        return stored;
    }

    var keys = Object.keys(stored);
    if (keys.length !== 1) {
        return;
    }

    var key = keys[0];
    var parts = key.split("_");
    var x = +parts[0];
    var y = +parts[1];
    var start = cleverapps.expandTime(stored[key], { seconds: true });

    if (x && y && start) {
        return {
            unit: { x: x, y: y },
            start: start
        };
    }

    if (x && y) {
        return {
            position: { x: x, y: y }
        };
    }
};

GameWorker.prototype.run = function () {
    if (this.options) {
        if (this.options.unit) {
            var unit = Map2d.currentMap.getUnit(this.options.unit.x, this.options.unit.y);

            var mineable = unit && unit.findComponent(Mineable);
            var buildable = unit && unit.findComponent(Buildable);

            var duration = this.options.duration || mineable && mineable.getTask().time || buildable && buildable.time;
            if (typeof duration === "string") {
                duration = cleverapps.parseInterval(duration);
            }

            if (cleverapps.skins.getSlot("walkingWorkersSkins")) {
                if (unit) {
                    this.position = this.findCellsNearUnit(unit, true)[0];
                }

                if (!this.position) {
                    this.position = cc.p(this.options.unit);
                }
            }

            if (!unit || typeof duration !== "number" || !this.assign(unit, duration, this.options.start)) {
                this.clearAssignment();
            }
        } else if (this.options.position) {
            this.position = this.options.position;
        }

        this.options = undefined;

        this.onUpdateState();
    }
};

GameWorker.prototype.setView = function (view) {
    this.view = view;
};

GameWorker.prototype.onRemove = function () {
    this.view.removeFromParent(true);
};

GameWorker.prototype.isInitialized = function () {
    return !this.options;
};

GameWorker.prototype.destructor = function () {
    this.clearTimeout();
    this.clearBonus();

    this.onDestructor();
    this.onDestructor = function () {};
};

GameWorker.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState();
    }
};

GameWorker.SHORT_ANIMATION_DURATION = 10000;
GameWorker.MINING_ANIMATION_TIME = 3000;

GameWorker.prototype.isBusy = function () {
    return this.unit !== undefined;
};

GameWorker.prototype.assign = function (unit, duration, start) {
    if (this.isBusy()) {
        this.completeAssignment();
    }

    var mineable = unit && unit.findComponent(Mineable);
    var buildable = unit && unit.findComponent(Buildable);

    if (!mineable && !buildable) {
        return false;
    }

    if (buildable) {
        var instantWorker = Map2d.currentMap.workers.findInstantAssigned(buildable);
        if (instantWorker) {
            return false;
        }
    }

    if (Map2d.currentMap.workers.findAssigned(unit) || Map2d.currentMap.workers.isRetiredWorker(this)) {
        return false;
    }

    if (!start && cleverapps.skins.getSlot("walkingWorkersSkins")) {
        var moveToCells = this.findCellsNearUnit(unit);
        if (!moveToCells.length) {
            cleverapps.centerHint.createTextHint("Workers.freeSpace");
            return false;
        }
    }

    this.unit = unit;
    this.duration = duration;
    this.start = start || Date.now();

    if (!start && moveToCells) {
        this.startMoving(this.findWalkingPath(moveToCells));
    } else {
        this.startWorking(start);
    }

    Map2d.currentMap.workers.save();
    Map2d.currentMap.workers.onChangeStatusListener();

    InfoView.CloseInfo();

    return true;
};

GameWorker.prototype.startWorking = function () {
    var mineable = this.unit && this.unit.findComponent(Mineable);
    var buildable = this.unit && this.unit.findComponent(Buildable);

    var animationTime = this.getTimeLeft();
    if (animationTime === 0) {
        animationTime = cleverapps.skins.getSlot("workerCutTime");
        if (animationTime === undefined) {
            animationTime = GameWorker.MINING_ANIMATION_TIME;
        }
    }

    if (animationTime < GameWorker.SHORT_ANIMATION_DURATION) {
        this.timeout = cleverapps.timeouts.setTimeout(this.completeAssignment.bind(this), animationTime);
    } else {
        this.timeout = new cleverapps.LongTimeout(this.completeAssignment.bind(this), animationTime);
    }

    Map2d.currentMap.workers.assignSpeedUpBonus(this);

    if (mineable) {
        this.view.startWorking(mineable);
        Merge.currentMerge.pushes.updateChopped();
    } else if (buildable) {
        cleverapps.audio.playSound(bundles.buildable.urls.build_start_effect);

        this.view.startWorking(buildable);
        Merge.currentMerge.pushes.updateBuilt();

        cleverapps.travelBook.updateBuilt();
    }
};

GameWorker.prototype.clearAssignment = function () {
    this.clearTimeout();

    this.unit = undefined;
    this.start = undefined;
    this.duration = undefined;

    this.clearBonus();

    Map2d.currentMap.workers.save();
    Map2d.currentMap.workers.onChangeStatusListener();

    this.onUpdateState();
};

GameWorker.prototype.completeAssignment = function (extraTime) {
    if (!this.isBusy()) {
        return;
    }

    var timeSpent = Date.now() - this.start + (extraTime || 0);
    var unit = this.unit;

    this.clearAssignment();

    var mineable = unit.findComponent(Mineable);
    if (mineable) {
        mineable.completeMining(this);
        this.view.complete(mineable);
    }

    var buildable = unit.findComponent(Buildable);
    if (buildable) {
        if (buildable.time > timeSpent) {
            unit.suspendBuilding(timeSpent);
        } else {
            unit.completeBuilding();
        }

        this.view.complete(buildable);
    }

    Map2d.currentMap.workers.adjustRegularIfNeeded();
};

GameWorker.prototype.getTimeLeft = function () {
    return Math.max(this.duration - (Date.now() - this.start), 0);
};

GameWorker.prototype.getFullJobTime = function () {
    if (!this.isBusy()) {
        return;
    }

    var build = this.unit.findComponent(Buildable);
    if (build) {
        return cleverapps.parseInterval(this.unit.getData().buildable);
    }

    var mine = this.unit.findComponent(Mineable);
    if (mine) {
        return cleverapps.parseInterval(mine.getTask().time);
    }
};

GameWorker.prototype.clearTimeout = function () {
    if (this.timeout instanceof cleverapps.CountDown) {
        this.timeout.remove();
        this.timeout = undefined;
    } else if (typeof this.timeout === "object") {
        cleverapps.timeouts.clearTimeout(this.timeout);
        this.timeout = undefined;
    }
};

GameWorker.prototype.speedUpPartially = function (extraTime, animateTimer) {
    if (!this.isBusy()) {
        return;
    }

    var unit = this.unit;

    this.speedupInProgress = true;
    var assignExtraTime = function () {
        this.completeAssignment(extraTime);

        var buildable = unit.findComponent(Buildable);
        if (buildable && !buildable.isCompleted()) {
            this.assign(unit, buildable.time);
        }

        this.speedupInProgress = false;
        Merge.currentMerge.pushes.updateBuilt();
        Merge.currentMerge.pushes.updateChopped();
    }.bind(this);

    if (animateTimer && this.unit.onGetView()) {
        this.view.showSpeedUp(unit, Math.min(extraTime, this.getTimeLeft()), assignExtraTime);
    } else {
        assignExtraTime();
    }
};

GameWorker.prototype.speedUp = function () {
    Map2d.mapEvent(Map2d.SPEED_UP, { affected: this.unit });
    this.speedUpPartially(this.getTimeLeft());
};

GameWorker.prototype.setSpeedUpBonus = function () {
    if (!this.bonus) {
        this.bonus = {
            time: Workers.SPEEDUP_BONUS_TIME,
            timeout: new cleverapps.LongTimeout(this.clearBonus.bind(this), cleverapps.parseInterval(Workers.SPEEDUP_BONUS_DURATION))
        };
    }
};

GameWorker.prototype.clearBonus = function () {
    if (this.bonus) {
        clearTimeout(this.bonus.timeout);
        this.bonus = undefined;
    }
};

GameWorker.prototype.isSpeedUpBonusAvailable = function () {
    return cleverapps.rewardedAdsManager.isRewardedAvailable() && this.isBusy() && this.getTimeLeft() > cleverapps.parseInterval(Workers.SPEEDUP_BONUS_MINIMUM_REQUIRED_TIME) && this.unit.findComponent(Buildable);
};

GameWorker.prototype.isSpeedUpBonusActive = function () {
    return this.bonus;
};

GameWorker.prototype.speedUpForVideo = function () {
    if (!this.isBusy()) {
        return;
    }

    cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.SPEEDUP, function () {
        this.speedUpPartially(cleverapps.parseInterval(Workers.SPEEDUP_BONUS_TIME), true);
    }.bind(this));
};

GameWorker.prototype.findCellsNearUnit = function (unit, any) {
    var directions = [Iso.DOWN_RIGHT, Iso.UP_LEFT, Iso.UP_RIGHT, Iso.DOWN_LEFT];
    var cells = {};

    unit.getShape().forEach(function (part) {
        directions.forEach(function (dir) {
            var x = unit.x + part.x + dir.x;
            var y = unit.y + part.y + dir.y;

            if (!cells[x + "_" + y]) {
                cells[x + "_" + y] = { x: x, y: y };
            }
        });
    });

    cells = Object.values(cells);

    if (this.position) {
        cells.sort(function (a, b) {
            return cc.pDistanceSQ(this.position, a) - cc.pDistanceSQ(this.position, b);
        }.bind(this));
    }

    if (any) {
        cells.sort(function (a, b) {
            return Boolean(GameWorker.canMove(b)) - Boolean(GameWorker.canMove(a));
        });
        return cells;
    }

    return cells.filter(function (cell) {
        return GameWorker.canMove(cell);
    });
};

GameWorker.prototype.findNearestAvailableCell = function (cell) {
    var moveToCells = [];

    Map2d.currentMap.iterateBfs(cell.x, cell.y, function (cell) {
        if (GameWorker.canMove(cell)) {
            if (moveToCells.length && moveToCells[0].step !== cell.step) {
                return true;
            }
            moveToCells.push(cell);
        }
    });

    if (!moveToCells.length) {
        return;
    }

    var relativePosition = this.position || cell;

    var bestMoveCell = moveToCells[0];
    var bestMoveDistance = cc.pDistanceSQ(relativePosition, bestMoveCell);

    for (var i = 1; i < moveToCells.length; i++) {
        var distance = cc.pDistanceSQ(relativePosition, moveToCells[i]);
        if (distance < bestMoveDistance) {
            bestMoveCell = moveToCells[i];
            bestMoveDistance = distance;
        }
    }

    return bestMoveCell;
};

GameWorker.prototype.findWalkingPath = function (moveToCells) {
    var from = this.view.getViewPosition();
    if (!from) {
        return [moveToCells[0]];
    }

    var rounded = cc.p(Math.round(from.x), Math.round(from.y));
    var path = Map2d.currentMap.findPath(rounded, moveToCells, function (x, y) {
        return GameWorker.canWalk(cc.p(x, y));
    });
    if (path.length < 2) {
        return [moveToCells[0]];
    }

    path[0] = from;
    return path;
};

GameWorker.prototype.startMoving = function (path) {
    this.path = path.reverse();
    this.position = cc.p(this.path[0]);

    Map2d.currentMap.workers.save();

    this.onUpdateState();
    this.makeStep();
};

GameWorker.prototype.makeStep = function () {
    if (!this.path) {
        this.view.dashToCell(this.position);
        return;
    }

    if (this.path.length <= 1) {
        this.path = undefined;

        this.view.dashToCell(this.position);
        this.view.stopMoving();

        if (this.unit) {
            this.startWorking();
        }
        return;
    }

    var skipped = false;

    while (this.path.length >= 3) {
        if (this.path.slice(-3).some(function (cell) {
            return Map2d.currentMap.isScreenCell(cell);
        })) {
            break;
        }

        skipped = true;
        this.path.pop();
    }

    if (skipped) {
        this.view.dashToCell(this.path[this.path.length - 1]);
    }

    this.view.moveToCell(this.path.pop(), this.path[this.path.length - 1], this.makeStep.bind(this));

    if (this.path.length === 1) {
        this.path.pop();
    }
};

GameWorker.prototype.hasPosition = function () {
    return Boolean(this.position);
};

GameWorker.prototype.move = function (cell) {
    if (!this.view || cc.pointEqualToPoint(this.position, cell)) {
        return true;
    }

    var bestMoveCell = this.findNearestAvailableCell(cell);
    if (!bestMoveCell) {
        return false;
    }

    this.startMoving(this.findWalkingPath([bestMoveCell]));

    return cc.pointEqualToPoint(cell, bestMoveCell);
};

GameWorker.prototype.getSkin = function () {
    return this.skin;
};

GameWorker.canMove = function (cell) {
    var map = Map2d.currentMap;

    if (!map.isGround(cell.x, cell.y) && !map.isWaterUnit(cell.x, cell.y) || map.getFog(cell.x, cell.y)) {
        return false;
    }

    var unit = map.getUnit(cell.x, cell.y);
    if (unit) {
        unit = unit.head || unit;

        if (unit.isGrounded() || unit.isStopsFog()) {
            return false;
        }

        var multicell = unit.findComponent(MultiCell);
        return multicell && multicell.canMoveThrough(cell.x, cell.y);
    }

    return true;
};

GameWorker.canWalk = function (cell) {
    var map = Map2d.currentMap;

    if (!map.isGround(cell.x, cell.y) && !map.isWaterUnit(cell.x, cell.y) || map.getFog(cell.x, cell.y)) {
        return false;
    }

    var unit = map.getUnit(cell.x, cell.y);
    if (unit) {
        unit = unit.head || unit;

        if (unit.isGrounded() || unit.isStopsFog()) {
            return false;
        }

        var multicell = unit.findComponent(MultiCell);
        if (multicell) {
            return multicell.canMoveThrough(cell.x, cell.y);
        }

        return true;
    }

    return true;
};