/**
 * Created by r4zi4l on 17.03.2021
 */

var InfoComponent = function (unit) {
    UnitComponent.call(this, unit);
};

InfoComponent.prototype = Object.create(UnitComponent.prototype);
InfoComponent.prototype.constructor = InfoComponent;

InfoComponent.prototype.getActionInfo = function () {
    var info = {
        title: cleverapps.unitsLibrary.getUnitName(this.unit)
    };

    var data = this.unit.getData().info;
    if (data.guide) {
        info.windowParameters = {
            unit: this.unit,
            guideOptions: data.guideOptions
        };
    }

    if (data.landmarkDonor) {
        info.windowParameters = {
            landmark: Merge.currentMerge.landmarks.findDonorLandmark(this.unit)
        };
    }

    if (data.description) {
        info.description = data.description;
    }

    if (data.season !== undefined) {
        if (!this.unit.isBuilt()) {
            return undefined;
        }
        if (Season.getCurrentSeason() === data.season) {
            info.windowParameters = {
                season: Merge.currentMerge.season
            };
        } else {
            info.buttons = {
                sell: { unit: this.unit }
            };
        }
    }

    return info;
};
