/**
 * Created by r4zi4l on 14.12.2021
 */

var Rudolf = function (unit, options) {
    UnitComponent.call(this, unit);

    this.progress = options.progress || 0;
    this.value = options.value || 0;
    this.time = options.time || 0;

    if (cleverapps.environment.isAdministratorScene()) {
        return;
    }

    this.data = Rudolf.DATA[cleverapps.meta.selectedLocationId()];
    if (!this.data) {
        return;
    }

    this.data.rewards.sort(function (a, b) {
        return a.value - b.value;
    });

    if (this.progress >= Rudolf.MAX_PROGRESS) {
        var leftTime = this.getTimeLeft();
        if (leftTime > 0) {
            this.timeout = new cleverapps.LongTimeout(this.complete.bind(this), leftTime);
        } else {
            this.complete();
        }
    }
};

Rudolf.prototype = Object.create(UnitComponent.prototype);
Rudolf.prototype.constructor = Rudolf;

Rudolf.prototype.removeView = function () {
    UnitComponent.prototype.removeView.call(this);

    this.animating = undefined;
};

Rudolf.prototype.onPrizeHarvested = function () {
    this.reset();
};

Rudolf.prototype.onInfoOpened = function () {
    this.onUpdateState();
};

Rudolf.prototype.onInfoClosed = function () {
    this.onUpdateState();
};

Rudolf.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    } 
};

Rudolf.prototype.onExchangeStart = function () {
    if (this.view) {
        this.view.startExchange();
    } 
};

Rudolf.prototype.onExchangeComplete = function () {
    if (this.view) {
        this.view.completeExchange();
    } 
};

Rudolf.prototype.onAcceptDraggable = function (unit) {
    if (this.view) {
        this.view.acceptDraggable(unit);
    } 
};

Rudolf.prototype.save = function (data) {
    data.progress = this.progress;
    data.value = this.value;
    data.time = this.time;
};

Rudolf.prototype.destructor = function () {
    clearTimeout(this.timeout);
};

Rudolf.prototype.getTimeLeft = function () {
    return Math.max(0, this.time + Rudolf.EXCNAGE_DURATION - Date.now());
};

Rudolf.prototype.reset = function () {
    clearTimeout(this.timeout);
    this.progress = 0;
    this.value = 0;
    this.time = 0;
    this.unit.save();
    this.onUpdateState();
};

Rudolf.prototype.start = function () {
    clearTimeout(this.timeout);
    this.time = Date.now();
    this.timeout = new cleverapps.LongTimeout(this.complete.bind(this), this.getTimeLeft());
    this.unit.save();
    this.onExchangeStart();
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.UNIT_VALUE + this.unit.code, { value: this.value });
};

Rudolf.prototype.handlePrizes = function () {
    if (this.isAnimating()) {
        this.setAnimating(false);
        this.onUpdateState();
    }

    if (this.feastPoints) {
        var points = this.feastPoints;
        Merge.currentMerge.counter.setTimeout(function () {
            Map2d.mapEvent(Map2d.RUDOLF_COMPLETED, { unit: this.unit, points: points });
        }.bind(this), 1000);
        this.feastPoints = 0;
    }
};

Rudolf.prototype.complete = function () {
    clearTimeout(this.timeout);

    var rewards = [], totalValue = this.value;
    while (rewards.length < this.data.maxRewards) {
        var last = 0;
        while (last < this.data.rewards.length && this.data.rewards[last].value <= totalValue) {
            last++;
        }
        if (last === 0) {
            break;
        }
        var first = last - 3;
        if (first < 0) {
            first = 0;
        }
        var reward = cleverapps.Random.choose(this.data.rewards.slice(first, last));
        totalValue -= reward.value;
        reward = reward.unit;

        if (reward.family) {
            var codes = cleverapps.unitsLibrary.listAvailableByType(reward.family);
            if (codes) {
                reward.code = cleverapps.Random.choose(codes);
            } else {
                reward.code = "coins";
            }
        }

        if (reward.chest) {
            codes = cleverapps.unitsLibrary.listAvailableByType(reward.chest);
            if (codes) {
                reward.code = cleverapps.Random.choose(codes) + "chest";

                if (!Families[reward.code]) {
                    reward.code = "coins";
                }
            } else {
                reward.code = "coins";
            }
        }

        if (reward.hero) {
            reward.code = cleverapps.unitsLibrary.getCurrentHero(reward.hero);

            if (!reward.code || reward.stage === Families[reward.code].units.length - 1) {
                reward.code = "coins";
            }
        }

        rewards.push(reward);
    }

    var exp = Math.floor(Math.max(1, this.value / 3));

    this.time = 0;
    this.unit.setPrizes(rewards, exp);
    this.feastPoints = this.value;

    this.onExchangeComplete();
};

Rudolf.prototype.canAcceptDraggable = function (unit) {
    return this.data.values[unit.code] && this.progress < Rudolf.MAX_PROGRESS;
};

Rudolf.prototype.acceptDraggable = function (unit) {
    this.progress += 1;
    this.value += this.data.values[unit.code][unit.stage];
    this.unit.save();
    this.onAcceptDraggable(unit);

    if (this.progress >= Rudolf.MAX_PROGRESS) {
        this.start();
    } else {
        this.onUpdateState();
    }
};

Rudolf.prototype.getActionInfo = function () {
    var info = {
        title: this.unit,
        windowParameters: {
            unit: this.unit,
            guideOptions: RudolfGuideOptions
        }
    };

    if (this.progress < Rudolf.MAX_PROGRESS) {
        info.progress = {
            total: Rudolf.MAX_PROGRESS,
            value: Rudolf.MAX_PROGRESS - this.progress
        };
        info.description = "Units." + this.unit.code + ".info";
        return info;
    } if (this.getTimeLeft()) {
        info.countdown = this.getTimeLeft();
        info.buttons = {
            speedup: {
                unit: this.unit,
                action: this.complete.bind(this),
                duration: this.getTimeLeft(),
                price: 99
            }
        };
        return info;
    }
};

Rudolf.prototype.getProgressPercentage = function () {
    return Math.floor(this.progress / Rudolf.MAX_PROGRESS * 100);
};

Rudolf.prototype.setAnimating = function (animating) {
    this.animating = animating;
};

Rudolf.prototype.isAnimating = function () {
    return this.animating;
};

Rudolf.EXCNAGE_DURATION = cleverapps.parseInterval("15 minutes");
Rudolf.MAX_PROGRESS = 3;

Rudolf.DATA = {};