/**
 * Created by ivan on 11.04.18.
 */

var DynamiteToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.DYNAMITE);

    this.onUpdateDragState = function () {};
};

DynamiteToolbarItem.prototype = Object.create(ToolbarItem.prototype);
DynamiteToolbarItem.prototype.constructor = DynamiteToolbarItem;

DynamiteToolbarItem.prototype.getPrice = function () {
    return "$$" + DynamiteToolbarItem.PRICE;
};

DynamiteToolbarItem.prototype.onFollowPointer = function (touch) {
    var map = Map2d.currentMap;
    var cell = map.getMapView().getCellByTouch(touch);

    if (!map.isValid(cell.x, cell.y) || this.invalidTouch(touch)) {
        this.clearHighlight();
        return;
    }

    var data = Families[DynamiteToolbarItem.UNIT.code].units[DynamiteToolbarItem.UNIT.stage];

    this.clearHighlight();
    this.highlightCells(cell, data.dynamite.radius);
};

DynamiteToolbarItem.prototype.dragAction = function (touch) {
    this.clearHighlight();

    var map = Map2d.currentMap;
    var mapView = map.getMapView();

    var cell = mapView.getCellByTouch(touch);

    if (!this.invalidTouch(touch) && this.canPlaceDynamite(cell) && cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.DYNAMITE, DynamiteToolbarItem.PRICE)) {
        cleverapps.focusManager.display({
            focus: "placeDynamite",
            control: ["toolbar", "MenuBarGoldItem"],
            action: function (f) {
                var unit = new Unit(DynamiteToolbarItem.UNIT);
                unit.setPosition(cell.x, cell.y);
                Map2d.currentMap.onAddUnit(unit.x, unit.y, unit);
                Map2d.currentMap.onUnitAvailable(unit);

                var dynamite = unit.findComponent(Dynamite);
                dynamite.explode(f);
            }
        });

        return true;
    }

    return false;
};

DynamiteToolbarItem.prototype.isVisible = function () {
    return cleverapps.meta.getSelectedLocation().withToolbarDynamite();
};

DynamiteToolbarItem.prototype.clearHighlight = function () {
    this.lastCell = undefined;

    if (this.highlights) {
        this.highlights.forEach(function (highlight) {
            highlight.remove();
        });
        this.highlights = undefined;
    }
};

DynamiteToolbarItem.prototype.highlightCells = function (cell, radius) {
    if (cc.pointEqualToPoint(this.lastCell, cell)) {
        return;
    }

    var map = Map2d.currentMap;

    this.lastCell = cell;
    this.highlights = [];

    var error = !this.canPlaceDynamite(cell);
    if (error) {
        var highlight = new Highlight(cell.x, cell.y, {
            isErrorLight: error
        });

        this.highlights.push(highlight);

        map.onAdd(Map2d.LAYER_UNITS, highlight.x, highlight.y, highlight);
        return;
    }

    var shape = Dynamite.calcExplosionShape(radius);
    var affected = [];

    shape.forEach(function (part) {
        var x = cell.x + part.x;
        var y = cell.y + part.y;

        if (!map.isGround(x, y) && !map.isWaterUnit(x, y) || map.getFog(x, y)) {
            return;
        }

        var unit = map.getUnit(x, y);
        if (unit) {
            if (unit.head) {
                unit = unit.head;
            }

            if (affected.indexOf(unit) !== -1) {
                return;
            }

            x = unit.x;
            y = unit.y;
            affected.push(unit);
        }

        var highlight = new Highlight(x, y, {
            isErrorLight: Dynamite.canExplodeUnit(unit),
            unit: unit
        });

        this.highlights.push(highlight);

        map.onAdd(Map2d.LAYER_UNITS, highlight.x, highlight.y, highlight);
    }.bind(this));
};

DynamiteToolbarItem.prototype.canPlaceDynamite = function (cell) {
    var map = Map2d.currentMap;
    return map.isGround(cell.x, cell.y) && !map.getFog(cell.x, cell.y) && !map.getUnit(cell.x, cell.y);
};

DynamiteToolbarItem.prototype.invalidTouch = function (touch) {
    var toolbarBg = cleverapps.toolbar.onGetView().bg;
    return toolbarBg.hasTouch(touch);
};

DynamiteToolbarItem.PRICE = 99;
DynamiteToolbarItem.UNIT = { code: "advdynamite", stage: 0 };
