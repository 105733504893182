/**
 * Created by mac on 12/23/20
 */

var ViewFactory = function (component, unitView) {
    var componentView;
    var x = unitView.width / 2, y = unitView.height / 2;
    var zOrder = 0;

    if (component instanceof Fruit) {
        componentView = new FruitView(component);
    } else if (component instanceof Shine) {
        ShineView(unitView);
    } else if (component instanceof Mineable) {
        componentView = new MineableView(component, unitView);
    } else if ((component instanceof Buildable || component instanceof Creatable) && !component.isCompleted()) {
        componentView = new BuildableView(component, unitView);
    } else if (component instanceof Collectible) {
        componentView = new CollectibleView(component, unitView);
    } else if (component instanceof ResourceCollectible) {
        componentView = new ResourceCollectibleView(component, unitView);
    } else if (component instanceof Pulsing) {
        componentView = new PulsingView(component, unitView);
    } else if (component instanceof Monster) {
        componentView = new MonsterView(component, unitView);
    } else if (component instanceof Chest) {
        componentView = new ChestView(component, unitView);
    } else if (component instanceof GeneratorComponent) {
        componentView = new GeneratorView(component, unitView);
    } else if (component instanceof Climbable) {
        componentView = new ClimbableView(component, unitView);
    } else if (component instanceof MiniGame) {
        componentView = new MiniGameView(component);
    } else if (component instanceof Upgradable) {
        componentView = new UpgradableView(component, unitView);
        zOrder = 1;
    } else if (component instanceof Grounded) {
        componentView = new GroundedView(component, unitView);
        x = unitView.width * unitView.anchorX;
        y = unitView.height * unitView.anchorY;
        zOrder = -1;
    } else if (component instanceof MultiCell) {
        MultiCellView(component.unit, unitView);
    } else if (component instanceof InstantWorker) {
        componentView = new InstantWorkerView(component, unitView);
    } else if (component instanceof ThirdElement) {
        componentView = new ThirdElementView(component, unitView);
    } else if (component instanceof Tank) {
        componentView = new TankView(component, unitView);
    } else if (component instanceof Barrel) {
        componentView = new BarrelView(component, unitView);
    } else if (component instanceof Ruins) {
        componentView = new RuinsView(component, unitView);
    } else if (component instanceof LockedComponent) {
        componentView = new LockedComponentView(component, unitView);
    } else if (component instanceof MissionTree) {
        componentView = new MissionTreeView(component, unitView);
    } else if (component instanceof Rudolf) {
        componentView = new RudolfView(component, unitView);
    } else if (component instanceof Improver) {
        componentView = new ImproverView(component, unitView);
    } else if (component instanceof Portal) {
        componentView = new PortalView(component, unitView);
    } else if (component instanceof ToyFactory) {
        componentView = new ToyFactoryView(component, unitView);
    } else if (component instanceof Draggable) {
        componentView = new DraggableView(component, unitView);
    } else if (component instanceof MergeComponent) {
        componentView = new MergeComponentView(component, unitView);
    } else if (component instanceof FieldComponent) {
        componentView = new FieldComponentView(component, unitView);
    } else if (component instanceof CupComponent) {
        componentView = new CupComponentView(component, unitView);
    } else if (component instanceof Ship) {
        componentView = new ShipView(component, unitView);
    } else if (component instanceof Bank) {
        componentView = new BankView(component, unitView);
    } else if (component instanceof UnitsShopComponent) {
        componentView = new UnitsShopComponentView(component, unitView);
    } else if (component instanceof Customer) {
        componentView = new CustomerView(component, unitView);
    } else if (component instanceof Landmark || component instanceof LandmarkSpot) {
        componentView = new LandmarkView(component, unitView);
    } else if (component instanceof Feedable) {
        componentView = new FeedableView(component, unitView);
    } else if (component instanceof Dynamite) {
        componentView = new DynamiteView(component, unitView);
    } else if (component instanceof Throwaway) {
        componentView = new ThrowawayView(component, unitView);
    } else if (component instanceof Switchable) {
        componentView = new SwitchableView(component, unitView);
    } else if (component instanceof HighLight) {
        componentView = new HighLightView(component, unitView);
    } else if (component instanceof CompleteLocationComponent) {
        componentView = new CompleteLocationComponentView(component, unitView);
    } else if (component instanceof Invisible) {
        componentView = new InvisibleView(component, unitView);
    } else if (component instanceof Fixable) {
        componentView = new FixableView(component, unitView);
    } else if (component instanceof HeroTutorial) {
        componentView = new HeroTutorialView(component, unitView);
    }

    if (componentView instanceof cc.Node) {
        componentView.setPositionRound(x, y);
        componentView.setAnchorPoint(0.5, 0.5);
        unitView.addChild(componentView, zOrder);
    }

    if (componentView) {
        component.setView(componentView);
    }
};