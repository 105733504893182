/**
 * Created by spepa on 26.01.2024
 */

var LandmarkPreview = function (unit) {
    UnitComponent.call(this, unit);
};

LandmarkPreview.prototype = Object.create(UnitComponent.prototype);
LandmarkPreview.prototype.constructor = LandmarkPreview;

LandmarkPreview.prototype.handleClick = function () {
    var landmark = Merge.currentMerge.landmarks.listActiveLandmarks().filter(function (landmark) {
        return landmark.stage === this.unit.stage;
    }.bind(this))[0];

    if (landmark) {
        this.unit.squeeze();

        cleverapps.focusManager.display({
            focus: "LandmarkPreview",
            action: function (f) {
                new LandMarkDonorWindow(landmark);
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
    }
    return true;
};