/**
 * Created by mac on 12/23/20
 */

var GroundedView = cc.Sprite.extend({
    ctor: function (grounded) {
        this.grounded = grounded;
        this._super(GroundedView.getImage(grounded.unit.x, grounded.unit.y));
    },

    onRemove: function (silent) {
        if (silent) {
            this.removeFromParent();
        } else {
            this.runAction(
                new cc.Sequence(
                    new cc.FadeOut(0.5),
                    new cc.RemoveSelf()
                )
            );
        }
    },

    highlight: function () {
        this.light = new Highlight(this.grounded.unit.x, this.grounded.unit.y, {
            unit: this.grounded.unit
        });
        var highlightView = new HighlightView(this.light);
        this.addChild(highlightView, 1);
        highlightView.setPositionRound(this.width / 2, this.height / 2);
    },

    unhighlight: function () {
        if (this.light) {
            this.light.remove();
            delete this.light;
        }
    }
});

GroundedView.getImage = function (x, y) {
    var terrain = Map2d.currentMap.getTerrainCode(x, y);
    var tiles = Merge.currentMerge.level.meta.tiles;
    return bundles["grounded_" + tiles].frames["grounded_" + Map2d.TERRAINS[terrain]];
};