/**
 * Created by vladislav on 04/01/22
 */

var CupComponent = function (unit, options) {
    UnitComponent.call(this, unit);

    var data = unit.getData();

    this.missionType = data.cup.mission !== undefined ? Mission.CompoundTypeFromLocationId(data.cup.mission, cleverapps.meta.selectedLocationId()) : undefined;
    this.amount = options.amount === undefined ? data.cup.amount : options.amount;

    this.fixedAmount = (options.fa === undefined ? data.cup.fixedAmount : options.fa) || 0;

    if (this.isEmpty()) {
        setTimeout(this.remove.bind(this, true), 0);
    }
};

CupComponent.prototype = Object.create(UnitComponent.prototype);
CupComponent.prototype.constructor = CupComponent;

CupComponent.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

CupComponent.prototype.save = function (data) {
    data.amount = this.amount;

    if (this.unit.getData().cup.fixedAmount) {
        data.fa = this.fixedAmount;
    }
};

CupComponent.prototype.handleClick = function () {
    this.unit.squeeze();

    if (this.missionType && !cleverapps.missionManager.findRunningMission(this.missionType)) {
        cleverapps.centerHint.createTextHint("MissionNotStarted." + Missions[this.missionType].name);
    } else {
        this.spendCharge();
    }

    return true;
};

CupComponent.prototype.pickRandomUnit = function (data) {
    var units = Prizes.Generate([data], {
        listOnly: true
    });
    return units[cleverapps.Random.pickOne(data.probabilities)];
};

CupComponent.prototype.remove = function (silent) {
    if (silent) {
        this.unit.onRemove();
    } else {
        this.unit.onDestruction();
    }
    this.unit.remove(true);
};

CupComponent.prototype.getFixedCupData = function () {
    var data = cleverapps.clone(this.unit.getData().cup, true);

    var units = [];
    var probabilities = [];
    var sum = 0;

    for (var i = 0; i < data.units.length; i++) {
        if (data.fixed.includes(data.units[i].code)) {
            sum += data.probabilities[i];
            probabilities.push(data.probabilities[i]);
            units.push(data.units[i]);
        }
    }
    probabilities = probabilities.map(function (probability) {
        return probability / sum;
    });

    data.units = units;
    data.probabilities = probabilities;

    return data;
};

CupComponent.prototype.spendCharge = function () {
    if (!this.isEmpty()) {
        var data = this.unit.getData().cup;
        if (this.amount <= this.fixedAmount) {
            data = this.getFixedCupData();
        }
        var unit = this.pickRandomUnit(data);

        var remains = Merge.currentMerge.spawn(unit, this.unit, { radius: Map2d.RADIUS_SMALL });
        if (remains.length > 0) {
            return;
        }

        if (this.fixedAmount && this.unit.getData().cup.fixed.includes(unit.code)) {
            this.fixedAmount--;
        }

        this.amount--;

        this.unit.save();

        if (this.isEmpty()) {
            this.remove();
        }

        this.onUpdateState();
    }
};

CupComponent.prototype.isFull = function () {
    return this.amount >= this.unit.getData().cup.amount;
};

CupComponent.prototype.isEmpty = function () {
    return this.amount < 1;
};

CupComponent.CanMerge = function (unit1, unit2) {
    var cup1 = unit1.findComponent(CupComponent);

    if (!cup1) {
        return true;
    }

    var cup2 = unit2.findComponent(CupComponent);

    if (!cup2) {
        return true;
    }

    return cup1.isFull() && cup2.isFull();
};
