/**
 * Created by iamso on 20.12.2021
 */

var ExpeditionFeastWindow = FeastMissionWindow.extend({
    onWindowLoaded: function (mission) {
        this._super({
            mission: mission,
            name: "ExpeditionFeastWindow",
            bundleName: cleverapps.skins.getSlot("expeditionFeastBundle").getBundleName()
        });
    },

    getPerson: function () {
        return "king";
    },

    getGuideOptions: function () {
        return ExpeditionFeastGuideOptions;
    },

    createTable: function () {
        var table = this._super();

        var bundle = bundles[this.bundleName];
        if (bundle && bundle.jsons.win_decors) {
            var decor = new cleverapps.Spine(bundle.jsons.win_decors);
            decor.setAnimation(0, "animation", true);
            table.addChild(decor, 1);
            decor.setPositionRound(cleverapps.styles.ExpeditionFeastWindow.decor);
        }

        return table;
    },

    listBundles: function () {
        return [cleverapps.skins.getSlot("expeditionFeastBundle").getBundleName()];
    }
});

cleverapps.styles.ExpeditionFeastWindow = {
    decor: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -10 }
    }
};
