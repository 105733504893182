/**
 * Created by andrey on 01.10.2024
 */

Map2dFences.prototype.add = function (x, y, regionName) {
    cleverapps.BorderRenderer.place(
        Map2d.currentMap.regions[regionName].positions,
        this.imagePlacement.bind(this),
        {
            regionName: regionName,
            part: [{ x: x, y: y }]
        }
    );
    Map2d.currentMap.showTiles(x, y);

    this.updateNeighbours(x, y, regionName);
};

Map2dFences.prototype.remove = function (x, y, regionName) {
    this.clearFences(x, y, regionName);
    this.updateNeighbours(x, y, regionName);
};

Map2dFences.prototype.updateNeighbours = function (x, y, regionName) {
    var self = this;
    var dirs = cleverapps.BorderRenderer.DIRS;
    var diagonalDirs = cleverapps.BorderRenderer.DIAGONAL_DIRS;

    var neighbours = [];

    var allDirs = Object.values(dirs).concat(Object.values(diagonalDirs));

    allDirs.forEach(function (dir) {
        var nx = x + dir.x;
        var ny = y + dir.y;
        if (self.getFences(nx, ny, regionName)) {
            neighbours.push({ x: nx, y: ny });
        }
    });

    neighbours.forEach(function (neighbour) {
        self.clearFences(neighbour.x, neighbour.y, regionName);
    });

    cleverapps.BorderRenderer.place(
        Map2d.currentMap.regions[regionName].positions,
        self.imagePlacement.bind(self),
        {
            regionName: regionName,
            part: neighbours
        }
    );

    neighbours.forEach(function (neighbour) {
        Map2d.currentMap.showTiles(neighbour.x, neighbour.y);
    });
};

Map2dFences.prototype.getFences = function (x, y, regionName) {
    return this.fences[regionName]
    && this.fences[regionName][x]
    && this.fences[regionName][x][y]
        ? this.fences[regionName][x][y]
        : undefined;
};

Map2dFences.prototype.clearFences = function (x, y, regionName) {
    var fences = this.getFences(x, y, regionName);
    if (fences) {
        Map2d.currentMap.removeTiles(x, y);
        fences.forEach(function (fence) {
            Map2d.currentMap.decorators.removeDecorator(fence.x, fence.y, fence);
        });
        delete this.fences[regionName][x][y];
    }
};
