/**
 * Created by razial on 14.01.2025.
 */

var MergeMetaAdapter = function () {

};

MergeMetaAdapter.prototype.load = function () {

};

MergeMetaAdapter.prototype.save = function () {

};

MergeMetaAdapter.prototype.updateInfo = function () {

};

MergeMetaAdapter.prototype.getInfo = function () {
    return {};
};

MergeMetaAdapter.prototype.loadLocation = function () {
    return {};
};

MergeMetaAdapter.prototype.saveLocation = function () {

};

MergeMetaAdapter.prototype.reset = function () {
    cleverapps.meta.logic.locations = {};

    cleverapps.meta.logic.listAvailableLocations().forEach(function (locationId) {
        var page = MergeLogic.findPageById(locationId);
        if (page.missionType === undefined && cleverapps.user.checkAvailable(page.available)) {
            cleverapps.meta.logic.addLocation(page.id, page.slot);
        }
    });
};

MergeMetaAdapter.prototype.addLocation = function (locationId, mission) {
    cleverapps.meta.logic.addLocation(locationId, mission.slot, mission.started);
};

MergeMetaAdapter.prototype.setLastRemovedLocation = function (locationId, finishDate) {
    cleverapps.meta.logic.removeLocation(locationId, finishDate);
};

MergeMetaAdapter.prototype.setOldLocationData = function (locationId, finishDate) {
    if (!cleverapps.meta.logic.locations[locationId]) {
        cleverapps.meta.logic.removeLocation(locationId, finishDate);
    }
};