/**
 * Created by Andrey Popov on 29.11.22
 */

var Campaign = function (unit) {
    UnitComponent.call(this, unit);

    if (cleverapps.environment.isAdministratorScene()) {
        return;
    }

    if (unit.stage < Families[unit.code].units.length - 1) {
        this.addQuest();
    }
};

Campaign.prototype = Object.create(UnitComponent.prototype);
Campaign.prototype.constructor = Campaign;

Campaign.prototype.addQuest = function () {
    var id = Unit.GetKey(this.unit);

    var questsData = bundles["quests_" + Merge.currentMerge.level.meta.units].jsons.quests_json.resolve().getJson();

    var questTemplate = questsData[id];

    if (!questTemplate) {
        return;
    }

    var questConfig = {
        trigger: {
            unit: { code: this.unit.code, stage: this.unit.stage }
        },
        campaign: true,
        quest: {
            id: Unit.GetKey(this.unit),
            type: Map2d.SPAWN,
            unit: { code: this.unit.code, stage: this.unit.stage + 1 }
        }
    };
    Quests.appendJsonDataToQuest(questConfig, questTemplate);
    QuestsConfig[cleverapps.meta.selectedLocationId()][Unit.GetKey(this.unit)] = questConfig;
};

Campaign.prototype.handleRemove = function () {
    delete QuestsConfig[cleverapps.meta.selectedLocationId()][Unit.GetKey(this.unit)];
};

Campaign.getCampaignQuestIcon = function (unit) {
    var recipes = CustomerRecipes[cleverapps.meta.selectedLocationId()][Unit.GetKey({
        code: unit.code,
        stage: unit.stage - 1
    })];
    var recipe = recipes && recipes[0];
    if (recipe && ["rptrolley", "rpcrane", "rppress"].indexOf(unit.code) === -1) {
        var ingredient = recipe.ingredients.filter(function (ingredient) {
            return ingredient.type === "campaignorder";
        })[0];
        if (ingredient) {
            unit = {
                code: cleverapps.unitsLibrary.listCodesByType(ingredient.type)[0],
                stage: ingredient.stage
            };
        }
    }

    var icon = cleverapps.skins.getSlot("campaignQuestIcon", unit);
    if (icon) {
        return icon;
    }

    return UnitView.getUnitImage(unit, { preferStatic: true });
};