/**
 * Created by Vladislav on 25.09.2024.
 */

var WandsMenuBarItem = {
    name: "WandsItem",
    plusButton: false,
    targets: "wands",
    value: function () {
        return Merge.currentMerge.wands;
    },
    updater: function (f) {
        Merge.currentMerge.onChangeWandsListener = f;
    },
    onClickWindow: GuideWindow,
    onClickOptions: function () {
        var location = cleverapps.meta.getSelectedLocation();
        return location.isExpedition() ? ExpeditionWandsGuideOptions : {
            name: "WandsGuideWindow",
            bundle: bundles.wandsguidewindow
        };
    },
    icon: function () {
        return cleverapps.skins.getSlot("merge_wand_png") || bundles.menubar.frames.merge_wand_png;
    },
    incSound: bundles.menubar.urls.wand_fly_finish_effect,
    decSound: bundles.menubar.urls.wand_fly_finish_effect,
    availableOnScenes: function () {
        var location = cleverapps.meta.getSelectedLocation();
        if (location.withWands()) {
            return [cleverapps.Environment.SCENE_MAIN];
        }
    },
    sparks: true
};