/**
 * Created by slava on 4/3/20
 */

var MergeScene = MainScene.extend({
    ctor: function (options) {
        options = options || {};

        if (!options.level) {
            options.level = new Level(cleverapps.meta.selectedLocationId(), 0);
        }

        this._super(options);
    },

    destructor: function () {
        if (typeof Merge !== "undefined") {
            Merge.currentMerge.stop();
            Merge.currentMerge = undefined;
        }

        this._super();
    },

    onSceneLoaded: function () {
        cleverapps.environment.levelNo = this.level.levelNo;
        cleverapps.environment.episodeNo = this.level.episodeNo;
        cleverapps.environment.setScene(cleverapps.Environment.SCENE_MAIN);

        this.game = new Merge(this.level);

        this._super();

        this.createMap();

        this.game.onShowMergeBonus = this.createListener(this.showMergeBonus.bind(this));

        this.addTutorial();

        this.advice = new MergeAdviceView(this.game.advice, this);

        cleverapps.gameLevel.on("levelUp", this.onLevelUp.bind(this), this);

        if (this.styling.surfaceAnimation) {
            var surfaceAnimation = new cleverapps.Spine(this.styling.surfaceAnimation);
            surfaceAnimation.setPositionRound({ align: "center" }, { align: "center" });
            surfaceAnimation.setAnimation(0, "animation", true);
            this.getCloudsAboveLayer().addChild(surfaceAnimation);
        }

        if (this.styling.topAnimation) {
            this.background.topAnimation = new cleverapps.Spine(this.styling.topAnimation);
            this.background.topAnimation.setAnimation(0, "animation", true);
            this.background.topAnimation._hideInSceneDebugger = true;
            this.getAnimationsLayer().addChild(this.background.topAnimation);
        }

        if (this.game.pixelsPlanner) {
            var pixelsPlannerView = new PixelsPlannerView(this.game.pixelsPlanner);
            this.getAnimationsLayer().addChild(pixelsPlannerView);
        }

        if (this.game.friendPixelsPlanner) {
            var friendPixelsPlannerView = new PixelsPlannerView(this.game.friendPixelsPlanner);
            this.getAnimationsLayer().addChild(friendPixelsPlannerView);
        }

        if (this.game.pocket) {
            this.game.pocket.onAddBubble = this.createPocketBubbleView.bind(this);
            this.game.pocket.bubbles.forEach(function (bubble) {
                this.createPocketBubbleView(bubble);
            }, this);
        }

        var orangery = Map2d.currentMap.orangery;
        if (orangery && !cleverapps.gameModes.noControls && !cleverapps.config.wysiwygMode) {
            var orangeryView = new OrangeryView(orangery);
            this.addChild(orangeryView);

            cleverapps.focusManager.registerControl(orangery.getControlName(), new HidingNode(orangeryView, cleverapps.UI.VERTICAL));
        }

        if (cleverapps.gameModes.axemerge) {
            // as after education stage
            var fogBlocks = this.game.map.fogs.blocks;
            if (fogBlocks.fog0) {
                fogBlocks.fog0.unlock();
            }
            if (fogBlocks.fog1) {
                fogBlocks.fog1.unlock();
            }
        }

        if (cleverapps.git) {
            cleverapps.git.changeFolders([GitManager.FOLDERS.UNIT_ANCHORS]);
        }

        var roomToneEffect = cleverapps.skins.getSlot("roomToneEffect");
        if (roomToneEffect) {
            this.runAction(new cc.RepeatForever(new cc.Sequence(
                new cc.DelayTime(5),
                new cc.PlaySound(roomToneEffect),
                new cc.DelayTime(5)
            )));
        }
    },

    createMap: function () {
        this.innerMap = new Map2dInnerView(this.game.map);
        this.map = this.innerMap.wrapWithScroll(this.mapPositionChanged.bind(this));
        this.addChild(this.map);
        this.setInitialScroll(this.game.map.getFocusCell() || this.game.map.getMapCenterCell());
        this.updateSize();
    },

    setInitialScroll: function (cell) {
        var scrollCell = this.innerMap.getCell(cell.x, cell.y);
        scrollCell = scrollCell ? this.map.normalizePoint(this.map.targetToPoint(scrollCell)) : this.map.getContainerPosition();

        this.map.scrollToPoint(scrollCell);
    },

    playIntro: function (f, silent) {
        var actions = this.game.listIntroActions();

        if (silent) {
            actions = actions.map(function (action) {
                return function (f) {
                    action(f, silent);
                };
            });
        }

        this.game.introPlaying = true;

        cleverapps.focusManager.compound(function () {
            this.game.introPlaying = false;
            f();
        }.bind(this), actions);
    },

    updateSize: function () {
        this._super();

        if (this.map) {
            var bgSize = cleverapps.resolution.getBgSize();
            var safePadding = cleverapps.resolution.getSafePadding();
            this.map.setContentSize(bgSize.width, bgSize.height);
            this.map.setPositionRound(bgSize.width / 2 - safePadding.left, bgSize.height / 2 - safePadding.bottom);

            this.map.zoomHandler.setZoom(this.map.zoom, true);
        }
    },

    getAnimationsLayer: function () {
        return this.innerMap.animations;
    },

    getCloudsAboveLayer: function () {
        return this.innerMap.cloudsAbove;
    },

    getBackgroundStyles: function () {
        return cleverapps.skins.getSlot("gameSceneBg") || GameSceneBase.ChooseStyling(this.level);
    },

    createPocketBubbleView: function (bubble) {
        var pocketBubbleView = new PocketBubbleView(bubble, this.map.getVisibleRect());
        this.getAnimationsLayer().addChild(pocketBubbleView);
    },

    showMergeBonus: function (units) {
        var styles = cleverapps.styles.MergeScene.mergeBonus;

        var position = cc.getCenter(units.map(function (unit) {
            return this.game.map.getMapView().alignInGrid(unit.x, unit.y);
        }, this));

        var animation = new cleverapps.Spine(bundles.merge_animations.jsons.bonus_shining_json);
        animation.setAnchorPoint(0.5, 0.5);
        animation.setPositionRound(position.x + styles.x, position.y + styles.y);
        animation.setAnimation(0, "animation", false);
        animation.setCompleteListenerRemove();
        this.getAnimationsLayer().addChild(animation);
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }
        this._super();
    },

    onLevelUp: function () {
        cleverapps.focusManager.display({
            focus: "onLevelUp",
            control: ["MenuBarGameLevelItem"],
            actions: [
                function (f) {
                    new LevelUpWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                },

                function (f) {
                    f();

                    Map2d.currentMap.fogs.calcFogStates();
                },

                function (f) {
                    var fakeUnit = Map2d.currentMap.fogs.findFakeUnit({ code: "warcraft" });

                    if (!cleverapps.forces.isShown(Forces.FOG_EXPLORE.id) && fakeUnit && cleverapps.user.level >= 17) {
                        Map2d.currentMap.exploreArea(fakeUnit, f);

                        cleverapps.forces.saveForce(Forces.FOG_EXPLORE.id);
                    } else {
                        f();
                    }
                },

                function (f) {
                    if (cleverapps.platformLogic.reportLevelUp) {
                        cleverapps.platformLogic.reportLevelUp(f);
                    } else {
                        f();
                    }
                }
            ]
        });
    },

    makeNodeVisible: function (target, options) {
        options = options || {};

        var currentZoom = Map2dScroller.currentScroller.getScrollZoom();

        // SCROLL SPEED options.speed
        this.map.runAction(new cc.Sequence(
            new cc.ScrollAction(target, {
                skipFocusReport: true,
                duration: 1,
                visibleBox: {
                    left: 0.74,
                    right: 0.74,
                    top: 1 / (currentZoom * 1.87),
                    bottom: 0.8
                }
            }),
            new cc.CallFunc(options.callback || function () {})
        ));
    },

    addTutorial: function () {
        var mergeTutorialView = new MergeTutorialView(this.game.tutorial);
        mergeTutorialView.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(mergeTutorialView);
    },

    completeAnimationOnResize: function () {
        this._super.apply(this);
        this.mapPositionChanged();
    },

    mapPositionChanged: function () {
        if (!this.map) {
            return;
        }

        var styles = this.styling;

        Merge.currentMerge.sidebarQuests.hideInfo();

        if (!styles.parallax || !this.background) {
            return;
        }

        var safePadding = cleverapps.resolution.getSafePadding();
        var bgSize = cleverapps.resolution.getBgSize();

        var scale = this.map.zoom - this.map.zoomHandler.minZoom;
        var normalizedScale = Math.max(1.1 * bgSize.height / this.background.height, 1.25 + MergeScene.PARALLAX_FACTOR * scale)
            + 0.001 * (safePadding.bottom + safePadding.top);
        this.background.setScale(normalizedScale);

        var scroll = this.map.getCurrentScroll();
        var normalizedScroll = {
            x: 2 * (scroll.x - 0.5),
            y: 2 * (scroll.y - 0.5)
        };

        var x = this.width / 2 + MergeScene.PARALLAX_FACTOR * this.width * normalizedScroll.x * (1 + scale);
        var y = this.height / 2 + MergeScene.PARALLAX_FACTOR * this.height * normalizedScroll.y * (1 + scale);

        if (styles.parallax.hideHorizon) {
            x = this.width * (0.5 + 0.1 * normalizedScroll.x);
            y = this.height * (1.2 + 0.9 * normalizedScroll.y);
        }

        this.background.setPositionRound(x - safePadding.left, y - safePadding.bottom / 2);

        if (styles.topAnimation && this.background.topAnimation) {
            this.background.topAnimation.setPositionRound(this.map.getCurrentPosition().x + safePadding.left, this.map.getCurrentPosition().y + safePadding.bottom);
            this.background.topAnimation.setScale(Math.max(bgSize.height / this.background.topAnimation.height, 1.05 / this.map.zoom));
        }
    },

    listBundles: function () {
        var result = NoneMainGameScene.prototype.listBundles.apply(this, arguments);

        result.push(Map2d.getTilesTexture(this.level.meta.tiles));
        result.push(Map2d.getUnitsTexture(this.level.meta.units));
        result.push("location_" + this.level.meta.location);

        if (cleverapps.gameModes.axemerge) {
            result.push("axemerge");
        }

        result.push("quests_" + this.level.meta.units);
        result.push("unitstories_" + this.level.meta.expedition);

        return result;
    },

    cachedBundles: function () {
        return ["levelupwindow", "startquest", "finishquest"];
    },

    lazyBundles: function () {
        var result = [
            "pixeloffer"
        ];

        if (bundles["pixels_" + this.level.meta.units]) {
            result.push("pixels_" + this.level.meta.units);
        } else {
            result.push("pixels_main");
        }

        result = result.concat(Families.listLazyBundles(Object.keys(this.level.families)));
        return result;
    },

    animateZoom: function (zoom, duration, f) {
        zoom = this.map.zoomHandler.normalizeZoom(zoom);

        this.map.runAction(new cc.Sequence(
            new cc.ZoomAction(duration, {
                zoom: zoom
            }),
            new cc.CallFunc(f)
        ));

        this.mapPositionChanged();
    },

    getAudioStyles: function () {
        return {
            res: cleverapps.skins.getSlot("gameAudio") || this.styling.music,
            fadeIn: cleverapps.styles.GameScene.sound.fadeIn,
            delay: cleverapps.config.wysiwygMode ? 0 : cleverapps.styles.GameScene.sound.delay
        };
    }
});

var GameScene = MergeScene;
MergeScene.PARALLAX_FACTOR = 0.08;
SideBarView.ZORDER = -1;

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SCENE_ANIMATE_DELTA_TEXT: {
        size: 52,
        color: cleverapps.styles.FONTS.LIGHT_TEXT_COLOR
    }
});

cleverapps.styles.MergeScene = {
    mergeBonus: {
        x: 0,
        y: 30
    }
};
