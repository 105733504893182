/**
 * Created by razial on 12.10.2021
 */

var Tank = function (unit, options) {
    UnitComponent.call(this, unit);

    this.lastHarvested = options.lastHarvested || 0;
    this.amount = options.amount || 0;

    this.data = unit.getData().tank;

    if (Merge.currentMerge.tutorial.isActive()
        && Merge.currentMerge.tutorial.step.tankAmount === "beginner") {
        this.amount = Tank.BEGINNER_AMOUNT;
        this.lastHarvested = Date.now();
    } else {
        this.updateAmount();
    }

    this.waitForMakeReady();
};

Tank.prototype = Object.create(UnitComponent.prototype);
Tank.prototype.constructor = Tank;

Tank.prototype.onChangeAmount = function () {
    if (this.view) {
        this.view.restoreState(true);
    } 
};

Tank.prototype.onPrizeHarvested = function () {
    this.updateAmount();

    if (this.amount > 0) {
        if (this.amount === this.data.maxGrow) {
            this.lastHarvested = Date.now();
        }
        this.amount--;
    }

    this.onChangeAmount();
    clearTimeout(this.timeout);
    this.timeout = new cleverapps.LongTimeout(this.waitForMakeReady.bind(this), Tank.PRIZE_VIEW_DELAY);
};

Tank.prototype.waitForMakeReady = function () {
    if (this.amount <= 0) {
        this.unit.setPrizes({ waiting: true });
    }
    clearTimeout(this.timeout);
    this.timeout = new cleverapps.LongTimeout(this.makeReady.bind(this), this.getTimeLeft());
};

Tank.prototype.handlePrizes = function () {
    if (this.unit.prizes.waiting) {
        this.unit.squeeze();

        cleverapps.focusManager.display({
            stack: Merge.currentMerge.tutorial.isActive() && Merge.currentMerge.tutorial.step.type === Map2d.REFILL,
            focus: "refillTankWindow",
            action: function (f) {
                new RefillTankWindow({
                    tank: this
                });
                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this)
        });
        
        return true;
    }

    return false;
};

Tank.prototype.updateAmount = function () {
    while (this.amount < this.data.maxGrow && this.lastHarvested + this.getGrowTime() < Date.now()) {
        this.amount++;
        this.lastHarvested += this.getGrowTime();
    }

    if (this.lastHarvested > Date.now()) {
        this.lastHarvested = Date.now();
    }

    var timeout;
    if (this.amount < this.data.maxGrow) {
        var ripeTime = this.lastHarvested + this.getGrowTime() * (this.data.maxGrow - this.amount);

        timeout = ripeTime - Date.now();

        cleverapps.travelBook.getCurrentPage().planEvent(ExpeditionPage.EVENT_KEYS, ripeTime);
        cleverapps.travelBook.getCurrentPage().setAttentionEvent(ExpeditionPage.EVENT_KEYS);
    } else {
        cleverapps.travelBook.getCurrentPage().planEvent(ExpeditionPage.EVENT_KEYS, Date.now());
    }

    cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES.PUMPKIN_RIPE, timeout);
};

Tank.prototype.save = function (data) {
    data.lastHarvested = this.lastHarvested;
    data.amount = this.amount;
};

Tank.prototype.destructor = function () {
    clearTimeout(this.timeout);
};

Tank.prototype.refreshAmount = function () {
    this.updateAmount();
    this.onChangeAmount();
    this.timeout = new cleverapps.LongTimeout(this.refreshAmount.bind(this), this.getGrowTime());
};

Tank.prototype.getRefillPrice = function () {
    return Merge.currentMerge.tutorial.isActive() && Merge.currentMerge.tutorial.step.type === Map2d.REFILL ? 0 : this.data.refillPrice;
};

Tank.prototype.makeReady = function () {
    clearTimeout(this.timeout);

    this.updateAmount();
    this.onChangeAmount();

    this.unit.setPrizes(this.data.prize, Tank.HARVESTED_EXP);
    this.timeout = new cleverapps.LongTimeout(this.refreshAmount.bind(this), this.getGrowTime());
};

Tank.prototype.refill = function () {
    if (Merge.currentMerge.tutorial.isActive()
        && Merge.currentMerge.tutorial.step.type === Map2d.REFILL
        && Merge.currentMerge.tutorial.step.tankAmount === "refill") {
        this.amount = Tank.REFILL_AMOUNT;
        this.lastHarvested = Date.now();
        Map2d.mapEvent(Map2d.REFILL, { affected: this.unit });
    } else {
        this.lastHarvested = 0;
        this.amount = 0;
        this.updateAmount();
    }
    this.unit.save();
    this.onChangeAmount();

    this.waitForMakeReady();
};

Tank.prototype.getTimeLeft = function () {
    if (this.amount > 0) {
        return 0;
    }
    return Math.max(0, this.lastHarvested + this.getGrowTime() - Date.now());
};

Tank.prototype.getGrowTime = function () {
    return cleverapps.parseInterval(this.data.growTime);
};

Tank.HARVESTED_EXP = 1;
Tank.BEGINNER_AMOUNT = 2;
Tank.REFILL_AMOUNT = 3;

Tank.PRIZE_VIEW_DELAY = 1000;