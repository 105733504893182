/**
 * Created by razial on 02.01.2021
 */

var OpenChestWindow = CleverappsWindow.extend({
    onWindowLoaded: function (chest) {
        this.chest = chest;

        this.showAdOffer = Merge.currentMerge.isMainGame() && !this.chest.extraPrizes && this.getPrize() && cleverapps.adsLimits.state(AdsLimits.TYPES.OPEN_CHEST) === AdsLimits.STATE_READY;

        this._super({
            title: cleverapps.unitsLibrary.getUnitName(chest.unit),
            name: "OpenChestWindow",
            content: this.createContent(),
            closeButton: true,
            styles: {
                padding: cleverapps.styles.OpenChestWindow.padding
            },
            help: function () {
                new ChestPrizeProbWindow(chest.unit, Chest.ListPrizeProb(chest.unit));
            }
        });
    },

    beforeCloseAnimation: function (callback) {
        if (!this.isChestOpened) {
            callback();
            return;
        }

        this.hideSelf();
        this.chest.unit.findComponent(Chest).open();
        Map2d.mapEvent(Map2d.OPENCHEST, { affected: this.chest.unit });
        Merge.currentMerge.counter.setTimeout(callback, Merge.currentMerge.tutorial.isActive() ? 1000 : 300);
    },

    openChest: function () {
        this.isChestOpened = true;
        this.close();
    },

    watchVideoAndOpenChest: function () {
        cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.OPEN_CHEST, this.createListener(function () {
            this.chest.setExtraPrizes(this.getPrize());
            cleverapps.adsLimits.watch(AdsLimits.TYPES.OPEN_CHEST);
            new OpenChestWindow(this.chest);
            this.close();
        }.bind(this)));
    },

    createContent: function () {
        var styles = cleverapps.styles.OpenChestWindow;

        var buttons = this.createButtons();
        var text = this.watchButton ? "OpenChestWindow.questionWithAd" : "OpenChestWindow.question";

        var question = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.OPENCHESTWINDOW_TITLE_TEXT, {
            name: Messages.get(this.chest.unit.getResource() === "sacks" ? "sack" : "chest")
        });
        question.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        question.setDimensions(styles.question.width, 0);

        return new cleverapps.Layout([this.createChest(), question, buttons], {
            margin: styles.margin
        });
    },

    createChest: function () {
        var styles = cleverapps.styles.OpenChestWindow.chest;

        if (this.chest.extraPrizes) {
            var plus = cleverapps.UI.generateImageText("+", cleverapps.styles.FONTS.OPENCHESTWINDOW_PLUS_TEXT);

            var extra = UnitView.getUnitImage(this.getPrize());
            extra.setScale(styles.extra.scale);
            extra.clearTrack && extra.clearTrack(0);
        }

        var icon = new UnitOnCell(this.chest.unit, {
            energy: this.chest.isEnergyAvailable() && this.chest.getEnergyAmount(),
            shining: true,
            stripe: true
        });
        icon.setScale(styles.scale);
        icon.pulsate();

        return new cleverapps.Layout([icon, plus, extra], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    },

    createButtons: function () {
        var buttons = [];
        var styles = this.showAdOffer ? cleverapps.styles.OpenChestWindow.showAd : cleverapps.styles.OpenChestWindow;
        var options = {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.buttons.margin,
            padding: styles.buttons.padding
        };

        if (this.showAdOffer) {
            buttons.push(this.createWatchButton());
            buttons.push(this.createOpenText());
        } else {
            buttons.push(this.createOpenButton());
        }

        return new cleverapps.Layout(buttons, options);
    },

    getPrize: function () {
        if (cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_ADS) {
            return OpenChestWindow.PRIZES_VIDEO_ADS_MAIN_MONETIZATION[this.chest.unit.stage];
        }
        return OpenChestWindow.PRIZES[this.chest.unit.stage];
    },

    createWatchButton: function () {
        var styles = cleverapps.styles.OpenChestWindow.showAd;

        var unit = UnitView.getUnitImage(this.getPrize(), { preferStatic: true });
        var options = {
            text: "%%OpenChestWindow.freeGift##",
            icons: {
                "%%": bundles.buttons_inlined_icons.frames.ad_icon_png,
                "^^": bundles.openchestwindow.frames.ads_arrow_png,
                "##": unit
            },
            width: styles.buttons.width,
            height: styles.buttons.height,
            onClicked: this.watchVideoAndOpenChest.bind(this)
        };

        var button = this.watchButton = new cleverapps.UI.Button(options);
        return button;
    },

    createOpenButton: function () {
        var styles = cleverapps.styles.OpenChestWindow.buttons;

        var button = new cleverapps.UI.Button({
            text: "OpenChestWindow.button",
            width: styles.width,
            height: styles.height,
            onClicked: this.openChest.bind(this)
        });
        button.adviceTarget = Map2d.SPAWN;

        return button;
    },

    createOpenText: function () {
        var styles = cleverapps.styles.OpenChestWindow;

        var openText = new cc.Node();
        openText.setAnchorPoint(0.5, 0.5);
        var text = cleverapps.UI.generateOnlyText("OpenChestWindow.rejectAd", cleverapps.styles.FONTS.OPENCHESTWINDOW_LINK_TEXT);
        openText.addChild(text);
        cleverapps.UI.onClick(openText, this.openChest.bind(this), {
            interactiveScale: false
        });
        cleverapps.UI.applyHover(openText);
        openText.adviceTarget = Map2d.SPAWN;
        cleverapps.UI.wrap(openText);

        openText.setCascadeOpacityEnabledRecursively(true);
        openText.setOpacity(0);

        openText.runAction(
            new cc.Sequence(
                new cc.DelayTime(0.5),
                new cc.FadeIn(1),
                new cc.DelayTime(0.5),
                new cc.CallFunc(function () {
                    openText.addChild(cleverapps.UI.drawUnderline(text, styles.underline));
                })
            )
        );
        return openText;
    }
});

OpenChestWindow.PRIZES = [
    undefined,
    undefined,
    {
        code: "wands",
        stage: 0
    }
];

OpenChestWindow.PRIZES_VIDEO_ADS_MAIN_MONETIZATION = [
    undefined,
    {
        code: "wands",
        stage: 0
    },
    {
        code: "wands",
        stage: 1
    }
];

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    OPENCHESTWINDOW_TITLE_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    OPENCHESTWINDOW_PLUS_TEXT: {
        size: 92,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    OPENCHESTWINDOW_LINK_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.OpenChestWindow = {
    margin: 70,
    padding: {
        top: 100
    },

    showAd: {
        buttons: {
            x: { align: "center" },
            y: { align: "bottom", dy: 85 },
            width: 480,
            height: 110,
            margin: 130,
            padding: {
                top: 40,
                bottom: 40,
                left: 160,
                right: 160
            }
        }
    },

    question: {
        width: 800
    },

    underline: {
        x: { align: "left", dx: 0 },
        y: { align: "bottom", dy: -2 },
        height: 3
    },

    buttons: {
        x: { align: "center" },
        y: { align: "bottom", dy: 85 },
        width: 300,
        height: 110,
        margin: 120,
        padding: {
            top: -35
        }
    },

    chest: {
        scale: 1.6,
        margin: 30,
        extra: {
            scale: 1.4
        }
    },

    watchButton: {
        unit: {
            x: { align: "right", dx: -19 },
            y: { align: "bottom", dy: 18 },
            scale: 1
        }
    }
};
