/**
 * Created by andrey on 26.12.2020.
 */

var EditorFogBlock = function () {
    FogBlock.apply(this, arguments);

    this.onGetView = function () {};

    this.calcState();
};

EditorFogBlock.prototype = Object.create(FogBlock.prototype);
EditorFogBlock.prototype.constructor = EditorFogBlock;

EditorFogBlock.prototype.calcState = function () {
    this.setState(FogBlock.UNAVAILABLE);
};

EditorFogBlock.prototype.moveBalloon = function (x, y) {
    if (cc.pointEqualToPoint(this.balloonTile, cc.p(x, y))) {
        return;
    }

    if (this.balloonTile) {
        var balloonTile = this.balloonTile;
        this.remove(this.balloonTile.x, this.balloonTile.y);
        if (!balloonTile.fakeEditor) {
            this.add(balloonTile.x, balloonTile.y, balloonTile.options);
        }
    }

    this.addBalloon(x, y);

    Map2d.currentMap.regions[this.id].balloons = cc.p(x, y);
    Map2d.currentMap.saveLevel();
};

EditorFogBlock.prototype.moveHead = function (x, y) {
    if (cc.pointEqualToPoint(this.head, cc.p(x, y))) {
        return;
    }

    if (this.head) {
        var head = this.head;
        this.remove(head.x, head.y);
        this.add(head.x, head.y);
    }
    this.addHead(x, y);

    Map2d.currentMap.regions[this.id].head = cc.p(x, y);
    Map2d.currentMap.saveLevel();
};

EditorFogBlock.prototype.addHead = function (x, y) {
    this.remove(x, y);
    this.add(x, y, { head: true });
};

EditorFogBlock.prototype.add = function () {
    var tile = FogBlock.prototype.add.apply(this, arguments);

    if (tile && tile.isHead) {
        this.editorHead = new EditorFogHead(tile, this);
        tile.onUpdateState(this.state, true);
    }

    this.editorHead && this.editorHead.onUpdateTitle();

    return tile;
};

EditorFogBlock.prototype.rename = function (newId) {
    this.id = cleverapps.castType(newId);
    this.editorHead && this.editorHead.onUpdateTitle();
};

EditorFogBlock.prototype.remove = function (x, y) {
    var removeTile = this.findTile(x, y);
    if (!removeTile) {
        return;
    }
    removeTile.open();
    removeTile.animateOpen();

    this.tiles = this.tiles.filter(function (tile) {
        return tile !== removeTile;
    });

    if (this.balloonTile === removeTile) {
        delete this.balloonTile;
    }

    if (this.head === removeTile) {
        delete this.head;
        delete this.editorHead;
    }

    this.editorHead && this.editorHead.onUpdateTitle();
};
