/**
 * Created by r4zi4l on 18.03.2021
 */

var EnergyLottery = function (stored) {
    cleverapps.EventEmitter.call(this);

    this.onLotteryAttentionChanged = function () {};
    this.onLotteryTileChanged = function () {};

    if (!this.isAvailable()) {
        cleverapps.exp.on("changeExp", function () {
            if (this.isAvailable()) {
                this.onChangeGameLevel();
            }
        }.bind(this), this);
    }

    this.onShowHint = function () {};
    this.onHideHint = function () {};

    this.load(stored);

    this.update();

    this.calcValues();

    this.canBuyWithGold = ["wondermerge", "fairy"].indexOf(cleverapps.config.name) !== -1 && !connector.platform.oneOf(connector.SP_MOBAGE, connector.MOBAGE);

    this.start();
};

EnergyLottery.prototype = Object.create(cleverapps.EventEmitter.prototype);
EnergyLottery.prototype.constructor = EnergyLottery;

EnergyLottery.prototype.showHint = function () {
    this.hint = true;

    this.onShowHint();

    setTimeout(function () {
        this.hint = false;

        this.onHideHint();
    }.bind(this), 11000);
};

EnergyLottery.prototype.isHintActive = function () {
    return this.hint;
};

EnergyLottery.prototype.onChangeGameLevel = function () {
    this.update();
};

EnergyLottery.prototype.load = function (stored) {
    this.last = 0;

    if (!stored) {
        return;
    }

    stored = cleverapps.castType(stored);

    if (typeof stored === "number") {
        this.last = cleverapps.expandTime(stored, { seconds: true });
    } else if (typeof stored === "object") {
        this.last = stored.last || 0;

        // migrate
        if (stored.refreshTime) {
            this.last = stored.refreshTime;
        }
    }
};

EnergyLottery.prototype.save = function () {
    return cleverapps.compactTime(this.last, { seconds: true });
};

EnergyLottery.prototype.update = function () {
    this.clearTimeout();

    var ready = this.isReady();
    if (this.ready !== ready) {
        this.ready = ready;
        this.onLotteryAttentionChanged();
        this.onLotteryTileChanged();
    }

    var timeLeft = this.getTimeLeft();
    if (timeLeft > 0) {
        this.timeout = new cleverapps.LongTimeout(this.update.bind(this), timeLeft);
    }
};

EnergyLottery.prototype.destructor = function () {
    this.clearTimeout();
    runCleaners(this);
};

EnergyLottery.prototype.clearTimeout = function () {
    if (this.timeout !== undefined) {
        clearTimeout(this.timeout);
        delete this.timeout;
    }
};

EnergyLottery.prototype.isAvailable = function () {
    return cleverapps.user.checkAvailable(EnergyLottery.AVAILABLE);
};

EnergyLottery.prototype.isReady = function () {
    return this.isAvailable() && this.getTimeLeft() <= 0;
};

EnergyLottery.prototype.getTimeLeft = function () {
    return Math.max(0, this.last + cleverapps.parseInterval(EnergyLottery.REFRESH_TIMEOUT) - Date.now());
};

EnergyLottery.prototype.reset = function () {
    this.last = 0;
    Merge.currentMerge.storeSave();
    this.update();
};

EnergyLottery.prototype.nextValue = function () {
    var value;

    if (this.last === 0) {
        value = this.maxValue();
    } else {
        value = cleverapps.Random.choose(this.getValues(), Date.now());
    }

    return value;
};

EnergyLottery.prototype.start = function () {
    this.tries = EnergyLottery.TRIES;

    this.isAnimating = false;
};

EnergyLottery.prototype.play = function (options) {
    if (this.isAnimating) {
        return;
    }

    if (!this.canPlay()) {
        return;
    }

    options = options || {};
    options.amount = this.nextValue();

    if (this.tries === EnergyLottery.TRIES) {
        this.last = Date.now();

        Merge.currentMerge.storeSave();

        this.update();

        cleverapps.eventBus.trigger("taskEvent", DailyTasks.ENERGY_LOTTERY);
    }

    this.tries--;

    this.isAnimating = true;

    this.trigger("play", options);

    if (this.tries < 1) {
        if (!this.canBuyWithGold) {
            this.finish();
        }
    }
};

EnergyLottery.prototype.onAnimationFinished = function () {
    this.isAnimating = false;

    if (this.canPlay()) {
        this.trigger("prepareNext");
    }
};

EnergyLottery.prototype.finish = function () {
    this.trigger("finish");
};

EnergyLottery.prototype.canPlay = function () {
    return this.tries > 0 || this.canBuyWithGold;
};

EnergyLottery.prototype.maxValue = function () {
    return Math.max.apply(Math, this.getValues());
};

EnergyLottery.prototype.getValues = function () {
    this.calcValues();
    return this.values;
};

EnergyLottery.prototype.calcValues = function () {
    if (cleverapps.lives.amount < cleverapps.lives.getMaxLives() * 1.5) {
        this.values = [100, 45, 65, 40, 60, 35, 55, 30, 50];
        return;
    }
    if (cleverapps.lives.amount < cleverapps.lives.getMaxLives() * 2) {
        this.values = [80, 25, 45, 20, 40, 15, 35, 10, 30];
        return;
    }
    this.values = [30, 10, 15, 5, 25, 5, 20, 5, 15];
};

EnergyLottery.AVAILABLE = {
    level: 5.5
};

EnergyLottery.REFRESH_TIMEOUT = "3 hours";
EnergyLottery.TRIES = 1;

if (cleverapps.config.debugMode) {
    EnergyLottery.REFRESH_TIMEOUT = "1 minute";
}
