/**
 * Created by andrey on 02.07.2021.
 */

var UnitAdditionalView = cc.Node.extend({
    ctor: function (unitView, view, type) {
        this._super();

        this.unitView = unitView;
        this.view = view;
        this.type = type;

        this.setCascadeOpacityEnabledRecursively(true);

        this.setBasePosition();

        this.view.runAnimation && this.view.runAnimation();
    },

    setBasePosition: function () {
        this.setPositionRound(this.view.alignment || this.view.getPosition());

        delete this.view.alignment;
        this.view.setPositionRound(0, 0);
        this.addChild(this.view);

        this.unitView.addChild(this);
        delete this.alignment;

        this.basePosition = this.getPosition();
    },

    animateAppear: function () {
        if (cleverapps.silentIntro) {
            return;
        }

        this.stopAllActions();

        var actions = [];

        if (this.type === "customerMark") {
            actions.push(
                AnimationsLibrary.showUp(this.view, {
                    customSqueeze: true,
                    move: cleverapps.styles.AnimationsLibrary.showUpMove.fromLeft
                })
            );
        } else {
            this.setScale(0.5);
            actions.push(new cc.Show());
            actions.push(new cc.ScaleTo(0.2, 1, 1).easing(cc.easeIn(1)));
        }

        if (this.view.runAnimation) {
            actions.push(new cc.CallFunc(function () {
                this.view.setPositionRound(0, 0);
                this.view.runAnimation();
            }.bind(this)));
        }

        this.setVisible(false);
        this.runAction(actions.length === 1 ? actions[0] : new cc.Sequence(actions));
    },

    hide: function () {
        this.stopAllActions();
        this.setVisible(false);
    },

    animateHide: function (callback) {
        this.stopAllActions();

        var actions = [
            new cc.ScaleTo(0.05, 0.5, 0.5).easing(cc.easeIn(1)),
            new cc.Hide()
        ];
        if (callback) {
            actions.push(new cc.CallFunc(callback));
        }
        this.runAction(new cc.Sequence(actions));
    },

    remove: function (silent) {
        if (!silent) {
            this.animateHide(function () {
                this.removeFromParent();
            }.bind(this));
        } else {
            this.removeFromParent();
        }
        if (this.view.beforeRemove) {
            this.view.beforeRemove();
        }
    }
});