/**
 * Created by Denis Kuzin on 12 february 2025
 */

var SidebarQuests = function () {
    cleverapps.EventEmitter.call(this);

    this.active = Merge.currentMerge.quests.quests;
    this.active = this.createFakeIcons().concat(this.active);
    this.active.reverse();
};

SidebarQuests.prototype = Object.create(cleverapps.EventEmitter.prototype);
SidebarQuests.prototype.constructor = SidebarQuests;

SidebarQuests.prototype.createFakeIcons = function () {
    var icons = [];
    for (var i = 0; i < 4 - this.active.length; i++) {
        icons.push(undefined);
    }
    return icons;
};

SidebarQuests.prototype.removeIcon = function (quest, callback) {
    var toRemoveIndex = this.active.indexOf(quest);

    this.active.forEach(function (icon, i) {
        if (i === toRemoveIndex) {
            return;
        }

        this.trigger("move", i, i < toRemoveIndex ? "down" : "up");
    }.bind(this));

    new ActionPlayer([
        function (f) {
            this.trigger("remove", toRemoveIndex, false, f);
        }.bind(this),

        function (f) {
            this.active.splice(toRemoveIndex, 1);

            var fakeIcons = this.createFakeIcons();
            this.active = this.active.concat(fakeIcons);
            fakeIcons.forEach(function () {
                this.trigger("add", { fake: true }, callback);
            }.bind(this));

            f();
        }.bind(this),

        function (f) {
            this.trigger("resize", f);
        }.bind(this),

        function (f) {
            var iconIndex = toRemoveIndex;
            if (!this.active[toRemoveIndex]) {
                iconIndex--;
            }
            this.trigger("scrollTo", iconIndex, true);

            f();
        }.bind(this)
    ]).play(function () {
        callback();
    });
};

SidebarQuests.prototype.addIcon = function (quest, options) {
    options = options || {};

    var callback = function () {
        this.showInfo(quest);

        options.callback && options.callback();
    }.bind(this);

    if (!options.silent) {
        cleverapps.audio.playSound(bundles.questsgroupicon.urls.quest_new_close_effect);
    }

    var currentIcon = this.active.find(function (icon) {
        if (!icon || icon.id !== quest.id) {
            return false;
        }

        if (quest && quest.recipe !== icon.recipe) {
            return false;
        }

        return true;
    });

    if (currentIcon) {
        var currentIconIndex = this.active.indexOf(currentIcon);
        this.active[currentIconIndex] = quest;

        this.trigger("add", { replaceIndex: currentIconIndex }, callback);
    } else {
        this.active.unshift(quest);

        this.trigger("add", undefined, callback);

        var lastIndex = this.active.length - 1;
        if (this.active.length > 3 && !this.active[lastIndex]) {
            this.active.pop();

            this.trigger("remove", lastIndex, true);
        }
    }
};

SidebarQuests.prototype.scrollTo = function (quest, silent, callback) {
    this.trigger("scrollTo", this.active.indexOf(quest), silent, callback);
};

SidebarQuests.prototype.hasIcon = function (quest) {
    return this.active.includes(quest);
};

SidebarQuests.prototype.finishQuest = function (quest, callback) {
    var isIconEnabled = cleverapps.focusManager.isControlEnabled("QuestsGroupIcon");
    if (!isIconEnabled) {
        cleverapps.focusManager.showControlsWhileFocused("QuestsGroupIcon");
    }

    if (!this.hasIcon(quest)) {
        callback();

        var rewardsList = new RewardsList(quest.config.prize || {}, {
            toMainWorld: quest.config.toMainWorld,
            event: cleverapps.EVENTS.EARN.OTHER
        });
        rewardsList.receiveRewards();
        rewardsList.updateViewAmount();

        return;
    }

    new ActionPlayer([
        function (f) {
            this.scrollTo(quest, false, f);
        }.bind(this),

        function (f) {
            cleverapps.timeouts.setTimeout(f, isIconEnabled ? 0 : 250);
        },

        function (f) {
            this.showInfo(quest, {
                canShowInfoView: true,
                callback: f
            });
        }.bind(this),

        function (f) {
            this.removeIcon(quest, f);
            callback();
        }.bind(this)
    ]).play();
};

SidebarQuests.prototype.showInfo = function (quest, options) {
    this.trigger("showInfo", quest, options);
};

SidebarQuests.prototype.hideInfo = function () {
    this.trigger("hideInfo");
};