/**
 * Created by andrey on 06.09.2021.
 */

var UnitsShop = function () {
    this.tabs = {};

    for (var tab in UnitsShop.PRODUCTS) {
        this.tabs[tab] = new UnitsShopTab(cleverapps.castType(tab));
    }

    this.load();
};

UnitsShop.prototype.getAvailableShops = function () {
    var pageId = cleverapps.meta.selectedLocationId();
    var shops = UnitsShop.SHOPS[pageId];

    var tabs = [];
    if (shops) {
        for (var i = 0; i < shops.length; i++) {
            if (cleverapps.unitsLibrary.isOpened(shops[i].unit)) {
                tabs.push(shops[i]);
            }
        }
    }
    return tabs;
};

UnitsShop.prototype.reset = function () {
    this.load({});
    this.save();
};

UnitsShop.prototype.isActiveTab = function (tab) {
    var locations = cleverapps.meta.listAvailableLocations();

    if (tab === ShopWindow.TABS.UNITS_EXPEDITION_HALLOWEEN && locations.indexOf("halloween") === -1) {
        return false;
    }
    if (tab === ShopWindow.TABS.UNITS_EXPEDITION_BLACKFRIDAY && locations.indexOf("blackfriday") === -1) {
        return false;
    }
    if (tab === ShopWindow.TABS.UNITS_EXPEDITION_XMAS && locations.indexOf("xmas") === -1) {
        return false;
    }
    if (tab === ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA && locations.indexOf("dragonia") === -1) {
        return false;
    }
    if (tab === ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA2 && locations.indexOf("dragonia2") === -1) {
        return false;
    }
    if (tab === ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA3 && locations.indexOf("dragonia3") === -1) {
        return false;
    }
    if (tab === ShopWindow.TABS.UNITS_EXPEDITION_UNDERSEA
        && locations.indexOf("undersea") === -1
        && locations.indexOf("undersea2") === -1) {
        return false;
    }
    if (tab === ShopWindow.TABS.UNITS_EXPEDITION_RAPUNZEL
        && locations.indexOf("rapunzel") === -1
        && locations.indexOf("rapunzel2") === -1
        && locations.indexOf("rapunzel3") === -1) {
        return false;
    }
    if (tab === ShopWindow.TABS.UNITS_EXPEDITION_EASTER && locations.indexOf("easter") === -1) {
        return false;
    }
    if (tab === ShopWindow.TABS.UNITS_EXPEDITION_CHINA && locations.indexOf("china") === -1) {
        return false;
    }
    return true;
};

UnitsShop.prototype.load = function (stored) {
    stored = stored || cleverapps.dataLoader.load(DataLoader.TYPES.UNITS_SHOP) || {};
    var needSave = false;
    for (var id in this.tabs) {
        if (stored[id] && !this.isActiveTab(id)) {
            stored[id] = undefined;
            needSave = true;
        }
        this.tabs[id].load(stored[id] || {});
    }

    if (needSave) {
        this.save();
    }
};

UnitsShop.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.UNITS_SHOP, this.getInfo());

    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("unitsshop");
    }
};

UnitsShop.prototype.getInfo = function () {
    var info = {};
    for (var id in this.tabs) {
        info[id] = this.tabs[id].save();
    }
    return info;
};

UnitsShop.prototype.updateInfo = function (serverData) {
    this.load(serverData || {});
    this.save(true);
};

UnitsShop.SHOPS = {
    main: [{
        tab: ShopWindow.TABS.UNITS_FRUIT,
        unit: { code: "fruitshop", stage: 0 }
    }, {
        tab: ShopWindow.TABS.UNITS_RESOURCE,
        unit: { code: "resourceshop", stage: 0 }
    }, {
        tab: ShopWindow.TABS.UNITS_DISCOUNT,
        unit: { code: "discountshop", stage: 0 }
    }],

    dragonia: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA,
        unit: { code: "drshop", stage: 0 }
    }],

    dragonia2: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA2,
        unit: { code: "dr2shop", stage: 0 }
    }],

    dragonia3: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA3,
        unit: { code: "dr2shop", stage: 0 }
    }],

    undersea: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_UNDERSEA,
        unit: { code: "seashop", stage: 0 }
    }],

    undersea2: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_UNDERSEA,
        unit: { code: "seashop", stage: 0 }
    }],

    undersea3: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_UNDERSEA,
        unit: { code: "seashop", stage: 0 }
    }],

    halloween: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_HALLOWEEN,
        unit: { code: "dr2shop", stage: 0 }
    }],

    rapunzel: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_RAPUNZEL,
        unit: { code: "rpshop", stage: 0 }
    }],

    rapunzel2: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_RAPUNZEL,
        unit: { code: "rpshop", stage: 0 }
    }],

    rapunzel3: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_RAPUNZEL,
        unit: { code: "rpshop", stage: 0 }
    }],

    xmas: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_XMAS,
        unit: { code: "dr2shop", stage: 0 }
    }],

    easter: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_EASTER,
        unit: { code: "eashop", stage: 0 }
    }],

    collections: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_COLLECTIONS,
        unit: { code: "clshop", stage: 0 }
    }],

    china: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_CHINA,
        unit: { code: "dr2shop", stage: 0 }
    }],

    spring: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_SPRING,
        unit: { code: "drshop", stage: 0 }
    }],

    blackfriday: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_BLACKFRIDAY,
        unit: { code: "dr2shop", stage: 0 }
    }]
};
