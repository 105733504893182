/**
 * Created by olga on 27.07.2023
 */

var NotEnoughSpaceWindow = CleverappsWindow.extend({
    onWindowLoaded: function (unit) {
        this.unit = unit;

        this._super({
            name: "NotEnoughSpaceWindow",
            title: "NotEnoughSpaceWindow.title",
            closeButton: true,
            content: this.createContent()
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.NotEnoughSpaceWindow;

        var icon = this.createIcon();

        var sellMessage, buttonText;
        var price = Unit.CalcSellPrice(this.unit);
        if (price > 0) {
            sellMessage = "NotEnoughSpaceWindow.text.sell";
            buttonText = "@@" + price;
        } else {
            sellMessage = "NotEnoughSpaceWindow.text.remove";
            buttonText = "Remove";
        }

        var sellText = cleverapps.UI.generateOnlyText(sellMessage, cleverapps.styles.FONTS.SELLUNITWINDOW_TEXT, { unit: cleverapps.unitsLibrary.getUnitName(this.unit) });
        sellText.setAnchorPoint(0.5, 0.5);
        sellText.setDimensions(styles.text.width, 0);
        sellText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var sellButton = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            type: cleverapps.styles.UI.Button.Images.button_blue,
            text: buttonText,
            onClicked: function () {
                var price = Unit.CalcSellPrice(this.unit);
                if (price) {
                    Merge.currentMerge.addReward("soft", Unit.CalcSellPrice(this.unit), this.unit.onGetView(), {
                        withoutDelta: true
                    });
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.UNITS_SOLD, { value: price });
                }

                this.unit.onDestruction();
                this.unit.remove(true);
                this.close();
            }.bind(this)
        });

        return new cleverapps.Layout([icon, sellText, sellButton], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createIcon: function () {
        var styles = cleverapps.styles.NotEnoughSpaceWindow.icon;

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(styles);

        var icon = new UnitOnCell(this.unit);
        icon.setScale(styles.scale);
        icon.setPositionRound(container.width / 2, container.height / 2);
        container.addChild(icon);

        return container;
    },

    getPreferredBundles: function () {
        return ["game"];
    }
});

cleverapps.styles.NotEnoughSpaceWindow = {
    margin: 30,

    icon: {
        width: 280,
        height: 240,
        scale: 1.8
    },

    text: {
        width: 1000
    },

    button: {
        width: 340,
        height: 110
    }
};
