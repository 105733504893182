/**
 * Created by vladislav on 31/10/2022
 */

var CustomerView = function (customer, unitView) {
    this.unitView = unitView;
    this.customer = customer;

    this.restoreState();
};

CustomerView.prototype.restoreState = function (animated) {
    if (cleverapps.config.demoMode || !this.customer.getCurrentRecipe() || (cleverapps.windows.currentWindow() instanceof ExchangeWindow)) {
        this.removeInfoView(animated);
    } else {
        this.createInfoView(animated);
    }

    if (this.customer.getCookingRecipe() && this.customer.getCookingTimeLeft()) {
        this.createTimer(animated);
    } else {
        this.removeTimer(animated);
    }

    if (this.customer.isProcessingCustomOrder()) {
        this.setHangerAnimation();
    } else {
        this.setMainAnimation();
    }
};

CustomerView.prototype.walkToCreateOrder = function (callback) {
    if (this.isWalking) {
        return;
    }

    this.isWalking = true;
    var point1 = Map2d.currentMap.getMapView().alignInGrid(this.unitView.unit.x, this.unitView.unit.y - 4);
    var point2 = Map2d.currentMap.getMapView().alignInGrid(this.unitView.unit.x, this.unitView.unit.y);
    var vector = cc.pSub(point2, point1);

    this.unitView.setPositionRound(point1.x, point1.y);
    this.unitView.showSprite();

    this.unitView.runAction(new cc.Sequence(
        new cc.CallFunc(this.setMoveAnimation.bind(this, "s")),
        new cc.MoveBy(cc.pLength(vector) / 100, vector),
        new cc.CallFunc(function () {
            this.unitView.setPositionRound(point2.x, point2.y);
            this.unitView.sprite.setAnimation(0, "idle", true);
            this.isWalking = false;

            callback();
        }.bind(this))
    ));
};

CustomerView.prototype.leaveAfterOrder = function () {
    var point1 = Map2d.currentMap.getMapView().alignInGrid(this.unitView.unit.x, this.unitView.unit.y);
    var point2 = Map2d.currentMap.getMapView().alignInGrid(this.unitView.unit.x, this.unitView.unit.y - 4);
    var vector = cc.pSub(point2, point1);

    this.unitView.runAction(new cc.Sequence(
        new cc.CallFunc(this.setMoveAnimation.bind(this, "n")),
        new cc.MoveBy(cc.pLength(vector) / 100, vector),
        new cc.CallFunc(function () {
            this.unitView.sprite.setAnimation(0, "idle", true);
            this.unitView.hideSprite();
        }.bind(this))
    ));
};

CustomerView.prototype.setMoveAnimation = function (direction) {
    this.unitView.hideAdditionalViews();
    this.unitView.sprite.setAnimation(0, "walk_" + direction, true);
};

CustomerView.prototype.createInfoView = function (animated) {
    var ingredientsView = this.unitView.getAdditionalView("ingredients");
    if (ingredientsView) {
        return;
    }

    var recipe = this.customer.getCurrentRecipe();

    var infoView = new IngredientsInfoView({ ingredients: recipe.getIngredients() });
    infoView.setPositionRound(this.unitView.width / 2, this.unitView.height + cleverapps.styles.InfoView.dy);
    infoView.setAnchorPoint(0.5, 0);
    this.unitView.createAdditionalView("ingredients", infoView);

    if (animated) {
        ingredientsView = this.unitView.getAdditionalView("ingredients");
        ingredientsView.animateAppear();
    }

    cleverapps.UI.onClick(infoView, this.unitView.handleClick.bind(this.unitView));
};

CustomerView.prototype.removeInfoView = function (animated) {
    this.unitView.removeAdditionalView("ingredients", !animated);
};

CustomerView.prototype.createTimer = function (animated) {
    var timerView = this.unitView.getAdditionalView("timer");
    if (timerView) {
        return;
    }

    var styles = cleverapps.styles.CustomerView;

    var cookingRecipe = this.customer.getCookingRecipe();
    var cookingTimeLeft = this.customer.getCookingTimeLeft();

    var countdown = new cleverapps.CountDown(cookingTimeLeft, {
        onTick: this.updateBtn.bind(this)
    });

    var timer = new cleverapps.CountDownView(countdown, {
        font: cleverapps.styles.FONTS.COMPONENT_TIMER_TEXT,
        icon: bundles.timer_merge.frames.timer_png,
        bar: {
            type: ScaledProgressBar.Types.blue,
            width: styles.timer.width,
            fullValue: cookingRecipe.getCookingDuration(),
            reversed: false
        }
    });
    timer.setPositionRound(styles.timer);
    this.unitView.createAdditionalView("timer", timer);

    if (!this.customer.hasOrdersWindow) {
        var button = this.button = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "$$" + SpeedUpWindow.CalcPrice(cookingTimeLeft) + ">>",
            type: cleverapps.styles.UI.Button.Images.button_blue,
            onClicked: this.speedUp.bind(this)
        });
        this.updateBtn(cookingTimeLeft);
        button.setPositionRound(styles.button);
        timer.addChild(button);
    }

    if (animated) {
        timerView = this.unitView.getAdditionalView("timer");
        timerView.animateAppear();
    }
};

CustomerView.prototype.speedUp = function () {
    if (this.button) {
        var price = SpeedUpWindow.CalcPrice(this.customer.getCookingTimeLeft());
        if (cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.CUSTOMER, price)) {
            this.customer.enjoy();
        }
    }
};

CustomerView.prototype.updateBtn = function (timeLeft) {
    if (this.button) {
        timeLeft = timeLeft !== undefined ? timeLeft : this.customer.getCooldownTimeLeft();
        var price = SpeedUpWindow.CalcPrice(timeLeft);
        if (this.button.speedPrice !== price) {
            this.button.speedPrice = price;
            this.button.setString((price ? ("$$" + price) : "UseGoldButton.free") + TextWithIcon.ICONS_BY_NAME.speedup);
        }
    }
};

CustomerView.prototype.removeTimer = function (animated) {
    this.unitView.removeAdditionalView("timer", !animated);
    if (!this.customer.hasOrdersWindow) {
        delete this.button;
    }
};

CustomerView.prototype.collectUnitAnimation = function (unit, callback) {
    var unitView = unit.onGetView();
    var targetView = this.unitView;

    if (!unitView) {
        callback();
        return;
    }

    unitView.runAction(CollectibleView.FeedAnimation(unitView, targetView)).setFinalize(callback);
};

CustomerView.prototype.setHangerAnimation = function () {
    if (this.unitView.sprite instanceof cleverapps.Spine && this.unitView.sprite.hasAnimation("hungry")) {
        this.unitView.sprite.setCompleteListener();
        this.unitView.sprite.setAnimation(0, "hungry", true);
    }
};

CustomerView.prototype.setMainAnimation = function () {
    var heroTutorial = this.customer.unit.findComponent(HeroTutorial);

    if (this.unitView.sprite instanceof cleverapps.Spine && (!heroTutorial || !heroTutorial.isTutorialRunning)) {
        UnitView.applyDefaultAnimation(this.unitView.sprite, this.customer.unit);
    }
};

cleverapps.styles.CustomerView = {
    distance: 600,

    button: {
        height: 50,
        width: 130,
        x: { align: "center" },
        y: { align: "bottom", dy: -52 }
    },

    timer: {
        x: { align: "center" },
        y: { align: "bottom", dy: 40 },
        width: 140
    }
};