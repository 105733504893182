/**
 * Created by spepa on 10.10.2022
 */

var LandMarkWindow = CleverappsWindow.extend({
    onWindowLoaded: function (landmark) {
        this.initialLandmark = landmark;
        this.landmark = landmark;

        this.changeSelectedThrottled = cleverapps.throttle(300, this.createListener(this.changeSelected.bind(this)));
        this.changeBackgroundThrottled = cleverapps.throttle(300, this.createListener(this.changeBackground.bind(this)));

        this._super({
            styles: cleverapps.styles.LandMarkWindow.window,
            title: "LandMarkWindow.title",
            name: "LandMarkWindow",
            content: this.createContent(),
            contentPadding: Map2d.currentMap.isCloseToMapLeft(landmark.unit) ? cleverapps.UI.DOCK_RIGHT : cleverapps.UI.DOCK_LEFT,
            foreground: bundles.windows.frames.window_foreground_png,
            help: function () {
                this.close();
                new GuideWindow(Merge.currentMerge.landmarks.getGuideWindowOptions());
            }.bind(this)
        });

        this.updateItems();
    },

    setupChildren: function () {
        this._super();

        if (Map2dScroller.currentScroller) {
            Map2dScroller.currentScroller.scrollToUnit(0, this.landmark.unit, {
                position: cleverapps.UI.DOCK_LEFT | cleverapps.UI.DOCK_RIGHT
            });
        }
    },

    onClose: function () {
        if (this.initialLandmark && this.initialLandmark.stage !== this.landmark.stage) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.LANDMARKS.REPLACE + this.landmark.stage);
        }
        Map2d.currentMap.unhighlightUnit();
    },

    isSelectedLandmark: function (target) {
        return Unit.IsApplicable(target.landmark, this.landmark.unit);
    },

    showHint: function () {
        if (this.dialogue) {
            this.stopAction(this.minimalDialogueAction);
            this.dialogue.remove();
            this.dialogue = undefined;
        }

        var dialogue = this.dialogue = new MinimalDialogue({
            person: "king",
            delay: 0,
            text: "LandmarkUnavailable",
            rects: [this.window.getSceneBoundingBox(), this.windowTitle.getSceneBoundingBox()]
        });
        this.addChild(dialogue);
        dialogue.display();

        this.minimalDialogueAction = this.runAction(new cc.Sequence(
            new cc.DelayTime(3),
            new cc.CallFunc(function () {
                this.dialogue.remove();
                this.dialogue = undefined;
            }.bind(this))
        ));
    },

    createContent: function () {
        var bg = this.bg = new cc.Node();
        this.bg.setContentSize2(cleverapps.styles.LandMarkWindow.grid);

        this.addScroll(this.createGrid());

        return bg;
    },

    addScroll: function (grid) {
        var scroll = this.scroll = new cleverapps.UI.ScrollView(grid, {
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL
        });
        scroll.setContentSize2(this.bg.width, this.bg.height);
        scroll.setPositionRound(this.bg.width / 2, this.bg.height / 2);
        scroll.setBarPadding(cleverapps.styles.LandMarkWindow.scrollBarPadding);
        this.bg.addChild(scroll);

        if (this.landmark instanceof Landmark) {
            scroll.scrollTo(this.availableUnits.find(function (unit) {
                return this.isSelectedLandmark(unit);
            }.bind(this)));
        } else {
            scroll.scrollToPercent(100);
        }
    },

    updateItems: function () {
        this.availableUnits.forEach(function (item) {
            item.updateState();
        }, this);
    },

    createUnits: function () {
        return Merge.currentMerge.landmarks.listWindowLandmarks().map(function (landmark) {
            return new LandmarkItem(landmark);
        });
    },

    createGrid: function () {
        var styles = cleverapps.styles.LandMarkWindow.grid;

        this.availableUnits = this.createUnits();

        var grid = this.grid = new cleverapps.GridLayout(this.availableUnits, {
            columns: 3,
            margin: styles.margin,
            padding: styles.padding
        });

        cleverapps.UI.onClick(grid, this.onClick.bind(this), {
            interactiveScale: false,
            onDoubleClick: cleverapps.config.debugMode && this.onDoubleClick.bind(this)
        });

        return grid;
    },

    findClickTarget: function (point) {
        return this.availableUnits.filter(function (node) {
            return cc.rectContainsPoint(node.getBoundingBox(), point);
        })[0];
    },

    onClick: function (touch) {
        var point = this.grid.convertTouchToNodeSpace(touch);
        var target = this.findClickTarget(point);
        if (target) {
            if (target.canDeploy()) {
                this.changeSelectedThrottled(target);
            } else if (target.canHint()) {
                this.showHint();
            } else if (!this.isSelectedLandmark(target)) {
                this.swapLandmarks(target);
            }
        }
    },

    onDoubleClick: function (touch) {
        var point = this.grid.convertTouchToNodeSpace(touch);
        var target = this.findClickTarget(point);
        if (!target) {
            return;
        }

        var unitsLibrary = UnitsLibrary.getInstance(target.landmark.expedition);

        if (!unitsLibrary.isOpened(target.landmark)) {
            unitsLibrary.openUnit(target.landmark);
            this.changeSelectedThrottled(target);
            return;
        }

        unitsLibrary.resetUnit(target.landmark);

        var newChosen = this.availableUnits.find(function (unit) {
            return unit.canDeploy();
        }) || new LandmarkItem({ code: "landmarkspot", stage: 0 });

        if (this.isSelectedLandmark(target)) {
            this.changeSelectedThrottled(newChosen);
        } else if (target.getDeployed()) {
            this.changeBackgroundThrottled(target, newChosen);
        } else {
            this.updateItems();
        }
    },

    swapLandmarks: function (target) {
        this.changeBackgroundThrottled(target, this.landmark);
        this.changeSelectedThrottled(target);
    },

    changeBackground: function (current, newLandmark) {
        var deployLocation = cc.p(current.getDeployed());
        Merge.currentMerge.landmarks.construct(newLandmark.unit, deployLocation);
        this.updateItems();
    },

    changeSelected: function (target) {
        this.landmark.unit = Merge.currentMerge.landmarks.construct(target.landmark, cc.p(this.landmark.unit));

        Map2dScroller.currentScroller.scrollToUnit(0, this.landmark.unit, {
            position: cleverapps.UI.DOCK_LEFT | cleverapps.UI.DOCK_RIGHT,
            callback: function () {
                Map2d.currentMap.unhighlightUnit(function () {
                    Map2d.currentMap.highlightUnit(this.landmark.unit);
                }.bind(this));
            }.bind(this)
        });

        this.updateItems();
    },

    listBundles: function () {
        var bundles = ["landmarkwindow"];
        var families = {};

        Merge.currentMerge.landmarks.listWindowLandmarks().forEach(function (landmark) {
            families[landmark.code] = true;
        });

        bundles = bundles.concat(Families.listLazyBundles(Object.keys(families)));

        return bundles;
    }
});

cleverapps.styles.LandMarkWindow = {
    sideOffset: 40,

    window: {
        padding: {
            left: 85,
            right: 85,
            top: 10,
            bottom: 10
        }
    },

    grid: {
        width: 900,
        height: 800,

        margin: {
            x: 30,
            y: 30
        },

        padding: {
            x: 15,
            y: 75
        }
    },
    scrollBarPadding: {
        sidePadding: 0
    }
};
