/**
 * Created by andrey on 26.12.2020.
 */

// eslint-disable-next-line no-unused-vars
var EditorFogTile = function (x, y, fogBlock, options) {
    FogTile.apply(this, arguments);

    this.options = options;

    if (this.fake) {
        delete this.fake;
        this.fakeEditor = true;
    }
};

EditorFogTile.prototype = Object.create(FogTile.prototype);
EditorFogTile.prototype.constructor = EditorFogTile;

EditorFogTile.prototype.open = function () {
    Map2d.currentMap.setPointer(this.x, this.y);
    Map2d.currentMap.setValue(Map2d.LAYER_FOG, Iso.SAME, undefined);
    this.fogBlock = undefined;
};

EditorFogTile.prototype.animateOpen = function () {
    this.onOpen();
};
