/**
 * Created by Aleksandr on 27.04.2023
 */

var PawBoxIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.pawbox_icon_json,
        titleIcon: bundles.sidebar.frames.pawbox_titleIcon,
        control: "PawBoxIcon",
        targets: ["pawsPoints"]
    });
};

PawBoxIcon.prototype = Object.create(SideBarIcon.prototype);
PawBoxIcon.prototype.constructor = PawBoxIcon;

PawBoxIcon.prototype.onPressed = function () {
    cleverapps.focusManager.display({
        focus: "PawBoxIconClicked",
        action: function (f) {
            new GuideWindow({
                name: "PawBoxGuideWindow",
                bundle: bundles.pawbox_guide_window
            });
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};

PawBoxIcon.prototype.resetState = function () {
    this.available = false;
    if (cleverapps.config.wysiwygMode || cleverapps.config.editorMode || !cleverapps.meta.getSelectedLocation().isExpedition() || !Merge.currentMerge || !Merge.currentMerge.pawBox) {
        return;
    }

    if (typeof Merge !== "undefined" && Merge.currentMerge && Merge.currentMerge.pawBox && PawBox.IsIconActive()) {
        this.available = true;
    }

    this.setTitle(Merge.currentMerge.pawBox.getPoints() + "/" + PawBox.GOAL);
};
