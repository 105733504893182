/**
 * Created by mac on 12/23/20
 */

var HeroTutorialView = cc.Node.extend({
    ctor: function (heroTutorial, unitView) {
        this._super();

        this.heroTutorial = heroTutorial;
        this.unitView = unitView;

        this.restoreState();
    },

    restoreState: function () {
        var styles = cleverapps.styles.HeroTutorialView;

        if (this.heroTutorial.isHidden) {
            this.unitView.visible = false;

            this.heroTutorial.isHidden = false;
        } else if (this.heroTutorial.isTutorialRunning) {
            this.unitView.sprite.setAnimation(0, "idle_unconscious", true);
            this.unitView.sprite.setCompleteListener(function () {});
            this.unitView.sprite.setSafeToRemove();

            this.unitView.clickRect = cc.rect(styles.unit.clickRect);

            if (this.heroTutorial.stage < HeroTutorial.REMOVE_DEBRIS_STAGES) {
                this.createBarrel();
            } else {
                this.createHeroHover();
            }

            this.applyHover();
        }
    },

    createBarrel: function () {
        var styles = cleverapps.styles.HeroTutorialView;

        this.barrelAnimation = new cleverapps.Spine(bundles.merge.jsons.tutorial_barrel_json);
        this.addChild(this.barrelAnimation);
        this.barrelAnimation.setPositionRound(styles.barrel.offsetX, styles.barrel.offsetY);
        this.barrelAnimation.setAnimation(0, "idle_" + this.heroTutorial.stage, true);
        this.barrelHover = new cleverapps.Spine(bundles.merge.jsons.tutorial_barrel_hover_json);
        this.addChild(this.barrelHover, -1);
        this.barrelHover.setPosition(this.barrelAnimation.getPosition());
        this.barrelHover.visible = false;

        this.unitView.clickRect = cc.rect(styles.barrel.clickRect);
    },

    createHeroHover: function () {
        this.heroHover = new cleverapps.Spine(bundles.merge.jsons.tutorial_hero_hover);
        this.unitView.sprite.parent.addChild(this.heroHover, -1);
        this.heroHover.setAnchorPoint(this.unitView.sprite.anchorX, this.unitView.sprite.anchorY);
        this.heroHover.setPosition(this.unitView.sprite.getPosition());
        this.heroHover.visible = false;
    },

    applyHover: function () {
        this.hoverHandler = cleverapps.UI.applyHover(this.unitView, {
            onMouseOver: function () {
                var hover = this.barrelHover || this.heroHover;

                hover.visible = true;

                hover.setAnimation(0, "hover_idle", true);
            }.bind(this),
            onMouseOut: function () {
                var hover = this.barrelHover || this.heroHover;

                hover.visible = false;
            }.bind(this)
        });
    },

    waveAnimation: function () {
        var styles = cleverapps.styles.HeroTutorialView;

        var waveAnimation = new cleverapps.Spine(bundles.merge.jsons.tutorial_wave_json);

        var mapView = Merge.currentMerge.map.getMapView();

        var position = mapView.alignInGrid(this.heroTutorial.unit.x, this.heroTutorial.unit.y);

        mapView.animations.addChild(waveAnimation);
        waveAnimation.setPositionRound(position.x + styles.wave.offsetX, position.y + styles.wave.offsetY);
        waveAnimation.setAnimation(0, "animation", false);
        waveAnimation.setCompleteListenerRemove();
        waveAnimation.setSafeToRemove();

        cleverapps.audio.playSound(bundles.merge.urls.tutorial_barrel_spawn_sfx);

        this.runAction(
            new cc.Sequence(
                new cc.DelayTime(1.25),
                new cc.CallFunc(function () {
                    this.unitView.visible = true;
                    this.unitView.sprite.setAnimation(0, "idle_unconscious", true);
                    this.unitView.sprite.setCompleteListener(function () {});
                    this.unitView.sprite.setSafeToRemove();

                    this.createBarrel();

                    this.applyHover();

                    this.barrelAnimation.setAnimationAndIdleAfter("in", "idle_0");
                    this.barrelAnimation.setSafeToRemove();
                }.bind(this))
            )
        );
    },

    animateRemoveDebris: function () {
        var styles = cleverapps.styles.HeroTutorialView;

        if (this.heroTutorial.stage === HeroTutorial.REMOVE_DEBRIS_STAGES - 1) {
            this.barrelAnimation.setAnimation(0, "transition_" + this.heroTutorial.stage, false);
            this.barrelAnimation.setCompleteListenerRemove();
            this.unitView.sprite.setSafeToRemove();

            this.barrelHover.setAnimation(0, "hover_out", false);
            this.barrelHover.setCompleteListenerRemove();
            this.barrelHover.setSafeToRemove();
            delete this.barrelHover;

            this.createHeroHover();

            this.unitView.clickRect = cc.rect(styles.unit.clickRect);

            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.3),
                new cc.CallFunc(function () {
                    this.unitView.sprite.setAnimationAndIdleAfter("fountain", "idle_unconscious");

                    cleverapps.audio.playSound(bundles.merge.urls.tutorial_resuscitate_sfx);
                }, this)
            ));
        } else {
            this.barrelAnimation.setAnimationAndIdleAfter("transition_" + this.heroTutorial.stage, "idle_" + (this.heroTutorial.stage + 1));
        }

        cleverapps.audio.playSound(bundles.merge.urls["tutorial_transition_sfx_" + this.heroTutorial.stage]);
    },

    animateResuscitation: function () {
        if (this.heroTutorial.stage < HeroTutorial.TOTAL_STAGES - 1) {
            this.unitView.sprite.setAnimationAndIdleAfter("fountain", "idle_unconscious");
        } else {
            this.unitView.sprite.setAnimation(0, "fountain", false);
            this.unitView.sprite.setSafeToRemove();

            delete this.unitView.clickRect;

            if (this.hoverHandler) {
                this.hoverHandler.remove();
                delete this.hoverHandler;
            }

            this.heroHover.setAnimation(0, "hover_out", false);
            this.heroHover.setCompleteListenerRemove();
            this.heroHover.setSafeToRemove();
            delete this.heroHover;

            this.unitView.sprite.setCompleteListener(function () {
                this.unitView.sprite.setAnimation(0, "wakeup", false);

                cleverapps.audio.playSound(bundles.merge.urls.tutorial_finish_sfx);

                this.unitView.sprite.setSafeToRemove();
                this.unitView.sprite.setCompleteListener(function () {
                    UnitView.applyDefaultAnimation(this.unitView.sprite, this.unitView.unit);
                }.bind(this));
            }.bind(this));
        }

        cleverapps.audio.playSound(bundles.merge.urls.tutorial_resuscitate_sfx);
    }
});

cleverapps.styles.HeroTutorialView = {
    wave: {
        offsetX: -260,
        offsetY: -120
    },

    unit: {
        clickRect: {
            x: -30,
            y: -10,
            width: 200,
            height: 120
        }
    },

    barrel: {
        offsetX: 20,
        offsetY: -105,

        clickRect: {
            x: -50,
            y: -40,
            width: 200,
            height: 150
        }
    }
};