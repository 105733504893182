/**
 * Created by mac on 5/25/21
 */

var HeroTutorial = function (unit) {
    UnitComponent.call(this, unit);

    this.stage = 0;
    this.isTutorialRunning = true;
    this.isHidden = true;

    this.unit.movable = false;
    this.animate = true;
};

HeroTutorial.prototype = Object.create(UnitComponent.prototype);
HeroTutorial.prototype.constructor = HeroTutorial;

HeroTutorial.prototype.start = function (callback) {
    cleverapps.focusManager.display({
        stack: true,
        focus: "customMergeTutorial",
        action: function (f) {
            this.finishTutorialStepHandler = Merge.currentMerge.tutorial.on("finishTutorialStep", function () {
                this.stage++;
                this.animate = true;
            }.bind(this));

            this.view.waveAnimation();

            cleverapps.timeouts.setTimeout(function () {
                Merge.currentMerge.tutorial.showTutorial(MergeTutorials.dwarf_hustlemerge, function () {
                    cleverapps.timeouts.setTimeout(function () {
                        f();
                        callback();

                        this.finish();
                    }.bind(this), 1000);
                }.bind(this));
            }.bind(this), 2000);
        }.bind(this)
    });
};

HeroTutorial.prototype.handleClick = function () {
    if (!this.animate) {
        return false;
    }

    if (this.stage < HeroTutorial.TOTAL_STAGES) {
        if (this.stage < HeroTutorial.REMOVE_DEBRIS_STAGES) {
            this.view.animateRemoveDebris();
        }

        this.view.animateResuscitation();
        this.animate = false;
    }

    return true;
};

HeroTutorial.prototype.finish = function () {
    this.isTutorialRunning = false;
    this.unit.movable = true;
    this.finishTutorialStepHandler.clear();
};

HeroTutorial.FINISH_ANIMATION_DURATION = 1000;
HeroTutorial.REMOVE_DEBRIS_STAGES = 3;
HeroTutorial.TOTAL_STAGES = 4;