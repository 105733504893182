/**
 * Created by razial on 22.05.2024.
 */

var WarehouseTile = cc.Node.extend({
    ctor: function (ingredient, onSelect) {
        this._super();

        var styles = cleverapps.styles.WarehouseTile;

        this.selected = false;
        this.ingredient = ingredient;
        this.onSelect = onSelect;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(styles.width, styles.height);

        var shadow = this.shadow = cleverapps.UI.createScale9Sprite(bundles.warehousewindow.frames.tile_shadow, cleverapps.UI.Scale9Rect.TwoPixelXY);
        shadow.setContentSize(this.width, this.height);
        shadow.setAnchorPoint(0.5, 0);
        shadow.setPosition(this.width / 2, 0);
        this.addChild(shadow);

        var background = this.background = cleverapps.UI.createScale9Sprite(bundles.warehousewindow.frames.tile_background);
        background.setContentSize(this.width, this.height);
        background.setAnchorPoint(0.5, 0);
        background.setPosition(this.width / 2, 0);
        this.addChild(background);

        var icon = Merge.currentMerge.harvested.getIngredientIcon(ingredient);
        cleverapps.UI.fitToBox(icon, {
            width: styles.icon.width,
            height: styles.icon.height,
            maxScale: 1.6
        });
        icon.setPositionRound(styles.icon);
        background.addChild(icon);

        var title = cleverapps.UI.generateOnlyText(Merge.currentMerge.harvested.getIngredientName(ingredient), cleverapps.styles.FONTS.WAREHOUSE_TILE_TITLE_TEXT);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        title.setDimensions(styles.title.width, 0);
        title.fitTo(undefined, styles.title.height);
        title.setPositionRound(styles.title);
        background.addChild(title);

        var amount = this.amount = cleverapps.UI.generateImageText(Merge.currentMerge.harvested.get(ingredient), cleverapps.styles.FONTS.WAREHOUSE_TILE_AMOUNT_TEXT);
        amount.fitTo(styles.amount.width);
        amount.setPositionRound(styles.amount);
        background.addChild(amount);

        var exchangeButton = this.exchangeButton = new cleverapps.UI.Button({
            text: "WarehouseWindow.exchange",
            width: styles.exchange.width,
            height: styles.exchange.height,
            onClicked: this.exchange.bind(this)
        });
        exchangeButton.setPositionRound(styles.exchange);
        exchangeButton.setVisible(false);
        this.addChild(exchangeButton);

        this.clickHandler = cleverapps.UI.onClick(this, this.onClick.bind(this));

        Merge.currentMerge.harvested.on("onChangeItems", this.onUpdateState.bind(this), this);
    },

    onUpdateState: function () {
        var styles = cleverapps.styles.WarehouseTile;

        var amount = Merge.currentMerge.harvested.get(this.ingredient);
        var exchangeMinimum = Merge.currentMerge.harvested.getExchangeMinimum();

        this.amount.setString(amount);
        this.amount.fitTo(styles.amount.width);
        this.amount.setPositionRound(styles.amount);

        this.exchangeButton.setVisible(this.selected && amount > exchangeMinimum);

        this.clickHandler.setEnabled(!this.selected);
    },

    onClick: function () {
        this.onSelect(this);
    },

    selectTile: function () {
        var styles = cleverapps.styles.WarehouseTile;

        this.selected = true;

        this.onUpdateState();

        this.background.stopAllActions();
        this.background.runAction(new cc.Spawn(
            new cc.ScaleTo(0.1, 1.1),
            new cc.MoveTo(0.1, this.width / 2 + styles.backgroundOffsetX, styles.backgroundOffsetY)
        ));

        this.shadow.stopAllActions();
        this.shadow.runAction(new cc.Spawn(
            new cc.ScaleTo(0.1, 1.1),
            new cc.MoveTo(0.1, this.width / 2 + styles.shadowOffsetX, styles.shadowOffsetY)
        ));
    },

    unselectTile: function () {
        this.selected = false;

        this.onUpdateState();

        this.background.stopAllActions();
        this.background.runAction(new cc.Spawn(
            new cc.ScaleTo(0.1, 1),
            new cc.MoveTo(0.1, this.width / 2, 0)
        ));

        this.shadow.stopAllActions();
        this.shadow.runAction(new cc.Spawn(
            new cc.ScaleTo(0.1, 1),
            new cc.MoveTo(0.1, this.width / 2, 0)
        ));
    },

    exchange: function () {
        if (Merge.currentMerge.harvested.canTake(this.ingredient, 1)) {
            Merge.currentMerge.harvested.take(this.ingredient, 1);

            var reward = new Reward("exp", 1);
            reward.receiveReward();
            reward.collectRewardsAnimation(this.amount);
        }
    }
});

cleverapps.styles.WarehouseTile = {
    width: 410,
    height: 540,

    backgroundOffsetX: 0,
    backgroundOffsetY: 60,

    shadowOffsetX: 14,
    shadowOffsetY: 30,

    title: {
        width: 360,
        height: 80,

        x: { align: "center" },
        y: { align: "center", dy: 200 }
    },

    icon: {
        x: { align: "center" },
        y: { align: "center" },
        width: 300,
        height: 400
    },

    amount: {
        width: 200,

        x: { align: "left", dx: 40 },
        y: { align: "bottom", dy: 40 }
    },

    exchange: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -10 },

        width: 360,
        height: 110
    }
};
